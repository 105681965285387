import { UseMutateFunction, useMutation, useQuery } from "@tanstack/react-query";
import { CreatePickupApi, ListCommanWay, GetCommanWay, UpdateCommanWay, CreateCommanWay } from "API/commonApi";
import { AxiosResponse } from "axios";
import Modal from "components/Modal/Modal";
import { useFormik } from "formik";
import { debounce } from "lodash";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Select from "react-select";
import UseToast from "utils/AfToast";
import { roles } from "utils/constant";
import { AfDataType as ADT } from "utils/TableColumnData";
import { number, object, string } from "yup";
import { PickupDatatable } from "./PickupDatatable";
import Spinner from "utils/Spinner";

const tableColumnData = [
  {
    label: "Sr. ",
    key: "sr_no",
    type: ADT.TEXT,
    view: true,
    add: false,
    edit: false,
    filter: false,
    sort: false,
  },
  {
    label: "Action",
    key: "id",
    render: (_) => {
      return `<a>${_}</a>`;
    },
    type: ADT.EDIT,
    view: true,
    add: false,
    edit: false,
    filter: false,
    sort: false,
  },
  {
    label: "Cust Code",
    key: "customer_code",
    type: ADT.TEXT,
    view: true,
    add: true,
    edit: true,
    filter: false,
    sort: false,
    bg_color_key: "status",
  },
  {
    label: "Reference Code",
    key: "pickup_code",
    type: ADT.TEXT,
    view: true,
    add: true,
    edit: true,
    filter: false,
    sort: false,
    bg_color_key: "status",
  },
  {
    label: "Date",
    key: "pickup_date_time",
    type: ADT.DATE,
    view: true,
    add: true,
    edit: true,
    filter: false,
    sort: false,
    dateFormat: "DD-MM-YYYY HH:mm:ss",
    bg_color_key: "status",
    css: "min-w-[100px]",
  },
  {
    label: "Reg. At",
    key: "createdAt",
    type: ADT.DATETIME,
    view: true,
    dateFormat: "DD-MM-YYYY HH:mm:ss",
    add: false,
    edit: false,
    filter: false,
    sort: false,
    bg_color_key: "status",
    css: "min-w-[100px]",
  },
  {
    label: "Project Code",
    key: "project_code",
    type: ADT.TEXT,
    view: true,
    add: true,
    edit: true,
    filter: false,
    sort: false,
    bg_color_key: "status",
  },
  {
    label: "Shipper",
    key: "shipper_sh",
    type: ADT.TEXT,
    view: true,
    add: true,
    edit: true,
    filter: false,
    sort: false,
    bg_color_key: "status",
  },
  {
    label: "Consignee",
    key: "consignee_cgnee",
    type: ADT.TEXT,
    view: true,
    add: true,
    edit: true,
    filter: false,
    sort: false,
    bg_color_key: "status",
  },
  {
    label: "Origin",
    key: "shipper_city",
    type: ADT.TEXT,
    view: true,
    add: true,
    edit: true,
    filter: false,
    sort: false,
    bg_color_key: "status",
  },
  {
    label: "Dest.",
    key: "consignee_city",
    type: ADT.TEXT,
    view: true,
    add: true,
    edit: true,
    filter: false,
    sort: false,
    bg_color_key: "status",
  },
  {
    label: "Pieces",
    key: "quantity",
    type: ADT.TEXT,
    view: true,
    add: true,
    edit: true,
    filter: false,
    sort: false,
    bg_color_key: "status",
  },
  {
    label: "Weight",
    key: "weight",
    type: ADT.TEXT,
    view: true,
    add: true,
    edit: true,
    filter: false,
    sort: false,
    bg_color_key: "status",
  },
  {
    label: "Booked AWB",
    key: "foundAwbs",
    type: ADT.TEXT,
    view: true,
    bg_color_key: "status",
  },
  {
    label: "Emp Name",
    key: "assgined_to_agent_name",
    type: ADT.DB,
    db: "agent",
    db_col: "agent_name",
    bg_color_key: "status",
    view: true,
    add: true,
    edit: true,
    filter: false,
    sort: false,
  },
  {
    label: "Emp Mobile No",
    key: "assgined_to_agent_name",
    type: ADT.DB,
    db: "agent",
    db_col: "contact",
    bg_color_key: "status",
    view: true,
    add: true,
    edit: true,
    filter: false,
    sort: false,
  },
  {
    label: "Status",
    key: "status",
    type: ADT.SELECT,
    bg_color_key: "status",
    bg_color: {
      0: "",
      1: "green-200",
      2: "red-200",
      3: "yellow-200",
    },
    options: [
      { value: 0, label: "Pickup Processed" },
      { value: 1, label: "Pickup Done" },
      { value: 2, label: "Pickup Cancel" },
      { value: 3, label: "Pickup Reschedule" },
      { value: 4, label: "Confirmed" },
    ],
    view: true,
    add: true,
    edit: true,
    filter: false,
    sort: false,
  },
  {
    label: "Remark",
    key: "status_reason",
    type: ADT.TEXT,
    view: true,
    bg_color_key: "status",
  },
  {
    label: "Last Update",
    key: "updatedBy",
    type: ADT.DB,
    db: "user",
    db_col: "name",
    bg_color_key: "status",
    view: true,
    add: true,
    edit: true,
    filter: false,
    sort: false,
  },
];

const ToPayPickpAdd: React.FC = () => {
  let isDisabled = false;
  const navigate = useNavigate();

  const [typeOpt, setTypeOtp] = useState<any>();
  const [ten_30Opt, setTen_30Opt] = useState<any>();
  const [holidayOpt, setHolidayOtp] = useState<any>();
  const [branchOpt, setBranchOtp] = useState<any>();
  const [subbranchOpt, setSubBranchOtp] = useState<any>();
  const [productOpt, setProductOtp] = useState<any>();
  const [agentOpt, setAgentOtp] = useState<any>();
  const [isBranchSet, setIsBranchSet] = useState<any>();
  const [pincodeSection, setPincodeSection] = useState<any>("");
  const [mobileSection, setMobileSection] = useState<any>("");
  const [pickupCode, setPickupCode] = useState("");
  const [isDataAdded, setIsDataAdded] = useState<any>(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [editMode, setEditMode] = useState(false);
  const [selectedData, setSelectedData] = useState<any>({});
  const [filters, setFilters] = useState<any>({
    filter_date: moment().format("YYYY-MM-DD"),
    filter_date_to: moment().format("YYYY-MM-DD"),
  });
  const [userDetails, setUserDetails] = useState<any>({});

  const [old_pickup_code, setold_pickup_code] = useState<any>("");

  const [statusReason, setStatusReason] = useState<any>();
  const [selectedStatus, setSelectedStatus] = useState<any>({ value: -1, label: "" });
  const [rescheduleDate, setRescheduleDate] = useState<any>();

  const [employeeWapp, setEmployeeWapp] = useState<any>("");

  const [pickupDate, setPickupDate] = useState<any>(moment().format("YYYY-MM-DD"));
  const [pickupTime, setPickupTime] = useState<any>(moment().format("HH:mm"));

  useEffect(() => {
    const userD = localStorage.getItem("auth") ? JSON.parse(localStorage.getItem("auth")) : {};
    if (Object.keys(userD).length) {
      setUserDetails(userD.state.user);
      console.log("userD.state", userD.state.user);
      if (userD.state.user.roleName !== roles.SUPER_ADMIN && userD.state.user.roleName !== roles.BRANCH_ADMIN) {
        setBranchOtp(userD.state.user.branch_name);
        setSubBranchOtp(userD.state.user.branch_name);

        mutateListAgents({ label: "-", value: userD.state.user?.subbranch });
      }
    }
  }, []);

  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const typeOptions: any = [
    { value: "DOX", label: "DOX" },
    { value: "NON-DOX", label: "NON-DOX" },
  ];
  const ten_30Options: any = [
    { value: 1, label: "Yes" },
    { value: 0, label: "No" },
  ];
  const holidayOptions: any = [
    { value: 1, label: "Yes" },
    { value: 0, label: "No" },
  ];

  const statusOptions: any = [
    { value: 0, label: "0 - Pickup Processed" },
    { value: 1, label: "1 - Pickup Done" },
    { value: 2, label: "2 - Pickup Cancel" },
    { value: 3, label: "3 - Pickup Reschedule" },
    { value: 4, label: "4 - Confirmed" },
  ];

  const [productOptions, setProductOptions] = useState<any>([{ value: 0, label: "Loading..." }]);

  const { error, isFetching, isError, isFetched, isLoading, refetch } = useQuery(
    ["ProductsListApi"],
    () => ListCommanWay("/admin/product/list", {}, { paginate: 1000 }),
    {
      keepPreviousData: true,
      onSuccess(data) {
        if (data.status == "SUCCESS") {
          let opt = [];
          data.data.data.forEach((p) => {
            opt.push({
              value: p.id,
              label: p.product_name,
            });
          });
          setProductOptions(opt);
        }
      },
    }
  );

  const [subbranchOptions, setSubBranchOptions] = useState<any>([{ value: 0, label: "Loading..." }]);

  const {
    error: subbranchError,
    isFetching: subbranchIsFetching,
    isError: subbranchIsError,
    isFetched: subbranchIsFetched,
    isLoading: subbranchIsLoading,
    refetch: subbranchRefetch,
  } = useQuery(["SubBranchesListApi"], () => ListCommanWay("/admin/subbranch/list", {}, { paginate: 1000 }), {
    keepPreviousData: true,
    onSuccess(data) {
      if (data.status == "SUCCESS") {
        let opt = [];
        data.data.data.forEach((p) => {
          opt.push({
            value: p.id,
            label: p.branch_name,
          });
        });
        setSubBranchOptions(opt);
      }
    },
  });

  const [branchOptions, setBranchOptions] = useState<any>([{ value: 0, label: "Loading..." }]);

  const {
    error: branchError,
    isFetching: branchIsFetching,
    isError: branchIsError,
    isFetched: branchIsFetched,
    isLoading: branchIsLoading,
    refetch: branchRefetch,
  } = useQuery(["BranchesListApi"], () => ListCommanWay("/admin/subbranch/list", {}, { paginate: 1000 }), {
    keepPreviousData: true,
    onSuccess(data) {
      if (data.status == "SUCCESS") {
        let opt = [];
        data.data.data.forEach((p) => {
          opt.push({
            value: p.id,
            label: p.branch_name,
          });
        });
        setBranchOptions(opt);
      }
    },
  });
  const [agentOptions, setAgentOptions] = useState<any>([{ value: 0, label: "Loading..." }]);

  useEffect(() => {
    if (isBranchSet) {
      mutateListAgents(values.branch_name);
    }
  }, [isBranchSet]);

  const { mutate: mutateListAgents, isLoading: isAgentsloading }: any = useMutation(
    (qry: any) => ListCommanWay("/admin/agent/list", { subbranch: /*isBranchSet*/ qry?.value }, { paginate: 1000 }),
    {
      onSuccess: (data: any) => {
        if (data.status == "SUCCESS") {
          let opt = [];
          data.data.data.forEach((p) => {
            opt.push({
              value: p.id,
              label: p.agent_name,
            });
          });
          setAgentOptions(opt);
        }
      },
      onError: (data: any) => {
        typeof data === "string" ? UseToast(data, "error") : UseToast(data.message, "error");
      },
    }
  );

  const { handleSubmit, handleBlur, values, handleChange, errors, setValues, setErrors, touched, dirty, setFieldValue, resetForm } =
    useFormik({
      validationSchema: object().shape({}),
      initialValues: {
        date: moment().format("YYYY-MM-DD"),
        pickup_date_time: moment().format("YYYY-MM-DD HH:mm"),
        doSendWapp: true,
        quantity: "",
        type: { value: 0 },
        customer_code: "",
        weight: 0,
        ten_30: { value: 0 },
        project_code: "",
        product_name: { value: 0, label: "" },
        holiday: { value: 0, label: "" },

        customer_pincode: "",
        customer_mobile: "",
        customer_sh: "",
        customer_city: "",
        customer_state: "",
        customer_country: "",
        customer_address: "",
        change_consignee: false,
        consignee_pincode: "",
        consignee_mobile: "",
        consignee_cgnee: "",
        consignee_city: "",
        consignee_state: "",
        consignee_country: "",
        consignee_address: "",

        shipper_pincode: "",
        shipper_mobile: "",
        shipper_sh: "",
        shipper_city: "",
        shipper_state: "",
        shipper_country: "",
        shipper_pan: "",
        shipper_gst_no: "",
        shipper_address: "",

        dice_weight: 0,
        oda_charge: 0,
        pkg_charge: 0,
        pkg1Charge: 0,
        pkg2Charge: 0,
        pkg3Charge: 0,
        d_ice_charge: 0,

        branch_id: { value: 0, label: "" },
        subbranch: { value: 0, label: "" },
        branch_name: { value: 0, label: "" },
        assgined_to_agent_name: { value: 0, label: "" },
      },
      onSubmit: async (values, { resetForm }) => {
        let req: any = { ...values };
        req.pickup_date_time = pickupDate + "T" + pickupTime;
        req.type = req.type ? req.type.value : undefined;
        req.product_name = req.product_name ? req.product_name.label : undefined;
        req.assgined_to_branch_name = req.branch_name ? req.branch_name.value : null;
        userDetails?.roleName !== roles.SUPER_ADMIN && userDetails?.roleName !== roles.BRANCH_ADMIN
          ? (req.subbranch = userDetails.subbranch)
          : (req.subbranch = req.subbranch ? req.subbranch.value : null);

        // req.subbranch = req.subbranch ? req.subbranch.value : null;
        req.assgined_to_agent_name = req.assgined_to_agent_name ? req.assgined_to_agent_name.value : null;
        if (!req.assgined_to_agent_name) {
          delete req.assgined_to_agent_name;
        }
        if (req.assgined_to_branch_name == 0) {
          delete req.assgined_to_branch_name;
        }

        if (req.status == undefined || req.status.value == -1) {
          delete req.status;
        }

        req.ten_30 = req.ten_30 === 1 ? true : false;
        req.holiday = req.holiday === 1 ? true : false;

        req.weight = parseFloat(req.weight);
        req.customer_mobile = req.customer_mobile.toString();

        req.oda_charge = parseFloat(req.oda_charge);
        req.pkg_charge = parseFloat(req.pkg_charge);
        req.d_ice_charge = parseFloat(req.d_ice_charge);

        if (!req.assgined_by_third_party) {
          delete req.assgined_by_third_party;
        }
        if (!req.assgined_by_branch) {
          delete req.assgined_by_branch;
        }
        if (!req.shipper_sms_disable) {
          delete req.shipper_sms_disable;
        }
        let toDeleteBlank = ["dice_weight", "d_ice_charge", "dg_charge", "pkg_charge", "pkg1Charge", "pkg2Charge", "pkg3Charge"];
        toDeleteBlank.forEach((x) => {
          if (!req[x]) {
            req[x] = 0;
          }
        });

        if (req.customer_code == "") {
          if (!window.confirm("Customer Code is Blank, Click ok to continue, Click Cancel to Edit Customer?")) {
            return;
          }
        }
        if (req.shipper_pincode == "" || req.shipper_city == "") {
          return alert("Shipper Pincode / City is missing");
        }
        mutate({
          url: "/admin/pickup" + (editMode ? "/update/" + selectedData.id : "/create"),
          data: req,
        });
      },
    });

  const changeStatus = () => {
    mutate({
      url: "/admin/pickup/status/" + selectedData.id,
      data: {
        status: selectedStatus.value,
        status_reason: statusReason,
        pickup_date_time: rescheduleDate != "" ? rescheduleDate : undefined,
      },
    });
  };

  const {
    mutate,
    isLoading: isloading,
  }: {
    mutate: UseMutateFunction<AxiosResponse<any, any>, unknown, any, unknown>;
    isLoading: any;
  } = useMutation(editMode ? UpdateCommanWay : CreateCommanWay, {
    onSuccess: (data: any) => {
      if (data.status == "SUCCESS") {
        window.location.reload();
        return;
        if (!editMode) {
          UseToast("Data Added Successfully", "success");
          setIsDataAdded(!isDataAdded);
          setPickupCode(data.data.pickup_code);
          openModal();
          setEditMode(false);
          resetForm();
          setSelectedStatus("");
          setStatusReason("");
          setRescheduleDate("");
        } else {
          UseToast("Data Updated Successfully", "success");
          setIsDataAdded(!isDataAdded);
          setEditMode(false);
          resetForm();
          setSelectedStatus("");
          setStatusReason("");
          setRescheduleDate("");
        }
      } else {
        UseToast(data.message, "error");
      }
    },
    onError: (data: any) => {
      typeof data === "string" ? UseToast(data, "error") : UseToast(data.message, "error");
    },
  });

  const {
    mutate: mutatePincode,
    isLoading: isPincodeloading,
  }: {
    mutate: UseMutateFunction<AxiosResponse<any, any>, unknown, any, unknown>;
    isLoading: any;
  } = useMutation((pincode) => GetCommanWay("/admin/address/" + pincode, {}, {}), {
    onSuccess: (data: any) => {
      if (data.status === "SUCCESS") {
        Object.keys(data.data).map((d) => {
          values[pincodeSection + "_" + d] = data.data[d];
        });
        return data.data;
      }
    },
    onError: (data: any) => {
      typeof data === "string" ? UseToast(data, "error") : UseToast(data.message, "error");
      values[pincodeSection + "_city"] = "";
      values[pincodeSection + "_country"] = "";
      values[pincodeSection + "_state"] = "";
      return { city: "", country: "", pincode: "", region: "", state: "" };
    },
  });
  const { mutate: mutateSavedMobile }: any = useMutation((code) => ListCommanWay("/admin/savedcustomer/list/", { mobile: code }, {}), {
    onSuccess: (data: any) => {
      if (data.status === "SUCCESS") {
        if (data.data?.data?.length > 0) {
          console.log(mobileSection.split("|")[1]);
          if (mobileSection.split("|")[1] == "customer_mobile") {
            setFieldValue("customer_sh", data.data.data[0].name);
            setFieldValue("customer_address", data.data.data[0].address);
          } else if (mobileSection.split("|")[1] == "consignee_mobile") {
            setFieldValue("consignee_cgnee", data.data.data[0].name);
            setFieldValue("consignee_address", data.data.data[0].address);
          } else if (mobileSection.split("|")[1] == "shipper_mobile") {
            setFieldValue("shipper_sh", data.data.data[0].name);
            setFieldValue("shipper_address", data.data.data[0].address);
          }
        }

        return data.data;
      }
    },
    onError: (data: any) => {
      //typeof data === "string" ? UseToast(data, "error") : UseToast(data.message, "error");
    },
  });
  useEffect(() => {
    if (mobileSection) {
      mutateSavedMobile(mobileSection.split("|")[0]);
    }
  }, [mobileSection]);

  const { mutate: mutateCustomer }: any = useMutation((code) => GetCommanWay("/admin/customer/get/" + code, {}, {}), {
    onSuccess: (data: any) => {
      if (data.status === "SUCCESS") {
        Object.keys(values).map((d) => {
          if (d.split("customer_")[1]) {
            console.log(d.split("customer_")[1], values["customer_" + d.split("customer_")[1]], data.data[d.split("customer_")[1]]);
            values["customer_" + d.split("customer_")[1]] = data.data[d.split("customer_")[1]];
          }
        });
        return data.data;
      }
    },
    onError: (data: any) => {
      typeof data === "string" ? UseToast(data, "error") : UseToast(data.message, "error");
    },
  });

  const getInput: any = (type, label, value, name, isDisabled, width) => {
    let ip = <div></div>;

    switch (type) {
      case ADT.NUMBER:
        ip = (
          <div className={`inline-flex items-center gap-2 ${width ? width : "w-auto"}`}>
            <label className="text-sm font-semibold text-gray-900">{label}</label>
            <input
              type="number"
              className={`${
                isDisabled ? "bg-gray-300" : ""
              } bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-1`}
              placeholder={label}
              name={name}
              value={values[name]}
              onBlur={handleBlur}
              onChange={handleChange}
              disabled={isDisabled}
            />
          </div>
        );
        break;
      case ADT.TEXT:
        ip = (
          <div className={`inline-flex items-center gap-2 ${width ? width : "w-auto"}`}>
            <label className="text-sm font-semibold text-gray-900">{label}</label>
            <input
              type="text"
              className={`${
                isDisabled ? "bg-gray-300" : ""
              } bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-1`}
              placeholder={label}
              name={name}
              value={values[name]}
              onBlur={async (e) => {
                handleBlur(e);
                if (label === "Pincode") {
                  setPincodeSection(e.target.name.split("_")[0]);
                  const responseData = mutatePincode(e.target.value);
                }
                if (name === "customer_code") {
                  const responseData = mutateCustomer(e.target.value);
                }

                if (name === "customer_mobile" || name === "consignee_mobile" || name === "shipper_mobile") {
                  setMobileSection(e.target.value + "|" + name);
                  //const responseData = mutateSavedMobile(e.target.value);
                }
              }}
              onChange={handleChange}
              disabled={isDisabled}
            />
          </div>
        );
        break;
      case ADT.DB:
        ip = (
          <div className={`inline-flex items-center gap-2 ${width ? width : "w-auto"}`}>
            <label className="text-sm font-semibold text-gray-900">{label}</label>
            <Select
              className={`${isDisabled ? "bg-gray-300" : ""} bg-gray-50 border-gray-300 text-gray-900 text-sm rounded-lg block w-full `}
              options={value.all}
              onBlur={handleBlur}
              onChange={(e) => {
                if (name === "branch_name") setIsBranchSet(e.value);

                setFieldValue(name, e);
              }}
              name={name}
              isDisabled={isDisabled}
              value={values[name]}
              styles={{
                control: (provided, state) => ({
                  ...provided,
                  minHeight: "30px",
                  height: "30px",
                }),
                valueContainer: (provided, state) => ({
                  ...provided,
                  height: "30px",
                  padding: "0 6px",
                }),
                input: (provided, state) => ({
                  ...provided,
                  margin: "0px",
                }),

                indicatorsContainer: (provided, state) => ({
                  ...provided,
                  height: "30px",
                }),
              }}
            />
          </div>
        );
        break;
      case ADT.TEXTAREA:
        break;
      case ADT.DATETIME:
        ip = (
          <div className={`inline-flex items-center gap-2 ${width ? width : "w-auto"}`}>
            <label className="text-sm font-semibold text-gray-900">{label}</label>
            <input
              type="datetime-local"
              className={`${
                isDisabled ? "bg-gray-300" : ""
              } bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-1`}
              placeholder={label}
              name={name}
              value={values[name].split("T")[0]}
              onBlur={handleBlur}
              onChange={handleChange}
              disabled={isDisabled}
            />
          </div>
        );
        break;
      case ADT.DATE:
        ip = (
          <div className={`inline-flex items-center gap-2 ${width ? width : "w-auto"}`}>
            <label className="text-sm font-semibold text-gray-900">{label}</label>
            <input
              type="date"
              className={`${
                isDisabled ? "bg-gray-300" : ""
              } bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-1`}
              placeholder={label}
              name={name}
              value={values[name]}
              onBlur={handleBlur}
              onChange={handleChange}
              disabled={isDisabled}
            />
          </div>
        );
        break;
      case ADT.FILE:
        break;
      case ADT.CHECKBOX:
        ip = (
          <div className={`inline-flex items-center border border-black-900 p-1 rounded-md gap-0.5 ${width ? width : "w-auto"}`}>
            <label className="text-sm font-semibold text-gray-900">{label}</label>
            <input
              type="checkbox"
              name={name}
              checked={values[name] != "0" && values[name]}
              onBlur={(e) => {
                setFieldValue(name, e.target.checked ? 1 : "");
              }}
              onChange={(e) => {
                setFieldValue(name, e.target.checked ? 1 : "");
              }}
              className={`w-5 h-5 focus:border-black-900 p-1 rounded-md`}
            />
          </div>
        );
        break;
      default:
        ip = <></>;
        break;
    }
    return ip;
  };

  const handleEdit = (_data) => {
    let data = { ..._data };
    console.log("data", data);

    console.log(data.pickup_date_time.substring(0, 4).includes("-"), data.pickup_date_time);
    if (data.pickup_date_time.substring(0, 4).includes("-")) {
      //data.pickup_date_time = moment(data.pickup_date_time, "DD-MM-YYYY HH:mm:ss").format("YYYY-MM-DD HH:mm");
      setPickupDate(moment(data.pickup_date_time, "DD-MM-YYYY HH:mm:ss").format("YYYY-MM-DD"));
      setPickupTime(moment(data.pickup_date_time, "DD-MM-YYYY HH:mm:ss").format("HH:mm"));
    } else {
      //data.pickup_date_time = moment(data.pickup_date_time, "YYYY-MM-DD HH:mm").format("YYYY-MM-DD HH:mm");
      setPickupDate(moment(data.pickup_date_time, "YYYY-MM-DD HH:mm").format("YYYY-MM-DD"));
      setPickupTime(moment(data.pickup_date_time, "YYYY-MM-DD HH:mm").format("HH:mm"));
    }

    setEmployeeWapp(data?.whatsapp_copy);

    setSelectedData(data);
    setEditMode(true);
    Object.keys(data).map((d) => {
      if (Object.keys(values).includes(d)) {
        if (["holiday", "product_name", "ten_30", "type", "subbranch"].includes(d)) {
          /*console.log("{value: data[d],label: data[d] === 0 ?  : data[d] === 1 ? [d],", d, {
            value: data[d] === 0 || data[d] === false ? 0 : data[d] === 1 || data[d] === true ? 1 : data[d],
            label: data[d] === 0 || data[d] === false ? "No" : data[d] === 1 || data[d] === true ? "Yes" : data[d],
          });*/

          setFieldValue(d, {
            value: data[d] === 0 || data[d] === false ? 0 : data[d] === 1 || data[d] === true ? 1 : data[d],
            label: data[d] === 0 || data[d] === false ? "No" : data[d] === 1 || data[d] === true ? "Yes" : data[d],
          });
          if (d === "subbranch" && (userDetails?.roleName === roles.SUPER_ADMIN || userDetails?.roleName === roles.BRANCH_ADMIN)) {
            setFieldValue(d, {
              value: data[d] === 0 || data[d] === false ? 0 : data[d] === 1 || data[d] === true ? 1 : data[d],
              label: data[d] === 0 || data[d] === false ? "No" : data[d] === 1 || data[d] === true ? "Yes" : data[d],
            });
          }
        } else {
          console.log(d);
          if (d == "dice_weight") {
            console.log("dice_weight", data["dice_weight"]);
          }
          //console.log(d);
          setFieldValue(d, data[d]);
        }
      }
    });
  };

  const {
    mutate: mutatePickup,
    isLoading: isPickuploading,
  }: {
    mutate: UseMutateFunction<AxiosResponse<any, any>, unknown, any, unknown>;
    isLoading: any;
  } = useMutation((pickup_code) => ListCommanWay("/admin/pickup/list", { pickup_code }, {}), {
    onSuccess: async (data: any) => {
      if (data.status === "SUCCESS") {
        if (data.data?.data?.length > 0) {
          let pdata = data.data.data[0];

          /*typeOptions.forEach((x) => {
            x.value == pdata.type && setFieldValue("type", x);
          });*/
          ten_30Options.forEach((x) => {
            x.value == pdata.ten_30 && setFieldValue("ten_30", x);
          });
          holidayOptions.forEach((x) => {
            x.value == pdata.holiday && setFieldValue("holiday", x);
          });

          /*productOptions.forEach((x) => {
            if (x.label == pdata.product_name) {
              console.log(x.label, pdata.product_name, x);
              setFieldValue("product_name", x);
            }
          });*/

          Object.keys(pdata).map((obj) => {
            if (
              ![
                "type",
                "createdAt",
                "product_name",
                "ten_30",
                "holiday",
                "type",
                "branch_id",
                "subbranch",
                "branch_name",
                "assgined_to_agent_name",
                "quantity",
                "project_code",
                "weight",
                "status",
                "status_reason",
                "whatsapp_copy",
              ].includes(obj)
            ) {
              setFieldValue(obj, pdata[obj]);
              //values[obj] = pdata[obj];
            }
          });
        }
      }
    },
    onError: (data: any) => {
      //typeof data === "string" ? UseToast(data, "error") : UseToast(data.message, "error");
    },
  });

  return (
    <>
      {isloading && <Spinner />}
      <h1 className="text-center my-0 text-lg font-semibold underline">Topay Pickup</h1>
      <Modal
        isOpen={isModalOpen}
        onClose={
          /*closeModal*/ () => {
            window.location.reload();
          }
        }
      >
        <h2 className="text-2xl font-bold mb-4">New pickup is added</h2>
        <p>Code: {pickupCode}</p>
      </Modal>

      <form
        onSubmit={(e) => {
          e.preventDefault();
          handleSubmit(e);
        }}
      >
        <div className="grid grid-cols-2 sm:grid-cols-1 gap-0.5 px-4">
          <div className="flex gap-x-1 gap-y-[1px] flex-wrap border p-1 rounded-xl bg-gray-200 border-blue-600">
            <div className="w-full font-semibold bg-blue-900 bg-opacity-20 flex items-center px-2 rounded-lg h-6">
              Pickup Reg. Form |
              <div className={`inline-flex items-center gap-2`}>
                <label className="text-sm font-semibold text-gray-900 whitespace-nowrap"> &gt; Reference Code</label>
                <input
                  type="text"
                  className={`bg-gray-50 border  text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full px-1 py-0.5`}
                  value={old_pickup_code}
                  onBlur={(e) => {
                    if (e.target.value) {
                      mutatePickup(e.target.value || "");
                    }
                  }}
                  onChange={(e) => {
                    setold_pickup_code(e.target.value);
                  }}
                />
              </div>
            </div>

            {Object.keys(userDetails).length &&
            (userDetails?.roleName === roles.SUPER_ADMIN || userDetails?.roleName === roles.BRANCH_ADMIN)
              ? getInput(
                  ADT.DB,
                  "Sub-branch",
                  { sel: subbranchOpt, all: subbranchOptions },
                  "subbranch",
                  isDisabled,
                  "w-[calc(33.33%-6px)]"
                )
              : null}
            {/*getInput(ADT.DATETIME, "Date", "", "pickup_date_time", isDisabled, "")*/}

            <div className={`inline-flex items-center gap-2 w-[280px]`}>
              <label className="text-sm font-semibold text-gray-900">Date</label>
              <input
                type="date"
                className={`${
                  isDisabled ? "bg-gray-300" : ""
                } bg-red-300 border-2 border-primary text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-1`}
                placeholder={"Date"}
                name={"pickup_date_only"}
                min={editMode ? undefined : moment().format("YYYY-MM-DD")}
                value={pickupDate}
                onChange={(e) => {
                  setPickupDate(e.target.value);
                }}
                disabled={isDisabled}
              />
              <input
                type="time"
                className={`${
                  isDisabled ? "bg-gray-300" : ""
                } bg-red-300 border-2 border-primary text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-1`}
                placeholder={"Date"}
                name={"pickup_time_only"}
                value={pickupTime}
                onChange={(e) => {
                  setPickupTime(e.target.value);
                }}
                disabled={isDisabled}
              />
            </div>
            {getInput(ADT.TEXT, "Customer", "", "customer_code", isDisabled, "w-[calc(33.33%-6px)]")}

            {getInput(ADT.NUMBER, "Qty", "", "quantity", isDisabled, "w-[calc(23.33%-6px)]")}
            {getInput(ADT.DB, "Type", { sel: typeOpt, all: typeOptions }, "type", isDisabled, "w-[calc(33.33%-6px)]")}

            {getInput(ADT.NUMBER, "Weight", "", "weight", isDisabled, "w-[calc(33.33%-6px)]")}
            {getInput(ADT.DB, "10:30", { sel: ten_30Opt, all: ten_30Options }, "ten_30", isDisabled, "w-[calc(33.33%-6px)]")}

            {getInput(ADT.TEXT, "Project_code", "", "project_code", isDisabled, "w-[calc(33.33%-6px)]")}
            {getInput(ADT.DB, "Product", { sel: productOpt, all: productOptions }, "product_name", isDisabled, "w-[calc(33.33%-6px)]")}
            {getInput(ADT.DB, "Holiday", { sel: holidayOpt, all: holidayOptions }, "holiday", isDisabled, "w-[calc(33.33%-6px)]")}

            {getInput(ADT.NUMBER, "Dry··Ice··WT.", "", "dice_weight", isDisabled, "w-[calc(33.33%-6px)]")}
            {/*getInput(ADT.CHECKBOX, "Dry_Ice", "", "d_ice_charge", isDisabled, "w-[80px]")*/}
            {getInput(ADT.CHECKBOX, "DG_Chg", "", "dg_charge", isDisabled, "w-[82px]")}
            {getInput(ADT.CHECKBOX, "Pkg_Chg", "", "pkg_charge", isDisabled, "w-[86px]")}
            {getInput(ADT.CHECKBOX, "1LT", "", "pkg1Charge", isDisabled, "w-[56px]")}
            {getInput(ADT.CHECKBOX, "4LT", "", "pkg2Charge", isDisabled, "w-[56px]")}
            {getInput(ADT.CHECKBOX, "10LT", "", "pkg3Charge", isDisabled, "w-[62px]")}

          </div>

          <div className="flex gap-x-1 gap-y-[1px] items-start flex-wrap border p-1 rounded-xl bg-gray-200 border-blue-600">
            <div className="w-full font-semibold bg-blue-900 bg-opacity-20 flex items-center px-2 rounded-lg h-6">Shipper Information</div>
            {getInput(ADT.TEXT, "Pincode", "", "shipper_pincode", isDisabled, "w-[125px]")}
            {getInput(ADT.TEXT, "Mobile", "", "shipper_mobile", isDisabled, "w-[140px]")}
            {getInput(ADT.TEXT, "S/H", "", "shipper_sh", isDisabled, "w-[calc(33.33%-6px)]")}
            {getInput(ADT.TEXT, "PAN", "", "shipper_pan", isDisabled, "w-[140px]")}

            {getInput(ADT.TEXT, "City", "", "shipper_city", true, "w-[calc(25%-6px)]")}
            {getInput(ADT.TEXT, "State", "", "shipper_state", true, "w-[calc(25%-6px)]")}
            {getInput(ADT.TEXT, "Country", "INDIA", "shipper_country", true, "w-[calc(150px)]")}

            {getInput(ADT.TEXT, "GST", "", "shipper_gst_no", isDisabled, "w-[180px]")}

            {getInput(ADT.TEXT, "Address", "", "shipper_address", isDisabled, "w-full")}
          </div>

          <div className="flex gap-x-1 gap-y-[1px] flex-wrap border p-1 rounded-xl bg-gray-200 border-blue-600">
            <div className="w-full font-semibold bg-blue-900 bg-opacity-20 flex items-center px-2 rounded-lg h-6">Customer Information</div>
            {getInput(ADT.TEXT, "Pincode", "", "customer_pincode", isDisabled, "w-[calc(33.33%-6px)]")}
            {getInput(ADT.TEXT, "Mobile", "", "customer_mobile", isDisabled, "w-[calc(33.33%-6px)]")}
            {getInput(ADT.TEXT, "S/H", "", "customer_sh", isDisabled, "w-[calc(33.33%-6px)]")}

            {getInput(ADT.TEXT, "City", "", "customer_city", true, "w-[calc(33.33%-6px)]")}
            {getInput(ADT.TEXT, "State", "", "customer_state", true, "w-[calc(33.33%-6px)]")}
            {getInput(ADT.TEXT, "Country", "INDIA", "customer_country", true, "w-[calc(33.33%-6px)]")}

            {getInput(ADT.TEXT, "Address", "", "customer_address", isDisabled, "w-full")}
          </div>

          <div className="flex gap-x-1 gap-y-[1px] flex-wrap border p-1 rounded-xl bg-gray-200 border-blue-600">
            <div className="w-full font-semibold bg-blue-900 bg-opacity-20 flex items-center px-2 rounded-lg h-6">
              Consignee Information
            </div>
            {getInput(ADT.TEXT, "Pincode", "", "consignee_pincode", isDisabled, "w-[calc(33.33%-6px)]")}
            {getInput(ADT.TEXT, "Mobile", "", "consignee_mobile", isDisabled, "w-[calc(33.33%-6px)]")}
            {getInput(ADT.TEXT, "Cgnee", "", "consignee_cgnee", isDisabled, "w-[calc(33.33%-6px)]")}

            {getInput(ADT.TEXT, "City", "", "consignee_city", true, "w-[calc(33.33%-6px)]")}
            {getInput(ADT.TEXT, "State", "", "consignee_state", true, "w-[calc(33.33%-6px)]")}
            {getInput(ADT.TEXT, "Country", "INDIA", "consignee_country", true, "w-[calc(33.33%-6px)]")}

            {getInput(ADT.TEXT, "Address", "", "consignee_address", isDisabled, "w-full")}
          </div>

          <div className="flex gap-1 flex-wrap border p-1 rounded-xl bg-gray-200 border-blue-600">
            {/*getInput(ADT.TEXT, "ODA_Chg", "", "oda_charge", isDisabled, "w-[calc(33%-6px)]")*/}

            
          </div>
          <div className="flex gap-1 col-span-1 items-center flex-wrap border p-1 rounded-xl bg-gray-200 border-blue-600">
            <div className="font-semibold bg-blue-900 bg-opacity-20 flex items-center px-2 rounded-lg h-6">Assign Employee</div>
            {getInput(
              ADT.DB,
              "",
              { sel: branchOpt, all: branchOptions },
              "branch_name",
              isDisabled ||
                userDetails.role_name == 3 ||
                userDetails.role_name == 4 ||
                userDetails.role_name == 5 ||
                userDetails.role_name == 9,
              "w-[calc(33.33%-6px)]"
            )}
            {getInput(ADT.DB, "", { sel: agentOpt, all: agentOptions }, "assgined_to_agent_name", isDisabled, "w-[calc(33.33%-6px)]")}
          </div>
          <div className="flex gap-1 col-span-2 items-center justify-between flex-wrap border p-1 rounded-xl bg-gray-200 border-blue-600">
            <div className="flex gap-1 col-span-2 items-center flex-wrap ">
              <div className="font-semibold bg-blue-900 bg-opacity-20 flex items-center px-2 rounded-lg h-8">Status</div>

              <div className={`inline-flex items-center gap-2`}>
                <Select
                  className={` bg-gray-50 border-gray-300 text-gray-900 text-sm rounded-lg block w-[200px] `}
                  options={statusOptions}
                  onChange={(e) => {
                    setSelectedStatus(e);
                  }}
                  value={selectedStatus}
                />
              </div>

              <div className={`inline-flex items-center gap-2`}>
                <label className="text-sm font-semibold text-gray-900"> Remark:</label>
                <input
                  type="text"
                  className={`bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-1`}
                  value={statusReason}
                  onChange={(e) => {
                    setStatusReason(e.target.value);
                  }}
                />
              </div>
              {selectedStatus.value == 3 && (
                <div className={`inline-flex items-center gap-2`}>
                  <label className="text-sm font-semibold text-gray-900">Reschedule Date </label>
                  <input
                    type="date"
                    className={`bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-1`}
                    value={rescheduleDate}
                    onChange={(e) => {
                      setRescheduleDate(e.target.value);
                    }}
                  />
                </div>
              )}

              <button
                onClick={() => {
                  changeStatus();
                }}
                type="button"
                className="rounded-md px-4 py-2 mr-2 text-sm font-medium text-white bg-secondary1 border border-gray-200 hover:bg-gray-100 hover:text-blue-700"
              >
                Update Status
              </button>
            </div>


            <div>
            <button
            type="submit"
            className="rounded-md px-6 py-2 text-xl font-medium text-white bg-blue-800 border border-gray-200 hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-2 focus:ring-blue-700 focus:text-blue-700"
          >
            {editMode ? "Update" : "Generate"}
          </button>
            </div>

            <div className="flex gap-1 items-center flex-wrap">
              <textarea value={employeeWapp?.replaceAll("%0A", "\n") || ""}></textarea>
              <label className="border border-primary p-2 rounded-md">
                Send Whatsapp
                <input
                  className="ml-1"
                  name="doSendWapp"
                  type="checkbox"
                  checked={values.doSendWapp}
                  onBlur={handleBlur}
                  onChange={(e) => {
                    console.log(e);
                    setFieldValue("doSendWapp", e.target.checked);
                  }}
                />
              </label>
            </div>
          </div>
        </div>

        <div className="mt-4 text-right px-4">
          {editMode && (
            <button
              onClick={() => {
                window.location.reload();
              }}
              type="button"
              className="rounded-md px-4 py-2 mr-2 text-sm font-medium text-primary bg-warning border border-gray-200 hover:bg-gray-100 hover:text-blue-700"
            >
              Cancel Edit
            </button>
          )}
          
        </div>
      </form>
      <hr />
      <div className="mt-2 flex px-4 ">
        <div className={`inline-flex items-center gap-2 w-`}>
          <label className="mb-2 text-sm font-semibold text-gray-900">Reference Code</label>
          <input
            type="text"
            className={`${
              isDisabled ? "bg-gray-300" : ""
            } bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-40 p-2.5`}
            placeholder="Reference Code"
            name="filter_pickup_code"
            value={filters.filter_pickup_code}
            onBlur={(e) => {
              setFilters((prevData) => ({
                ...prevData,
                [e.target.name]: e.target.value,
              }));
            }}
            onChange={(e) => {
              setFilters((prevData) => ({
                ...prevData,
                [e.target.name]: e.target.value,
              }));
            }}
          />
        </div>
        <div className={`inline-flex items-center gap-2 w- ml-10`}>
          <label className="mb-2 text-sm font-semibold text-gray-900">Date_From</label>
          <input
            type="date"
            className={`${
              isDisabled ? "bg-gray-300" : ""
            } bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5`}
            name="filter_date"
            value={filters.filter_date}
            onBlur={(e) => {
              setFilters((prevData) => ({
                ...prevData,
                [e.target.name]: e.target.value,
              }));
            }}
            onChange={(e) => {
              setFilters((prevData) => ({
                ...prevData,
                [e.target.name]: e.target.value,
              }));
            }}
          />
        </div>
        <div className={`inline-flex items-center gap-2 w- ml-2`}>
          <label className="mb-2 text-sm font-semibold text-gray-900">Date_To</label>
          <input
            type="date"
            className={`${
              isDisabled ? "bg-gray-300" : ""
            } bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5`}
            name="filter_date_to"
            value={filters.filter_date_to}
            onBlur={(e) => {
              setFilters((prevData) => ({
                ...prevData,
                [e.target.name]: e.target.value,
              }));
            }}
            onChange={(e) => {
              setFilters((prevData) => ({
                ...prevData,
                [e.target.name]: e.target.value,
              }));
            }}
          />
        </div>
        <button
          onClick={() => setIsDataAdded(!isDataAdded)}
          type="button"
          className="ml-2 rounded-md px-4 py-2 text-sm font-medium text-white bg-green-500 border border-gray-200 hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-2 focus:ring-blue-700 focus:text-blue-700"
        >
          Search
        </button>
      </div>
      <PickupDatatable
        apiUrl={`/admin/pickup/list`}
        columnData={tableColumnData}
        isDataAdded={isDataAdded}
        handleEdit={handleEdit}
        query={
          filters?.filter_pickup_code || filters?.filter_date || filters?.filter_date_to
            ? {
                ...(filters.filter_pickup_code && {
                  pickup_code: filters.filter_pickup_code,
                }),
                ...(!filters.filter_pickup_code &&
                  filters.filter_date && {
                    pickup_date_time: {
                      $gte: filters.filter_date,
                      $lte: moment(filters?.filter_date_to).endOf("day"),
                    },
                  }),
              }
            : {}
        }
      />
    </>
  );
};

export default ToPayPickpAdd;
