import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { AfDataType } from "utils/TableColumnData";
import Select from "react-select";
import handleWheel from "utils/handleOnWheel";
import { ErrorMessage } from "components/ErrorMessage";
import { UseMutateFunction, useMutation } from "@tanstack/react-query";
import { GetCommanWay } from "API/commonApi";
import { AxiosResponse } from "axios";
import Swal from "sweetalert2";

export type AfTableProps = Partial<{
  selectedAwb: any;
  isExtraOpen: any;
  tableData: any[];
  setIsExtraOpen: any;
  setTableData: any;
  product: string;
}>;

const ExtraAmtData: React.FC<AfTableProps> = ({ tableData = [], selectedAwb = 0, isExtraOpen, setIsExtraOpen, setTableData, product }) => {
  //const [tableData_, settableData_] = useState<any[]>(tableData);

  const [amtOrGrand, setAmtOrGrand] = useState("");

  useEffect(() => {
    setTableData(tableData);
  }, [tableData]);

  const onInputchange = (e, key) => {
    //return;
    let _tableData_ = JSON.parse(JSON.stringify(tableData));

    let val = e.target.value === "" ? e.target.value : Number(e.target.value);
    if (isNaN(val)) {
      return;
    }
    _tableData_[selectedAwb][key] = val;

    let keys = ["chcPer", "abRate", "diceRate", "dgRate", "pkgRate"]; //grandtotal;
    let allamt = 0;
    keys.forEach((k) => {
      let am = _tableData_[selectedAwb][k];
      allamt += am || 0;
    });

    if (_tableData_[selectedAwb].grandtotal && _tableData_[selectedAwb].grandtotal > 0) {
      setAmtOrGrand("grand");
      keys.forEach((k) => {
        _tableData_[selectedAwb][k] = 0;
      });
    } else if (allamt > 0) {
      setAmtOrGrand("amt");
      _tableData_[selectedAwb]["grandtotal"] = 0;
    } else {
      setAmtOrGrand("");
    }

    setTableData(_tableData_);
  };

  return (
    <div className="absolute top-0 left-0 w-full h-full bg-white bg-opacity-60 z-10 flex justify-center items-center">
      <div className="bg-white border border-blue-300 p-3 rounded-md shadow-lg">
        <table>
          <tr className="border-b border-black-900">
            <td className="pb-1 border-x px-2 text-center">CHC (%)</td>
            <td className="pb-1 border-x px-2 text-center">AB Charge</td>
            <td className="pb-1 border-x px-2 text-center">Dry Ice</td>
            <td className="pb-1 border-x px-2 text-center">DG</td>
            <td className="pb-1 border-x px-2 text-center">PKG</td>
            <td className="pb-1 border-x-2 border-black-900 px-2">Grand Total</td>
          </tr>

          {tableData && (
            <tr>
              <td className="pt-2 border-x px-2">
                <input
                  type="text"
                  value={tableData[selectedAwb]["chcPer"]}
                  onChange={(e) => {
                    onInputchange(e, "chcPer");
                  }}
                  disabled={amtOrGrand == "grand"}
                  className={`w-20 focus:border-black-900 p-1 rounded-md ${amtOrGrand == "grand" ? "bg-gray-400" : ""}`}
                />
              </td>

              <td className="pt-2 border-x px-2">
                <input
                  type="text"
                  value={tableData[selectedAwb]["abRate"]}
                  onChange={(e) => {
                    onInputchange(e, "abRate");
                  }}
                  disabled={amtOrGrand == "grand"}
                  className={`w-20 focus:border-black-900 p-1 rounded-md ${amtOrGrand == "grand" ? "bg-gray-400" : ""}`}
                />
              </td>

              <td className="pt-2 border-x px-2">
                <input
                  type="text"
                  value={tableData[selectedAwb]["diceRate"]}
                  onChange={(e) => {
                    onInputchange(e, "diceRate");
                  }}
                  disabled={amtOrGrand == "grand"}
                  className={`w-20 focus:border-black-900 p-1 rounded-md ${amtOrGrand == "grand" ? "bg-gray-400" : ""}`}
                />
              </td>

              <td className="pt-2 border-x px-2">
                <input
                  type="text"
                  value={tableData[selectedAwb]["dgRate"]}
                  onChange={(e) => {
                    onInputchange(e, "dgRate");
                  }}
                  disabled={amtOrGrand == "grand"}
                  className={`w-20 focus:border-black-900 p-1 rounded-md ${amtOrGrand == "grand" ? "bg-gray-400" : ""}`}
                />
              </td>

              <td className="pt-2 border-x px-2">
                <input
                  type="text"
                  value={tableData[selectedAwb]["pkgRate"]}
                  onChange={(e) => {
                    onInputchange(e, "pkgRate");
                  }}
                  disabled={amtOrGrand == "grand"}
                  className={`w-20 focus:border-black-900 p-1 rounded-md ${amtOrGrand == "grand" ? "bg-gray-400" : ""}`}
                />
              </td>

              <td className="pt-2 border-x-2 border-black-900 px-2">
                <input
                  type="text"
                  value={tableData[selectedAwb]["grandtotal"]}
                  onChange={(e) => {
                    onInputchange(e, "grandtotal");
                  }}
                  disabled={amtOrGrand == "amt"}
                  className={`w-28 focus:border-black-900 p-1 rounded-md ${amtOrGrand == "amt" ? "bg-gray-400" : ""}`}
                />
              </td>
            </tr>
          )}
        </table>
        <div className="text-right">
          <button
            className="rounded-md py-2 px-3 mt-3 text-sm font-medium text-white bg-blue-800 border border-gray-200 hover:bg-gray-100 hover:text-blue-700 ml-2"
            type="button"
            onClick={() => {
              setIsExtraOpen(false);
              console.log(tableData);
            }}
          >
            Close
          </button>
        </div>
      </div>
    </div>
  );
};

export { ExtraAmtData };
