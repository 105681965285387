import React, { useEffect, useRef, useState } from "react";
import axios, { AxiosResponse } from "axios";
import { DataTableWithoutButtons } from "components/DataTable/DataTableWithoutButtons";
import { AfDataType } from "utils/TableColumnData";
import handleWheel from "utils/handleOnWheel";
import { ListCommanWay, UpdateCommanWay } from "API/commonApi";
import { UseMutateFunction, useMutation, useQuery } from "@tanstack/react-query";
import { Img } from "components";
import moment from "moment";
import Swal from "sweetalert2";
import UseToast from "utils/AfToast";
import Select from "react-select";
import { object } from "yup";
import { useFormik } from "formik";
import { Link, useNavigate } from "react-router-dom";
import { utils, write, writeFile } from "xlsx";
import { saveAs } from "file-saver";

const CustomerBookReport: React.FC<any> = (props) => {
  const ADT = AfDataType;
  const navigator = useNavigate();
  const tableRef = useRef(null);

  const [tableData, setTableData] = useState<any>([]);
  const [agentId, setAgentId] = useState("");
  const [startDate, setStartDate] = useState<any>(moment().startOf("day").subtract(1, "days").format("YYYY-MM-DD"));
  const [endDate, setEndDate] = useState<any>(moment().endOf("day").format("YYYY-MM-DD"));
  const [query, setQuery] = useState<any>({
    /*date: {
      $gte: moment().startOf("day").subtract(7, "days"),
      $lte: moment().endOf("day"),
    },*/
    qry: `date >= '${moment().startOf("day").subtract(1, "days").format("YYYY-MM-DD HH:mm:ss")}' AND date <= '${moment()
      .endOf("day")
      .format("YYYY-MM-DD HH:mm:ss")}'`,
  });
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const [fetchedData, setFetchedData] = useState([]);
  const [isDataAdded, setIsDataAdded] = useState(false);

  const [nestedTables, setNestedTables] = useState<any>({});
  const [nestedSelectedOptions, setNestedSelectedOptions] = useState<any>({});

  let hardcode_json_data = [
    { status_code: 0, reason: "Shipment Delivered", action: "Success" },
    { status_code: 1, reason: "residence/office closed", action: "Re-Assign" },
    { status_code: 2, reason: "add incomplete/incorrect/not locatable", action: "Re-Assign" },
    { status_code: 3, reason: "no such consignee at given address", action: "Re-Assign" },
    { status_code: 4, reason: "consignee refused to accept", action: "Re-Assign" },
    { status_code: 5, reason: "RTO ( shipper request )", action: "Return" },
    { status_code: 6, reason: "need dept name/extn.no:unable to deliver", action: "Re-Assign" },
    { status_code: 7, reason: "consignee not available", action: "Re-Assign" },
    { status_code: 8, reason: "IN TRANSIT", action: "Re-Assign" },
    { status_code: 9, reason: "hold at location", action: "Re-Assign" },
    { status_code: 10, reason: "out of delivery area", action: "Re-Assign" },
    { status_code: 11, reason: "disturbance/natural disaster/strike ", action: "Re-Assign" },
    { status_code: 12, reason: "shipment handed over to agent/subcouriered pod no available", action: "Success" },
    { status_code: 13, reason: "consginee/shipper not ready to pay tax/demurrag ", action: "Re-Assign" },
    { status_code: 14, reason: "held at hub:regulatory paperwork reqd", action: "Re-Assign" },
    { status_code: 15, reason: "Shipment Missrouted due to wrong pincode/adddress", action: "Return" },
    { status_code: 16, reason: "shipment redirected on same airwaybill", action: "Re-Assign" },
    { status_code: 17, reason: "shipment redirected on new airwaybill", action: "Return" },
    { status_code: 18, reason: "held for duty/taxes/fees payment", action: "Re-Assign" },
    { status_code: 19, reason: "consigment partially deliverd", action: "Success" },
    { status_code: 20, reason: "shipment/piece missing", action: "Destroy" },
    { status_code: 21, reason: "shipment returned back to shipper", action: "CANCEL" },
    { status_code: 22, reason: "delivery area not accessible", action: "Re-Assign" },
    { status_code: 23, reason: "shipment partially deliverd", action: "Re-Assign" },
    { status_code: 24, reason: "please contact customer service", action: "Re-Assign" },
    { status_code: 25, reason: "attempt at secondary address", action: "Re-Assign" },
    { status_code: 26, reason: "shipment destroyed/sent for disposal", action: "Destroy" },
    { status_code: 27, reason: "UnDelivered", action: "Re-Assign" },
  ];
  hardcode_json_data[99] = { status_code: 99, reason: "Shipment Cancelled", action: "CANCEL" };  
  hardcode_json_data[100] = { status_code: 100, reason: "Sent To Third Party", action: "Success" };
  const [HARDCODE_JSON_STATUS_LIST] = useState<any>(hardcode_json_data);

  let HARDCORE_MBAG_STATUS = [
    { status_code: 0, reason: "PENDING" },
    { status_code: 1, reason: "flight delay due to bad weather" },
    { status_code: 2, reason: "late arrival scheduled.for next working day" },
    { status_code: 3, reason: "missed connection" },
    { status_code: 4, reason: "shipment off-loaded by airline security/space contraint" },
    { status_code: 5, reason: "flight/vehicle delayed cancelled" },
    { status_code: 6, reason: "misrouted in network" },
    { status_code: 7, reason: "scheduled for movement in network" },
    { status_code: 8, reason: "linehaul delayed accident/traffic-jam" },
    { status_code: 9, reason: "security cleared" },
    { status_code: 10, reason: "shipment under cooling by airline" },
    { status_code: 11, reason: "under security investigation" },
    { status_code: 12, reason: "please contact customer service" },
    { status_code: 13, reason: "shipment detained/seized by regulatory" },
    { status_code: 14, reason: "awaiting connection on next available flight" },
    { status_code: 15, reason: "Shipment Held by GST Deparment under investigation" },
  ];

  const defaultOptions: any = [{ value: 0, label: "Loading..." }];
  type OptionType = {
    value: number;
    label: string;
  };
  const columnData = [
    {
      label: "Reference Code",
      key: "pickup_code",
      type: ADT.TEXT,
    },
    {
      label: "Product",
      key: "product",
      type: ADT.DB,
      db: "product",
      db_col: "product_name",
    },
    {
      label: "Origin City",
      key: "org_city",
      type: ADT.DB,
      db: "city",
      db_col: "city_name",
    },
    {
      label: "Destination City",
      key: "dest_city",
      type: ADT.DB,
      db: "city",
      db_col: "city_name",
    },

    {
      label: "Dox/Non Dox",
      key: "isdoc",
      type: ADT.SELECT,
      options: [
        { value: "0", label: "NON DOX" },
        { value: "1", label: "DOX" },
      ],
    },

    {
      label: "Shipper",
      key: "shipper",
      type: ADT.TEXT,
    },
    {
      label: "Consignee",
      key: "consignee",
      type: ADT.TEXT,
    },
    {
      label: "Holiday",
      key: "holiday",
      type: ADT.SELECT,
      options: [
        { value: "1", label: "Yes" },
        { value: "0", label: "No" },
      ],
    },
    {
      label: "10:30",
      key: "ten_30",
      type: ADT.SELECT,
      options: [
        { value: "1", label: "Yes" },
        { value: "0", label: "No" },
      ],
    },
    {
      label: "Status",
      key: "status",
      type: ADT.SELECT,
      options: [
        { value: "0", label: "Delivered" },
        { value: "-1", label: "Non Delivered" },
        { value: "5", label: "RTO" },
      ],
    },
  ];

  useEffect(() => {
    let proms = [];

    let nestedApiData = {};
    columnData.forEach((cd) => {
      if (cd.type == ADT.DB) {
        proms.push(
          new Promise((res, rej) => {
            ListCommanWay(`/admin/${cd.db}/list`, {}, { paginate: 1000 })
              .then((x) => {
                let data: OptionType[] = [];
                if (x.status == "SUCCESS") {
                  let ary = [...x.data.data];

                  data = ary.map((d) => {
                    if (cd.db == "country") {
                      return { value: d.country_code, label: d[cd.db_col] };
                    } else if (cd.db == "product") {
                      return { value: d.product_code, label: d[cd.db_col] };
                    } else if (cd.db == "city") {
                      return { value: d[cd.db_col], label: d[cd.db_col] };
                    } else {
                      return { value: d.id, label: d[cd.db_col] };
                    }
                  });
                  nestedApiData[cd.db] = data;
                }
                res(1);
              })
              .catch((c) => {
                res(0);
              });
          })
        );
      }
    });

    Promise.all(proms).then((x) => {
      setNestedTables(nestedApiData);
    });
  }, []);

  const { isFetching, isLoading, refetch } = useQuery(
    ["admin_booking_report_list"],
    () =>
      ListCommanWay(
        "/admin/booking/list",
        {
          ...query,
          qry2: values,
        },
        {}
      ),
    {
      keepPreviousData: true,
      onSuccess(data) {
        if (data.status == "SUCCESS") {
          console.log("data.data", data.data);
          setTableData(data.data.data);
        } else {
          setTableData([]);
        }
      },
      onError: (data) => {
        setTableData([]);
      },
    }
  );

  useEffect(() => {
    refetch();
  }, [query]);

  const __handleSubmit = async () => {
    setIsDataAdded(!isDataAdded);

    setQuery({
      qry: `date >= '${startDate ? startDate : moment().startOf("day").subtract(7, "days").format("YYYY-MM-DD HH:mm:ss")}' AND date <= '${
        endDate ? endDate : moment().endOf("day").format("YYYY-MM-DD HH:mm:ss")
      }'`,
    });

    /*setQuery({
      //agent_id: Number(agentId),
      date: {
        $gte: startDate ? startDate : moment().startOf("day").subtract(7, "days"),
        $lte: endDate ? endDate : moment().endOf("day"),
      },
    });*/
  };

  const BASE_URL = process.env.REACT_APP_BACKEND_URL;
  const userD = localStorage.getItem("auth") ? JSON.parse(localStorage.getItem("auth")) : {};
  const [userDetails, setUserDetails] = useState<any>(userD?.state?.user || {});

  const { handleSubmit, handleBlur, values, handleChange, errors, setValues, touched, setFieldValue } = useFormik({
    validationSchema: object().shape({}),
    initialValues: {
      pickup_code: "",
      customerCode: "",
      product: "",
      org_city: "",
      dest_city: "",
      isdoc: "",
      shipper: "",
      consignee: "",
      holiday: "",
      ten_30: "",
      status: "",
    },
    onSubmit: async (values, { resetForm }) => {
      console.log("valuesssss", values);
    },
  });

  const getStatusText = (lastMsg: any) => {
    //let lastMsg = resData.tracking_data[resData.tracking_data.length - 1]?.message_code;
    if (
      lastMsg.includes("Motherbag Tally") ||
      lastMsg.includes("Motherbag Out-Scan") ||
      lastMsg.includes("Motherbag In-Scan") ||
      lastMsg.includes("DRS Generated") ||
      lastMsg.includes("Send To ThirdParty") ||
      lastMsg.includes("Sent To ThirdParty")
    ) {
      return "In Transit";
    } else {
      return lastMsg;
    }
  };

  const exportToExcel = () => {
    const table = document.getElementById("myTable");

    const ws = utils.table_to_sheet(table);
    const wb = utils.book_new();
    utils.book_append_sheet(wb, ws, "Sheet1");

    Object.keys(ws).forEach((key) => {
      if (key.startsWith("!")) return;

      let cell = ws[key];
      if (cell && cell.h && typeof cell.h === "string") {
        cell.h = null;
      }
      if (cell && cell.l) {
        delete cell.l;
      }
    });

    const wbout = write(wb, { bookType: "xlsx", type: "array" });

    const blob = new Blob([wbout], { type: "application/octet-stream" });
    saveAs(blob, "Booking Report.xlsx");
  };

  return (
    <>
      <h1 className="text-center mb-4 mt-3 text-lg font-semibold ">Book Report</h1>
      <div className="px-4  border-[1px] p-4 m-4 rounded-md">
        <form
          className="flex flex-col"
          onSubmit={(e) => {
            e.preventDefault();
            handleSubmit(e);
          }}
        >
          <div className="grid grid-cols-5 md:grid-cols-4 gap-x-4 gap-y-2">
            <div className="">
              <label className="block mb-0.5 text-sm font-medium text-gray-900">From Date</label>
              <input
                type="date"
                className={`bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5`}
                placeholder="Start Date"
                name="startDate"
                value={startDate}
                onChange={(e) => setStartDate(e.target.value)}
              />
            </div>
            <div className="">
              <label className="block mb-0.5 text-sm font-medium text-gray-900">To Date</label>
              <input
                type="date"
                className={`bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5`}
                placeholder="End Date"
                name="endDate"
                value={endDate}
                onChange={(e) => setEndDate(e.target.value)}
              />
            </div>
            {columnData.map((c) => {
              switch (c.type) {
                case ADT.NUMBER:
                  return (
                    <div key={"general_" + c.key}>
                      <label htmlFor={"general_" + c.key} className="block mb-0.5 text-sm font-medium text-gray-900 dark:text-white">
                        {c.label}
                      </label>
                      <input
                        type="number"
                        id={"general_" + c.key}
                        className={` bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5`}
                        placeholder={c.label}
                        onBlur={handleBlur}
                        onChange={handleChange}
                        name={c.key}
                        value={values[c.key]}
                        onWheel={handleWheel}
                      />

                      <p className="mt-21 text-sm text-red-600">{touched[c.key] && Boolean(errors[c.key]) ? errors[c.key] : ""}</p>
                    </div>
                  );
                  break;
                case ADT.TEXT:
                  return (
                    <div key={"general_" + c.key}>
                      <label htmlFor={"general_" + c.key} className="block mb-0.5 text-sm font-medium text-gray-900 dark:text-white">
                        {c.label}
                      </label>
                      <input
                        type="text"
                        id={"general_" + c.key}
                        className={`bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5`}
                        placeholder={c.label}
                        onBlur={handleBlur}
                        onChange={handleChange}
                        name={c.key}
                        value={values[c.key]}
                      />

                      <p className="mt-21 text-sm text-red-600">{touched[c.key] && Boolean(errors[c.key]) ? errors[c.key] : ""}</p>
                    </div>
                  );
                  break;
                case ADT.DB:
                  return (
                    <div key={"general_" + c.key}>
                      <label htmlFor={"general_" + c.key} className="block mb-0.5 text-sm font-medium text-gray-900 dark:text-white">
                        {c.label}
                      </label>

                      <Select
                        key={"select-" + (nestedTables[c.db] ? nestedTables[c.db].length : "undef")}
                        className={`bg-gray-50 border-gray-300 text-gray-900 text-sm rounded-lg block w-full`}
                        id={"general_" + c.key}
                        options={nestedTables[c.db] ? nestedTables[c.db] : defaultOptions}
                        onChange={(el) => {
                          setFieldValue(c.key, el?.value || "");
                        }}
                        isClearable={true}
                        name={c.key}
                        value={nestedSelectedOptions[c.db]}
                      />

                      <p className="mt-21 text-sm text-red-600">{touched[c.key] && Boolean(errors[c.key]) ? errors[c.key] : ""}</p>
                    </div>
                  );
                  break;
                case ADT.SELECT:
                  return (
                    <div key={"general_" + c.key}>
                      <label htmlFor={"general_" + c.key} className="block mb-0.5 text-sm font-medium text-gray-900 dark:text-white">
                        {c.label}
                      </label>

                      <Select
                        key={"select-" + c.key}
                        className={`bg-gray-50 border-gray-300 text-gray-900 text-sm rounded-lg block w-full`}
                        id={"general_" + c.key}
                        options={c?.options}
                        onChange={(el) => {
                          setFieldValue(c.key, el?.value || "");
                        }}
                        isClearable={true}
                        name={c.key}
                        //value={values[c.key]}
                        value={nestedSelectedOptions[c.db]}
                      />

                      <p className="mt-21 text-sm text-red-600">{touched[c.key] && Boolean(errors[c.key]) ? errors[c.key] : ""}</p>
                    </div>
                  );
                  break;
                case ADT.TEXTAREA:
                  break;
                case ADT.DATETIME:
                  break;
                case ADT.FILE:
                  break;
                default:
                  return <></>;
                  break;
              }
            })}
          </div>
          <button
            type="button"
            onClick={__handleSubmit}
            className="ml-2 mt-2 rounded-md px-4 py-2 text-sm font-medium text-white bg-green-500 border border-gray-200 hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-2 focus:ring-blue-700 focus:text-blue-700"
          >
            Submit
          </button>
        </form>
      </div>

      <div className="px-4">
        <button onClick={exportToExcel} className="mb-4 bg-blue-600 text-white px-4 py-1 rounded-md">Excel Export</button>
        <table className="w-full table- text-sm mb-4" ref={tableRef} id="myTable">
          <thead>
            <tr>
              <th className="border border-slate-200">Date</th>
              <th className="border border-slate-200">Ref No</th>
              <th className="border border-slate-200">Airwaybill</th>
              <th className="border border-slate-200">Shipper</th>
              <th className="border border-slate-200">Origin</th>
              <th className="border border-slate-200">Consignee</th>
              <th className="border border-slate-200">Destination</th>
              <th className="border border-slate-200">Status</th>
            </tr>
          </thead>
          <tbody>
            {tableData.map((r, i) => {
              return (
                <tr className={`${i % 2 ? "" : "bg-amber-50"}`} key={"book-report-"+i}>
                  <td className="border border-slate-200 px-1.5 py-1">{moment(r.date).format("DD/MM/YYYY")}</td>
                  <td className="border border-slate-200 px-1.5 py-1">{r.pickup_code}</td>
                  <td className="border border-slate-200 px-1.5 py-1">
                    <a target="_blank" href={`/customer-pod/tracking?awb=${r.awb_no}`} className="text-primary2 fon text-base">
                      {r.awb_no}
                    </a>
                  </td>
                  <td className="border border-slate-200 px-1.5">{r.shipper_sh}</td>
                  <td className="border border-slate-200 px-1.5">{r.shipper_city}</td>
                  <td className="border border-slate-200 px-1.5">{r.consignee}</td>
                  <td className="border border-slate-200 px-1.5">{r.destination}</td>
                  <td className="border border-slate-200 px-1.5">{getStatusText(r.pod_message.replaceAll("0 - ", ""))}</td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    </>
  );
};

export default CustomerBookReport;
