import { UseMutateFunction, useMutation, useQuery } from "@tanstack/react-query";
import { GetCommanWay, ListCommanWay } from "API/commonApi";
import { AxiosResponse } from "axios";
import { Button, Img } from "components";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import UseToast from "utils/AfToast";

const PublicTrackingAWB = () => {
  const BASE_URL = process.env.REACT_APP_BACKEND_URL;

  const [awbNo, setAwbNo] = useState("");
  const [awbTrackingData, setAwbTrackingData] = useState<any>({
    tracking_data: [],
    bookingDate: "",
    awbData: {},
    bookingData: {},
  });
  const [searchParams, setSearchParams] = useSearchParams();
  const [deliveryDoneInfo, setDeliveryDoneInfo] = useState<any>({});
  const [productList, setProductList] = useState<any>([]);
  const [showVolume, setShowVolume] = useState<any>(false);
  const [serviceType, setServiceType] = useState<any>("");

  const [lastStatus, setLastStatus] = useState<string>("Delivered");
  const [currentStatus, setCurrentStatus] = useState<string>("");
  const [lastColor, setLastColor] = useState<string>("blue-600");

  const [trackPointNo, setTrackPointNo] = useState<number>(1);

  const [awbMissing, setAwbMissing] = useState<any>(false);

  const { isFetching, isLoading, refetch } = useQuery(["admin_product_list"], () => ListCommanWay("/admin/product/list", {}, {}), {
    keepPreviousData: true,
    onSuccess(data) {
      if (data.status == "SUCCESS") {
        setProductList(data.data.data);
      }
    },
    onError: (data) => {},
  });

  const {
    mutate,
    isLoading: isloading,
  }: {
    mutate: UseMutateFunction<AxiosResponse<any, any>, unknown, any, unknown>;
    isLoading: any;
  } = useMutation((awb_no) => GetCommanWay("/public/proof_of_delivery/track/" + awb_no, {}, {}), {
    onSuccess: (data: any) => {
      console.log(data, data.status == "SUCCESS");
      if (data.status == "SUCCESS") {
        setDeliveryDoneInfo({});
        setLastStatus("");
        setCurrentStatus("");
        setTrackPointNo(0);
        setAwbTrackingData(data.data);
        if (data.data.tracking_data) {
          try {
            data.data.tracking_data.forEach((t) => {
              let finalPoint = 0;
              switch (t.message_code.split(":")[0]) {
                case "Shipment Booked":
                  setCurrentStatus("In Transit");
                  finalPoint = 1;
                  break;
                case "Motherbag Out-Scan":
                  setCurrentStatus("In Transit");
                  finalPoint = 2;
                  break;
                case "Motherbag In-Scan":
                  setCurrentStatus("In Transit");
                  finalPoint = 3;
                  break;
                case "Sent to ThirdParty":
                  finalPoint = 3;
                  //setLastStatus("Sent to ThirdParty");
                  setCurrentStatus("Processing At Hub");
                  break;
                case "DRS Generated":
                  setCurrentStatus("Out for delivery");
                  finalPoint = 4;
                  break;
                case "Shipment Delivered":
                case "0 - Shipment Delivered":
                  finalPoint = 5;
                  setDeliveryDoneInfo(t);
                  setCurrentStatus("Shipment Delivered");
                  break;
                default:
                  finalPoint = 5;
                  setLastStatus(t.message_code);
                  setCurrentStatus(t.message_code);
                  setLastColor("red-600");
                  break;
              }
              setTrackPointNo(finalPoint);
            });
          } catch (e) {}
        }
        if (data.data.awbData) {
          let pName = data.data.awbData.prefix;
          let pcodes = ["S", "P", "V", "X", "I", "C", "B"];
          let pnames = ["SMARTEX", "PRIMEX", "VERTEX", "SURFEX", "INTELEX", "LOCAL C", "BIOMEX"];
          setServiceType(pnames[pcodes.indexOf(pName.toUpperCase())]);
        }
        setAwbMissing(false);
      } else {
        setAwbMissing(true);
        //UseToast(data.message, "error");
      }
    },
    onError: (data: any) => {
      setAwbMissing(true);
      //typeof data === "string" ? UseToast(data, "error") : UseToast(data.message, "error");
    },
  });
  const handleSubmit = () => {
    setAwbTrackingData({
      tracking_data: [],
      bookingDate: "",
      awbData: {},
      bookingData: {},
    });
    mutate(awbNo);
  };

  useEffect(() => {
    if (searchParams.get("awb")) {
      setAwbNo(searchParams.get("awb"));
      setAwbTrackingData({
        tracking_data: [],
        bookingDate: "",
        awbData: {},
        bookingData: {},
      });
      mutate(searchParams.get("awb"));
    }
  }, [searchParams]);

  const getTRline = (name, value) => {
    return (
      <tr>
        <td className="text-right pr-5">{name}</td>
        <td>{value}</td>
      </tr>
    );
  };

  const getChargeableWeight = (weight, product_code, ll, bb, hh) => {
    ll = Number(ll);
    bb = Number(bb);
    hh = Number(hh);
    let dWeight = (ll * bb * hh) / (product_code == "X" ? 2700 : 5000);
    return Math.max(Number(weight), dWeight);
  };

  const GetTrackPoint = ({ hasPre, hasPost, img, title, isActive }) => {
    let color = isActive ? "blue-600" : "gray-400";
    if (img == "done") {
      color = isActive ? lastColor : "gray-400";
    }
    return (
      <div className="text-center flex-1 relative">
        <div className="hidden bg-gray-400 after:bg-gray-400 before:bg-gray-400 before:bg-red-600"></div>
        <div className="hidden bg-blue-600 after:bg-blue-600 before:bg-blue-600"></div>
        <Img src={`/${img}.png`} className="h-10 mx-auto" />
        <div
          className={`bg- bg-${color} w-8 h-8 rounded-full mx-auto ${
            hasPre ? "before:absolute before:w-1/2 before:h-1 before:bg-" + color + " before:left-0 before:mt-[14px]" : ""
          } ${hasPost ? "after:absolute after:w-1/2 after:h-1 after:bg-" + color + " after:mt-[14px]" : ""}`}
        ></div>
        <p>{title}</p>
      </div>
    );
  };

  useEffect(() => {
    const resizeObserver = new ResizeObserver(() => {
      const height = document.documentElement.scrollHeight;
      window.parent.postMessage({ iframeHeight: height }, "*");
    });

    resizeObserver.observe(document.documentElement);

    return () => resizeObserver.disconnect();
  }, []);

  return (
    <>
      {awbMissing && (
        <div className="flex justify-center w-full p-5 flex-col">
          <p className="text-xl underline text-blue-600">No matching AWB No. /Reference Number. The reasons could be 1 or more of the following:</p>

          <div>
            <ul className="list-disc">
              <li>You may have entered incorrect AWB no/ Ref no.</li>
              <li>
                The AWB no should be 11, 12 or 13 digits and first letter of AWB no should be Alphabetic.
                <ul>
                  <li>Please check AWB No you have entered.</li>
                </ul>
              </li>
              <li>Shipment can be tracked for a period of 60 days from the date of Booking.</li>
              <li>Please try again or contact customer service.</li>
            </ul>
          </div>
        </div>
      )}
      {showVolume && (
        <div className="absolute left-0 top-0 w-full h-full bg-black-900 bg-opacity-20 flex items-center justify-center">
          <div className="rounded-xl border-2 border-primary p-4 bg-white relative">
            <div className="flex items-center justify-between bg-primary text-white p-2 rounded-lg">
              <p>Total Weight: {awbTrackingData?.awbData?.weight}</p>
              <button
                className="px-1"
                onClick={() => {
                  setShowVolume(false);
                }}
              >
                ✖
              </button>
            </div>

            <div>
              <table className="table mt-2">
                <tr>
                  <th className="px-2 border-r">Sr.</th>
                  <th className="px-2">Lenght</th>
                  <th className="px-2">Width</th>
                  <th className="px-2">Height</th>
                  <th className="px-2">Weight</th>
                </tr>
                {awbTrackingData?.awbData?.innerItemsJson ? (
                  JSON.parse(awbTrackingData?.awbData?.innerItemsJson).map((i, a) => {
                    return (
                      <tr className="border-t">
                        <td className="px-4 border-r">{a + 1}</td>
                        <td className="px-4">{i.l}</td>
                        <td className="px-4">{i.b}</td>
                        <td className="px-4">{i.h}</td>
                        <td className="px-4">{i.weight}</td>
                      </tr>
                    );
                  })
                ) : (
                  <tr className="border-t">
                    <td className="px-4">1</td>
                    <td className="px-4">{awbTrackingData?.awbData?.l}</td>
                    <td className="px-4">{awbTrackingData?.awbData?.b}</td>
                    <td className="px-4">{awbTrackingData?.awbData?.h}</td>
                    <td className="px-4">{awbTrackingData?.awbData?.weight}</td>
                  </tr>
                )}
              </table>
            </div>
          </div>
        </div>
      )}

      {!awbMissing && (
        <div className="flex flex-col gap-4 px-4 mt-2">
          <div>
            <h1 className="text-center mb-4 mt-3 text-2xl underline whitespace-nowrap uppercase">Courier Details</h1>
          </div>

          <div className="border p-6 border-black-900 rounded-md">
            <div className="flex">
              <GetTrackPoint isActive={trackPointNo >= 1} hasPre={false} hasPost={true} img={"icon-pc"} title={"Booked"} />
              <GetTrackPoint isActive={trackPointNo >= 2} hasPre={true} hasPost={true} img={"flight"} title={"Outscan from origin"} />
              <GetTrackPoint isActive={trackPointNo >= 3} hasPre={true} hasPost={true} img={"flight"} title={"Arrived at Hub"} />
              <GetTrackPoint isActive={trackPointNo >= 4} hasPre={true} hasPost={true} img={"truck"} title={"Out for delivery"} />
              <GetTrackPoint isActive={trackPointNo >= 5} hasPre={true} hasPost={false} img={"done"} title={lastStatus} />
            </div>

            <div className="p-6  flex justify-between">
              <div className="border-x flex-1 border-blue-600 px-4">
                <div className="border-b-2 border-blue-600 pb-2 mb-2">
                  <p>Pickup Date</p>
                  <p className="bg-blue-100 rounded-md p-1">
                    {awbTrackingData?.bookingDate && moment(awbTrackingData.bookingDate).format("DD-MM-YYYY")}
                  </p>
                </div>
                <div className="">
                  <p>Service Type</p>
                  <p className="bg-blue-100 rounded-md p-1">{serviceType}</p>
                </div>
              </div>

              <div className="border-x flex-1 border-blue-600 px-4">
                <div className="mb-2 border-b-2 border-blue-600 pb-2">
                  <p>Airwaybill No</p>
                  <p className="bg-blue-100 rounded-md p-1 whitespace-nowrap">{awbNo}</p>
                </div>

                <div>
                  <p>Status</p>
                  <p className={`bg-blue-100 rounded-md p-1 ${currentStatus.length < 70 ? "whitespace-nowrap" : ""}`}>
                    {awbTrackingData.tracking_data.length && currentStatus}
                  </p>
                </div>
              </div>

              <div className="border-x flex-1 border-blue-600 px-4">
                <div className="mb-2 border-b-2 border-blue-600 pb-2">
                  <p>Origin</p>
                  <p className="bg-blue-100 rounded-md p-1">{awbTrackingData?.bookingData?.shipper_city}</p>
                </div>
                {deliveryDoneInfo?.updatedAt ? (
                  <div>
                    <p>Delivery Date & Time</p>
                    <p className="bg-blue-100 rounded-md p-1">{deliveryDoneInfo?.updatedAt?.slice(0, -3) || "-"}</p>
                  </div>
                ) : (
                  ""
                )}
              </div>

              <div className="border-x flex-1 border-blue-600 px-4">
                <div className="mb-2 border-b-2 border-blue-600 pb-2">
                  <p>Destination</p>
                  <p className="bg-blue-100 rounded-md p-1">{awbTrackingData?.awbData?.destination}</p>
                </div>

                {deliveryDoneInfo?.updatedAt ? (
                  <div>
                    <p>Receiver Name</p>
                    <p className="bg-blue-100 rounded-md p-1">
                      {(awbTrackingData.tracking_data.length && awbTrackingData.tracking_data[0].receiver) || "-"}
                    </p>
                  </div>
                ) : (
                  ""
                )}
                {/*<div>
                <p>Consignee</p>
                <p className="bg-blue-100 rounded-md p-1">{awbTrackingData?.awbData?.consignee || "-"}</p>
              </div>*/}
              </div>

              {/*<div className="border-x flex-1 border-blue-600 px-4">
              <div className="mb-2 border-b-2 border-blue-600 pb-2">
                <p>Weight</p>
                <p className="bg-blue-100 rounded-md p-1">{awbTrackingData?.awbData?.weight || "-"}</p>
              </div>
              <div>
                <p>Update Date time</p>
                <p className="bg-blue-100 rounded-md p-1">
                  {(awbTrackingData.tracking_data.length && awbTrackingData.tracking_data[0].updatedAt?.slice(0, -3)) || "-"}
                </p>
              </div>
            </div>*/}
            </div>
          </div>

          <div className=" border p-2 rounded-2xl hidden">
            <div>
              <table className="border">
                <thead>
                  <tr>
                    <th className="border border-primary">From</th>
                    <th className="border border-primary">Destination</th>
                    <th className="border border-primary">Destination Pincode</th>
                    <th className="border border-primary">Product</th>
                  </tr>
                </thead>
                <tbody className="text-center">
                  <tr>
                    <td className="border border-primary">{awbTrackingData.bookingData?.shipper_city}</td>
                    <td className="border border-primary">{awbTrackingData.awbData?.destination}</td>
                    <td className="border border-primary">{awbTrackingData.awbData?.pincode}</td>
                    <td className="border border-primary">
                      {productList
                        .map((p) => {
                          return p.product_code == awbTrackingData.awbData?.prefix ? p.product_name : "";
                        })
                        .join("")}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>

            <div className="flex justify-between mt-2 border-t pt-2">
              <div className="flex gap-8">
                <p>{awbTrackingData.awbData.l + " x " + awbTrackingData.awbData.b + " x " + awbTrackingData.awbData.h}</p>
                <p>
                  Weight: <b>{awbTrackingData.awbData.weight} kg</b>
                </p>
                <p>
                  Chargeable Weight:{" "}
                  <b>
                    {getChargeableWeight(
                      awbTrackingData.awbData.weight,
                      awbTrackingData.awbData.prefix,
                      awbTrackingData.awbData.l,
                      awbTrackingData.awbData.b,
                      awbTrackingData.awbData.h
                    )}{" "}
                    kg
                  </b>
                </p>
                <p>
                  10_30: <b>{awbTrackingData.awbData.is10_30 ? "YES" : "NO"}</b>
                </p>
                <p>
                  Holiday: <b>{awbTrackingData.awbData.holiday ? "YES" : "NO"}</b>
                </p>
              </div>

              <div>
                <Button
                  className="bg-blue-600 px-2 py-1 rounded-md text-white mr-3"
                  onClick={() => {
                    setShowVolume(true);
                  }}
                >
                  Show Volume Info
                </Button>
              </div>
            </div>
          </div>

          {awbTrackingData.tracking_data.length ? (
            <div className="flex gap-2 flex-wrap border p-2 rounded-2xl pb-8 hidden">
              <div className="w-full font-bold underline ">Tracking Details</div>
              <table className={`flex-1 items-center w-auto justify-center`}>
                <tr className="border-y-2 text-left text-blue-800">
                  <th>Date time</th>
                  <th>Status</th>
                  <th>Location</th>
                  <th>Employee</th>
                  <th>Device</th>
                </tr>
                {awbTrackingData.tracking_data.length &&
                  [
                    /*{
                    id: 0,
                    message_code: "Shipment Booked",
                    createdAt: awbTrackingData.bookingDate,
                  },*/
                    ...awbTrackingData.tracking_data,
                  ]
                    .sort((a, b) => a.id - b.id)
                    .map((d, i) => (
                      <>
                        <tr className="" key={`track-data${d.id}`}>
                          <td>
                            {moment(d.updatedAt).format("DD-MM-YYYY") == "Invalid date"
                              ? d.updatedAt
                              : moment(d.updatedAt, "DD-MM-YYYY HH:mm").format("DD-MM-YYYY HH:mm:ss")}
                          </td>
                          <td>{d.message_code}</td>
                          <td>{d.subbranch?._pincode?._city?.city_name}</td>
                          <td>{d._addedBy ? d._addedBy.name : "-"}</td>
                          <td>{d.isMobile ? "Mobile" : "Web"}</td>
                        </tr>
                      </>
                    ))}
              </table>
            </div>
          ) : null}
        </div>
      )}
    </>
  );
};

export default PublicTrackingAWB;
