import React, { useCallback, useEffect, useState } from "react";
import {
  UseMutateFunction,
  useMutation,
  useQuery,
} from "@tanstack/react-query";
import {
  CreateCommanWay,
  GetCommanWay,
  ListCommanWay,
  UpdateCommanWay,
} from "API/commonApi";
import { AfDataType as ADT } from "utils/TableColumnData";
import { useFormik } from "formik";
import { AxiosResponse } from "axios";
import UseToast from "utils/AfToast";
import { debounce } from "lodash";
import { useNavigate, useParams } from "react-router-dom";
import Select from "react-select";
import { RateDataTables } from "components/DataTable/RateDataTables";

export type AfTableProps = Partial<{
  className: string;
  columnData: any[];
  selectable: boolean;
  doAdd: boolean;
  doEdit: boolean;
  doDelete: boolean;
  doExport: boolean;
  paginate: boolean;
  addBtnUrl: string;
  validationSchema: any;
  initialValues: any;
  apiUrl: string;
  successNav: string;
  cancelNav: string;
  mode: string;
  fetchRateList: any;
}>;

const RateCreate: React.FC<AfTableProps> = ({
  columnData = [],
  doAdd = true,
  doEdit = true,
  doDelete = true,
  doExport = true,
  paginate = true,
  validationSchema,
  initialValues = {},
  apiUrl = "",
  successNav = "/",
  cancelNav = "/",
  mode = "View",
}) => {
  const navigate = useNavigate();
  const params = useParams();
  const [dataMode, setDataMode] = useState<string>(mode);

  const newColumnData = [
    {
      label: "Zone",
      key: "zone",
      type: ADT.TEXT,
      add: true,
    },
    {
      label: "DOX / NON-DOX",
      key: "is_dox",
      type: ADT.BOOLEAN,
      options: [
        { value: true, label: "DOX" },
        { value: false, label: "NON DOX" },
      ],
      add: true,
    },
    ...columnData,
    {
      label: "Country Name",
      key: "country",
      type: ADT.DB,
      db: "country",
      db_col: "country_name",
      add: true,
    },
    {
      label: "State Name",
      key: "state",
      type: ADT.DB,
      db: "state",
      db_col: "state_name",
      add: true,
    },
    {
      label: "City Name",
      key: "city",
      type: ADT.DB,
      db: "city",
      db_col: "city_name",
      add: true,
    },
    {
      label: "Customer Name",
      key: "customer",
      type: ADT.DB,
      db: "customer",
      db_col: "code",
      add: true,
    },
    {
      label: "Bound",
      key: "bound",
      type: ADT.SELECT,
      options: [
        { value: null, label: "-- ALL --" },
        { value: "IN", label: "IN" },
        { value: "OUT", label: "OUT" },
      ],
      add: true,
    },
    {
      label: "Product",
      key: "product",
      type: ADT.DB,
      db: "product",
      db_col: "product_name",
      add: true,
    },
  ];

  type OptionType = {
    value: number;
    label: string;
  };
  const defaultOptions: any = [{ value: 0, label: "Loading..." }];
  const [nestedTables, setNestedTables] = useState<any>({});
  const [nestedSelectedOptions, setNestedSelectedOptions] = useState<any>({});
  const [tableData, setTableData] = useState<any>([]);
  const [newTableData, setNewTableData] = useState<any>({});
  const [isVisible, setIsVisible] = useState<any>(false);
  const {
    mutate: fetchRateList,
    isLoading: isloadingFetch,
  }: {
    mutate: UseMutateFunction<AxiosResponse<any, any>, unknown, any, unknown>;
    isLoading: any;
  } = useMutation(CreateCommanWay, {
    onSuccess: (data: any) => {
      if (data.status == "SUCCESS") {
        UseToast("Data Added Successfully", "success");
        setTableData(data.data || []);
        if (data.data?.length) setIsVisible(true);
        else {
          setIsVisible(false);
        }
      } else {
        UseToast(data.message, "error");
      }
    },
    onError: (data: any) => {
      typeof data === "string"
        ? UseToast(data, "error")
        : UseToast(data.message, "error");
    },
  });
  useEffect(() => {
    let proms = [];

    let nestedApiData = {};
    newColumnData.forEach((cd) => {
      if (cd.type == ADT.DB) {
        console.log(cd.db);
        proms.push(
          new Promise((res, rej) => {
            ListCommanWay(`/admin/${cd.db}/list`, {}, { paginate: 1000 })
              .then((x) => {
                let data: OptionType[] = [];
                if (x.status == "SUCCESS") {
                  if(cd.db == "customer"){
                    x.data.data = [{id: "", product_name: "--- ALL ---", code: "--- ALL ---"}, ...x.data.data]
                  }
                  data = x.data.data.map((d) => {
                    return { value: d.id, label: d[cd.db_col] };
                  });
                  nestedApiData[cd.db] = data;
                }
                res(1);
              })
              .catch((c) => {
                res(0);
              });
          })
        );
      }
    });

    Promise.all(proms).then((x) => {
      console.log(nestedApiData);
      setNestedTables(nestedApiData);
    });
  }, [dataMode]);

  const zonesArr = [];
  useEffect(() => {
    if (isVisible) {
      if (tableData?.length) {
        tableData?.forEach((element) => {
          if (element.zone) {
            if (!zonesArr.includes(element.zone.toLowerCase())) {
              zonesArr.push(element.zone.toLowerCase());
            }
          }
        });
      }
      console.log("zonesArr", zonesArr);

      zonesArr.forEach((zoneName) => {
        setNewTableData((prevState) => {
          prevState[zoneName] = tableData.filter(
            (element) => element.zone.toLowerCase() === zoneName
          );
          return prevState;
        });
      });
    }
  }, [isVisible]);
  const {
    mutate,
    isLoading: isloading,
  }: {
    mutate: UseMutateFunction<AxiosResponse<any, any>, unknown, any, unknown>;
    isLoading: any;
  } = useMutation(CreateCommanWay, {
    onSuccess: (data: any) => {
      if (data.status == "SUCCESS") {
        UseToast("Data Added Successfully", "success");
        let fetchData:any = {
          country: values.country,
          state: values.state,
          city: values.city,
          bound: values.bound,
          product: values.product,
        }
        if(values.customer != undefined && values.customer != ""){
          fetchData.customer = values.customer;
        }

        fetchRateList({
          url: `/admin/rate/product`,
          data: fetchData,
        });
      } else {
        UseToast(data.message, "error");
      }
    },
    onError: (data: any) => {
      typeof data === "string"
        ? UseToast(data, "error")
        : UseToast(data.message, "error");
    },
  });
  const {
    handleSubmit,
    handleBlur,
    values,
    handleChange,
    errors,
    setValues,
    setErrors,
    touched,
    dirty,
    setFieldValue,
  } = useFormik({
    validationSchema: validationSchema,
    initialValues: initialValues,
    onSubmit: async (values, { resetForm }) => {
      console.log("values", values);
      mutate({
        url: apiUrl + "/create",
        data: values,
      });
    },
  });

  const successNavigate = useCallback(
    debounce(() => navigate(successNav), 200),
    []
  );

  const onCancel = () => {
    navigate(cancelNav);
  };

  return (
    <div className="p-5">
      <form
        onSubmit={(e) => {
          e.preventDefault();
          console.log("dhjfafjhgdsjgajgf", errors);

          handleSubmit(e);
        }}
      >
        <div className="grid grid-cols-3 gap-4">
          {newColumnData.map((c) => {
            if (c.add == true) {
              switch (c.type) {
                case ADT.NUMBER:
                  return (
                    <div key={"general_" + c.key}>
                      <label
                        htmlFor={"general_" + c.key}
                        className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                      >
                        {c.label}
                      </label>
                      <input
                        type="number"
                        id={"general_" + c.key}
                        className={` bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5`}
                        placeholder={c.label}
                        onBlur={handleBlur}
                        onChange={handleChange}
                        name={c.key}
                        value={values[c.key]}
                      />

                      <p className="mt-21 text-sm text-red-600">
                        {touched[c.key] && Boolean(errors[c.key])
                          ? errors[c.key]
                          : ""}
                      </p>
                    </div>
                  );
                  break;
                case ADT.TEXT:
                  return (
                    <div key={"general_" + c.key}>
                      <label
                        htmlFor={"general_" + c.key}
                        className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                      >
                        {c.label}
                      </label>
                      <input
                        type="text"
                        id={"general_" + c.key}
                        className={` bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5`}
                        placeholder={c.label}
                        onBlur={handleBlur}
                        onChange={handleChange}
                        name={c.key}
                        value={values[c.key]}
                      />

                      <p className="mt-21 text-sm text-red-600">
                        {touched[c.key] && Boolean(errors[c.key])
                          ? errors[c.key]
                          : ""}
                      </p>
                    </div>
                  );
                  break;
                case ADT.DB:
                  return (
                    <div key={"general_" + c.key}>
                      <label
                        htmlFor={"general_" + c.key}
                        className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                      >
                        {c.label}
                      </label>

                      <Select
                        key={
                          "select-" +
                          (nestedTables[c.db]
                            ? nestedTables[c.db].length
                            : "undef")
                        }
                        className={` bg-gray-50 border-gray-300 text-gray-900 text-sm rounded-lg block w-full`}
                        id={"general_" + c.key}
                        options={
                          nestedTables[c.db]
                            ? nestedTables[c.db]
                            : defaultOptions
                        }
                        onChange={(el) => {
                          setFieldValue(c.key, el.value);
                        }}
                        name={c.key}
                        value={nestedSelectedOptions[c.db]}
                      />

                      <p className="mt-21 text-sm text-red-600">
                        {touched[c.key] && Boolean(errors[c.key])
                          ? errors[c.key]
                          : ""}
                      </p>
                    </div>
                  );
                  break;
                case ADT.SELECT:
                  return (
                    <div key={"general_" + c.key}>
                      <label
                        htmlFor={"general_" + c.key}
                        className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                      >
                        {c.label}
                      </label>

                      <Select
                        key={"select-" + c.key}
                        className={`bg-gray-50 border-gray-300 text-gray-900 text-sm rounded-lg block w-full`}
                        id={"general_" + c.key}
                        options={c.options}
                        onChange={(el) => {
                          setFieldValue(c.key, el.value);
                        }}
                        name={c.key}
                        //   value={values[c.key]}
                        value={nestedSelectedOptions[c.db]}
                      />

                      <p className="mt-21 text-sm text-red-600">
                        {touched[c.key] && Boolean(errors[c.key])
                          ? errors[c.key]
                          : ""}
                      </p>
                    </div>
                  );
                  break;
                case ADT.BOOLEAN:
                  return (
                    <div key={"general_" + c.key}>
                      <label
                        htmlFor={"general_" + c.key}
                        className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                      >
                        {c.label}
                      </label>

                      <Select
                        key={"select-" + c.key}
                        className={`bg-gray-50 border-gray-300 text-gray-900 text-sm rounded-lg block w-full`}
                        id={"general_" + c.key}
                        options={c.options}
                        onChange={(el) => {
                          setFieldValue(c.key, el.value);
                        }}
                        name={c.key}
                        //   value={values[c.key]}
                        value={nestedSelectedOptions[c.db]}
                      />

                      <p className="mt-21 text-sm text-red-600">
                        {touched[c.key] && Boolean(errors[c.key])
                          ? errors[c.key]
                          : ""}
                      </p>
                    </div>
                  );
                  break;

                case ADT.TEXTAREA:
                  break;
                case ADT.DATETIME:
                  break;
                case ADT.FILE:
                  break;
                default:
                  return <></>;
                  break;
              }
            }
          })}
        </div>
        <div
          className="inline-flex rounded-md overflow-hidden shadow-sm mb-1 mt-4"
          role="group"
        >
          <button
            type="submit"
            className="px-4 py-2 text-sm font-medium text-white bg-green-500 border border-gray-200 hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-2 focus:ring-blue-700 focus:text-blue-700"
          >
            Add
          </button>
          <button
            type="button"
            onClick={onCancel}
            className="px-4 py-2 text-sm font-medium text-white bg-gray-500 border border-gray-200 hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-2 focus:ring-blue-700 focus:text-blue-700"
          >
            Back to List
          </button>
        </div>
      </form>
      {console.log(isVisible, newTableData)}
      {isVisible && Object.keys(newTableData)?.length
        ? Object.keys(newTableData)?.map((zoneName) => (
            <div className="flex" key={zoneName}>
              <div className="flex flex-col flex-1 overflow-auto p-5">
                <h3 className="mb-3">{zoneName.toUpperCase()} Dox</h3>
                <RateDataTables
                  routeUrl={`/master/rate`}
                  columnData={columnData}
                  tableData={newTableData[zoneName].filter(
                    (data) => data.is_dox === true
                  )}
                  selectable={true}
                  customClasses="flex-1"
                />
              </div>
              <div className="flex flex-col flex-1 overflow-auto p-5 ml-3">
                <h3 className="mb-3">{zoneName.toUpperCase()} Non-Dox</h3>
                <RateDataTables
                  routeUrl={`/master/rate`}
                  columnData={columnData}
                  tableData={newTableData[zoneName].filter(
                    (data) => data.is_dox === false
                  )}
                  selectable={true}
                  customClasses="flex-1"
                />
              </div>
            </div>
          ))
        : null}
    </div>
  );
};

export default RateCreate;
