import { MinusCircleIcon } from "@heroicons/react/24/outline";
import { UseMutateFunction, useMutation, useQuery } from "@tanstack/react-query";
import { CreateCommanWay, GetCommanWay, ListCommanWay, UpdateBookingCommanWay, UpdateCommanWay } from "API/commonApi";
import { AxiosResponse } from "axios";
import { Button, Input } from "components";
import { InputTable } from "components/DataTable/InputTable";
import { useFormik } from "formik";
import { debounce } from "lodash";
import moment from "moment";
import { any } from "prop-types";
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Select from "react-select";
import Swal from "sweetalert2";
import UseToast from "utils/AfToast";
import { roles } from "utils/constant";
import handleWheel from "utils/handleOnWheel";
import { AfDataType as ADT } from "utils/TableColumnData";
import { number, object, string } from "yup";

type OptionType = {
  value: number;
  label: string;
};

interface MotherBagState {
  mode?: string;
  data?: { [key: string]: any }; // Adjust the type based on your data structure
  // Add other properties if needed
}

interface ValuesType {
  destination?: { value: string; label: string };
}

const Overedge: React.FC = ({}) => {
  let isDisabled = false;
  const navigate = useNavigate();
  const { state } = useLocation();
  const { mode = "add", data = {} } = (state || {}) as MotherBagState; // Check for existence and type assertion

  const [awbScanVal, setAwbScanVal] = useState<any>("");
  const [acceptedAwbNos, setAcceptedAwbNos] = useState<any>([]);

  const [awbTrackingData, setAwbTrackingData] = useState<any>({
    tracking_data: [],
    bookingDate: "",
    awbData: {},
    bookingData: {},
  });
  const [deliveryDoneInfo, setDeliveryDoneInfo] = useState<any>({});
  const [productList, setProductList] = useState<any>([]);
  const [showVolume, setShowVolume] = useState<any>(false);

  const [dataList, setDataList] = useState<any>([]);
  const { refetch: refetchData } = useQuery(["DRSAwbList"], () => ListCommanWay("/admin/drs_awb/list", {}, {}), {
    keepPreviousData: true,
    onSuccess(data) {
      if (data.status == "SUCCESS") {
        console.log(data.data);
        setDataList(data.data || []);
      }
    },
  });

  const onInputAWB = (no) => {};

  const { mutate: mutateSearchAwb, isLoading: isUpdateAWBloading } = useMutation(
    (awbNo) => GetCommanWay("/admin/proof_of_delivery/track/" + awbNo, {}, {}),
    {
      onSuccess: (data: any) => {
        console.log(data, data.status == "SUCCESS");
        if (data.status == "SUCCESS") {
          setAwbTrackingData(data.data);
        } else {
          UseToast(data.message, "error");
        }
      },
      onError: (data: any) => {
        typeof data === "string" ? UseToast(data, "error") : UseToast(data.message, "error");
      },
    }
  );

  const { mutate: mutateOveredgeAwb } = useMutation((awbId) => GetCommanWay("/admin/proof_of_delivery/overedge/" + awbId, {}, {}), {
    onSuccess: (data: any) => {
      console.log(data, data.status == "SUCCESS");
      if (data.status == "SUCCESS") {
        UseToast("Overedge Success", "success");
        mutateSearchAwb(awbScanVal);
      } else {
        UseToast(data.message, "error");
      }
    },
    onError: (data: any) => {
      typeof data === "string" ? UseToast(data, "error") : UseToast(data.message, "error");
    },
  });

  const getTRline = (name, value) => {
    return (
      <tr>
        <td className="text-right pr-5">{name}</td>
        <td>{value}</td>
      </tr>
    );
  };
  const getChargeableWeight = (weight, product_code, ll, bb, hh) => {
    ll = Number(ll);
    bb = Number(bb);
    hh = Number(hh);
    let dWeight = (ll * bb * hh) / (product_code == "X" ? 2700 : 5000);
    return Math.max(Number(weight), dWeight);
  };

  const disableEnterKey = (event) => {
    if (event.key === "Enter" || event.key === "Tab") {
      event.preventDefault();
    }
  };

  return (
    <>
      <h1 className="text-center mb-4 mt-3 text-lg font-semibold underline">Overedge (Missroute)</h1>

      <div>
        {/* <div className="grid grid-cols-2 sm:grid-cols-1 gap-4 px-4"> */}
        <div className="flex gap-2 flex-wrap border p-2">
          <div className="w-full text-center flex justify-center items-center gap-4">
            Scan AWB No:{" "}
            <input
              type="text"
              value={awbScanVal}
              className="bg-blue-200 rounded"
              onKeyDown={disableEnterKey}
              onInput={(e: any) => {
                setAwbScanVal(e.target.value);
              }}
            />
            <Button
              type="button"
              className="rounded-md inline-block px-4 py-2 text-sm font-medium text-white bg-blue-800 border border-gray-200 hover:bg-opacity-60 focus:z-10 "
              onClick={() => {
                mutateSearchAwb(awbScanVal);
              }}
            >
              Search
            </Button>
            {awbTrackingData.awbData?.id && (
              <Button
                type="button"
                className="rounded-md inline-block px-4 py-2 text-sm font-medium text-white bg-green-700 hover:bg-opacity-60 focus:z-10 "
                onClick={() => {
                  mutateOveredgeAwb(awbTrackingData.awbData?.id);
                }}
              >
                In-Scan
              </Button>
            )}
          </div>
        </div>

        <div>
          {showVolume && (
            <div className="absolute left-0 top-0 w-full h-full bg-black-900 bg-opacity-20 flex items-center justify-center">
              <div className="rounded-xl border-2 border-primary p-4 bg-white relative">
                <div className="flex items-center justify-between bg-primary text-white p-2 rounded-lg">
                  <p>Total Weight: {awbTrackingData?.awbData?.weight}</p>
                  <button
                    className="px-1"
                    onClick={() => {
                      setShowVolume(false);
                    }}
                  >
                    ✖
                  </button>
                </div>

                <div>
                  <table className="table mt-2">
                    <tr>
                      <th className="px-2 border-r">Sr.</th>
                      <th className="px-2">Lenght</th>
                      <th className="px-2">Width</th>
                      <th className="px-2">Height</th>
                      <th className="px-2">Weight</th>
                    </tr>
                    {awbTrackingData?.awbData?.innerItemsJson ? (
                      JSON.parse(awbTrackingData?.awbData?.innerItemsJson).map((i, a) => {
                        return (
                          <tr className="border-t">
                            <td className="px-4 border-r">{a + 1}</td>
                            <td className="px-4">{i.l}</td>
                            <td className="px-4">{i.b}</td>
                            <td className="px-4">{i.h}</td>
                            <td className="px-4">{i.weight}</td>
                          </tr>
                        );
                      })
                    ) : (
                      <tr className="border-t">
                        <td className="px-4">1</td>
                        <td className="px-4">{awbTrackingData?.awbData?.l}</td>
                        <td className="px-4">{awbTrackingData?.awbData?.b}</td>
                        <td className="px-4">{awbTrackingData?.awbData?.h}</td>
                        <td className="px-4">{awbTrackingData?.awbData?.weight}</td>
                      </tr>
                    )}
                  </table>
                </div>
              </div>
            </div>
          )}

          {awbTrackingData.awbData?.id && (
            <div className="flex flex-col gap-4 px-4 mt-2">
              <div className=" border p-2 rounded-2xl">
                <div className="flex gap-2">
                  <table>
                    {getTRline(
                      "Product",
                      productList
                        .map((p) => {
                          return p.product_code == awbTrackingData.awbData.prefix ? p.product_name : "";
                        })
                        .join("")
                    )}
                    {getTRline("Reference Code", awbTrackingData.bookingData.pickup_code)}
                    {getTRline("From", awbTrackingData.bookingData.shipper_city)}
                    {getTRline("To", awbTrackingData.awbData.destination)}
                    {getTRline("Product Type", awbTrackingData.awbData.dox_or_non_dox ? "DOX" : "Non-Dox")}
                    {getTRline("Client Name", awbTrackingData.bookingData.customer_sh)}
                    {getTRline("Shipper Name", awbTrackingData.bookingData.shipper_sh)}
                    {getTRline("Shipper Addr", awbTrackingData.bookingData.shipper_address)}
                    {getTRline("Consignee Name", awbTrackingData.awbData.consignee)}
                    {getTRline("Consignee Addr", awbTrackingData.awbData.address)}
                    {getTRline("Pincode", awbTrackingData.awbData.pincode)}
                  </table>

                  <table>
                    {getTRline("Book Date", awbTrackingData.bookingData?.createdAt)}
                    {getTRline("Receiver", deliveryDoneInfo.receiver)}
                    {getTRline("Relation", deliveryDoneInfo.receiver_relation)}
                    {getTRline("Delivery Date", deliveryDoneInfo.createdAt)}
                    {getTRline("Shipper Mobile", awbTrackingData.bookingData?.consignee_mobile)}
                    {getTRline("Consignee Mobile", awbTrackingData.bookingData?.shipper_mobile)}
                    {getTRline("MAWB No", awbTrackingData?.awbData?._mother_bag_id?.mawb || "-")}
                    {getTRline("Motherbag No", awbTrackingData?.awbData?._mother_bag_id?.mother_bag_code || "-")}
                    {/*getTRline("TP ref. No.", awbTrackingData?.awbData?._mother_bag_id?.refNo || "-")*/}
                    {getTRline("TP Name", <b>{awbTrackingData?.awbData?.thirdPartyName || "-"}</b>)}
                    {getTRline("TP ref. No.", <b>{awbTrackingData?.awbData?.thirdPartyRef || "-"}</b>)}
                    {getTRline("Delivery Emp.", deliveryDoneInfo._addedBy?.name)}
                    {getTRline(
                      <b>Current Status</b>,
                      awbTrackingData.tracking_data[awbTrackingData.tracking_data.length - 1]?.message_code
                    )}
                    {awbTrackingData.tracking_data[awbTrackingData.tracking_data.length - 1]?.message_code.includes("RTO") &&
                      getTRline(
                        <b>RTO Awb No.</b>,
                        (
                          <a style={{ color: "blue" }} href={`/pod/tracking?awb=${awbTrackingData.awbData?.returnAwb}`}>
                            {awbTrackingData.awbData?.returnAwb}
                          </a>
                        ) || "-"
                      )}
                  </table>
                </div>
                <div className="flex justify-between mt-2 border-t pt-2">
                  <div className="flex gap-8">
                    <p>
                      Weight: <b>{awbTrackingData.awbData.weight} kg</b>
                    </p>
                    <p>
                      Chargeable Weight:{" "}
                      <b>
                        {getChargeableWeight(
                          awbTrackingData.awbData.weight,
                          awbTrackingData.awbData.prefix,
                          awbTrackingData.awbData.l,
                          awbTrackingData.awbData.b,
                          awbTrackingData.awbData.h
                        )}{" "}
                        kg
                      </b>
                    </p>
                    <p>
                      10_30: <b>{awbTrackingData.awbData.is10_30 ? "YES" : "NO"}</b>
                    </p>
                    <p>
                      Holiday: <b>{awbTrackingData.awbData.holiday ? "YES" : "NO"}</b>
                    </p>
                  </div>

                  <div>
                    <Button
                      className="bg-blue-600 px-2 py-1 rounded-md text-white mr-3"
                      onClick={() => {
                        setShowVolume(true);
                      }}
                    >
                      Show Volume Info
                    </Button>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default Overedge;
