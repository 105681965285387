import { UseMutateFunction, useMutation, useQuery } from "@tanstack/react-query";
import { CreateCommanWay, GetCommanWay, ListCommanWay } from "API/commonApi";
import { AxiosResponse } from "axios";
import { forIn } from "lodash";
import moment from "moment";
import React, { useEffect, useState } from "react";
import Barcode from "react-barcode";
import Select from "react-select";
import Swal from "sweetalert2";
import UseToast from "utils/AfToast";

const GenerateAwb = () => {
  const [startAwbNo, setStartAwbNo] = useState<number>();
  const [qty, setQty] = useState<number>(1);

  const [productList, setProductList] = useState<any>([]);
  const [selectedProduct, setSelectedProduct] = useState<any>();

  const [branchList, setBranchList] = useState<any>([]);
  const [selectedBranch, setSelectedBranch] = useState<any>();

  const [result, setResult] = useState([]);

  useQuery(["ProductListApi"], () => ListCommanWay("/admin/product/list", {}, { paginate: 1000 }), {
    keepPreviousData: true,
    onSuccess(data) {
      if (data.status == "SUCCESS") {
        console.log(data.data.data);
        setProductList(data.data.data);
      }
    },
  });

  useQuery(["subbranchListApi"], () => ListCommanWay("/admin/subbranch/list", {}, { paginate: 1000 }), {
    keepPreviousData: true,
    onSuccess(data) {
      if (data.status == "SUCCESS") {
        setBranchList(data.data.data);
      }
    },
  });

  const { refetch: refetchLastAwb } = useQuery(
    ["refetchLastAwbApi"],
    () =>
      ListCommanWay(
        "/admin/awb_data/get_last",
        {
          product: selectedProduct?.product_code,
        },
        { paginate: 1000 }
      ),
    {
      enabled: false,
      keepPreviousData: true,
      onSuccess(data) {
        if (data.status == "SUCCESS") {
          try {
            if (data.data.data[0].postfix) {
              setStartAwbNo(Number(data.data.data[0].postfix) + 1);
            }
          } catch (c) {
            setStartAwbNo(10000000000 + 1);
          }
        }
      },
    }
  );
  useEffect(() => {
    if (selectedProduct && selectedProduct?.product_code) {
      refetchLastAwb();
    }
  }, [selectedProduct]);

  useEffect(() => {
    console.log("branmch list change", branchList);
  }, [branchList]);

  const {
    mutate,
    isLoading: isloading,
  }: {
    mutate: UseMutateFunction<AxiosResponse<any, any>, unknown, any, unknown>;
    isLoading: any;
  } = useMutation(
    (awb_no) =>
      CreateCommanWay({
        url: "/admin/awb/create",
        data: {
          startAwbNo: startAwbNo,
          quantity: qty,
          product: selectedProduct.product_code,
          subbranch: selectedBranch.id,
        },
      }),
    {
      onSuccess: (data: any) => {
        console.log(data, data.status == "SUCCESS");
        if (data.status == "SUCCESS") {
          Swal.fire({
            title: "Assign Success",
            icon: "success",
          });
        } else {
          UseToast(data.message, "error");
        }
      },
      onError: (data: any) => {
        typeof data === "string" ? UseToast(data, "error") : UseToast(data.message, "error");
      },
    }
  );

  const handleSubmit = () => {
    console.log(startAwbNo);
    mutate(startAwbNo);
  };
  const handlePrint = () => {
    let w = window.open();
    w.document.write(document.getElementById("result").innerHTML);
    w.print();
    w.close();
  };

  return (
    <>
      <h1 className="text-center mb-4 mt-3 text-lg font-semibold underline">AWB Generate and assign</h1>

      <div className="flex flex-col gap-4 px-4">
        <div className="flex gap-2 flex-wrap border p-2 rounded-2xl">
          <div className={`inline-flex items-center gap-2 w-auto`}>
            <label className="mb-2 text-sm font-semibold text-gray-900">Product</label>
            <Select
              className={`bg-gray-50 border-gray-300 text-gray-900 text-sm rounded-lg block w-full`}
              options={productList}
              getOptionValue={(e: any) => {
                return e.id;
              }}
              getOptionLabel={(e: any) => {
                return e.product_name;
              }}
              onChange={(e: any) => {
                setSelectedProduct(e);
              }}
              name={"product"}
            />
          </div>

          <div className={`inline-flex items-center gap-2 w-auto`}>
            <label className="mb-2 text-sm font-semibold text-gray-900">Start</label>
            <input
              type="number"
              className={`bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5`}
              placeholder={"Starting From"}
              name="startAwbNo"
              value={startAwbNo || ""}
              onBlur={(e) => {
                setStartAwbNo(Number(e.target.value));
              }}
              onChange={(e) => setStartAwbNo(Number(e.target.value))}
            />
          </div>

          <div className={`inline-flex items-center gap-2 w-auto`}>
            <label className="mb-2 text-sm font-semibold text-gray-900">Quantity</label>
            <input
              type="number"
              className={`bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5`}
              placeholder={"Starting From"}
              name="qty"
              value={qty}
              onBlur={(e) => {
                setQty(Number(e.target.value));
              }}
              onChange={(e) => setQty(Number(e.target.value))}
            />
          </div>

          <div className={`inline-flex items-center gap-2 w-auto`}>
            <label className="mb-2 text-sm font-semibold text-gray-900">Branch</label>
            <Select
              className={`bg-gray-50 border-gray-300 text-gray-900 text-sm rounded-lg block w-full`}
              options={branchList}
              getOptionValue={(e: any) => {
                return e.id;
              }}
              getOptionLabel={(e: any) => {
                return e.branch_name;
              }}
              onChange={(e: any) => {
                setSelectedBranch(e);
              }}
              name={"branch"}
            />
          </div>

          <button
            type="button"
            onClick={handlePrint}
            className="rounded-md px-4 py-2 text-sm font-medium text-white bg-green-500 border border-gray-200 hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-2 focus:ring-blue-700 focus:text-blue-700"
          >
            Print
          </button>
          <button
            type="button"
            onClick={handleSubmit}
            className="rounded-md px-4 py-2 text-sm font-medium text-white bg-green-500 border border-gray-200 hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-2 focus:ring-blue-700 focus:text-blue-700"
          >
            Assign
          </button>
        </div>
        <div id="result">
          {[...Array(qty)].map((e, i) => {
            let main_string = (startAwbNo + i);
            let divide_calc = main_string / 7;
            let mutiply_string = Number(divide_calc.toFixed(1));

            let n = mutiply_string.toString().indexOf(".");
            if (n != -1) {
              let aa = mutiply_string.toString().split(".")[1];
              mutiply_string = Math.round(parseFloat("0." + aa) * 7);
            } else {
              mutiply_string = 0;
            }

            let code = selectedProduct?.product_code + (startAwbNo + i).toString() + mutiply_string;

            return (
              <>
                <div style={{ display: "inline-block", margin: `${(i + 1) % 2 == 0 ? "0 0 0 0.5cm" : "0 0.5cm"}`, marginTop: "0.8cm" }}>
                  <div style={{ textAlign: "center", fontFamily: "arial" }}>
                    <b>AIRFIELD EXPRESS</b>
                    <br />
                    <Barcode value={code} marginTop={0.75} width={1.5} height={50} />
                    <div>
                      <span style={{ fontSize: "0.8em" }}>Track us: www.airfield.co.in</span>
                      <br />
                      <span style={{ fontSize: "0.8em" }}>Email Id: info@airfield.co.in</span>
                      <br />
                      <span style={{ fontSize: "0.8em" }}>Tollfree No.: 92272 23131</span>
                      <br />
                      <b style={{ fontSize: "1.25em" }}>{selectedBranch && selectedBranch?.branch_code}</b>
                    </div>
                  </div>
                </div>
                {(i + 1) % 2 == 0 && <div style={{ pageBreakAfter: "always" }}></div>}
              </>
            );
          })}
        </div>
      </div>
    </>
  );
};

export default GenerateAwb;
