import React from "react";
import { DataTable } from "components/DataTable/DataTable";
import { AfDataType, GenericColumnData } from "utils/TableColumnData";
import { DataTableWithoutButtons } from "components/DataTable/DataTableWithoutButtons";
import { useNavigate } from "react-router-dom";

const viewActionColumn = {
  label: "Action",
  key: "id",
  render: (_) => {
    return `<a>${_}</a>`;
  },
  type: AfDataType.VIEW,
  view: true,
  add: false,
  edit: false,
  filter: false,
  sort: false,
};
const BookingList: React.FC<any> = (props) => {
  const ADT = AfDataType;
  const navigator = useNavigate();

  const getChargeableWeight = (weight, product_code, ll, bb, hh) => {
    let dWeight = (ll * bb * hh) / (product_code == "X" ? 2700 : 5000);
    return Math.max(Number(weight), dWeight);
  };

  const tableColumnData = [
    {
      label: "AWB No",
      key: "awb_no",
      type: ADT.TEXT,
      view: true,
      add: false,
      edit: true,
      filter: false,
      sort: false,
    },
    {
      label: "Cust Code",
      key: "customer_code",
      type: ADT.TEXT,
      view: true,
      add: true,
      edit: true,
      filter: false,
      sort: true,
    },
    {
      label: "Type",
      key: "type",
      type: ADT.TEXT,
      view: true,
      add: true,
      edit: true,
      filter: false,
      sort: true,
    },
    {
      label: "Date",
      key: "date",
      type: ADT.DATE,
      dateFormat: "YYYY-MM-DD",
      view: true,
      add: true,
      edit: true,
      filter: false,
      sort: true,
    },
    {
      label: "Shipper",
      key: "shipper_sh",
      type: ADT.TEXT,
      view: true,
      add: true,
      edit: true,
      filter: false,
      sort: true,
    },
    {
      label: "Consignee",
      key: "consignee_cgnee",
      type: ADT.TEXT,
      view: true,
      add: true,
      edit: true,
      filter: false,
      sort: true,
    },
    {
      label: "Origin",
      key: "shipper_city",
      type: ADT.TEXT,
      view: true,
      add: true,
      edit: true,
      filter: false,
      sort: true,
    },
    {
      label: "Dest.",
      key: "destination",
      type: ADT.TEXT,
      view: true,
      add: true,
      edit: true,
      filter: false,
      sort: true,
    },
    {
      label: "Chargeable Weight",
      key: "weight",
      render: (_, row) => {
        return getChargeableWeight(Number(_), row.prefix, Number(row.l), Number(row.b), Number(row.h));
      },
      type: ADT.TEXT,
      view: true,
      add: false,
      edit: false,
      filter: false,
      sort: false,
    },
    {
      label: "Pieces",
      key: "quantity",
      type: ADT.TEXT,
      view: true,
      add: true,
      edit: true,
      filter: false,
      sort: true,
    },
    {
      label: "Action",
      key: "editId",
      render: (_) => {
        return `<a>${_}</a>`;
      },
      type: ADT.EDIT,
      view: true,
      add: false,
      edit: false,
      filter: false,
      sort: false,
    },
    /*{
      label: "Action",
      key: "viewId",
      render: (_) => {
        return `<a>${_}</a>`;
      },
      type: ADT.VIEW,
      view: true,
      add: false,
      edit: false,
      filter: false,
      sort: false,
    },*/
    {
      label: "Download",
      key: "inv",
      render: (_, r) => {
        return (
          <a target="_blank" href={`${process.env.REACT_APP_BACKEND_URL}/invoices/${r.subbranch}/${_}.pdf`} className="text-blue-A700">
            Download PDF
          </a>
        );
      },
      type: ADT.TEXT,
      view: true,
      add: false,
      edit: false,
      filter: false,
      sort: false,
    },
  ];

  return (
    <>
      <DataTableWithoutButtons
        apiUrl={`/admin/booking/list`}
        columnData={tableColumnData}
        handleEdit={(data) => {
          navigator("/booking/add", {
            state: {
              mode: "edit",
              data,
            },
          });
        }}
        handleView={(data) => {
          navigator("/booking/add", {
            state: {
              mode: "view",
              data,
            },
          });
        }}
      />
    </>
  );
};

export default BookingList;
