import React from "react";
import { GenericColumnData } from "utils/TableColumnData";
import { GeneralAddForm } from "components/GeneralAddForm/GeneralAddForm";

const GenericTableDetails: React.FC<any> = (props) => {
    return (
        <>
            <GeneralAddForm
                successNav={`/master/${props.tableName}/list`}
                cancelNav={`/master/${props.tableName}/list`}
                apiUrl={`/admin/${props.tableName}`}
                mode="View"
                columnData={GenericColumnData[props.tableName].tableColumnData}
                validationSchema={GenericColumnData[props.tableName].validationSchema}
                initialValues={GenericColumnData[props.tableName].initialValues}
                selectable={true}
                doAdd={true}
                doEdit={true}
                doDelete={true}
                doExport={true}
                paginate={true}
            />
        </>
    );
};

export default GenericTableDetails;
