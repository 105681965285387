import React, { useEffect, useState } from "react";
import { ErrorMessage } from "../ErrorMessage";
import { useQuery } from "@tanstack/react-query";
import { ListCommanWay } from "API/commonApi";
import { Link } from "react-router-dom";
import { AfDataType } from "utils/TableColumnData";
import { PencilSquareIcon, PlusCircleIcon } from "@heroicons/react/24/outline";

export type AfTableProps = Partial<{
  onColor: string;
  offColor: string;
  onHandleColor: string;
  offHandleColor: string;
  value: string | boolean;
  className: string;
  checkedIcon: React.ReactNode;
  uncheckedIcon: React.ReactNode;
  errors: any[];
  onChange: Function;
  routeUrl: string;

  columnData: any[];
  tableData: any[];
  selectable: boolean;
  customClasses: string;
  cellMode: string;
  setCellMode: any;
  newTableData: any;
  setNewTableData: any;
  formData: any;
  isAnyChange: boolean;
  setIsAnyChange: any;
}>;

const RateDataTables: React.FC<AfTableProps> = ({
  onChange,

  onColor = "",
  offColor = "",
  onHandleColor = "",
  offHandleColor = "",
  value = false,
  className = "",
  checkedIcon = <></>,
  uncheckedIcon = <></>,
  errors = [],

  columnData = [],
  tableData = [],
  routeUrl = "/",
  customClasses = "",
  cellMode,
  setCellMode,
  newTableData,
  setNewTableData,
  formData,
  isAnyChange,
  setIsAnyChange,
}) => {
  let incAry = [];
  columnData.forEach((c) => {
    if (c.db_col) {
      incAry.push("_" + c.db_col);
    }
  });
  //setIncludes(incAry);
  const renderSwitch = (param) => {
    switch (param) {
      case "foo":
        return "bar";
      default:
        return "foo";
    }
  };

  useEffect(()=>{
    console.log(tableData);
  }, [tableData]);

  const handleChange = (e, id) => {
    console.log("handle change id:", id);
    setNewTableData((prevData) => {
      const newData = prevData[tableData[0].zone.toLowerCase()].find(
        (d) => Number(d.id) === Number(id)
      );
      console.log(e, id);
      console.log(tableData, prevData, tableData[0].zone.toLowerCase());
      console.log(newData, [e.target.name]);
      if(newData){
        newData[e.target.name] = Number(e.target.value);
      }else{
        newData[e.target.name] = Number(e.target.value);
      }

      return {
        ...prevData,
        [tableData[0].zone.toLowerCase()]: [
          ...prevData[tableData[0].zone.toLowerCase()].filter(
            (d) => d.id !== id
          ),
          newData,
        ].sort((a, b) => Number(a.id) - Number(b.id)),
      };
    });
  };

  return (
    <div className={`flex ${customClasses}`}>
      <table className="w-full text-sm text-left text-gray-500 border-r border-t">
        <thead className="text-xs text-gray-700 uppercase bg-gray-50 ">
          <tr>
            {[{ type: "button", view: true }, ...columnData].map((c) => {
              return (
                c.view && (
                  <th
                    scope="col"
                    className="px-4 py-3 border-l border-b"
                    key={c.label}
                  >
                    <div className="flex items-center">
                      {c.label}
                      
                    </div>
                  </th>
                )
              );
            })}
          </tr>
        </thead>

        <tbody>
          {cellMode === "R"
            ? tableData.map((rd, j) => {
                return (
                  <tr className="bg-white border-b" key={"tbl-tr-" + j}>
                    {[{ type: "button", view: true }, ...columnData].map(
                      (cd, i) => {
                        return (
                          cd.view && (
                            <td
                              className="border-l px-0.5 py-0.5"
                              key={"tbl-td-" + i}
                            >
                              {cd.type == AfDataType.VIEW ? (
                                <Link
                                  className="text-dark1"
                                  to={`${routeUrl}/list/${rd[cd.key]}`}
                                >
                                  View
                                </Link>
                              ) : cd.type == AfDataType.DB ? (
                                <>
                                  {rd["_" + cd.key]
                                    ? rd["_" + cd.key][cd.db_col]
                                    : "-"}
                                </>
                              ) : cd.type == AfDataType.BOOLEAN ? (
                                <>{rd[cd.key] === true ? "true" : "false"}</>
                              ) : cd.type === "button" ? (
                                j === tableData.length - 1 && (
                                  <button
                                    onClick={() => {
                                      setCellMode("R");
                                      let maxId = 0;
                                      Object?.keys(newTableData)?.forEach(
                                        (zoneName) => {
                                          newTableData[zoneName]?.forEach(
                                            (data) => {
                                              if (
                                                Number(maxId) < Number(data?.id)
                                              ) {
                                                maxId = Number(data?.id);
                                              }
                                            }
                                          );
                                        }
                                      );
                                      setNewTableData((prevData) => {
                                        return {
                                          ...prevData,
                                          [tableData[0].zone.toLowerCase()]: [
                                            ...prevData[
                                              tableData[0].zone.toLowerCase()
                                            ],
                                            {
                                              id:
                                                (
                                                  Number(maxId) + 1
                                                ).toString() ||
                                                Math.round(
                                                  Math.random() * 100000000
                                                ).toString(),
                                              zone: tableData[0].zone,
                                              is_dox: tableData[0].is_dox,
                                              ...formData,
                                            },
                                          ],
                                        };
                                      });
                                    }}
                                    className="px-1 py-1 text-sm font-medium rounded text-white bg-blue-500 border border-gray-200 hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-2 focus:ring-blue-700 focus:text-blue-700"
                                  >
                                    {/* <PencilSquareIcon className="flex-shrink-0 h-6 w-6" /> */}
                                    <PlusCircleIcon className="flex-shrink-0 h-6 w-6" />
                                  </button>
                                )
                              ) : (
                                <input
                                  type="number"
                                  id={"general_" + cd.key}
                                  className={` bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-md focus:ring-blue-500 focus:border-blue-500 block w-full p-1`}
                                  onWheel={(e:any) => e?.target?.blur()} 
                                  placeholder={cd.label}
                                  onBlur={(e) => {
                                    handleChange(e, rd.id);
                                  }}
                                  onChange={(e) => {
                                    handleChange(e, rd.id);
                                  }}
                                  name={cd.key}
                                  value={rd[cd.key]}
                                />
                              )}
                            </td>
                          )
                        );
                      }
                    )}
                  </tr>
                );
              })
            : tableData
                .sort((a, b) => a.from - b.from)
                .map((rd, j) => {
                  return (
                    <tr className="bg-white border-b" key={"tbl-tr-" + j}>
                      {[{ type: "button", view: true }, ...columnData].map(
                        (cd, i) => {
                          return (
                            cd.view && (
                              <td
                                className="border-l px-2 py-1"
                                key={"tbl-td-" + i}
                              >
                                {cd.type == AfDataType.VIEW ? (
                                  <Link
                                    className="text-dark1"
                                    to={`${routeUrl}/list/${rd[cd.key]}`}
                                  >
                                    View
                                  </Link>
                                ) : cd.type == AfDataType.DB ? (
                                  <>
                                    {rd["_" + cd.key]
                                      ? rd["_" + cd.key][cd.db_col]
                                      : "-"}
                                  </>
                                ) : cd.type == AfDataType.BOOLEAN ? (
                                  <>{rd[cd.key] === true ? "true" : "false"}</>
                                ) : cd.type === "button" ? (
                                  j === tableData.length - 1 && (
                                    <button
                                      onClick={() => {
                                        setCellMode("R");
                                        let maxId = 0;
                                        Object?.keys(newTableData)?.forEach(
                                          (zoneName) => {
                                            newTableData[zoneName]?.forEach(
                                              (data) => {
                                                if (
                                                  Number(maxId) <
                                                  Number(data?.id)
                                                ) {
                                                  maxId = Number(data?.id);
                                                }
                                              }
                                            );
                                          }
                                        );
                                        setNewTableData((prevData) => {
                                          return {
                                            ...prevData,
                                            [tableData[0].zone.toLowerCase()]: [
                                              ...prevData[
                                                tableData[0].zone.toLowerCase()
                                              ],
                                              {
                                                id:
                                                  (
                                                    Number(maxId) + 1
                                                  ).toString() ||
                                                  Math.round(
                                                    Math.random() * 100000000
                                                  ).toString(),
                                                zone: tableData[0].zone,
                                                is_dox: tableData[0].is_dox,
                                                ...formData,
                                              },
                                            ],
                                          };
                                        });
                                      }}
                                      className="px-1 py-1 text-sm font-medium rounded text-white bg-blue-500 border border-gray-200 hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-2 focus:ring-blue-700 focus:text-blue-700"
                                    >
                                      {/* <PencilSquareIcon className="flex-shrink-0 h-6 w-6" /> */}
                                      <PlusCircleIcon className="flex-shrink-0 h-6 w-6" />
                                    </button>
                                  )
                                ) : cellMode === "N" ? (
                                  <div>{rd[cd.key]}</div>
                                ) : (
                                  <input
                                    type="number"
                                    id={"general_" + cd.key}
                                    className={` bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-1.5`}
                                    placeholder={cd.label}
                                    onBlur={(e) => {
                                      handleChange(e, rd.id);
                                    }}
                                    onChange={(e) => {
                                      handleChange(e, rd.id);
                                    }}
                                    name={cd.key}
                                    value={rd[cd.key]}
                                  />
                                )}
                              </td>
                            )
                          );
                        }
                      )}
                    </tr>
                  );
                })}
        </tbody>
      </table>
      <ErrorMessage errors={errors} />
    </div>
  );
};

export { RateDataTables };
