import axios, { type ResponseType } from 'axios'
import { useAuthStore } from '../store/auth'
import { type AxiosResponse } from '../types'
let authToken = useAuthStore.getState().token
const removeUser = useAuthStore.getState().removeAll

useAuthStore.subscribe((state) => {
  // subscribe to token change and updating it
  authToken = state.token
})

axios.interceptors.request.use(
  function (config) {
    if (config.headers == null) {
      config.headers = {}
    }
    if (authToken) {
      config.headers.Authorization = 'Bearer ' + authToken
    }
    config.headers['Content-Type'] = 'application/json'
    config.headers.Accept = 'application/json'

    return config
  },

  async function (error) {
    return await Promise.reject(error)
  }
)

axios.interceptors.response.use(
  async function (response) {
    // Any status code that lie within the range of 2xx cause this function to trigger
    if (response.headers['content-type']?.includes('application/json')) {
      response = response.data
      if (response.status) {
        return response
      } else {
        return await Promise.reject(response)
      }
    } else if (response.status === 200) {
      return response
    }

    return await Promise.reject(response)
  },

  async function (error) {
    if (error?.response?.status === 401 ) {
      removeUser()
    }
    console.log(error.response)
    // Any status codes that falls outside the range of 2xx cause this function to trigger
    /*const message =
      error.response?.status === 404
        ? 'API not found.'
        : error?.response?.data?.message || 'Something went wrong'*/
        const message = error?.response?.data?.message || 'Something went wrong';
    return await Promise.reject(message)
  }
)

export const request = async ({
  url,
  method = 'GET',
  params,
  body,
  headers,
  responseType
}: {
  url: string
  method?: 'GET' | 'POST' | 'PUT' | 'DELETE' | 'PATCH'
  params?: any
  body?: any
  headers?: any
  responseType?: ResponseType
}) => {
  const BASE_URL = process.env.REACT_APP_BACKEND_URL

  const res: AxiosResponse = await axios.request({
    url: BASE_URL + url,
    method,
    params,
    data: body,
    headers,
    responseType
  })

  return res
}
