/* eslint-disable react-hooks/exhaustive-deps */
import React, { Suspense } from "react";
import { Route, BrowserRouter as Router, Routes } from "react-router-dom";
import PrivateRouter, { PrivateRouteProps } from "./PrivateRouter";
import PublicRouter, { PublicRouteProps } from "./PublicRouter";
import Spinner from "../../utils/Spinner";
import { DefaultLayout } from "../../LayoutPage/MainLayout";
// import { ForgotPassword }  from "../../Layout/ForgotPassword";
// import { ResetPassword }  from "../../Layout/ResetPassword";
import { Login } from "../../pages/Auth";
import PublicTrackingAWB from "pages/POD/PublicTrackingAWB";

const RootNavigator = () => {
  return (
    <Suspense
      fallback={
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100%",
          }}
        >
          <Spinner />
        </div>
      }
    >
      {/* <Router> */}
      <Routes>
        <Route path="/login" element={<PublicRouter {...defaultPublicRouteProps} outlet={<Login />} />} />
        <Route path="/public-tracking" element={<PublicTrackingAWB />} />

        <Route path="*" element={<PrivateRouter {...defaultPrivateRouteProps} outlet={<DefaultLayout />} />} />
      </Routes>
      {/* </Router> */}
    </Suspense>
  );
};

export default RootNavigator;

const defaultPublicRouteProps: Omit<PublicRouteProps, "outlet"> = {
  authenticatedPath: "/",
};

const defaultPrivateRouteProps: Omit<PrivateRouteProps, "outlet"> = {
  authenticationPath: "/login",
};
