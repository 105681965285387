import { MinusCircleIcon } from "@heroicons/react/24/outline";
import { UseMutateFunction, useMutation, useQuery } from "@tanstack/react-query";
import { CreateCommanWay, GetCommanWay, ListCommanWay, UpdateBookingCommanWay, UpdateCommanWay } from "API/commonApi";
import { AxiosResponse } from "axios";
import { Button, Img, Input } from "components";
import { InputTable } from "components/DataTable/InputTable";
import { useFormik } from "formik";
import { debounce, includes } from "lodash";
import moment from "moment";
import { any } from "prop-types";
import React, { useEffect, useState } from "react";
import Barcode from "react-barcode";
import { useLocation, useNavigate } from "react-router-dom";
import Select from "react-select";
import Swal from "sweetalert2";
import UseToast from "utils/AfToast";
import { roles } from "utils/constant";
import handleWheel from "utils/handleOnWheel";
import Spinner from "utils/Spinner";
import { AfDataType as ADT } from "utils/TableColumnData";
import { number, object, string } from "yup";

type OptionType = {
  value: number;
  label: string;
};

interface MotherBagState {
  mode?: string;
  data?: { [key: string]: any }; // Adjust the type based on your data structure
  // Add other properties if needed
}

interface ValuesType {
  destination?: { value: string; label: string };
}

const DrsList: React.FC = ({}) => {
  let isDisabled = false;
  const navigate = useNavigate();
  const { state } = useLocation();

  const [dataList, setDataList] = useState<any>([]);
  const [selectedDRS, setSelectedDRS] = useState<string>("");
  const [awbData, setAwbData] = useState<any>([]);

  const [userProfile, setUserProfile] = useState<any>({});

  useEffect(() => {
    if(selectedDRS){
      //window.print();
    }
  }, [selectedDRS])

  const { refetch: refetchData } = useQuery(["DRSList"], () => ListCommanWay("/admin/drs/list", {}, {include: ["_addedBy"]}), {
    keepPreviousData: true,
    onSuccess(data) {
      if (data.status == "SUCCESS") {
        console.log(data.data);
        setDataList(data.data.data || []);
        //mutate(data.data.data[1].id);
      }
    },
  });

  const { refetch: refetchProfileData } = useQuery(["ProfileFetch"], () => GetCommanWay("/admin/user/me", {}, {}), {
    keepPreviousData: true,
    onSuccess(data) {
      if (data.status == "SUCCESS") {
        console.log(data.data);
        setUserProfile(data.data);
      }
    },
  });

  const { mutate, isLoading } = useMutation(
    (a) =>
      ListCommanWay(
        "/admin/awbdata/drslist",
        { drs: a, isInscan: true },
        {
          include: ["_assign_to_booking"],
        }
      ),
    {
      onSuccess(data) {
        if (data.status == "SUCCESS") {
          console.log(data.data);

          let scanOrder = [];
          dataList.forEach(d => {
            if(d.code == selectedDRS){
              scanOrder = d.scanOrder ? d.scanOrder.split(",") : [];
            }
          });
          
          let finalData = [...data.data];
          finalData.sort((a, b) => {
            let indexA = scanOrder.indexOf(a.awb_no);
            let indexB = scanOrder.indexOf(b.awb_no);
          
            if (indexA === -1) indexA = scanOrder.length;
            if (indexB === -1) indexB = scanOrder.length;
          
            return indexA - indexB;
          });

          setAwbData(finalData || []);
          setTimeout(x => {
            window.print();
          }, 350)
        }
      },
    }
  );

  const handlePrintClick = (id) => {
    mutate(id);
    //
  };

  return (
    <>
      <div className="flex justify-between px-4">
        <div className="flex-1">
          <Barcode renderer="canvas" value={selectedDRS ? selectedDRS : "-"} width={1.2} height={30} />
        </div>
        <div className="flex-1">
          <h1 className="text-center mb-4 mt-3 text-lg font-semibold underline">DRS Report</h1>
          <h2 className="text-center">Branch Code: {userProfile?.subbranch___?.branch_code}</h2>
        </div>
        <div className="flex-1 text-right">
          <Img src={"/logo.jpg"} className="h-12 ml-auto" />
        </div>
      </div>
      <div className="flex items-center justify-between px-4">
        <p>
          <b>DRS Date: </b>
          {moment().format("DD/MM/YYYY")}
        </p>
        <p>
          <b>Emp. Code: </b> {userProfile?.username}
        </p>
        <p>
          <b>Emp. Name: </b> {userProfile?.name}
        </p>
        <p>
          <b>Total Delivery: </b> {awbData.length}
        </p>
      </div>

      <form
        onSubmit={(e) => {
          e.preventDefault();
        }}
      >
        {/* <div className="grid grid-cols-2 sm:grid-cols-1 gap-4 px-4"> */}
        <div className="flex gap-2 flex-wrap border p-2 print:hidden">
          <div className="w-full relative overflow-x-auto">
            <table className="w-full text-sm text-left rtl:text-right text-black-900">
              <thead className="text-black-900 uppercase font-semibold bg-gray-50 border-y-2">
                <tr>
                  <td className="px-3 py-2">DRS code</td>
                  <td className="px-3 py-2">DRS Date</td>
                  <td className="px-3 py-2">Employee</td>
                  <td className="px-3 py-2">Action</td>
                </tr>
              </thead>
              <tbody>
                {dataList.map((e) => {
                  return (
                    <tr className={`border-b`} key={"drs-"+e.code}>
                      <td className="px-3 py-2">{e.code}</td>
                      <td className="px-3 py-2">{moment(e.createdAt, "DD-MM-YYYY HH:mm:ss").format("DD-MM-YYYY hh:mm A")}</td>
                      <td className="px-3 py-2">{e._addedBy?.username}</td>
                      <td className="px-3 py-2">
                        <Button
                          type="button"
                          className="rounded-md inline-block px-4 py-2 text-sm font-medium text-white bg-blue-800 border border-gray-200 hover:bg-opacity-60 focus:z-10 "
                          onClick={() => {
                            handlePrintClick(e.id);
                            setSelectedDRS(e.code);
                          }}
                        >
                          Print
                        </Button>
                        <Button
                          type="button"
                          className="rounded-md inline-block px-4 py-2 text-sm font-medium text-white bg-blue-800 border border-gray-200 hover:bg-opacity-60 focus:z-10 hidden"
                          onClick={() => {
                            window.print();
                          }}
                        >
                          Print
                        </Button>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        </div>

        <div id="DRS_PRINT">
          {isLoading && <Spinner />}
          <table className="w-full text-sm text-left rtl:text-right text-black-900 border-r border-black-900">
            <thead className="text-black-900 uppercase font-semibold bg-gray-50 border-y-2 text-center">
              <tr>
                <td className="px-1 py-2 border-x">Sr.</td>
                <td className="px-1 py-2 border-x" style={{width: '1cm'}}>ORG</td>
                <td className="px-1 py-2 border-x" style={{width: '20%'}}>Awb</td>
                <td className="px-1 py-2 border-x" style={{width: '30%'}}>Consignee</td>
                <td className="px-1 py-2 border-x">Receiver Name</td>
                <td className="px-1 py-2 border-x">Remarks</td>
              </tr>
            </thead>
            <tbody>
              {awbData.map((e, i) => {
                return (
                  <tr className={`border-b border-black-900 text-center`} key={"drs-awb-"+i}>
                    <td className="px-3 py-0 border-x border-black-900">{i + 1}</td>
                    <td className="px-3 py-10 border-x border-black-900">{e.cityCode}</td>
                    <td className="px-3 py-0 border-x border-black-900">
                      <Barcode renderer="img" value={e.awb_no} width={1.5} height={50} />
                    </td>
                    <td className="px-3 py-0 border-x border-black-900">{e.consignee}</td>
                    <td className="px-3 py-0 border-x border-black-900"></td>
                    <td className="px-3 py-0 border-x border-black-900"></td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      </form>
    </>
  );
};

export default DrsList;
