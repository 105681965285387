import { MinusCircleIcon } from "@heroicons/react/24/outline";
import { UseMutateFunction, useMutation, useQuery } from "@tanstack/react-query";
import { CreateCommanWay, GetCommanWay, ListCommanWay, UpdateBookingCommanWay, UpdateCommanWay } from "API/commonApi";
import { AxiosResponse } from "axios";
import { Button, Input } from "components";
import { InputTable } from "components/DataTable/InputTable";
import { useFormik } from "formik";
import { debounce } from "lodash";
import moment from "moment";
import { any } from "prop-types";
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Select from "react-select";
import Swal from "sweetalert2";
import UseToast from "utils/AfToast";
import { roles } from "utils/constant";
import handleWheel from "utils/handleOnWheel";
import { AfDataType as ADT } from "utils/TableColumnData";
import { number, object, string } from "yup";

type OptionType = {
  value: number;
  label: string;
};

interface MotherBagState {
  mode?: string;
  data?: { [key: string]: any }; // Adjust the type based on your data structure
  // Add other properties if needed
}

interface ValuesType {
  destination?: { value: string; label: string };
}

const AdminDataChange: React.FC = ({}) => {
  let isDisabled = false;
  const navigate = useNavigate();
  const [awbScanVal, setAwbScanVal] = useState<any>("");
  const [awbTrackingData, setAwbTrackingData] = useState<any>({
    tracking_data: [],
    bookingDate: "",
    awbData: {},
    bookingData: {},
  });
  const [showVolume, setShowVolume] = useState<any>(false);

  const [baseAmt, setBaseAmt] = useState<any>("");
  const [fuelPer, setFuelPer] = useState<any>("");
  const [finalAmt, setFinalAmt] = useState<any>("");

  const [incPerAmt, setincPerAmt] = useState<any>(0);
  const [incPerTax, setincPerTax] = useState<any>(0);

  const [amtData, setAmtData] = useState<any>({
    basic: 0,
    discount: 0,
    fuelPer: 0,
    fuelRate: 0,
    holidayRate: 0,
    ten_30Rate: 0,
    abRate: 0,
    chcPer: 0,
    chcRate: 0,
    diceRate: 0,
    dgRate: 0,
    pkgRate: 0,
    totalAmt: 0,
    tax: 0,
  });

  const handleAmtChange = (e, key) => {
    let _amtData = { ...amtData };
    _amtData[key] = Number(e.target.value) || 0;
    console.log(_amtData);

    let basicPlus = _amtData.basic - _amtData.basic * (_amtData.discount / 100) + _amtData.holidayRate + _amtData.ten_30Rate;
    let fuelRate = (basicPlus * _amtData.fuelPer) / 100;
    let chcRate = (basicPlus * _amtData.chcPer) / 100;
    let totalAmt = basicPlus + fuelRate + chcRate + _amtData.abRate + _amtData.diceRate + _amtData.dgRate + _amtData.pkgRate;
    _amtData.totalAmt = totalAmt;
    _amtData.fuelRate = fuelRate;
    _amtData.chcRate = chcRate;
    setAmtData(_amtData);
  };

  const { mutate: mutateUpdateAwb, isLoading: isUpdateAWBloading } = useMutation(
    (data) =>
      ListCommanWay(
        "/admin/booking/data-change",
        {
          awb: awbTrackingData?.awbData?.awb_no,
          data: amtData,
        },
        {}
      ),
    {
      onSuccess: (data: any) => {
        console.log(data, data.status == "SUCCESS");
        if (data.status == "SUCCESS") {
          UseToast("Data change successfully", "success");
        } else {
          UseToast(data.message, "error");
        }
      },
      onError: (data: any) => {
        typeof data === "string" ? UseToast(data, "error") : UseToast(data.message, "error");
      },
    }
  );

  const { mutate: mutateSearchAwb, isLoading } = useMutation((awbNo) => GetCommanWay("/admin/proof_of_delivery/track/" + awbNo, {}, {}), {
    onSuccess: (data: any) => {
      console.log(data, data.status == "SUCCESS");
      if (data.status == "SUCCESS") {
        setAwbTrackingData(data.data);
        console.log(data.data);
        let adata = data.data.awbData;

        //440.69+79.32 | f: 181.46
        let rates = [
          "base_value",
          "abRate",
          "fuelRate",
          "chcRate",
          "odaRate",
          "pkgRate",
          "diceRate",
          "dgRate",
          "holidayRate",
          "ten_30Rate",
        ];
        rates.forEach((f) => {
          adata[f] = Number(adata[f]) || 0;
        });

        let basic = 0;
        basic =
          (adata.base_value || 0) -
          (adata.abRate || 0) -
          (adata.fuelRate || 0) -
          (adata.chcRate || 0) -
          (adata.odaRate || 0) -
          (adata.pkgRate || 0) -
          (adata.diceRate || 0) -
          (adata.dgRate || 0);

        let basicPlus = Number(basic - basic * (adata.discount / 100) + adata.holidayRate + adata.ten_30Rate);
        let totalAmt = basicPlus + adata.fuelRate + adata.chcRate + adata.abRate + adata.diceRate + adata.dgRate + adata.pkgRate;
        console.log(basic, basic * (adata.discount / 100), adata.holidayRate, adata.ten_30Rate);
        console.log(basicPlus, totalAmt);

        setAmtData({
          basic: basic,
          discount: Number(adata.discount) || 0,
          basicPlus: basicPlus,
          fuelRate: Number(adata.fuelRate) || 0,
          chcRate: Number(adata.chcRate) || 0,

          fuelPer: Number(((adata.fuelRate / basic) * 100).toFixed(2)),
          chcPer: Number(((adata.chcRate / basic) * 100).toFixed(2)),
          holidayRate: Number(adata.holidayRate) || 0,
          ten_30Rate: Number(adata.ten_30Rate) || 0,
          abRate: Number(adata.abRate) || 0,
          diceRate: Number(adata.diceRate) || 0,
          dgRate: Number(adata.dgRate) || 0,
          pkgRate: Number(adata.pkgRate) || 0,
          totalAmt: totalAmt,
        });
      } else {
        UseToast(data.message, "error");
      }
    },
    onError: (data: any) => {
      typeof data === "string" ? UseToast(data, "error") : UseToast(data.message, "error");
    },
  });
  const getTRline = (name, value) => {
    return (
      <tr>
        <td className="text-right pr-5 whitespace-nowrap">{name}</td>
        <td>{value}</td>
      </tr>
    );
  };
  const getChargeableWeight = (weight, product_code, ll, bb, hh) => {
    ll = Number(ll);
    bb = Number(bb);
    hh = Number(hh);
    let dWeight = (ll * bb * hh) / (product_code == "X" ? 2700 : 5000);
    return Math.max(Number(weight), dWeight);
  };

  const disableEnterKey = (event) => {
    if (event.key === "Enter" || event.key === "Tab") {
      event.preventDefault();
    }
  };

  return (
    <>
      <h1 className="text-center mb-4 mt-3 text-lg font-semibold underline">Admin Data Change</h1>

      <div>
        <div className="flex gap-2 flex-wrap border p-2">
          <div className="w-full text-center flex justify-center items-center gap-4">
            Scan AWB No:{" "}
            <input
              type="text"
              value={awbScanVal}
              className="bg-blue-200 rounded"
              onKeyDown={disableEnterKey}
              onInput={(e: any) => {
                setAwbScanVal(e.target.value);
              }}
            />
            <Button
              type="button"
              className="rounded-md inline-block px-4 py-2 text-sm font-medium text-white bg-blue-800 border border-gray-200 hover:bg-opacity-60 focus:z-10 "
              onClick={() => {
                mutateSearchAwb(awbScanVal);
              }}
            >
              Search
            </Button>
          </div>
        </div>

        <div>
          {showVolume && (
            <div className="absolute left-0 top-0 w-full h-full bg-black-900 bg-opacity-20 flex items-center justify-center">
              <div className="rounded-xl border-2 border-primary p-4 bg-white relative">
                <div className="flex items-center justify-between bg-primary text-white p-2 rounded-lg">
                  <p>Total Weight: {awbTrackingData?.awbData?.weight}</p>
                  <button
                    className="px-1"
                    onClick={() => {
                      setShowVolume(false);
                    }}
                  >
                    ✖
                  </button>
                </div>

                <div>
                  <table className="table mt-2">
                    <tr>
                      <th className="px-2 border-r">Sr.</th>
                      <th className="px-2">Lenght</th>
                      <th className="px-2">Width</th>
                      <th className="px-2">Height</th>
                      <th className="px-2">Weight</th>
                    </tr>
                    {awbTrackingData?.awbData?.innerItemsJson ? (
                      JSON.parse(awbTrackingData?.awbData?.innerItemsJson).map((i, a) => {
                        return (
                          <tr className="border-t">
                            <td className="px-4 border-r">{a + 1}</td>
                            <td className="px-4">{i.l}</td>
                            <td className="px-4">{i.b}</td>
                            <td className="px-4">{i.h}</td>
                            <td className="px-4">{i.weight}</td>
                          </tr>
                        );
                      })
                    ) : (
                      <tr className="border-t">
                        <td className="px-4">1</td>
                        <td className="px-4">{awbTrackingData?.awbData?.l}</td>
                        <td className="px-4">{awbTrackingData?.awbData?.b}</td>
                        <td className="px-4">{awbTrackingData?.awbData?.h}</td>
                        <td className="px-4">{awbTrackingData?.awbData?.weight}</td>
                      </tr>
                    )}
                  </table>
                </div>
              </div>
            </div>
          )}

          {awbTrackingData.awbData?.id && (
            <div className="flex flex-col gap-4 px-4 mt-2">
              <div className=" border p-2 rounded-2xl">
                <div className="flex gap-2 justify-between">
                  <div className="flex-1">
                    <table>
                      {getTRline("Book Date", awbTrackingData.bookingData?.createdAt)}
                      {getTRline("Reference Code", awbTrackingData.bookingData.pickup_code)}
                      {getTRline("From", awbTrackingData.bookingData.shipper_city)}
                      {getTRline("To", awbTrackingData.awbData.destination)}
                      {getTRline("Product Type", awbTrackingData.awbData.dox_or_non_dox ? "DOX" : "Non-Dox")}
                      {getTRline(
                        "Client Name",
                        awbTrackingData.bookingData.customer_sh + ` (${awbTrackingData.bookingData.customer_code})`
                      )}
                      {getTRline("Shipper Name", awbTrackingData.bookingData.shipper_sh)}
                      {getTRline("Consignee Name", awbTrackingData.awbData.consignee)}
                      {getTRline("Origin", awbTrackingData.bookingData.shipper_pincode)}
                      {getTRline("Destination", awbTrackingData.awbData.pincode)}
                    </table>
                  </div>

                  <div className="border-l-2 flex-1">
                    <div className="flex gap-2 items-center  mx-2">
                      <div className="flex flex-col">
                        <label>Base Amount</label>
                        <input
                          type="text"
                          value={amtData.basic}
                          onChange={(e) => {
                            handleAmtChange(e, "basic");
                          }}
                          className="p-1 rounded-md bg-blue-200 w-40"
                        />
                      </div>
                      <div className="flex flex-col">
                        <label>Discount(%)</label>
                        <input
                          type="text"
                          value={amtData.discount}
                          onChange={(e) => {
                            handleAmtChange(e, "discount");
                          }}
                          className="p-1 rounded-md bg-blue-200 w-20"
                        />
                      </div>

                      <div className="flex flex-col">
                        <label>Holiday</label>
                        <input
                          type="text"
                          value={amtData.holidayRate}
                          onChange={(e) => {
                            handleAmtChange(e, "holidayRate");
                          }}
                          className="p-1 rounded-md bg-blue-200 w-20"
                        />
                      </div>
                      <div className="flex flex-col">
                        <label>10:30</label>
                        <input
                          type="text"
                          value={amtData.ten_30Rate}
                          onChange={(e) => {
                            handleAmtChange(e, "ten_30Rate");
                          }}
                          className="p-1 rounded-md bg-blue-200 w-20"
                        />
                      </div>
                      <div className="flex flex-col">
                        <label>Fuel (%)</label>
                        <input
                          type="text"
                          value={amtData.fuelPer}
                          onChange={(e) => {
                            handleAmtChange(e, "fuelPer");
                          }}
                          className="p-1 rounded-md bg-blue-200 w-24"
                        />
                      </div>
                      <div className="flex flex-col">
                        <label>CHC (%)</label>
                        <input
                          type="text"
                          value={amtData.chcPer}
                          onChange={(e) => {
                            handleAmtChange(e, "chcPer");
                          }}
                          className="p-1 rounded-md bg-blue-200 w-24"
                        />
                      </div>
                      <div className="flex flex-col">
                        <label>AB Charge</label>
                        <input
                          type="text"
                          value={amtData.abRate}
                          onChange={(e) => {
                            handleAmtChange(e, "abRate");
                          }}
                          className="p-1 rounded-md bg-blue-200 w-20"
                        />
                      </div>

                      <div className="flex flex-col">
                        <label>Dry Ice</label>
                        <input
                          type="text"
                          value={amtData.diceRate}
                          onChange={(e) => {
                            handleAmtChange(e, "diceRate");
                          }}
                          className="p-1 rounded-md bg-blue-200 w-20"
                        />
                      </div>
                      <div className="flex flex-col">
                        <label>DG</label>
                        <input
                          type="text"
                          value={amtData.dgRate}
                          onChange={(e) => {
                            handleAmtChange(e, "dgRate");
                          }}
                          className="p-1 rounded-md bg-blue-200 w-20"
                        />
                      </div>
                      <div className="flex flex-col">
                        <label>PKG</label>
                        <input
                          type="text"
                          value={amtData.pkgRate}
                          onChange={(e) => {
                            handleAmtChange(e, "pkgRate");
                          }}
                          className="p-1 rounded-md bg-blue-200 w-20"
                        />
                      </div>
                    </div>

                    <div className="flex mt-4 ml-4">
                      <div className="border p-2">
                        Total
                        <br />
                        <b>{amtData.totalAmt.toFixed(2)}</b>
                      </div>
                      <div className="border p-2">
                        Tax
                        <br />
                        <b>{(amtData.totalAmt * 0.18).toFixed(2)}</b>
                      </div>
                      <div className="border p-2">
                        Grand Total
                        <br />
                        <b>{(amtData.totalAmt + amtData.totalAmt * 0.18).toFixed(2)}</b>
                      </div>
                      <div>
                        <button
                          className="mx-2 my-4 rounded-md inline-block px-4 py-2 text-sm font-medium text-white bg-green-600 border border-gray-200 hover:bg-opacity-60 focus:z-10 "
                          onClick={() => {
                            mutateUpdateAwb();
                          }}
                        >
                          Change
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="flex justify-between mt-2 border-t pt-2">
                  <div className="flex gap-8">
                    <p>
                      Weight: <b>{awbTrackingData.awbData.weight} kg</b>
                    </p>
                    <p>
                      Chargeable Weight:{" "}
                      <b>
                        {getChargeableWeight(
                          awbTrackingData.awbData.weight,
                          awbTrackingData.awbData.prefix,
                          awbTrackingData.awbData.l,
                          awbTrackingData.awbData.b,
                          awbTrackingData.awbData.h
                        )}{" "}
                        kg
                      </b>
                    </p>
                    <p>
                      10_30: <b>{awbTrackingData.awbData.is10_30 ? "YES" : "NO"}</b>
                    </p>
                    <p>
                      Holiday: <b>{awbTrackingData.awbData.holiday ? "YES" : "NO"}</b>
                    </p>
                  </div>

                  <div>
                    <Button
                      className="bg-blue-600 px-2 py-1 rounded-md text-white mr-3"
                      onClick={() => {
                        setShowVolume(true);
                      }}
                    >
                      Show Volume Info
                    </Button>
                  </div>
                </div>
              </div>
            </div>
          )}

          <div className="px-4 my-4 flex gap-4">
            <label>
              Amount
              <br />
              <input
                type="number"
                placeholder="Amount"
                value={incPerAmt}
                onChange={(e) => {
                  setincPerAmt(e.target.value);
                }}
              />
            </label>

            <label>
              Tax
              <br />
              <input
                type="number"
                placeholder="Tax"
                value={incPerTax}
                onChange={(e) => {
                  setincPerTax(e.target.value);
                }}
              />
            </label>

            <p>
              Final:
              <br />
              {(incPerAmt - (incPerAmt - incPerAmt / (1 + incPerTax / 100))).toFixed(2)}
              <span> + </span>
              {(incPerAmt - incPerAmt / (1 + incPerTax / 100)).toFixed(4)}
              <span> = </span>
              {incPerAmt}
            </p>
          </div>
        </div>
      </div>
    </>
  );
};

export default AdminDataChange;
