import { ProSidebarProvider } from "react-pro-sidebar";
import React from "react";
import Routes from "./Routes";
import RootNavigator from "Routes/RootNavigator/RootNavigator";

function App() {
  return (
    <ProSidebarProvider>
      <RootNavigator />
    </ProSidebarProvider>

  );
}

export default App;
