import React from "react";
import { GenericColumnData } from "utils/TableColumnData";
import { GeneralAddForm } from "components/GeneralAddForm/GeneralAddForm";

const GenericTableCreate: React.FC<any> = (props: any) => {
  const CustomComponent =
    GenericColumnData[props.tableName]?.customAddComponent || null;
  return (
    <>
      {CustomComponent ? (
        <CustomComponent
          {...props}
          columnData={GenericColumnData[props.tableName].tableColumnData}
          validationSchema={GenericColumnData[props.tableName].validationSchema}
          initialValues={GenericColumnData[props.tableName].initialValues}
          successNav={`/master/${props.tableName}/list`}
          cancelNav={`/master/${props.tableName}/list`}
          apiUrl={`/admin/${props.tableName}`}
        />
      ) : (
        <GeneralAddForm
          successNav={`/master/${props.tableName}/list`}
          cancelNav={`/master/${props.tableName}/list`}
          apiUrl={`/admin/${props.tableName}`}
          mode="Add"
          columnData={GenericColumnData[props.tableName].tableColumnData}
          validationSchema={GenericColumnData[props.tableName].validationSchema}
          initialValues={GenericColumnData[props.tableName].initialValues}
          selectable={true}
          doAdd={true}
          doEdit={true}
          doDelete={true}
          doExport={true}
          paginate={true}
        />
      )}
    </>
  );
};

export default GenericTableCreate;
