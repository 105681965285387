import React, { useEffect, useState } from "react";
import { ErrorMessage } from "../../components/ErrorMessage";
import { useMutation, useQuery } from "@tanstack/react-query";
import { ListCommanWay } from "API/commonApi";
import { Link } from "react-router-dom";
import { AfDataType } from "utils/TableColumnData";
import UseToast from "utils/AfToast";
import Spinner from "utils/Spinner";

export type AfTableProps = Partial<{
  onColor: string;
  offColor: string;
  onHandleColor: string;
  offHandleColor: string;
  value: string | boolean;
  className: string;
  checkedIcon: React.ReactNode;
  uncheckedIcon: React.ReactNode;
  errors: any[];
  onChange: Function;
  handleCustom: Function;
  customBtnText?: string;
  customRenderFunction?: Function;

  columnData: any[];
  selectable: boolean;
  doAdd: boolean;
  doEdit: boolean;
  doDelete: boolean;
  doExport: boolean;
  paginate: boolean;
  routeUrl: string;
  apiUrl: string;
}>;

const DataTable: React.FC<AfTableProps> = ({
  onChange,

  onColor = "",
  offColor = "",
  onHandleColor = "",
  offHandleColor = "",
  value = false,
  className = "",
  checkedIcon = <></>,
  uncheckedIcon = <></>,
  errors = [],
  handleCustom = (id) => {},
  customRenderFunction = undefined,

  customBtnText = "",

  columnData = [],
  doAdd = true,
  doEdit = true,
  doDelete = true,
  doExport = true,
  paginate = true,
  routeUrl = "/",
  apiUrl = "/",
}) => {
  const [tableData, setTableData] = useState<any>([]);

  const [currentPage, serCurrentPage] = useState<number>(1);
  const [perPage, setPerPageCount] = useState<number>(25);
  const [pageCount, setPageCount] = useState<number>(1);
  const [itemCount, setItemCount] = useState<number>(1);

  //const [includes, setIncludes] = useState<any>([]);
  let incAry = [];
  columnData.forEach((c) => {
    if (c.db_col) {
      incAry.push("_" + c.key);
    }
  });
  console.log(incAry);
  //setIncludes(incAry);

  const { error, isFetching, isError, isFetched, isLoading, refetch } = useQuery(
    [apiUrl.replaceAll("/", "_")],
    () => ListCommanWay(apiUrl, {}, apiUrl.indexOf("state") !== -1 ? {} : { include: incAry, page: currentPage }),
    {
      keepPreviousData: true,
      onSuccess(data) {
        if (data.status == "SUCCESS") {
          setTableData(data.data.data);
          serCurrentPage(data.data.paginator?.currentPage);
          setPerPageCount(data.data.paginator?.perPage);
          setPageCount(data.data.paginator?.pageCount);
          setItemCount(data.data.paginator?.itemCount);
        }
      },
    }
  );

  useEffect(() => {
    refetch();
  }, [currentPage])

  const {
    mutate: mutateApproveDeviceId
  } = useMutation(
    (data) =>
      ListCommanWay('/admin/mac-id/approve', {id: data, apiUrl: apiUrl}, {}),
    {
      onSuccess: (data: any) => {
        console.log(data, data.status == "SUCCESS");
        if (data.status == "SUCCESS") {
          UseToast("Approval Successfully", "success");
          window.location.reload();
        } else {
          UseToast(data.message, "error");
        }
      },
      onError: (data: any) => {
        typeof data === "string"
          ? UseToast(data, "error")
          : UseToast(data.message, "error");
      },
    }
  );

  const renderSwitch = (param) => {
    switch (param) {
      case "foo":
        return "bar";
      default:
        return "foo";
    }
  };

  return (
    <div className="p-5">
      {isLoading && <Spinner />}
      <div className="inline-flex rounded-md overflow-hidden shadow-sm mb-1" role="group">
        <Link
          to={routeUrl + "/add"}
          type="button"
          className="px-4 py-2 text-sm font-medium text-white bg-green-500 border border-gray-200 hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-2 focus:ring-blue-700 focus:text-blue-700"
        >
          Add
        </Link>
        {/*<button
          type="button"
          className="px-4 py-2 text-sm font-medium text-white bg-yellow-500 border-t border-b border-gray-200 hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-2 focus:ring-blue-700 focus:text-blue-700"
        >
          Edit
        </button>
        <button
          type="button"
          className="px-4 py-2 text-sm font-medium text-white bg-red-500 border border-gray-200 hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-2 focus:ring-blue-700 focus:text-blue-700"
        >
          Delete
        </button>

        <button
          type="button"
          className="px-4 py-2 text-sm font-medium text-white bg-dark1 border-gray-200 hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-2 focus:ring-blue-700 focus:text-blue-700"
        >
          Download Excel
        </button>*/}
      </div>
      <div className="flex overflow-auto">
        <table className="w-full text-sm text-left text-gray-500 border-r border-t">
          <thead className="text-xs text-gray-700 uppercase bg-gray-50 ">
            <tr>
              {columnData.map((c) => {
                return (
                  c.view && (
                    <th scope="col" className="px-6 py-3 border-l border-b" key={c.label}>
                      <div className="flex items-center">
                        {c.label}
                        {c.sort && (
                          <a href="#">
                            <svg
                              className="w-3 h-3 ml-1.5"
                              aria-hidden="true"
                              xmlns="http://www.w3.org/2000/svg"
                              fill="currentColor"
                              viewBox="0 0 24 24"
                            >
                              <path d="M8.574 11.024h6.852a2.075 2.075 0 0 0 1.847-1.086 1.9 1.9 0 0 0-.11-1.986L13.736 2.9a2.122 2.122 0 0 0-3.472 0L6.837 7.952a1.9 1.9 0 0 0-.11 1.986 2.074 2.074 0 0 0 1.847 1.086Zm6.852 1.952H8.574a2.072 2.072 0 0 0-1.847 1.087 1.9 1.9 0 0 0 .11 1.985l3.426 5.05a2.123 2.123 0 0 0 3.472 0l3.427-5.05a1.9 1.9 0 0 0 .11-1.985 2.074 2.074 0 0 0-1.846-1.087Z" />
                            </svg>
                          </a>
                        )}
                      </div>
                      {c.filter && (
                        <>
                          {" "}
                          <input
                            type="text"
                            className="block p-1 text-sm text-gray-900 border border-gray-300 rounded-lg bg-gray-50 focus:ring-blue-500 focus:border-blue-500 "
                            placeholder="Search"
                          />
                        </>
                      )}
                    </th>
                  )
                );
              })}
            </tr>
          </thead>

          <tbody>
            {tableData.map((rd, j) => {
              return (
                <tr className="bg-white border-b" key={"tbl-tr-" + j}>
                  {columnData.map((cd, i) => {
                    return (
                      cd.view && (
                        <td className="border-l px-2 py-1" key={"tbl-td-" + i}>
                          {cd.type == AfDataType.CUSTOM ? (
                            customRenderFunction !== undefined ? (
                              customRenderFunction(rd)
                            ) : (
                              <button className="text-dark1" onClick={() => handleCustom(rd)}>
                                {customBtnText}
                              </button>
                            )
                          ) : cd.type == AfDataType.VIEW ? (
                            cd.key == "macId" ? (
                              <>
                                <button className="text-dark1" onClick={() => mutateApproveDeviceId(rd.id)}>Approve</button>
                              </>
                            ) : (
                              <Link className="text-dark1" to={`${routeUrl}/list/${rd[cd.key]}`}>
                                View
                              </Link>
                            )
                          ) : cd.type == AfDataType.DB ? (
                            <>{rd["_" + cd.key] ? rd["_" + cd.key][cd.db_col] : "-"}</>
                          ) : cd.type == AfDataType.BOOLEAN ? (
                            <>{rd[cd.key] === true ? "Yes" : "No"}</>
                          ) : (
                            <>{rd[cd.key]}</>
                          )}
                        </td>
                      )
                    );
                  })}
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>

      <nav className="flex items-center justify-between pt-4" aria-label="Table navigation">
        <span className="text-sm font-normal text-gray-500">
          Total: <span className="font-semibold text-gray-900">{itemCount}</span>
        </span>
        <ul className="inline-flex -space-x-px text-sm h-8">
          <li>
            <div
              onClick={() => serCurrentPage((prevData) => (prevData > 1 ? prevData - 1 : prevData))}
              className="flex items-center justify-center px-3 h-8 ml-0 leading-tight text-gray-500 bg-white border border-gray-300 rounded-l-lg hover:bg-gray-100 hover:text-gray-700 "
            >
              Previous
            </div>
          </li>
          {[pageCount].map((p) => {
            console.log("======> p", p);

            return currentPage === p ? (
              <li key={"paginate-" + p}>
                <a
                  href="#"
                  aria-current="page"
                  className="flex items-center justify-center px-3 h-8 text-blue-600 border border-gray-300 bg-blue-50 hover:bg-blue-100 hover:text-blue-700"
                >
                  {p}
                </a>
              </li>
            ) : (
              <li key={"paginate-" + p}>
                <a
                  href="#"
                  className="flex items-center justify-center px-3 h-8 leading-tight text-gray-500 bg-white border border-gray-300 hover:bg-gray-100 hover:text-gray-700 "
                >
                  {currentPage}
                </a>
              </li>
            );
          })}
          <li>
            <div
              // href="#"
              onClick={() => serCurrentPage((prevData) => (prevData < pageCount ? prevData + 1 : prevData))}
              className="flex items-center justify-center px-3 h-8 leading-tight text-gray-500 bg-white border border-gray-300 rounded-r-lg hover:bg-gray-100 hover:text-gray-700 "
            >
              Next
            </div>
          </li>
        </ul>
      </nav>
      <ErrorMessage errors={errors} />
    </div>
  );
};

export { DataTable };
