import RateCreate from "pages/Rate/RateCreate";
import RateList from "pages/Rate/RateList";
import { number, object, string } from "yup";

export enum AfDataType {
  NUMBER,
  TEXT,
  TEXTAREA,
  DATETIME,
  DATE,
  FILE,
  DB,
  VIEW,
  BOOLEAN,
  SELECT,
  EDIT,
  BUTTON,
  CUSTOM,
  TIME,
  CHECKBOX
}
const ADT = AfDataType;

const viewActionColumn = {
  label: "Action",
  key: "id",
  render: (_) => {
    return `<a>${_}</a>`;
  },
  type: ADT.VIEW,
  view: true,
  add: false,
  edit: false,
  filter: false,
  sort: false,
};

/* === Start === */
export const GenericColumnData = {
  branch: {
    tableColumnData: [
      {
        label: "Id",
        key: "id",
        type: ADT.TEXT,
        view: true,
        add: false,
        edit: true,
        filter: true,
        sort: true,
      },
      {
        label: "Name",
        key: "name",
        type: ADT.TEXT,
        view: true,
        add: true,
        edit: true,
        filter: true,
        sort: true,
      },
      {
        label: "Addr",
        key: "address",
        type: ADT.TEXT,
        view: true,
        add: true,
        edit: true,
        filter: true,
        sort: true,
      },
      {
        label: "Pincode",
        key: "pincode",
        type: ADT.TEXT,
        view: true,
        add: true,
        edit: true,
        filter: true,
        sort: true,
      },
      viewActionColumn,
    ],
    validationSchema: object().shape({
      name: string().required("Required Field"),
      address: string().required("Required Field"),
      pincode: string()
        .required("Required Field")
        .matches(/^[1-9][0-9]{5}$/, "Invalid Pincode"),
    }),
    initialValues: {
      name: "",
      address: "",
      pincode: "",
    },
  },
  subbranch: {
    tableColumnData: [
      {
        label: "Id",
        key: "id",
        type: ADT.TEXT,
        view: true,
        add: false,
        edit: true,
        filter: true,
        sort: true,
      },
      {
        label: "Name",
        key: "branch_name",
        type: ADT.TEXT,
        view: true,
        add: true,
        edit: true,
        filter: true,
        sort: true,
      },
      {
        label: "Addr",
        key: "address",
        type: ADT.TEXT,
        view: true,
        add: true,
        edit: true,
        filter: true,
        sort: true,
      },
      {
        label: "Pincode",
        key: "pincode",
        type: ADT.TEXT,
        view: true,
        add: true,
        edit: true,
        filter: true,
        sort: true,
      },
      viewActionColumn,
    ],
    validationSchema: object().shape({
      branch_name: string().required("Required Field"),
      address: string().required("Required Field"),
      pincode: string()
        .required("Required Field")
        .matches(/^[1-9][0-9]{5}$/, "Invalid Pincode"),
    }),
    initialValues: {
      branch_name: "",
      address: "",
      pincode: "",
    },
  },
  agent: {
    tableColumnData: [
      {
        label: "Id",
        key: "id",
        type: ADT.TEXT,
        view: true,
        add: false,
        edit: true,
      },
      {
        label: "Employee Code",
        key: "employeeCode",
        type: ADT.TEXT,
        view: true,
        add: true,
        edit: true,
      },
      {
        label: "Password",
        key: "password",
        type: ADT.TEXT,
        view: false,
        add: true,
        edit: true,
      },
      {
        label: "Email",
        key: "email",
        type: ADT.TEXT,
        view: true,
        add: true,
        edit: true,
      },
      {
        label: "First Name",
        key: "firstName",
        type: ADT.TEXT,
        view: true,
        add: true,
        edit: true,
      },
      {
        label: "Last Name",
        key: "lastName",
        type: ADT.TEXT,
        view: true,
        add: true,
        edit: true,
      },
      {
        label: "Gender",
        key: "gender",
        type: ADT.TEXT,
        view: true,
        add: true,
        edit: true,
      },
      {
        label: "Mobile",
        key: "mobile",
        type: ADT.TEXT,
        view: true,
        add: true,
        edit: true,
      },
      {
        label: "Qualification",
        key: "qualification",
        type: ADT.TEXT,
        view: true,
        add: true,
        edit: true,
      },
      {
        label: "Role",
        key: "type",
        type: ADT.DB,
        db: "role",
        db_col: "name",
        view: true,
        add: true,
        edit: true,
      },
      {
        label: "Subbranch",
        key: "subbranch",
        type: ADT.DB,
        db: "subbranch",
        db_col: "branch_code",
        view: true,
        add: true,
        edit: true,
      },
      {
        label: "Is Active",
        key: "isActive",
        type: ADT.BOOLEAN,
        view: true,
        add: true,
        edit: true,
      },
      {
        label: "Device ID",
        key: "macId",
        render: (_) => {
          return `<a>${_}</a>`;
        },
        type: ADT.VIEW,
        view: true,
        add: false,
        edit: false,
        filter: false,
        sort: false,
      },
      viewActionColumn,
    ],
    validationSchema: object().shape({
      mobile: number().required("Required Field"),
      pincode: string()
        .required("Required Field")
        .matches(/^[1-9][0-9]{5}$/, "Invalid Pincode"),
      subbranch: number().required("Required Field"),
    }),
    initialValues: {
      employeeCode: "",
      password: "",
      email: "",
      firstName: "",
      lastName: "",
      gender: "",
      mobile: "",
      qualification: "",
      role: "",
      subbranch: "",
      isActive: "",
    },
  },
  user: {
    tableColumnData: [
      {
        label: "Id",
        key: "id",
        type: ADT.TEXT,
        view: true,
        add: false,
        edit: true,
      },
      {
        label: "Name",
        key: "username",
        type: ADT.TEXT,
        view: true,
        add: true,
        edit: true,
      },
      {
        label: "Password",
        key: "password",
        type: ADT.TEXT,
        view: false,
        add: true,
        edit: true,
      },
      {
        label: "Email",
        key: "email",
        type: ADT.TEXT,
        view: true,
        add: true,
        edit: true,
      },
      {
        label: "Mobile",
        key: "mobile",
        type: ADT.TEXT,
        view: true,
        add: true,
        edit: true,
      },
      {
        label: "Role",
        key: "role_name",
        type: ADT.DB,
        db: "role",
        db_col: "name",
        view: true,
        add: true,
        edit: true,
      },
      {
        label: "Subbranch",
        key: "subbranch",
        type: ADT.DB,
        db: "subbranch",
        db_col: "branch_code",
        view: true,
        add: true,
        edit: true,
      },
      {
        label: "Is Active",
        key: "isActive",
        type: ADT.BOOLEAN,
        view: true,
        add: true,
        edit: true,
      },
      {
        label: "Device ID",
        key: "macId",
        render: (_) => {
          return `<a>${_}</a>`;
        },
        type: ADT.VIEW,
        view: true,
        add: false,
        edit: false,
        filter: false,
        sort: false,
      },
      viewActionColumn,
    ],
    validationSchema: object().shape({
      mobile: number().required("Required Field"),
      pincode: string()
        .required("Required Field")
        .matches(/^[1-9][0-9]{5}$/, "Invalid Pincode"),
      subbranch: number().required("Required Field"),
    }),
    initialValues: {
      employeeCode: "",
      password: "",
      email: "",
      firstName: "",
      lastName: "",
      gender: "",
      mobile: "",
      qualification: "",
      role: "",
      subbranch: "",
      isActive: "",
    },
  },
  customer: {
    extra: {
      buttons: ["add", "download"],
    },
    tableColumnData: [
      {
        label: "Id",
        key: "id",
        type: ADT.TEXT,
        view: true,
        add: false,
        edit: false,
      },
      {
        label: "Customer Code",
        key: "code",
        type: ADT.TEXT,
        view: true,
        add: true,
        edit: true,
      },
      {
        label: "Company Name",
        key: "compnay_name",
        type: ADT.TEXT,
        view: true,
        add: true,
        edit: true,
      },
      {
        label: "Customer Addr",
        key: "address",
        type: ADT.TEXT,
        view: true,
        add: true,
        edit: true,
      },
      {
        label: "Office Email",
        key: "officeEmail",
        type: ADT.TEXT,
        view: true,
        add: true,
        edit: true,
      },
      {
        label: "Website",
        key: "website",
        type: ADT.TEXT,
        view: true,
        add: true,
        edit: true,
      },
      {
        label: "Office Phone",
        key: "officePhone",
        type: ADT.TEXT,
        view: true,
        add: true,
        edit: true,
      },
      {
        label: "Is Active?",
        key: "isActive",
        type: ADT.BOOLEAN,
        view: true,
        add: true,
        edit: true,
      },
      {
        label: "Pincode",
        key: "pincode",
        type: ADT.TEXT,
        view: false,
        add: true,
        edit: true,
      },
      {
        label: "S/H",
        key: "sh",
        type: ADT.TEXT,
        view: true,
        add: true,
        edit: true,
      },
      /*{
        label: "Subbranch",
        key: "subbranch",
        type: ADT.DB,
        db: "subbranch",
        db_col: "branch_name",
        view: true,
        add: true,
        edit: true,
      },*/
      {
        label: "updatedBy",
        key: "updatedBy",
        type: ADT.DB,
        db: "user",
        db_col: "name",
        view: true,
        add: false,
        edit: false,
      },
      ...[
        "office_type|office type",
        "director|director",
        "designation|designation",
        "residentAddr|Residence Addr",
        "mobile|Mobile",
        "bankName|Bank Name",
        "bankBranch|Bank Branch",
        "share_capital|Share Capital",
        "annual_report_copy|Annual Report Copy",
        "pan|Shipper Pan",
        "terms_payment_type|Terms Pay Type",
        "service_center_code|Service Center Code",
        "courier_emplyee_name|Courier Emplyee",
        "service_name|Service Name",
        "cin|CIN No.",
        "gst|GST NO",
        "remarks|Remark",
        "password|Password",
        "aadharDoc|Aadhar No",
        "type|Type of Customer",
        "agreementPlace|Place of Agreement",
      ].map((x) => {
        return {
          label: x.split("|")[1],
          key: x.split("|")[0],
          type: ADT.TEXT,
          view: true,
          add: true,
          edit: true,
        };
      }),
      {
        label: "Agree. Start Date",
        key: "agreementStart",
        type: ADT.DATE,
        view: true,
        add: true,
        edit: true,
      },
      {
        label: "Agree. End Date",
        key: "agreementEnd",
        type: ADT.DATE,
        view: true,
        add: true,
        edit: true,
      },
      {
        label: "Special Fuel",
        key: "IsSpecialfuel",
        type: ADT.BOOLEAN,
        view: true,
        add: true,
        edit: true,
      },
      {
        label: "Special Rate",
        key: "IsSpecialRate",
        type: ADT.BOOLEAN,
        view: true,
        add: true,
        edit: true,
      },
      {
        label: "Nagative Ballance",
        key: "negative_balance",
        type: ADT.BOOLEAN,
        view: true,
        add: true,
        edit: true,
      },

      viewActionColumn,
    ],
    validationSchema: object().shape({
      code: string().required("Required Field"),
      mobile: number().required("Required Field"),
      pincode: string()
        .required("Required Field")
        .matches(/^[1-9][0-9]{5}$/, "Invalid Pincode"),
      address: string().required("Required Field"),
      subbranch: number().required("Required Field"),
    }),
    initialValues: {
      code: "",
      mobile: "",
      pincode: "",
      address: "",
      subbranch: "",
    },
  },
  rate: {
    tableColumnData: [
      {
        label: "From",
        key: "from",
        type: ADT.NUMBER,
        view: true,
        add: true,
        edit: true,
        filter: true,
        sort: true,
      },
      {
        label: "To",
        key: "to",
        type: ADT.NUMBER,
        view: true,
        add: true,
        edit: true,
        filter: true,
        sort: true,
      },
      {
        label: "Rate",
        key: "rate",
        type: ADT.NUMBER,
        view: true,
        add: true,
        edit: true,
        filter: true,
        sort: true,
      },
      {
        label: "Sunday",
        key: "sunday_rate",
        type: ADT.NUMBER,
        view: true,
        add: true,
        edit: true,
        filter: true,
        sort: true,
      },
      {
        label: "TDD",
        key: "rate_1030_1230",
        type: ADT.NUMBER,
        view: true,
        add: true,
        edit: true,
        filter: true,
        sort: true,
      },
      {
        label: "Wt Rng",
        key: "wt_range",
        type: ADT.NUMBER,
        view: true,
        add: true,
        edit: true,
        filter: true,
        sort: true,
      },
      {
        label: "Add 10:30",
        key: "addition_1030",
        type: ADT.NUMBER,
        view: true,
        add: true,
        edit: true,
        filter: true,
        sort: true,
      },
      {
        label: "Add Amt",
        key: "addition_amount",
        type: ADT.NUMBER,
        view: true,
        add: true,
        edit: true,
        filter: true,
        sort: true,
      },
      {
        label: "AWB Chg",
        key: "ab_charge",
        type: ADT.NUMBER,
        view: true,
        add: true,
        edit: true,
        filter: true,
        sort: true,
      },
      {
        label: "FS(%)",
        key: "f_charge",
        type: ADT.NUMBER,
        view: true,
        add: true,
        edit: true,
        filter: true,
        sort: true,
      },
      {
        label: "CHC%",
        key: "chc_charge",
        type: ADT.NUMBER,
        view: true,
        add: true,
        edit: true,
        filter: true,
        sort: true,
      },

      {
        label: "Dry Ice",
        key: "diceCharge",
        type: ADT.NUMBER,
        view: true,
        add: true,
        edit: true,
        filter: true,
        sort: true,
      },
      {
        label: "dg",
        key: "dgCharge",
        type: ADT.NUMBER,
        view: true,
        add: true,
        edit: true,
        filter: true,
        sort: true,
      },
      {
        label: "Pkg",
        key: "pkgCharge",
        type: ADT.NUMBER,
        view: true,
        add: true,
        edit: true,
        filter: true,
        sort: true,
      },
      {
        label: "Pkg 1LT",
        key: "pkg1Charge",
        type: ADT.NUMBER,
        view: true,
        add: true,
        edit: true,
        filter: true,
        sort: true,
      },
      {
        label: "Pkg 4LT",
        key: "pkg2Charge",
        type: ADT.NUMBER,
        view: true,
        add: true,
        edit: true,
        filter: true,
        sort: true,
      },
      {
        label: "Pkg 10LT",
        key: "pkg3Charge",
        type: ADT.NUMBER,
        view: true,
        add: true,
        edit: true,
        filter: true,
        sort: true,
      },
    ],
    customComponent: RateList,
    customAddComponent: RateCreate,
    validationSchema: object().shape({
      from: number().required("Required Field"),
      to: number().required("Required Field"),
      rate: number().required("Required Field"),
      sunday_rate: number().required("Required Field"),
      rate_1030_1230: number().required("Required Field"),
      wt_range: number().required("Required Field"),
      addition_1030: number().required("Required Field"),
      addition_amount: number().required("Required Field"),
      ab_charge: number().required("Required Field"),
      f_charge: number().required("Required Field"),
      chc_charge: number().required("Required Field"),
      country: number().required("Required Field"),
      state: number().required("Required Field"),
      city: number().required("Required Field"),
      customer: number().required("Required Field"),
      bound: string().required("Required Field"),
      zone: string().required("Required Field"),
      product: number().required("Required Field"),
    }),
    initialValues: {
      from: "",
      to: "",
      rate: "",
      sunday_rate: "",
      rate_1030_1230: "",
      wt_range: "",
      addition_1030: "",
      addition_amount: "",
      ab_charge: "",
      f_charge: "",
      chc_charge: "",
      country: "",
      state: "",
      city: "",
      customer: "",
      bound: "",
      product: "",
      zone: "",
      is_dox: false,
    },
  },

  product: {
    tableColumnData: [
      {
        label: "Id",
        key: "id",
        type: ADT.TEXT,
        view: true,
        add: false,
        edit: true,
        filter: true,
        sort: true,
      },
      {
        label: "Name",
        key: "product_name",
        type: ADT.TEXT,
        view: true,
        add: true,
        edit: true,
        filter: true,
        sort: true,
      },
      {
        label: "Product Desc",
        key: "production_description",
        type: ADT.TEXT,
        view: true,
        add: true,
        edit: true,
        filter: true,
        sort: true,
      },
      {
        label: "Product Code",
        key: "product_code",
        type: ADT.TEXT,
        view: true,
        add: true,
        edit: true,
        filter: true,
        sort: true,
      },
      viewActionColumn,
    ],
    validationSchema: object().shape({
      product_name: string().required("Required Field"),
      production_description: string().required("Required Field"),
    }),
    initialValues: {
      product_name: "",
      production_description: "",
    },
  },

  country: {
    tableColumnData: [
      {
        label: "Id",
        key: "id",
        type: ADT.TEXT,
        view: true,
        add: false,
        edit: true,
        filter: true,
        sort: true,
      },
      {
        label: "Name",
        key: "country_name",
        type: ADT.TEXT,
        view: true,
        add: true,
        edit: true,
        filter: true,
        sort: true,
      },
      {
        label: "Country Code",
        key: "country_code",
        type: ADT.TEXT,
        view: true,
        add: true,
        edit: true,
        filter: true,
        sort: true,
      },
      viewActionColumn,
    ],
    validationSchema: object().shape({
      country_name: string().required("Required Field"),
      country_code: string().required("Required Field"),
    }),
    initialValues: {
      country_name: "",
      country_code: "",
    },
  },

  region: {
    tableColumnData: [
      {
        label: "Id",
        key: "id",
        type: ADT.TEXT,
        view: true,
        add: false,
        edit: true,
        filter: true,
        sort: true,
      },
      {
        label: "Country Name",
        key: "country_name",
        type: ADT.DB,
        db: "country",
        db_col: "country_name",
        view: true,
        add: true,
        edit: true,
        filter: true,
        sort: true,
      },
      {
        label: "Name",
        key: "region_name",
        type: ADT.TEXT,
        view: true,
        add: true,
        edit: true,
        filter: true,
        sort: true,
      },
      viewActionColumn,
    ],
    validationSchema: object().shape({
      country_name: number().required("Required Field"),
      region_name: string().required("Required Field"),
    }),
    initialValues: {
      country_name: undefined,
      region_name: "",
    },
  },

  state: {
    tableColumnData: [
      {
        label: "Id",
        key: "id",
        type: ADT.TEXT,
        view: true,
        add: false,
        edit: true,
        filter: true,
        sort: true,
      },
      {
        label: "Country Name",
        key: "country_name",
        type: ADT.DB,
        db: "country",
        db_col: "country_name",
        view: true,
        add: true,
        edit: true,
        filter: true,
        sort: true,
      },
      {
        label: "Region Name",
        key: "region_name",
        type: ADT.DB,
        db: "region",
        db_col: "region_name",
        view: true,
        add: true,
        edit: true,
        filter: true,
        sort: true,
      },
      {
        label: "Name",
        key: "state_name",
        type: ADT.TEXT,
        view: true,
        add: true,
        edit: true,
        filter: true,
        sort: true,
      },
      viewActionColumn,
    ],
    validationSchema: object().shape({
      country_name: number().required("Required Field"),
      region_name: number().required("Required Field"),
      state_name: string().required("Required Field"),
    }),
    initialValues: {
      country_name: undefined,
      region_name: undefined,
      state_name: "",
    },
  },

  city: {
    tableColumnData: [
      {
        label: "Id",
        key: "id",
        type: ADT.TEXT,
        view: true,
        add: false,
        edit: true,
        filter: true,
        sort: true,
      },
      {
        label: "State Name",
        key: "state_name",
        type: ADT.DB,
        db: "state",
        db_col: "state_name",
        view: true,
        add: true,
        edit: true,
        filter: true,
        sort: true,
      },
      {
        label: "Name",
        key: "city_name",
        type: ADT.TEXT,
        view: true,
        add: true,
        edit: true,
        filter: true,
        sort: true,
      },
      {
        label: "City Code",
        key: "cityCode",
        type: ADT.TEXT,
        view: true,
        add: true,
        edit: true,
        filter: true,
        sort: true,
      },
      viewActionColumn,
    ],
    validationSchema: object().shape({
      state_name: number().required("Required Field"),
      city_name: string().required("Required Field"),
      pincode: string().required("Required Field"),
    }),
    initialValues: {
      state_name: undefined,
      city_name: "",
      pincode: "",
    },
  },

  tax: {
    tableColumnData: [
      {
        label: "Id",
        key: "id",
        type: ADT.TEXT,
        view: true,
        add: false,
        edit: true,
        filter: true,
        sort: true,
      },
      {
        label: "Name",
        key: "tax_name",
        type: ADT.TEXT,
        view: true,
        add: true,
        edit: true,
        filter: true,
        sort: true,
      },
      {
        label: "Desc",
        key: "tax_desc",
        type: ADT.TEXT,
        view: true,
        add: true,
        edit: true,
        filter: true,
        sort: true,
      },
      {
        label: "Tax %",
        key: "tax_percentage",
        type: ADT.NUMBER,
        view: true,
        add: true,
        edit: true,
        filter: true,
        sort: true,
      },
      viewActionColumn,
    ],
    validationSchema: object().shape({
      tax_name: string().required("Required Field"),
      tax_desc: string().required("Required Field"),
      tax_percentage: number().required("Required Field"),
    }),
    initialValues: {
      tax_name: "",
      tax_desc: "",
      tax_percentage: undefined,
    },
  },

  rc_code: {
    tableColumnData: [
      {
        label: "Id",
        key: "id",
        type: ADT.TEXT,
        view: true,
        add: false,
        edit: true,
        filter: true,
        sort: true,
      },
      {
        label: "Name",
        key: "rc_code",
        type: ADT.TEXT,
        view: true,
        add: true,
        edit: true,
        filter: true,
        sort: true,
      },
      {
        label: "Desc",
        key: "rc_name",
        type: ADT.TEXT,
        view: true,
        add: true,
        edit: true,
        filter: true,
        sort: true,
      },
      viewActionColumn,
    ],
    validationSchema: object().shape({
      rc_code: string().required("Required Field"),
      rc_name: string().required("Required Field"),
    }),
    initialValues: {
      rc_code: "",
      rc_name: "",
    },
  },

  international_zone: {
    tableColumnData: [
      {
        label: "Id",
        key: "id",
        type: ADT.TEXT,
        view: true,
        add: false,
        edit: true,
        filter: true,
        sort: true,
      },
      {
        label: "Country",
        key: "country_name",
        type: ADT.TEXT,
        view: true,
        add: true,
        edit: true,
        filter: true,
        sort: true,
      },
      {
        label: "Zone Name",
        key: "zone_name",
        type: ADT.TEXT,
        view: true,
        add: true,
        edit: true,
        filter: true,
        sort: true,
      },
      {
        label: "Type",
        key: "type_of_courier",
        type: ADT.TEXT,
        view: true,
        add: true,
        edit: true,
        filter: true,
        sort: true,
      },
      viewActionColumn,
    ],
    validationSchema: object().shape({
      country_name: string().required("Required Field"),
      zone_name: string().required("Required Field"),
      type_of_courier: string().required("Required Field"),
    }),
    initialValues: {
      country_name: "",
      zone_name: "",
      type_of_courier: "",
    },
  },

  international_rate: {
    tableColumnData: [
      {
        label: "Id",
        key: "id",
        type: ADT.TEXT,
        view: true,
        add: false,
        edit: true,
        filter: true,
        sort: true,
      },
      {
        label: "Zone",
        key: "international_zone",
        type: ADT.DB,
        db: "international_zone",
        db_col: "zone_name",
        view: true,
        add: true,
        edit: true,
        filter: true,
        sort: true,
      },
      {
        label: "Product",
        key: "product_name",
        type: ADT.DB,
        db: "product",
        db_col: "product_name",
        view: true,
        add: true,
        edit: true,
        filter: true,
        sort: true,
      },
      {
        label: "Rate",
        key: "rate",
        type: ADT.NUMBER,
        view: true,
        add: true,
        edit: true,
        filter: true,
        sort: true,
      },

      viewActionColumn,
    ],
    validationSchema: object().shape({
      international_zone: number().required("Required Field"),
      product_name: number().required("Required Field"),
      rate: number().required("Required Field"),
    }),
    initialValues: {
      international_zone: undefined,
      product_name: undefined,
      rate: undefined,
    },
  },
};

console.log(GenericColumnData.customer);
/* === --- === */

/* === Start === */
export const BrachColumnData = [
  {
    label: "Id",
    key: "id",
    type: ADT.TEXT,
    view: true,
    add: false,
    edit: true,
    filter: true,
    sort: true,
  },
  {
    label: "Name",
    key: "branch_name",
    type: ADT.TEXT,
    view: true,
    add: true,
    edit: true,
    filter: true,
    sort: true,
  },
  {
    label: "Addr",
    key: "address",
    type: ADT.TEXT,
    view: true,
    add: true,
    edit: true,
    filter: true,
    sort: true,
  },
  {
    label: "Pincode",
    key: "pincode",
    type: ADT.TEXT,
    view: true,
    add: true,
    edit: true,
    filter: true,
    sort: true,
  },
  viewActionColumn,
];
export const BranchValidationSchema = object().shape({
  branch_name: string().required("Required Field"),
  address: string().required("Required Field"),
  pincode: string()
    .required("Required Field")
    .matches(/^[1-9][0-9]{5}$/, "Invalid Pincode"),
});
export const BranchInitialValues = {
  branch_name: "",
  address: "",
  pincode: "",
};
/* === --- === */
