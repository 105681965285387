import React from "react";
import { DataTable } from "components/DataTable/DataTable";
import { AfDataType, GenericColumnData } from "utils/TableColumnData";
import { DataTableWithoutButtons } from "components/DataTable/DataTableWithoutButtons";
import { useNavigate } from "react-router-dom";
import handleWheel from "utils/handleOnWheel";

const viewActionColumn = {
  label: "Action",
  key: "id",
  render: (_) => {
    return `<a>${_}</a>`;
  },
  type: AfDataType.VIEW,
  view: true,
  add: false,
  edit: false,
  filter: false,
  sort: false,
};
const RegularVouchureList: React.FC<any> = (props) => {
  const ADT = AfDataType;
  const navigator = useNavigate();
  const [vouchureId, setVouchureId] = React.useState("");
  const [query, setQuery] = React.useState({});
  const tableColumnData = [
    // {
    //   label: "Id",
    //   key: "id",
    //   type: ADT.NUMBER,
    //   view: true,
    //   add: false,
    //   edit: true,
    //   filter: true,
    //   sort: true,
    // },
    // {
    //   label: "Vouchure ID",
    //   key: "vouchure_id",
    //   type: ADT.TEXT,
    //   view: true,
    //   add: true,
    //   edit: true,
    //   filter: true,
    //   sort: true,
    // },
    {
      label: "AWB No",
      key: "awb_nos",
      type: ADT.TEXT,
      view: true,
      add: true,
      edit: true,
      filter: true,
      sort: true,
    },
    {
      label: "Remark",
      key: "remark",
      type: ADT.TEXT,
      view: true,
      add: true,
      edit: true,
      filter: true,
      sort: true,
    },
    // {
    //   label: "Latitude",
    //   key: "lat",
    //   type: ADT.TEXT,
    //   view: true,
    //   add: true,
    //   edit: true,
    //   filter: true,
    //   sort: true,
    // },
    // {
    //   label: "Longitude",
    //   key: "long",
    //   type: ADT.TEXT,
    //   view: true,
    //   add: true,
    //   edit: true,
    //   filter: true,
    //   sort: true,
    // },
    {
      label: "Rate Charge",
      key: "rate_charge",
      type: ADT.TEXT,
      view: true,
      add: true,
      edit: true,
      filter: true,
      sort: true,
    },
    {
      label: "KM",
      key: "km",
      type: ADT.TEXT,
      view: true,
      add: true,
      edit: true,
      filter: true,
      sort: true,
    },
    {
      label: "Total",
      key: "total",
      type: ADT.TEXT,
      view: true,
      add: true,
      edit: true,
      filter: true,
      sort: true,
    },
    // {
    //   label: "Created At",
    //   key: "createdAt",
    //   type: ADT.TEXT,
    //   view: true,
    //   add: true,
    //   edit: true,
    //   filter: true,
    //   sort: true,
    // },
  ];

  const handleSubmit = () => {
    setQuery({ vouchure_id: Number(vouchureId) });
  };

  return (
    <>
      {" "}
      <h1 className="text-center mb-4 mt-3 text-lg font-semibold ">
        Regular Vouchure
      </h1>
      <div className="flex justify-center items-center flex-col gap-4 px-4">
        <div className="flex gap-2 flex-1 flex-wrap  p-2 rounded-2xl">
          <div className={`flex items-center`}>
            <label className="w-full mb-2 text-sm font-semibold text-gray-900">
              Vouchure No.
            </label>
            <input
              type="number"
              className={`bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5`}
              placeholder="Vouchure No."
              name="vouchure_id"
              value={vouchureId}
              onBlur={(e) => {
                setVouchureId(e.target.value);
                if (!e.target.value) {
                  setQuery({});
                }
              }}
              onChange={(e) => {
                setVouchureId(e.target.value);
                if (!e.target.value) {
                  setQuery({});
                }
              }}
              onWheel={handleWheel}
            />
            <button
              type="button"
              onClick={handleSubmit}
              className="ml-2 rounded-md px-4 py-2 text-sm font-medium text-white bg-green-500 border border-gray-200 hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-2 focus:ring-blue-700 focus:text-blue-700"
            >
              Submit
            </button>
          </div>
        </div>
      </div>
      {Object.keys(query).length ? (
        <DataTableWithoutButtons
          apiUrl={`/admin/regular_vouchure/list`}
          columnData={tableColumnData}
          query={query}
          responseData={"data"}
          handleEdit={(data) => {
            //   navigator("/booking/add", {
            //     state: {
            //       mode: "edit",
            //       data,
            //     },
            //   });
          }}
          handleView={(data) => {
            //   navigator("/booking/add", {
            //     state: {
            //       mode: "view",
            //       data,
            //     },
            //   });
          }}
        />
      ) : null}
    </>
  );
};

export default RegularVouchureList;
