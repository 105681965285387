import React from "react";

const sizeClasses = {
  txtGilroySemiBold24: "font-gilroy font-semibold",
  txtGilroyMedium12BlueA700: "font-gilroy font-medium",
  txtGilroySemiBold16WhiteA700: "font-gilroy font-semibold",
  txtGilroySemiBold16Gray90003: "font-gilroy font-semibold",
  txtGilroySemiBold28: "font-gilroy font-semibold",
  txtGilroySemiBold24Black90001: "font-gilroy font-semibold",
  txtGilroyRegular14Bluegray900: "font-gilroy font-normal",
  txtGilroyMedium16BlueA700: "font-gilroy font-medium",
  txtGilroyRegular18: "font-gilroy font-normal",
  txtOpenSansMedium18: "font-medium font-opensans",
  txtOpenSansMedium16: "font-medium font-opensans",
  txtGilroyMedium18Black90001: "font-gilroy font-medium",
  txtGilroySemiBold32: "font-gilroy font-semibold",
  txtGilroySemiBold18Black900: "font-gilroy font-semibold",
  txtGilroyMedium16Bluegray900: "font-gilroy font-medium",
  txtGilroySemiBold36: "font-gilroy font-semibold",
  txtOpenSansRomanRegular14: "font-normal font-opensans",
  txtGilroyMedium12Green600: "font-gilroy font-medium",
  txtOpenSansMedium24: "font-medium font-opensans",
  txtOpenSansMedium20: "font-medium font-opensans",
  txtGilroyMedium24Green600: "font-gilroy font-medium",
  txtOpenSansRomanSemiBold28: "font-opensans font-semibold",
  txtGilroyMedium18Black900: "font-gilroy font-medium",
  txtGilroyRegular18Bluegray900: "font-gilroy font-normal",
  txtGilroyMedium18Green600: "font-gilroy font-medium",
  txtGilroyMedium24: "font-gilroy font-medium",
  txtGilroySemiBold16Bluegray200: "font-gilroy font-semibold",
  txtGilroySemiBold24Bluegray900: "font-gilroy font-semibold",
  txtGilroyMedium14BlueA200: "font-gilroy font-medium",
  txtGilroyMedium16Bluegray600: "font-gilroy font-medium",
  txtGilroySemiBold14Bluegray900: "font-gilroy font-semibold",
  txtGilroyMedium16Bluegray400: "font-gilroy font-medium",
  txtOpenSansMedium18Bluegray400: "font-medium font-opensans",
  txtOpenSansRomanSemiBold16: "font-opensans font-semibold",
  txtGilroyRegular16Bluegray200: "font-gilroy font-normal",
  txtGilroyMedium14: "font-gilroy font-medium",
  txtGilroyMedium18BlueA700: "font-gilroy font-medium",
  txtGilroyMedium12: "font-gilroy font-medium",
  txtGilroySemiBold18: "font-gilroy font-semibold",
  txtGilroyRegular16Gray900: "font-gilroy font-normal",
  txtGilroyMedium18: "font-gilroy font-medium",
  txtGilroySemiBold16: "font-gilroy font-semibold",
  txtGilroyRegular16: "font-gilroy font-normal",
  txtOpenSansRomanSemiBold14: "font-opensans font-semibold",
  txtGilroyMedium16: "font-gilroy font-medium",
  txtOpenSansRomanSemiBold12: "font-opensans font-semibold",
  txtGilroyRegular14: "font-gilroy font-normal",
  txtGilroySemiBold14: "font-gilroy font-semibold",
  txtGilroySemiBold16Blue800: "font-gilroy font-semibold",
  txtGilroyMedium18Bluegray600: "font-gilroy font-medium",
  txtGilroySemiBold16Bluegray700: "font-gilroy font-semibold",
  txtGilroyMedium18Bluegray400: "font-gilroy font-medium",
  txtGilroySemiBold16Bluegray900: "font-gilroy font-semibold",
  txtGilroySemiBold18Gray90002: "font-gilroy font-semibold",
  txtGilroyMedium14Bluegray40090: "font-gilroy font-medium",
  txtOpenSansRomanSemiBold16BlueA700: "font-opensans font-semibold",
} as const;

export type TextProps = Partial<{
  className: string;
  size: keyof typeof sizeClasses;
  as: React.ElementType | string;
}> &
  React.DetailedHTMLProps<
    React.HTMLAttributes<HTMLSpanElement>,
    HTMLSpanElement
  >;

const Text: React.FC<React.PropsWithChildren<TextProps>> = ({
  children,
  className = "",
  size,
  as,
  ...restProps
}) => {
  const Component = as || "p";

  return (
    <Component
      className={`text-left ${className} ${size && sizeClasses[size]}`}
      {...restProps}
    >
      {children}
    </Component>
  );
};

export { Text };
