import React, { useCallback, useEffect, useState } from "react";
import { UseMutateFunction, useMutation, useQuery } from "@tanstack/react-query";
import { CreateCommanWay, GetCommanWay, ListCommanWay, UpdateCommanWay } from "API/commonApi";
import { AfDataType as ADT } from "utils/TableColumnData";
import { useFormik } from "formik";
import { AxiosResponse } from "axios";
import UseToast from "utils/AfToast";
import { debounce } from "lodash";
import { useNavigate, useParams } from "react-router-dom";
import Select from "react-select";

export type AfTableProps = Partial<{
  className: string;
  columnData: any[];
  selectable: boolean;
  doAdd: boolean;
  doEdit: boolean;
  doDelete: boolean;
  doExport: boolean;
  paginate: boolean;
  addBtnUrl: string;
  validationSchema: any;
  initialValues: any;
  apiUrl: string;
  successNav: string;
  cancelNav: string;
  mode: string;
}>;

const GeneralAddForm: React.FC<AfTableProps> = ({
  columnData = [],
  doAdd = true,
  doEdit = true,
  doDelete = true,
  doExport = true,
  paginate = true,
  validationSchema,
  initialValues = {},
  apiUrl = "",
  successNav = "/",
  cancelNav = "/",
  mode = "View",
}) => {
  const navigate = useNavigate();
  const params = useParams();
  const [dataMode, setDataMode] = useState<string>(mode);
  type OptionType = {
    value: number;
    label: string;
  };
  const defaultOptions: any = [{ value: 0, label: "Loading..." }];
  const [nestedTables, setNestedTables] = useState<any>({});
  const [nestedSelectedOptions, setNestedSelectedOptions] = useState<any>({});

  useEffect(() => {
    if (dataMode == "View") {
      console.log("branch_name set");
      const id: string | undefined = params?.id;
      console.log(id);

      let includes_ = [];
      columnData.forEach((cd) => {
        if (cd.type == ADT.DB) {
          includes_.push("_" + cd.key);
        }
      });

      GetCommanWay(apiUrl + "/" + id, {}, {}).then((x) => {
        if (x.status == "SUCCESS") {
          console.log(x.data);

          let vals = {};
          let n = { ...nestedSelectedOptions };
          columnData.forEach((cd) => {
            vals[cd.key] = x.data[cd.key];
            //setValues(vals);
            setFieldValue(cd.key, x.data[cd.key]);

            if (cd.type == ADT.BOOLEAN) {
              let opt = { value: x.data[cd.key], label: x.data[cd.key] ? "Yes" : "No" };
              n[cd.key] = opt;
            }

            if (cd.type == ADT.DB) {
              console.log(cd.db);
              n[cd.db] = { value: x.data[cd.key], label: x.data[cd.key] };
            }
          });
          setNestedSelectedOptions(n);
        }
      });
    } else {
      let proms = [];

      let nestedApiData = {};
      columnData.forEach((cd) => {
        if (cd.type == ADT.DB) {
          proms.push(
            new Promise((res, rej) => {
              ListCommanWay(`/admin/${cd.db}/list`, {}, { paginate: 1000 })
                .then((x) => {
                  let data: OptionType[] = [];
                  if (x.status == "SUCCESS") {
                    data = x.data.data.map((d) => {
                      return { value: d.id, label: d[cd.db_col] };
                    });
                    nestedApiData[cd.db] = data;
                  }
                  res(1);
                })
                .catch((c) => {
                  res(0);
                });
            })
          );
        }
      });

      Promise.all(proms).then((x) => {
        console.log("nestedApiData", nestedApiData, nestedSelectedOptions);
        setNestedTables(nestedApiData);
      });
    }
  }, [dataMode]);

  const {
    mutate,
    isLoading: isloading,
  }: {
    mutate: UseMutateFunction<AxiosResponse<any, any>, unknown, any, unknown>;
    isLoading: any;
  } = useMutation(dataMode == "Add" ? CreateCommanWay : UpdateCommanWay, {
    onSuccess: (data: any) => {
      if (data.status == "SUCCESS") {
        UseToast("Data Added Successfully", "success");
        successNavigate();
      } else {
        UseToast(data.message, "error");
      }
    },
    onError: (data: any) => {
      typeof data === "string" ? UseToast(data, "error") : UseToast(data.message, "error");
    },
  });
  const { handleSubmit, handleBlur, values, handleChange, errors, setValues, setErrors, touched, dirty, setFieldValue } = useFormik({
    validationSchema: validationSchema,
    initialValues: initialValues,
    onSubmit: async (values, { resetForm }) => {
      mutate({
        url: apiUrl + (dataMode == "Add" ? "/create" : "/update/" + params?.id),
        data:
          apiUrl.indexOf("branch") !== -1 && values["pincode"] !== undefined ? { ...values, pincode: Number(values["pincode"]) } : values,
      });
    },
  });

  const successNavigate = useCallback(
    debounce(() => navigate(successNav), 200),
    []
  );

  const onCancel = () => {
    navigate(cancelNav);
  };

  return (
    <div className="p-5">
      <div className="text-right">
        {dataMode == "View" ? (
          <button
            onClick={() => {
              setDataMode("Edit");
            }}
            type="submit"
            className="px-4 py-2 text-sm rounded-md font-semibold bg-warning border border-gray-200 hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-2 focus:ring-blue-700 focus:text-blue-700"
          >
            Edit Details
          </button>
        ) : (
          <button
            onClick={() => {
              setDataMode("View");
            }}
            type="submit"
            className="px-4 py-2 text-sm rounded-md font-semibold bg-warning border border-gray-200 hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-2 focus:ring-blue-700 focus:text-blue-700"
          >
            Cancel Edit
          </button>
        )}
      </div>
      <form
        onSubmit={(e) => {
          e.preventDefault();
          handleSubmit(e);
        }}
      >
        <div className="grid grid-cols-3 gap-4">
          {columnData.map((c) => {
            if (c.add == true) {
              let isDisabled = true;
              if ((dataMode == "Add" && c.add) || (dataMode == "Edit" && c.edit)) {
                isDisabled = false;
              }
              switch (c.type) {
                case ADT.NUMBER:
                  return (
                    <div key={"general_" + c.key}>
                      <label htmlFor={"general_" + c.key} className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
                        {c.label}
                      </label>
                      <input
                        type="number"
                        id={"general_" + c.key}
                        className={`${
                          isDisabled ? "bg-gray-300" : ""
                        } bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5`}
                        placeholder={c.label}
                        onBlur={handleBlur}
                        onChange={handleChange}
                        name={c.key}
                        value={values[c.key]}
                        disabled={isDisabled}
                      />

                      <p className="mt-21 text-sm text-red-600">{touched[c.key] && Boolean(errors[c.key]) ? errors[c.key] : ""}</p>
                    </div>
                  );
                  break;
                case ADT.TEXT:
                  return (
                    <div key={"general_" + c.key}>
                      <label htmlFor={"general_" + c.key} className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
                        {c.label}
                      </label>
                      <input
                        type="text"
                        id={"general_" + c.key}
                        className={`${
                          isDisabled ? "bg-gray-300" : ""
                        } bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5`}
                        placeholder={c.label}
                        onBlur={handleBlur}
                        onChange={handleChange}
                        name={c.key}
                        value={values[c.key]}
                        disabled={isDisabled}
                      />

                      <p className="mt-21 text-sm text-red-600">{touched[c.key] && Boolean(errors[c.key]) ? errors[c.key] : ""}</p>
                    </div>
                  );
                  break;
                case ADT.DB:
                  return (
                    <div key={"general_" + c.key}>
                      <label htmlFor={"general_" + c.key} className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
                        {c.label}
                      </label>

                      <Select
                        key={"select-" + (nestedTables[c.db] ? nestedTables[c.db].length : "undef")}
                        className={`${
                          isDisabled ? "bg-gray-300" : ""
                        } bg-gray-50 border-gray-300 text-gray-900 text-sm rounded-lg block w-full`}
                        id={"general_" + c.key}
                        options={nestedTables[c.db] ? nestedTables[c.db] : defaultOptions}
                        onChange={(el) => {
                          setFieldValue(c.key, el.value);
                        }}
                        name={c.key}
                        value={nestedSelectedOptions[c.db]}
                      />

                      <p className="mt-21 text-sm text-red-600">{touched[c.key] && Boolean(errors[c.key]) ? errors[c.key] : ""}</p>
                    </div>
                  );
                  break;
                case ADT.BOOLEAN:
                  return (
                    <div key={"general_" + c.key}>
                      <label htmlFor={"general_" + c.key} className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
                        {c.label}
                      </label>

                      <Select
                        className={`${
                          isDisabled ? "bg-gray-300" : ""
                        } bg-gray-50 border-gray-300 text-gray-900 text-sm rounded-lg block w-full`}
                        id={"general_" + c.key}
                        options={[
                          { value: true, label: "Yes" },
                          { value: false, label: "No" },
                        ]}
                        onChange={(el) => {
                          setFieldValue(c.key, el.value);
                        }}
                        name={c.key}
                        value={nestedSelectedOptions[c.key]}
                      />

                      <p className="mt-21 text-sm text-red-600">{touched[c.key] && Boolean(errors[c.key]) ? errors[c.key] : ""}</p>
                    </div>
                  );
                  break;
                case ADT.TEXTAREA:
                  break;
                case ADT.DATETIME:
                  break;
                case ADT.FILE:
                  break;
                default:
                  return <></>;
                  break;
              }
            }
          })}
        </div>
        {dataMode != "View" && (
          <div className="inline-flex rounded-md overflow-hidden shadow-sm mb-1 mt-4" role="group">
            <button
              type="submit"
              className="px-4 py-2 text-sm font-medium text-white bg-green-500 border border-gray-200 hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-2 focus:ring-blue-700 focus:text-blue-700"
            >
              {dataMode}
            </button>
            <button
              type="button"
              onClick={onCancel}
              className="px-4 py-2 text-sm font-medium text-white bg-gray-500 border border-gray-200 hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-2 focus:ring-blue-700 focus:text-blue-700"
            >
              Back to List
            </button>
          </div>
        )}
      </form>
    </div>
  );
};

export { GeneralAddForm };
