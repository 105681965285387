import { UseMutateFunction, useMutation, useQuery } from "@tanstack/react-query";
import { CreateCommanWay, GetCommanWay, ListCommanWay } from "API/commonApi";
import { AxiosResponse } from "axios";
import { Button, Img } from "components";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import Swal from "sweetalert2";
import UseToast from "utils/AfToast";
import { roles } from "utils/constant";

const CustomerTrackingAwb = () => {
  const BASE_URL = process.env.REACT_APP_BACKEND_URL;
  const navigator = useNavigate();

  const [awbNo, setAwbNo] = useState("");
  const [awbTrackingData, setAwbTrackingData] = useState<any>({
    tracking_data: [],
    bookingDate: "",
    awbData: {},
    bookingData: {},
  });
  const [searchParams, setSearchParams] = useSearchParams();
  const [deliveryDoneInfo, setDeliveryDoneInfo] = useState<any>({});
  const [productList, setProductList] = useState<any>([]);
  const [commentsList, setCommentsList] = useState<any>([]);

  const [showImg, setShowImg] = useState<any>(false);
  const [showVolume, setShowVolume] = useState<any>(false);

  const [canEditBook, setCanEditBook] = useState<any>(false);
  React.useEffect(() => {
    const userD = localStorage.getItem("auth") ? JSON.parse(localStorage.getItem("auth")) : {};
    if (Object.keys(userD).length) {
      if (userD.state.user.role_name == 1 || userD.state.user.role_name == 2 || userD.state.user.role_name == 3) {
        setCanEditBook(true);
      }
    }
  }, []);

  const { isFetching, isLoading, refetch } = useQuery(["admin_product_list"], () => ListCommanWay("/admin/product/list", {}, {}), {
    keepPreviousData: true,
    onSuccess(data) {
      if (data.status == "SUCCESS") {
        setProductList(data.data.data);
      }
    },
    onError: (data) => {},
  });

  const { refetch: commnetsRefetching } = useQuery(
    ["comments_list"],
    () =>
      ListCommanWay(
        "/admin/awbcomment/list",
        {
          awb_id: awbTrackingData?.awbData?.id,
        },
        { include: ["_addedBy"] }
      ),
    {
      keepPreviousData: true,
      enabled: false,
      onSuccess(data) {
        if (data.status == "SUCCESS") {
          setCommentsList(data.data.data);
        }
      },
      onError: (data) => {},
    }
  );

  useEffect(() => {
    if (awbTrackingData?.awbData?.id) {
      commnetsRefetching();
    }
  }, [awbTrackingData]);

  const {
    mutate,
    isLoading: isloading,
  }: {
    mutate: UseMutateFunction<AxiosResponse<any, any>, unknown, any, unknown>;
    isLoading: any;
  } = useMutation((awb_no) => GetCommanWay("/admin/proof_of_delivery/track/" + awb_no, {}, {}), {
    onSuccess: (data: any) => {
      console.log(data, data.status == "SUCCESS");
      if (data.status == "SUCCESS") {
        let resData = data.data;

        try {
          let lastMsg = resData.tracking_data[resData.tracking_data.length - 1]?.message_code;
          if (
            lastMsg.includes("Motherbag Tally") ||
            lastMsg.includes("Motherbag Out-Scan") ||
            lastMsg.includes("Motherbag In-Scan") ||
            lastMsg.includes("DRS Generated") ||
            lastMsg.includes("Send To ThirdParty") ||
            lastMsg.includes("Sent To ThirdParty")
          ) {
            resData.tracking_data[resData.tracking_data.length - 1].message_code = "In Transit";
          }
        } catch (ignored) {}

        setAwbTrackingData(resData);

        if (data.data.tracking_data) {
          try {
            data.data.tracking_data.forEach((t) => {
              if (t.message_code == "Shipment Delivered" || t.message_code == "0 - Shipment Delivered") {
                t.createdAt = t.createdAt.slice(0, -3); 
                setDeliveryDoneInfo(t);
              }
            });
          } catch (e) {}
        }
      } else {
        UseToast(data.message, "error");
      }
    },
    onError: (data: any) => {
      typeof data === "string" ? UseToast(data, "error") : UseToast(data.message, "error");
    },
  });
  const handleSubmit = () => {
    setAwbTrackingData({
      tracking_data: [],
      bookingDate: "",
      awbData: {},
      bookingData: {},
    });
    mutate(awbNo);
  };

  useEffect(() => {
    if (searchParams.get("awb")) {
      setAwbNo(searchParams.get("awb"));
      setAwbTrackingData({
        tracking_data: [],
        bookingDate: "",
        awbData: {},
        bookingData: {},
      });
      mutate(searchParams.get("awb"));
    }
  }, [searchParams]);

  const getTRline = (name, value, isEven: any = false) => {
    return (
      <tr className={isEven ? "bg-blue-100" : ""}>
        <td className="text-right pr-5  whitespace-nowrap">{name}</td>
        <td>{value}</td>
      </tr>
    );
  };

  const getChargeableWeight = (weight, product_code, ll, bb, hh) => {
    ll = Number(ll);
    bb = Number(bb);
    hh = Number(hh);
    let dWeight = (ll * bb * hh) / (product_code == "X" ? 2700 : 5000);
    return Math.max(Number(weight), dWeight);
  };

  const {
    mutate: mutateComment,
    isLoading: isCommentloading,
  }: {
    mutate: UseMutateFunction<AxiosResponse<any, any>, unknown, any, unknown>;
    isLoading: any;
  } = useMutation((data) => CreateCommanWay(data), {
    onSuccess: (data: any) => {
      console.log(data, data.status == "SUCCESS");
      if (data.status == "SUCCESS") {
        window.location.reload();
      } else {
        UseToast(data.message, "error");
      }
    },
    onError: (data: any) => {
      typeof data === "string" ? UseToast(data, "error") : UseToast(data.message, "error");
    },
  });

  const handleClickComment = () => {
    Swal.fire({
      title: "Post Comment",
      text: "",
      input: "text",
      confirmButtonText: "Save",
      showCancelButton: true,
      inputPlaceholder: "Enter Comment",
      inputAttributes: {
        style: "border: 1px solid gray; border-radius: 8px",
      },
    }).then((x) => {
      if (x.isConfirmed) {
        console.log(x);
        mutateComment({
          url: "/admin/awbcomment/create",
          data: { comment: x.value, awb_id: awbTrackingData?.awbData?.id },
        });
      }
    });
  };

  return (
    <>
      {showVolume && (
        <div className="absolute left-0 top-0 w-full h-full bg-black-900 bg-opacity-20 flex items-center justify-center">
          <div className="rounded-xl border-2 border-primary p-4 bg-white relative">
            <div className="flex items-center justify-between bg-primary text-white p-2 rounded-lg">
              <p>Total Weight: {awbTrackingData?.awbData?.weight}</p>
              <button
                className="px-1"
                onClick={() => {
                  setShowVolume(false);
                }}
              >
                ✖
              </button>
            </div>

            <div>
              <table className="table mt-2">
                <tr>
                  <th className="px-2 border-r">Sr.</th>
                  <th className="px-2">Lenght</th>
                  <th className="px-2">Width</th>
                  <th className="px-2">Height</th>
                  <th className="px-2">Weight</th>
                </tr>
                {awbTrackingData?.awbData?.innerItemsJson && awbTrackingData?.awbData?.innerItemsJson != "[]" ? (
                  JSON.parse(awbTrackingData?.awbData?.innerItemsJson).map((i, a) => {
                    return (
                      <tr className="border-t">
                        <td className="px-4 border-r">{a + 1}</td>
                        <td className="px-4">{i.l}</td>
                        <td className="px-4">{i.b}</td>
                        <td className="px-4">{i.h}</td>
                        <td className="px-4">{i.weight}</td>
                      </tr>
                    );
                  })
                ) : (
                  <tr className="border-t">
                    <td className="px-4">1</td>
                    <td className="px-4">{awbTrackingData?.awbData?.l}</td>
                    <td className="px-4">{awbTrackingData?.awbData?.b}</td>
                    <td className="px-4">{awbTrackingData?.awbData?.h}</td>
                    <td className="px-4">{awbTrackingData?.awbData?.weight}</td>
                  </tr>
                )}
              </table>
            </div>
          </div>
        </div>
      )}
      <div className="flex flex-col gap-4 px-4 mt-2">
        <div className=" border p-2 rounded-2xl">
          <h1 className="text-center mb-4 mt-3 text-2xl font- underline whitespace-nowrap uppercase">Courier Details</h1>

          <div className="flex gap-2">
            <table>
              {getTRline("Airway Bill No", awbNo, 1)}
              {getTRline("Book Date", moment(awbTrackingData.bookingData?.date, "DD-MM-YYYY HH:mm:ss").format("DD-MM-YYYY"))}
              {getTRline(
                "Product",
                productList
                  .map((p) => {
                    return p.product_code == awbTrackingData.awbData.prefix ? p.product_name : "";
                  })
                  .join(""),
                1
              )}
              {getTRline("Reference Code", awbTrackingData.bookingData.pickup_code)}
              {getTRline("From", awbTrackingData.bookingData.shipper_city, 1)}
              {getTRline("To", awbTrackingData.awbData.destination)}
              {getTRline("Product Type", awbTrackingData.awbData.dox_or_non_dox ? "DOX" : "Non-Dox", 1)}
              {getTRline("Client Name", awbTrackingData.bookingData.customer_sh)}
              {getTRline("Shipper Name", awbTrackingData.bookingData.shipper_sh, 1)}
              {getTRline("Shipper Addr", awbTrackingData.bookingData.shipper_address)}
              {getTRline("Consignee Name", awbTrackingData.awbData.consignee, 1)}
              {getTRline("Consignee Addr", awbTrackingData.awbData.address)}
              {getTRline("Pincode", awbTrackingData.awbData.pincode, 1)}
              {getTRline(<b>Current Status</b>, awbTrackingData.tracking_data[awbTrackingData.tracking_data.length - 1]?.message_code)}
              {getTRline("Delivery Date", deliveryDoneInfo.createdAt || "-",1)}
              {getTRline("Receiver", deliveryDoneInfo.receiver || '-')}
              {awbTrackingData.tracking_data[awbTrackingData.tracking_data.length - 1]?.message_code.includes("RTO") &&
                getTRline(
                  <b>RTO Awb No.</b>,
                  (
                    <a style={{ color: "blue" }} href={`/customer-pod/tracking?awb=${awbTrackingData.awbData?.returnAwb}`}>
                      {awbTrackingData.awbData?.returnAwb}
                    </a>
                  ) || "-"
                )}
            </table>
          </div>
          <div className="flex justify-between mt-2 border-t pt-2">
            <div className="flex gap-8 items-center">
              <p>
                Weight: <b>{awbTrackingData.awbData.weight} kg</b>
              </p>
              <p>
                Chargeable Weight:{" "}
                <b>
                  {getChargeableWeight(
                    awbTrackingData.awbData.weight,
                    awbTrackingData.awbData.prefix,
                    awbTrackingData.awbData.l,
                    awbTrackingData.awbData.b,
                    awbTrackingData.awbData.h
                  )}{" "}
                  kg
                </b>
              </p>
              <p>
                10_30: <b>{awbTrackingData.awbData.is10_30 ? "YES" : "NO"}</b>
              </p>
              <p>
                Holiday: <b>{awbTrackingData.awbData.holiday ? "YES" : "NO"}</b>
              </p>

              <Button
                className="bg-blue-600 px-2 py-1 rounded-md text-white mr-3"
                onClick={() => {
                  setShowVolume(true);
                }}
              >
                Show Volume Info
              </Button>
            </div>
          </div>
        </div>

        {showImg && (
          <div className="flex gap-4">
            {awbTrackingData.tracking_data[awbTrackingData.tracking_data.length - 1]?.signature && (
              <Img className="h-48" src={BASE_URL + awbTrackingData.tracking_data[awbTrackingData.tracking_data.length - 1]?.signature} />
            )}
            {awbTrackingData.tracking_data[awbTrackingData.tracking_data.length - 1]?.photo && (
              <Img className="h-48" src={BASE_URL + awbTrackingData.tracking_data[awbTrackingData.tracking_data.length - 1]?.photo} />
            )}
          </div>
        )}
      </div>
    </>
  );
};

export default CustomerTrackingAwb;
