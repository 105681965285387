import React, { memo, Suspense } from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import { lazyImport } from "../../utils/lazyImport";

import PrivateRouter, { PrivateRouteProps } from "../../Routes/RootNavigator/PrivateRouter";
import Spinner from "../../utils/Spinner";
import { PublicRouteProps } from "../../Routes/RootNavigator/PublicRouter";
import GenericTableList from "pages/GenericTable/GenericTableList";
import GenericTableDetails from "pages/GenericTable/GenericTableDetails";
import GenericTableCreate from "pages/GenericTable/GenericTableCreate";
import ToPayPickpAdd from "pages/ToPayPickup/ToPayPickup";
import AddBooking from "pages/Booking/AddBooking";
import BookingList from "pages/Booking/BookingList";
import UpdatePOD from "pages/POD/UpdatePOD";
import TrackingAWB from "pages/POD/TrackingAWB";
import AddMotherBag from "pages/MotherBag/AddMotherBag";
import MotherBagList from "pages/MotherBag/MotherBagList";
import AddRegularVouchure from "pages/Vouchure/AddRegularVouchure";
import RegularVouchureList from "pages/Vouchure/RegularVouchureList";
import AddExpenseVouchure from "pages/Vouchure/AddExpenseVouchure";
import ExpenseVouchureList from "pages/Vouchure/ExpenseVouchureList";
import AWBList from "pages/AWB/AWBList";
import RateCalculator from "pages/Rate/RateCalculator";
import MotherBagOutScan from "pages/MotherBag/MotherBagOutScan";
import MotherBagInScan from "pages/MotherBag/MotherBagInScan";
import ManageDRS from "pages/MotherBag/ManageDRS";
import GenerateAwb from "pages/AWB/GenerateAwb";
import DrsList from "pages/MotherBag/DrsList";
import BookingReport from "pages/Report/BookingReport";
import IntlRateCalculator from "pages/Rate/IntlRateCalculator";
import AddIntlBooking from "pages/Booking/AddIntlBooking";
import SendToThirdParty from "pages/MotherBag/SendToThirdParty";
import AttendanceReport from "pages/Report/AttendanceReport";
import Overedge from "pages/POD/Overedge";
import CashReport from "pages/Report/CashReport";
import VouchureGen from "pages/Billing/VouchureGen";
import CustomerBookReport from "pages/ReportCustomer/CustomerBookReport";
import CustomerTrackingAwb from "pages/POD/CustomerTrackingAwb";
import AdminDataChange from "pages/AdminDataChange";

//const { NotFound } = lazyImport(() => import("pages/NotFound"), "NotFound");

const { Dashboard } = lazyImport(() => import("pages/Dashboard"), "Dashboard");

const AppContent = () => {
  const GenericTables = [
    "branch",
    "subbranch",
    "customer",
    "agent",
    "user",
    "rate",
    "country",
    "region",
    "state",
    "city",
    "product",
    "tax",
    "rc_code",
    "international_zone",
    "international_rate",
  ];
  return (
    <Suspense
      fallback={
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100%",
          }}
        >
          <Spinner />
        </div>
      }
    >
      <Routes>
        <Route path="/dashboard">
          <Route index element={<PrivateRouter {...defaultPrivateRouteProps} outlet={<Dashboard />} />} />
        </Route>

        <Route path="/merchant" element={<Navigate to="/merchant/list" />} />
        <Route path="*" element={<Navigate to="/dashboard" />} />

        <Route path="routing-rule">
          <Route
            // index
            path="list"
            element={<PrivateRouter {...defaultPrivateRouteProps} outlet={<Dashboard />} />}
          />
          {/* <Route path="add" element={<CreateAdmin />} /> */}
          <Route path="list/:id" element={<Dashboard />} />
          <Route path="add" element={<Dashboard />} />
        </Route>

        {GenericTables.map((tbl) => {
          return (
            <Route path={`/master/${tbl}`} key={"route-" + tbl}>
              <Route path="list" element={<PrivateRouter {...defaultPrivateRouteProps} outlet={<GenericTableList tableName={tbl} />} />} />
              {/* <Route path="add" element={<CreateAdmin />} /> */}
              <Route path="list/:id" element={<GenericTableDetails tableName={tbl} />} />
              <Route path="add" element={<GenericTableCreate tableName={tbl} />} />
            </Route>
          );
        })}

        <Route path="/topay-pickup">
          <Route
            // index
            path="list"
            element={<PrivateRouter {...defaultPrivateRouteProps} outlet={<Dashboard />} />}
          />
          {/* <Route path="add" element={<CreateAdmin />} /> */}
          <Route path="list/:id" element={<Dashboard />} />
          <Route path="add" element={<ToPayPickpAdd />} />
        </Route>
        <Route path="/booking">
          <Route
            // index
            path="list"
            element={<BookingList />}
          />
          {/* <Route path="add" element={<CreateAdmin />} /> */}
          <Route path="list/:id" element={<Dashboard />} />
          <Route path="add" element={<AddBooking />} />
          <Route path="add-intl" element={<AddIntlBooking />} />
        </Route>
        <Route path="/awb">
          <Route path="list" element={<AWBList />} />
          <Route path="generate" element={<GenerateAwb />} />
        </Route>
        <Route path="/rate">
          <Route path="calculate" element={<RateCalculator />} />
          <Route path="intl-calculate" element={<IntlRateCalculator />} />
        </Route>
        <Route path="/mother_bag">
          <Route path="add" element={<AddMotherBag />} />
          <Route path="list" element={<MotherBagList />} />
          <Route path="outscan" element={<MotherBagOutScan />} />
          <Route path="inscan" element={<MotherBagInScan />} />
        </Route>
        <Route path="/drs">
          <Route path="manage" element={<ManageDRS />} />
          <Route path="list" element={<DrsList />} />
        </Route>
        <Route path="/third-party">
          <Route path="manage" element={<SendToThirdParty />} />
        </Route>
        <Route path="/pod">
          <Route path="update" element={<UpdatePOD />} />
          <Route path="tracking" element={<TrackingAWB />} />

          <Route path="overedge" element={<Overedge />} />

        </Route>
        <Route path="/vouchure">
          <Route
            // index
            path="regular/add"
            element={<AddRegularVouchure />}
          />
          <Route
            // index
            path="regular/list"
            element={<RegularVouchureList />}
          />
          <Route
            // index
            path="expense/add"
            element={<AddExpenseVouchure />}
          />
          <Route
            // index
            path="expense/list"
            element={<ExpenseVouchureList />}
          />
        </Route>

        <Route path="/report">
          <Route path="booking" element={<BookingReport />} />
          <Route path="cash" element={<CashReport />} />
          <Route path="attendance" element={<AttendanceReport />} />
        </Route>

        <Route path="/billing">
          <Route path="vouchure-gen" element={<VouchureGen />} />
        </Route>


        <Route path="/customer-report">
          <Route path="booking" element={<CustomerBookReport />} />
        </Route>
        <Route path="/customer-pod">
          <Route path="tracking" element={<CustomerTrackingAwb />} />
        </Route>


        <Route path="/admin">
          <Route path="data-change" element={<AdminDataChange />} />
        </Route>

      </Routes>
    </Suspense>
  );
};

export default memo(AppContent);

const defaultPublicRouteProps: Omit<PublicRouteProps, "outlet"> = {
  authenticatedPath: "/",
};

const defaultPrivateRouteProps: Omit<PrivateRouteProps, "outlet"> = {
  authenticationPath: "/login",
};
