import { MinusCircleIcon } from "@heroicons/react/24/outline";
import { UseMutateFunction, useMutation, useQuery } from "@tanstack/react-query";
import { CreateCommanWay, DeleteCommanWay, GetCommanWay, ListCommanWay, UpdateBookingCommanWay, UpdateCommanWay } from "API/commonApi";
import { AxiosResponse } from "axios";
import { InputTable } from "components/DataTable/InputTable";
import Modal from "components/Modal/Modal";
import { useFormik } from "formik";
import { debounce, includes } from "lodash";
import moment from "moment";
import { any } from "prop-types";
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Select from "react-select";
import Swal from "sweetalert2";
import UseToast from "utils/AfToast";
import { roles } from "utils/constant";
import handleWheel from "utils/handleOnWheel";
import { AfDataType as ADT } from "utils/TableColumnData";
import { number, object, string } from "yup";

type OptionType = {
  value: number;
  label: string;
};

interface MotherBagState {
  mode?: string;
  data?: { [key: string]: any }; // Adjust the type based on your data structure
  // Add other properties if needed
}

interface ValuesType {
  destination?: { value: string; label: string };
}

const AddMotherBag: React.FC = ({}) => {
  let isDisabled = false;
  const navigate = useNavigate();
  const { state } = useLocation();
  const { mode = "add", data = {} } = (state || {}) as MotherBagState; // Check for existence and type assertion

  const [newTableData, setNewTableData] = useState<any>([]);
  const [selectedTableData, setSelectedTableData] = useState<any>([]);

  const [savedAwbData, setSavedAwbData] = useState<any>([]);

  const [nestedTables, setNestedTables] = useState<any>({});
  const [pincodeSection, setPincodeSection] = useState<any>("");
  const [userDetails, setUserDetails] = useState<any>({});

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [newMotherbagCode, setNewMotherbagCode] = useState("");

  const [branchCity, setBranchCity] = useState("");
  const [isFirstLoadDone, setIsFirstLoadDone] = useState<boolean>(false);

  const handleDelete = (id) => {
    setNewTableData((prevData) => [...prevData, selectedTableData.find((d) => d.id === id)]);
    setSelectedTableData((prevData) => {
      return prevData.filter((d) => d?.id !== id);
    });
  };

  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  useEffect(() => {
    console.log(data);
    resetForm();
    setNewTableData([]);
    setSelectedTableData([]);
  }, [mode]);

  const columnData = [
    {
      label: "NO.",
      key: "id",
      type: ADT.TEXT,
      initialValue: "",
      view: false,
      edit: false,
      add: true,
      filter: true,
      sort: true,
    },
    {
      label: "Booking Date",
      key: "bookingDate",
      type: ADT.TEXT,
      initialValue: "",
      view: true,
      add: true,
      edit: false,
      filter: true,
      sort: true,
    },
    {
      label: "AWB NO.",
      key: "awb_no",
      type: ADT.TEXT,
      initialValue: "",
      view: true,
      edit: false,
      add: true,
      filter: true,
      sort: true,
    },
    {
      label: "Weight",
      key: "weight",
      type: ADT.NUMBER,
      initialValue: 0,
      view: true,
      add: true,
      edit: true,
      filter: true,
      sort: true,
    },

    {
      label: "Consignee",
      key: "consignee",
      type: ADT.TEXT,
      initialValue: "",
      view: true,
      add: true,
      edit: false,
      filter: true,
      sort: true,
    },
    {
      label: "Destination",
      key: "destination",
      type: ADT.TEXT,
      initialValue: "",
      view: true,
      add: true,
      edit: false,
      filter: true,
      sort: true,
    },
    mode == "view"
      ? {
          label: "Shipper City",
          key: "shipper_city",
          type: ADT.DB,
          db: "booking",
          db_col: "shipper_city",
          view: true,
        }
      : {},

    mode == "view"
      ? {
          label: "Shipper name",
          key: "shipper_sh",
          type: ADT.DB,
          db: "booking",
          db_col: "shipper_sh",
          view: true,
        }
      : {},
  ];

  const selectedColumnData = [
    {
      label: " ",
      key: "delete",
      type: ADT.BUTTON,
      btnComponent: (id) => (
        <>
          {mode === "view" ? null : (
            <button
              onClick={() => {
                handleDelete(id);
              }}
              type="button"
              className="rounded-md px-1 py-1 text-sm font-medium text-white bg-green-500 border border-gray-200 hover:bg-green-800 hover:text-blue-700 focus:z-10 focus:ring-2 focus:ring-blue-700 focus:text-blue-700"
            >
              <MinusCircleIcon color="white" height={25} width={25} />
            </button>
          )}
        </>
      ),
      initialValue: "",
      view: true,
      edit: false,
      add: true,
      filter: true,
      sort: true,
    },
    {
      label: "NO.",
      key: "id",
      type: ADT.TEXT,
      initialValue: "",
      view: false,
      edit: false,
      add: true,
      filter: true,
      sort: true,
    },
    {
      label: "Booking Date",
      key: "bookingDate",
      type: ADT.TEXT,
      initialValue: "",
      view: true,
      add: true,
      edit: false,
      filter: true,
      sort: true,
    },
    {
      label: "AWB NO.",
      key: "awb_no",
      type: ADT.TEXT,
      initialValue: "",
      view: true,
      edit: false,
      add: true,
      filter: true,
      sort: true,
    },
    {
      label: "Weight",
      key: "weight",
      type: ADT.NUMBER,
      initialValue: 0,
      view: true,
      add: true,
      edit: true,
      filter: true,
      sort: true,
    },

    mode == "view"
      ? {
          label: "Shipper name",
          key: "shipper_sh",
          type: ADT.DB,
          db: "booking",
          db_col: "shipper_sh",
          view: true,
        }
      : {},

    mode == "view"
      ? {
          label: "Shipper City",
          key: "shipper_city",
          type: ADT.DB,
          db: "booking",
          db_col: "shipper_city",
          view: true,
        }
      : {},

    {
      label: "Consignee",
      key: "consignee",
      type: ADT.TEXT,
      initialValue: "",
      view: true,
      add: true,
      edit: false,
      filter: true,
      sort: true,
    },

    {
      label: "Destination",
      key: "destination",
      type: ADT.TEXT,
      initialValue: "",
      view: true,
      add: true,
      edit: false,
      filter: true,
      sort: true,
    },
  ];

  const savedColumnData = [
    {
      label: " ",
      key: "delete",
      type: ADT.BUTTON,
      btnComponent: (id) => (
        <>
          {mode === "view" ? null : (
            <button
              onClick={() => {
                //handleDelete(id);
                //setNewTableData((prevData) => [...prevData, selectedTableData.find((d) => d.id === id)]);
                setSavedAwbData((prevData) => {
                  return prevData.filter((d) => d?.id !== id);
                });
              }}
              type="button"
              className="rounded-md px-1 py-1 text-sm font-medium text-white bg-green-500 border border-gray-200 hover:bg-green-800 hover:text-blue-700 focus:z-10 focus:ring-2 focus:ring-blue-700 focus:text-blue-700"
            >
              <MinusCircleIcon color="white" height={25} width={25} />
            </button>
          )}
        </>
      ),
      initialValue: "",
      view: true,
      edit: false,
      add: true,
      filter: true,
      sort: true,
    },
    {
      label: "NO.",
      key: "id",
      type: ADT.TEXT,
      initialValue: "",
      view: false,
      edit: false,
      add: true,
      filter: true,
      sort: true,
    },
    {
      label: "Booking Date",
      key: "bookingDate",
      type: ADT.TEXT,
      initialValue: "",
      view: true,
      add: true,
      edit: false,
      filter: true,
      sort: true,
    },
    {
      label: "AWB NO.",
      key: "awb_no",
      type: ADT.TEXT,
      initialValue: "",
      view: true,
      edit: false,
      add: true,
      filter: true,
      sort: true,
    },
    {
      label: "Weight",
      key: "weight",
      type: ADT.NUMBER,
      initialValue: 0,
      view: true,
      add: true,
      edit: true,
      filter: true,
      sort: true,
    },

    {
      label: "Consignee",
      key: "consignee",
      type: ADT.TEXT,
      initialValue: "",
      view: true,
      add: true,
      edit: false,
      filter: true,
      sort: true,
    },

    {
      label: "Destination",
      key: "destination",
      type: ADT.TEXT,
      initialValue: "",
      view: true,
      add: true,
      edit: false,
      filter: true,
      sort: true,
    },
  ];

  const {
    mutate,
    isLoading: isloading,
  }: {
    mutate: UseMutateFunction<AxiosResponse<any, any>, unknown, any, unknown>;
    isLoading: any;
  } = useMutation(
    (destination = "") =>
      ListCommanWay(
        "/admin/awbdata/list",
        {
          .../*mode !== "edit" */ (typeof destination == "string" &&
            mode !== "view" && {
              destination: destination != "INTERNATIONAL" ? destination : undefined,
              prefix: destination == "INTERNATIONAL" ? ["I", "i"] : undefined,
              subbranch: values.subbranch.value,
              drs: { $eq: null },
              thirdPartyName: { $eq: null },
              assign_to_booking: { $ne: null },
              mother_bag_id: { $eq: null },
              includeReRoute: true,
            }),
          ...(typeof destination != "string" &&
            (mode === "edit" || mode === "view") && {
              mother_bag_id: data.id,
              viewOnly: true,
            }),
        },
        { include: ["_assign_to_booking", "_mother_bag_id"], paginate: 5000 }
      ),
    {
      onSuccess: (data: any) => {
        console.log(data, data.status == "SUCCESS");
        if (data.status == "SUCCESS") {
          //UseToast("Data Added Successfully", "success");
          let cityName = data.data?.paginator?.city_name || "";

          let filteredAWBList;
          if (mode === "view" || mode === "edit") {
            filteredAWBList = data.data.data.filter((obj) => obj && (obj["mother_bag_id"] === 0 || obj["mother_bag_id"] === null));
          } else {
            filteredAWBList = data.data.data.filter(
              (obj) => obj && (obj.destination != cityName || obj["mother_bag_id"] === 0 || obj["mother_bag_id"] === null)
            );
          }

          if (filteredAWBList.length == 0) {
            setNewTableData([]);
          }

          if (filteredAWBList.length && /*mode !== "edit" &&*/ mode !== "view")
            setNewTableData((prevData) => {
              const newData = [];
              filteredAWBList.forEach((d) => {
                const obj = {};
                columnData.forEach((column) => {
                  obj[column["key"]] = d[column["key"]];
                  if (column["key"] === "bookingDate") {
                    //obj[column["key"]] = moment(d[column["key"]]).format("DD-MM-YYYY");
                    obj[column["key"]] = d["_assign_to_booking"] && moment(d["_assign_to_booking"]?.date, "DD-MM-YYYY HH:mm:ss").format("DD-MM-YYYY");
                  }
                });
                newData.push(obj);
              });

              return newData;
            });
          if (!isFirstLoadDone && /*mode === "edit" || */ mode === "view") {
            setSelectedTableData((prevData) => {
              const newData = [];
              data.data.data.forEach((d) => {
                const obj = {};
                columnData.forEach((column) => {
                  obj[column["key"]] = d[column["key"]];
                  if (column["key"] === "bookingDate") {
                    obj[column["key"]] = d["_assign_to_booking"] && moment(d["_assign_to_booking"]?.date, "DD-MM-YYYY HH:mm:ss").format("DD-MM-YYYY");
                  }
                  if (column["key"] === "shipper_sh") {
                    obj[column["key"]] = d["_assign_to_booking"] && d["_assign_to_booking"][column["db_col"]];
                  }
                  if (column["key"] === "shipper_city") {
                    obj[column["key"]] = d["_assign_to_booking"] && d["_assign_to_booking"][column["db_col"]];
                  }
                });
                newData.push(obj);
              });
              console.log("===>setNewTableData", newData);

              return newData;
            });
          }

          if (!isFirstLoadDone && mode == "edit") {
            setSavedAwbData((prevData) => {
              const newData = [];
              data.data.data.forEach((d) => {
                const obj = {};
                columnData.forEach((column) => {
                  obj[column["key"]] = d[column["key"]];
                  if (column["key"] === "bookingDate") {
                    //obj[column["key"]] = moment(d[column["key"]]).format("DD-MM-YYYY");
                    obj[column["key"]] =
                      d["_assign_to_booking"] && moment(d["_assign_to_booking"]?.date, "DD-MM-YYYY HH:mm:ss").format("DD-MM-YYYY");
                  }
                });
                newData.push(obj);
              });
              return newData;
            });
          }

          setIsFirstLoadDone(true);
        } else {
          UseToast(data.message, "error");
        }
      },
      onError: (data: any) => {
        typeof data === "string" ? UseToast(data, "error") : UseToast(data.message, "error");
      },
    }
  );

  const {
    mutate: mutateUpdateAWBs,
    isLoading: isUpdateAWBloading,
  }: {
    mutate: UseMutateFunction<AxiosResponse<any, any>, unknown, any, unknown>;
    isLoading: any;
  } = useMutation(
    (data) =>
      UpdateCommanWay({
        url: "/admin/awbdata/updateBulk",
        data: { data },
        query: {
          destination: values.destination.value,
        },
      }),
    {
      onSuccess: (data: any) => {
        console.log(data, data.status == "SUCCESS");
        if (data.status == "SUCCESS") {
          UseToast("Data Added Successfully", "success");
          window.location.reload();
          setNewTableData([]);
          setSelectedTableData([]);
        } else {
          UseToast(data.message, "error");
        }
      },
      onError: (data: any) => {
        typeof data === "string" ? UseToast(data, "error") : UseToast(data.message, "error");
      },
    }
  );

  const {
    mutate: mutateCreateMotherBag,
    isLoading: isCreateMotherBagloading,
  }: {
    mutate: UseMutateFunction<AxiosResponse<any, any>, unknown, any, unknown>;
    isLoading: any;
  } = useMutation(
    (data) =>
      CreateCommanWay({
        url: "/admin/mother_bag/create",
        data: {
          ...data,
          subbranch: data.subbranch.value,
          destination: data.destination.value,
          re_route_city: data.re_route_city.value,
          third_party: data.third_party.value,
          delivery_subbranch: data.delivery_subbranch.value,
          totalNos: selectedTableData.length || 0,
          awb_no_ids: selectedTableData.map((a) => {
            return a.id;
          }),
        },
      }),
    {
      onSuccess: (data: any) => {
        console.log(data, data.status == "SUCCESS");
        if (data.status == "SUCCESS") {
          setNewMotherbagCode(data.data.mother_bag_code);
          openModal();

          mutateUpdateAWBs(
            selectedTableData.map((d) => {
              console.log("======> d", {
                ...d,
                mother_bag_id: data.data.id,
              });

              return {
                ...d,
                mother_bag_id: data.data.id,
              };
            })
          );
        } else {
          UseToast(data.message, "error");
        }
      },
      onError: (data: any) => {
        typeof data === "string" ? UseToast(data, "error") : UseToast(data.message, "error");
      },
    }
  );

  const {
    mutate: mutateUpdateMotherBag,
    isLoading: isUpdateMotherBagloading,
  }: {
    mutate: UseMutateFunction<AxiosResponse<any, any>, unknown, any, unknown>;
    isLoading: any;
  } = useMutation(
    (obj) =>
      UpdateCommanWay({
        url: "/admin/mother_bag/update/" + data.id,
        data: obj,
      }),
    {
      onSuccess: (data: any) => {
        console.log(data, data.status == "SUCCESS");
        if (data.status == "SUCCESS") {
          mutateUpdateAWBs(
            newTableData.map((d) => {
              console.log("======> d", { ...d, mother_bag_id: null });
              return {
                ...d,
                mother_bag_id: null,
              };
            })
          );
        } else {
          UseToast(data.message, "error");
        }
      },
      onError: (data: any) => {
        typeof data === "string" ? UseToast(data, "error") : UseToast(data.message, "error");
      },
    }
  );

  const { handleSubmit, handleBlur, values, handleChange, errors, setValues, setErrors, touched, dirty, resetForm, setFieldValue } =
    useFormik({
      validationSchema: object().shape({}),
      initialValues: {
        subbranch: { value: 0, label: "" },
        destination: { value: "", label: "" },
        re_route_city: { value: "", label: "" },
        delivery_subbranch: { value: 0, label: "" },
        /*mother_bag_code: "MB" + Math.floor(Math.random() * 9000000),*/
        mother_bag_code: "",
        awb_no: "",
        third_party: { value: 0, label: "" },
      },
      onSubmit: async (values, { resetForm }) => {
        console.log("======> values", values);
        if (mode && mode === "edit") {
          let newAwbToAdd = [];
          let awbToRemote = [];

          mutateUpdateMotherBag({
            saved_awb_ids: savedAwbData.map((a: any) => {
              return a.id;
            }),
            selected_awb_ids: selectedTableData.map((a: any) => {
              return a.id;
            }),
          });
          //navigate("/mother_bag/list");
          return;
        }

        if (selectedTableData.length == 0) {
          alert("No Airwaybill is Selected");
          return;
        }

        let obj: any = { ...values };
        obj.sysWeight = 0;
        selectedTableData.forEach((a) => {
          obj.sysWeight += a.weight;
        });
        obj.sysWeight = obj.sysWeight + "";
        console.log(selectedTableData);
        //return;
        mutateCreateMotherBag(obj);
      },
    });

  const modeActions = () => {
    if (mode) {
      if (data.destination) {
        mutate(data.destination);
      }

      if (mode === "edit") {
        Object.keys(data).map((obj) => {
          console.log(obj);
          setFieldValue(obj, data[obj]);

          if (obj === "subbranch") {
            setBranchOtp((prevData) => {
              return data[obj];
            });
            setFieldValue(obj, data[obj]);

            values[obj] = data[obj];
          } else if (obj === "delivery_subbranch") {
            setDeliverySubbranchOpt((prevData) => {
              return data[obj];
            });
            setFieldValue(obj, data[obj]);
            values[obj] = data[obj];
          } else if (obj === "destination") {
            setDestinationOpt((prevData) => {
              return data[obj];
            });
            setFieldValue(obj, { value: data[obj], label: data[obj] });
            console.log("========> re_route_city", data[obj], data.subbranch);

            cityRefetch();
            values[obj] = data[obj];
          } else if (obj === "re_route_city") {
            setReRouteOpt((prevData) => {
              return data[obj];
            });
            setFieldValue(obj, { value: data[obj], label: data[obj] });
            console.log("========> re_route_city", data[obj], data.subbranch);

            cityRefetch();
          } else if (obj === "third_party") {
            setThirdPartyOtp((prevData) => {
              return data[obj];
            });
            values[obj] = data[obj];
          } else {
            values[obj] = data[obj];
          }
        });
      } else if (mode === "view") {
        Object.keys(data).map((obj) => {
          if (obj === "subbranch") {
            setBranchOtp(data[obj]);
            branchRefetch();
          } else if (obj === "delivery_subbranch") {
            setDeliverySubbranchOpt((prevData) => {
              return data[obj];
            });
            setFieldValue(obj, data[obj]);
            values[obj] = data[obj];
          } else if (obj === "destination") {
            setDestinationOpt((prevData) => {
              return data[obj];
            });
            setFieldValue(obj, { value: data[obj], label: data[obj] });
            console.log("========> re_route_city", data[obj], data.subbranch);

            cityRefetch();
          } else if (obj === "re_route_city") {
            setReRouteOpt((prevData) => {
              return data[obj];
            });
            console.log("========> re_route_city", data[obj], branchOpt);
            cityRefetch();
          } else if (obj === "third_party") {
            setThirdPartyOtp(data[obj]);
          } else {
            values[obj] = data[obj];
          }
        });
      }
    }
  };
  useEffect(() => {
    modeActions();
    cityRefetch();
  }, []);

  const [branchOpt, setBranchOtp] = useState<any>();

  const [branchOptions, setBranchOptions] = useState<any>([{ value: 0, label: "Loading..." }]);

  const {
    error: branchError,
    isFetching: branchIsFetching,
    isError: branchIsError,
    isFetched: branchIsFetched,
    isLoading: branchIsLoading,
    refetch: branchRefetch,
  } = useQuery(["BranchesListApi"], () => ListCommanWay("/admin/subbranch/list", {}, { paginate: 1000 }), {
    keepPreviousData: true,
    onSuccess(data) {
      if (data.status == "SUCCESS") {
        let opt = [];
        data.data.data.forEach((p) => {
          opt.push({
            value: p.id,
            label: p.branch_name,
          });
        });
        setBranchOptions(opt);
        if (mode === "edit" || mode === "view") {
          const findBranch = opt.find((d) => d.value === branchOpt);
          if (findBranch) {
            setFieldValue("subbranch", {
              value: findBranch.value,
              label: findBranch.label,
            });
            values.subbranch = {
              value: findBranch.value,
              label: findBranch.label,
            };
          }
        }
      }
    },
  });

  const [thirdPartyOpt, setThirdPartyOtp] = useState<any>();

  const [thirdPartyOptions, setThirdPartyOptions] = useState<any>([{ value: 0, label: "Loading..." }]);

  const {
    error: thirdPartyError,
    isFetching: thirdPartyIsFetching,
    isError: thirdPartyIsError,
    isFetched: thirdPartyIsFetched,
    isLoading: thirdPartyIsLoading,
    refetch: thirdPartyRefetch,
  } = useQuery(["ThirdPartyListApi"], () => ListCommanWay("/admin/third_party/list", {}, { paginate: 1000 }), {
    keepPreviousData: true,
    onSuccess(data) {
      if (data.status == "SUCCESS") {
        let opt = [];
        data.data.data.forEach((p) => {
          opt.push({
            value: p.id,
            label: p.name,
          });
        });
        setThirdPartyOptions(opt);
        if (mode === "edit" || mode === "view") {
          const findThirdParty = opt.find((d) => d.value === thirdPartyOpt);
          if (findThirdParty) {
            setFieldValue("third_party", {
              value: findThirdParty.value,
              label: findThirdParty.label,
            });
            values.third_party = {
              value: findThirdParty.value,
              label: findThirdParty.label,
            };
          }
        }
      }
    },
  });

  const [destinationOpt, setDestinationOpt] = useState<any>();

  const [destinationOptions, setDestinationOptions] = useState<any>([{ value: 0, label: "Loading..." }]);

  const { refetch: destCityRefetch } = useQuery(
    ["DestCityListApi"],
    () => ListCommanWay("/admin/city/list", {}, { paginate: 100000, isMotherbag: true }),
    {
      onSuccess(data) {
        if (data.status == "SUCCESS") {
          let opt = [];
          if (data?.data) {
            Object.values(data?.data?.data)?.forEach((d: any) => {
              opt.push({
                value: d?.id,
                label: d?.city_name,
              });
            });
            setDestinationOptions(opt);
          }
        }
      },
    }
  );

  const { refetch: cityRefetch } = useQuery(["CityListApi"], () => ListCommanWay("/admin/city/list", {}, { paginate: 100000 }), {
    onSuccess(data) {
      if (data.status == "SUCCESS") {
        let opt = [];
        if (data?.data) {
          Object.values(data?.data?.data)?.forEach((d: any) => {
            opt.push({
              value: d?.id,
              label: d?.city_name,
            });
          });
          setReRouteOptions(opt);

          if (mode === "edit" || mode === "view") {
            const findDestination = opt.find((d) => d.value === destinationOpt);
            if (findDestination) {
              setFieldValue("destination", { value: findDestination.value, label: findDestination.label });
            }
            const findReroute = opt.find((d) => d.value === reRouteOpt);
            if (findReroute) {
              setFieldValue("re_route_city", { value: findReroute.value, label: findReroute.label });
            }
          }
        }
      }
    },
  });

  const [reRouteOpt, setReRouteOpt] = useState<any>();
  const [reRouteOptions, setReRouteOptions] = useState<any>([{ value: 0, label: "Loading..." }]);
  const [deliverySubbranchOpt, setDeliverySubbranchOpt] = useState<any>();
  const [deliverySubbranchOptions, setDeliverySubbranchOptions] = useState<any>([{ value: 0, label: "Loading..." }]);

  const { refetch: deliverySubbranchRefetch } = useQuery(
    ["deliverySubbranchAPI"],
    () => ListCommanWay("/admin/subbranch/list", {}, { paginate: 1000 }),
    {
      keepPreviousData: true,
      onSuccess(data) {
        if (data.status == "SUCCESS") {
          let opt = [];
          data.data.data.forEach((p) => {
            opt.push({
              value: p.id,
              label: p.branch_name,
            });
          });
          setDeliverySubbranchOptions(opt);
          if (mode === "edit" || mode === "view") {
            console.log(deliverySubbranchOpt);
            const findBranch = opt.find((d) => d.value === deliverySubbranchOpt);
            if (findBranch) {
              setFieldValue("delivery_subbranch", {
                value: findBranch.value,
                label: findBranch.label,
              });
              values.delivery_subbranch = {
                value: findBranch.value,
                label: findBranch.label,
              };
            }
          }
        }
      },
    }
  );

  useEffect(() => {
    const userD = localStorage.getItem("auth") ? JSON.parse(localStorage.getItem("auth")) : {};
    if (Object.keys(userD).length) {
      setUserDetails(userD.state.user);
      console.log("userD.state", userD.state.user);
      if (userD.state.user.roleName !== roles.SUPER_ADMIN && userD.state.user.roleName !== roles.BRANCH_ADMIN) {
        setBranchOtp(userD.state.user.branch_name);
      }
    }
  }, []);

  useEffect(() => {
    cityRefetch();
  }, [branchOpt]);

  //console.log("-=======-----> newTableData", newTableData);

  const handleAWBNo = (awbNo) => {
    console.log(selectedTableData.find((d) => d.awb_no === awbNo));

    let found = false;
    newTableData.forEach((a) => {
      console.log(a.awb_no == awbNo, a.awb_no, awbNo);
      if (a.awb_no == awbNo) {
        found = true;
      }
    });

    if (found && awbNo && !selectedTableData.find((d) => d.awb_no === awbNo)) {
      setSelectedTableData((prevData) => [
        ...prevData,
        {
          ...newTableData.find((selectedAWB) => selectedAWB.awb_no === awbNo),
          mother_bag_id: values.mother_bag_code,
        },
      ]);
      setNewTableData((prevData) => {
        const findSelected = prevData.filter((obj) => obj.awb_no !== awbNo);
        return findSelected;
      });

      setFieldValue("awb_no", "");
    }
  };

  const getInput: any = (type, label, value, name, isDisabled, width) => {
    let ip = <div></div>;

    switch (type) {
      case ADT.NUMBER:
        ip = (
          <div className={`inline-flex items-center gap-2 ${width ? width : "w-auto"}`}>
            <label className="mb-2 text-sm font-semibold text-gray-900">{label}</label>
            <input
              type="number"
              className={`${
                isDisabled ? "bg-gray-300" : ""
              } bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5`}
              placeholder={label}
              name={name}
              value={values[name]}
              onBlur={handleBlur}
              onChange={(e) => {
                if (Number(e.target.value) >= 0) {
                  handleChange(e);
                }
              }}
              onWheel={handleWheel}
              disabled={mode && (mode === "view" || mode === "edit") ? true : isDisabled}
            />
          </div>
        );
        break;
      case ADT.TEXT:
        ip = (
          <div className={`inline-flex items-center gap-2 ${width ? width : "w-auto"}`}>
            <label className="mb-2 text-sm font-semibold text-gray-900">{label}</label>

            <input
              type="text"
              className={`${
                isDisabled ? "bg-gray-300" : ""
              } bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 ${
                name === "mother_bag_code" ? "bg-red-200" : name == "awb_no" ? "border-4 border-blue-500" : ""
              }`}
              placeholder={label}
              name={name}
              value={values[name]?.label !== undefined ? values[name]?.label : values[name]}
              onBlur={async (e) => {
                handleBlur(e);
                if (label === "Pincode") {
                  setPincodeSection(e.target.name.split("_")[0]);
                }
              }}
              onChange={(e) => {
                handleChange(e);
                if (name === "awb_no") {
                  handleAWBNo(e.target.value);
                }
              }}
              onKeyDown={disableEnterKey}
              disabled={mode && mode === "view" ? true : isDisabled}
            />
          </div>
        );
        break;
      case ADT.DB:
        ip = (
          <div className={`inline-flex items-center gap-2 ${width ? width : "w-auto"}`}>
            <label className="mb-2 text-sm font-semibold text-gray-900">{label}</label>
            <Select
              className={`${isDisabled ? "bg-gray-300" : ""} bg-gray-50 border-gray-300 text-gray-900 text-sm rounded-lg block w-full `}
              options={value.all}
              onBlur={handleBlur}
              onChange={(e) => {
                if (name === "subbranch") {
                  console.log("=====> e.value", e.value);
                  setBranchOtp(e.value);
                  //destinationRefetch(e.value);
                }
                if (name === "destination") {
                  console.log(e);
                  //setReRouteOpt({...e});
                  setFieldValue("re_route_city", e);
                  mutate(e.label);
                }
                setFieldValue(name, e);
              }}
              name={name}
              value={values[name]}
              isDisabled={mode === "view" ? true : isDisabled}
            />
          </div>
        );
        break;
      case ADT.TEXTAREA:
        break;
      case ADT.DATETIME:
        break;
      case ADT.DATE:
        ip = (
          <div className={`inline-flex items-center gap-2 ${width ? width : "w-auto"}`}>
            <label className="mb-2 text-sm font-semibold text-gray-900">{label}</label>
            <input
              type="date"
              className={`${
                isDisabled ? "bg-gray-300" : ""
              } bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5`}
              placeholder={label}
              name={name}
              value={values[name]}
              onBlur={handleBlur}
              onChange={handleChange}
              disabled={mode && (mode === "view" || mode === "edit") ? true : isDisabled}
            />
          </div>
        );
        break;
      case ADT.FILE:
        break;
      default:
        ip = <></>;
        break;
    }
    return ip;
  };

  const {
    mutate: mutateDeleteMotherBag,
  }: {
    mutate: UseMutateFunction<AxiosResponse<any, any>, unknown, any, unknown>;
    isLoading: any;
  } = useMutation((data) => DeleteCommanWay({ url: "/admin/mother_bag/delete/" + data }), {
    onSuccess: (data: any) => {
      console.log(data, data.status == "SUCCESS");
      if (data.status == "SUCCESS") {
        UseToast("Delete Success");
        navigate("/mother_bag/outscan");
      } else {
        UseToast(data.message, "error");
      }
    },
    onError: (data: any) => {
      typeof data === "string" ? UseToast(data, "error") : UseToast(data.message, "error");
    },
  });

  const handleDeleteMotherBag = (id: any) => {
    Swal.fire({
      title: "Are you sure!",
      icon: "warning",
      confirmButtonText: "Confirm Delete",
      showCancelButton: true,
    }).then((x) => {
      if (x.isConfirmed) {
        mutateDeleteMotherBag(id);
      }
    });
  };

  const disableEnterKey = (event) => {
    if (event.key === "Enter" || event.key === "Tab") {
      event.preventDefault();
    }
  };
  return (
    <>
      <h1 className="text-center mb-4 mt-3 text-lg font-semibold underline">Mother Bag Tally</h1>
      <Modal isOpen={isModalOpen} onClose={closeModal}>
        <h2 className="text-2xl font-bold mb-4">New Motherbag is added</h2>
        <p>Code: {newMotherbagCode}</p>
      </Modal>

      <form
        onSubmit={(e) => {
          e.preventDefault();
          handleSubmit(e);
        }}
      >
        {/* <div className="grid grid-cols-2 sm:grid-cols-1 gap-4 px-4"> */}
        <div className="flex gap-2 flex-wrap border p-2 rounded-2xl">
          <div className="w-full font-semibold underline">Customer Information</div>
          {Object.keys(userDetails).length && (userDetails?.roleName === roles.SUPER_ADMIN || userDetails?.roleName === roles.BRANCH_ADMIN)
            ? getInput(
                ADT.DB,
                "Sub_branch",
                { sel: branchOpt, all: branchOptions },
                "subbranch",
                mode === "view" ? true : isDisabled,
                "w-[calc(33.33%-6px)]"
              )
            : null}
          {getInput(
            ADT.DB,
            "Destination",
            { sel: destinationOpt, all: destinationOptions },
            "destination",
            mode === "view" ? true : isDisabled,
            "w-[calc(33.33%-6px)]"
          )}
          {}
          {getInput(
            ADT.DB,
            "Route_Via",
            { sel: reRouteOpt, all: reRouteOptions },
            "re_route_city",
            Object.keys(userDetails).length && userDetails?.roleName === roles.SUPER_ADMIN && values.subbranch.value
              ? false
              : Object.keys(userDetails).length && userDetails?.roleName !== roles.SUPER_ADMIN
              ? false
              : true,
            "w-[calc(33.33%-6px)] hidden"
          )}
          <div className="w-[calc(33.33%-6px)]"></div>
          {getInput(
            ADT.DB,
            "Outscan_To:",
            { sel: deliverySubbranchOpt, all: deliverySubbranchOptions },
            "delivery_subbranch",
            (mode && mode === "edit") || mode === "view" ? true : isDisabled,
            "w-[calc(33.33%-6px)]"
          )}
          {getInput(ADT.TEXT, "Mother Bag No", "", "mother_bag_code", true, "w-[calc(33.3%-6px)] bg-red")}
          {getInput(ADT.TEXT, "Scan AWB No", "", "awb_no", false, "w-[calc(33.3%-6px)]")}
          {getInput(
            ADT.DB,
            "Third Party",
            { sel: thirdPartyOpt, all: thirdPartyOptions },
            "third_party",
            isDisabled,
            "w-[calc(33.33%-6px)] hidden"
          )}
        </div>
        {/* </div> */}

        <div className="mt-4 text-right px-4">
          {mode && mode === "view" ? (
            <>
              <button
                type="button"
                onClick={() => handleDeleteMotherBag(data.id)}
                className="rounded-md px-4 py-2 text-sm font-medium text-white bg-red-700 border border-gray-200 hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-2 focus:ring-blue-700 focus:text-blue-700"
              >
                Delete
              </button>

              <button
                type="button"
                onClick={() => navigate(-1)}
                className="rounded-md px-4 py-2 text-sm font-medium text-white bg-green-500 border border-gray-200 hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-2 focus:ring-blue-700 focus:text-blue-700"
              >
                Go Back
              </button>
            </>
          ) : mode && mode === "edit" ? (
            <button
              type="submit"
              className="rounded-md px-4 py-2 text-sm font-medium text-white bg-green-500 border border-gray-200 hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-2 focus:ring-blue-700 focus:text-blue-700"
            >
              Update
            </button>
          ) : (
            <button
              type="submit"
              className="rounded-md px-4 py-2 text-sm font-medium text-white bg-green-500 border border-gray-200 hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-2 focus:ring-blue-700 focus:text-blue-700"
            >
              Save
            </button>
          )}
        </div>
        {mode != "view" && (
          <>
            <div className="flex flex-col flex-1 overflow-x-auto mx-4 my-4 border-2 p-2 border-blue-600">
              <h3 className="text-center font-semibold">Total Airwaybill in Location</h3>
              <InputTable
                columnData={columnData}
                tableData={newTableData}
                setNewTableData={setNewTableData}
                customClasses="flex-1"
                mode={"view"}
              />
            </div>
          </>
        )}

        <div className="flex flex-col flex-1 overflow-x-auto mx-4 my-4 border-2 p-2 border-green-400">
          <h3 className="text-center font-semibold">Selected Airwaybill</h3>
          <InputTable
            columnData={selectedColumnData}
            tableData={selectedTableData}
            setNewTableData={setSelectedTableData}
            customClasses="flex-1"
            mode={"view"}
          />
        </div>

        {mode == "edit" && (
          <div className="flex flex-col flex-1 overflow-x-auto mx-4 my-4 border-2 p-2 border-blue-900 bg-blue-200">
            <h3 className="text-center font-semibold">Saved Airwaybill in Motherbag</h3>
            <InputTable
              columnData={savedColumnData}
              tableData={savedAwbData}
              setNewTableData={setSavedAwbData}
              customClasses="flex-1"
              mode={"view"}
            />
          </div>
        )}
      </form>
    </>
  );
};

export default AddMotherBag;
