import React from "react";
import { DataTable } from "components/DataTable/DataTable";
import { AfDataType, GenericColumnData } from "utils/TableColumnData";
import { DataTableWithoutButtons } from "components/DataTable/DataTableWithoutButtons";
import { useNavigate } from "react-router-dom";

const viewActionColumn = {
  label: "Action",
  key: "id",
  render: (_) => {
    return `<a>${_}</a>`;
  },
  type: AfDataType.VIEW,
  view: true,
  add: false,
  edit: false,
  filter: false,
  sort: false,
};
const MotherBagList: React.FC<any> = (props) => {
  const ADT = AfDataType;
  const navigator = useNavigate();

  const tableColumnData = [
    {
      label: "Id",
      key: "id",
      type: ADT.NUMBER,
      view: true,
      add: false,
      edit: true,
      filter: false,
      sort: false,
    },
    {
      label: "Mother Bag Code",
      key: "mother_bag_code",
      type: ADT.TEXT,
      view: true,
      add: true,
      edit: true,
      filter: false,
      sort: false,
    },
    {
      label: "Third Party",
      key: "third_party",
      type: ADT.TEXT,
      view: true,
      add: true,
      edit: true,
      filter: false,
      sort: false,
    },
    {
      label: "Destination",
      key: "destination",
      type: ADT.DB,
      db: "city",
      db_col: "city_name",
      view: true,
      add: true,
      edit: true,
      filter: false,
      sort: false,
    },
    {
      label: "Re-route City",
      key: "re_route_city",
      type: ADT.DB,
      db: "city",
      db_col: "city_name",
      view: true,
      add: true,
      edit: true,
      filter: false,
      sort: false,
    },
    {
      label: "Action",
      key: "editId",
      render: (_) => {
        return `<a>${_}</a>`;
      },
      type: ADT.EDIT,
      view: true,
      add: false,
      edit: false,
      filter: false,
      sort: false,
    },
    {
      label: "Action",
      key: "viewId",
      render: (_) => {
        return `<a>${_}</a>`;
      },
      type: ADT.VIEW,
      view: true,
      add: false,
      edit: false,
      filter: false,
      sort: false,
    },
  ];

  return (
    <>
      <DataTableWithoutButtons
        apiUrl={`/admin/mother_bag/list`}
        columnData={tableColumnData}
        handleEdit={(data) => {
          navigator("/mother_bag/add", {
            state: {
              mode: "edit",
              data,
            },
          });
        }}
        handleView={(data) => {
          navigator("/mother_bag/add", {
            state: {
              mode: "view",
              data,
            },
          });
        }}
      />
    </>
  );
};

export default MotherBagList;
