import React from "react";
import AppContent from "../components/AppContent/AppContent";
import Navbar from "components/Navbar/Navbar";

export function DefaultLayout() {
  return (
    <>

      <section>
        <div className="relative">
        <Navbar />

          <div className="">
            {/* <div className="bg-background_grey h-screen"> */}
            <div className="bg-background_grey height-class">
              <AppContent />
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
