import {
  UseMutateFunction,
  useMutation,
  useQuery,
} from "@tanstack/react-query";
import {
  CreateCommanWay,
  GetCommanWay,
  ListCommanWay,
  UpdateBookingCommanWay,
} from "API/commonApi";
import axios, { AxiosResponse } from "axios";
import ImageUploadField from "components/ImageUploadField/ImageUploadField";
import { useFormik } from "formik";
import { isObject } from "lodash";
import moment from "moment";
import React, { useEffect, useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Select from "react-select";
import UseToast from "utils/AfToast";
import { roles } from "utils/constant";
import handleWheel from "utils/handleOnWheel";
import { AfDataType as ADT } from "utils/TableColumnData";
import { object } from "yup";

interface BookingState {
  mode?: string;
  data?: { [key: string]: any }; // Adjust the type based on your data structure
  // Add other properties if needed
}

const AddRegularVouchure: React.FC = ({}) => {
  let isDisabled = false;
  const navigate = useNavigate();
  const { state } = useLocation();
  const { mode = "add", data = {} } = (state || {}) as BookingState; // Check for existence and type assertion

  const [isBranchSet, setIsBranchSet] = useState<any>();
  const [newTableData, setNewTableData] = useState<any>([]);
  const [nestedTables, setNestedTables] = useState<any>({});
  const [pincodeSection, setPincodeSection] = useState<any>("");
  const [userDetails, setUserDetails] = useState<any>({});
  const [subbranchOpt, setSubBranchOpt] = useState<any>();

  useEffect(() => {
    const userD = localStorage.getItem("auth")
      ? JSON.parse(localStorage.getItem("auth"))
      : {};
    if (Object.keys(userD).length) {
      setUserDetails(userD.state.user);
      console.log("userD.state", userD.state.user);
      if (
        userD.state.user.roleName !== roles.SUPER_ADMIN &&
        userD.state.user.roleName !== roles.BRANCH_ADMIN
      ) {
        setSubBranchOpt(userD.state.user.branch_name);
      }
    }
  }, []);

  const initialValues = {
    remark: "",
    agent_id: "",
    awb_no: "",
    awb_nos: "",
    lat: "",
    long: "",
    km: "",
    subbranch: { value: 0, label: "" },
  };
  const {
    mutate,
    isLoading: isloading,
  }: {
    mutate: UseMutateFunction<AxiosResponse<any, any>, unknown, any, unknown>;
    isLoading: any;
  } = useMutation((data) => CreateCommanWay(data), {
    onSuccess: (data: any) => {
      console.log(data, data.status == "SUCCESS");
      if (data.status == "SUCCESS") {
        UseToast("Data Added Successfully", "success");
        resetForm();
        setSelectedAWB("");
        //successNavigate();
      } else {
        UseToast(data.message, "error");
      }
    },
    onError: (data: any) => {
      typeof data === "string"
        ? UseToast(data, "error")
        : UseToast(data.message, "error");
    },
  });

  const [subbranchOptions, setSubBranchOptions] = useState<any>([
    { value: 0, label: "Loading..." },
  ]);

  const {
    error: subbranchError,
    isFetching: subbranchIsFetching,
    isError: subbranchIsError,
    isFetched: subbranchIsFetched,
    isLoading: subbranchIsLoading,
    refetch: subbranchRefetch,
  } = useQuery(
    ["SubBranchesListApi"],
    () => ListCommanWay("/admin/subbranch/list", {}, { paginate: 1000 }),
    {
      keepPreviousData: true,
      onSuccess(data) {
        if (data.status == "SUCCESS") {
          let opt = [];
          data.data.data.forEach((p) => {
            opt.push({
              value: p.id,
              label: p.branch_name,
            });
          });
          setSubBranchOptions(opt);
        }
      },
    }
  );

  const {
    handleSubmit,
    handleBlur,
    values,
    handleChange,
    errors,
    setValues,
    setErrors,
    touched,
    dirty,
    setFieldValue,
    handleReset,
    resetForm,
  } = useFormik({
    validationSchema: object().shape({}),
    initialValues: initialValues,
    onSubmit: async (values, { resetForm }) => {
      let req = { ...values };
      console.log("===> req", req);
      mutate({
        url: "/admin/vouchure/regular/create",
        data: {
          ...req,
          subbranch:
            userDetails?.roleName !== roles.SUPER_ADMIN &&
            userDetails?.roleName !== roles.BRANCH_ADMIN
              ? userDetails.subbranch
              : req.subbranch
              ? req.subbranch.value
              : null,
        },
      });
    },
  });

  const fetchDBInputData = async () => {
    let proms = [];

    let nestedApiData = {};

    return Promise.all(proms).then((x) => {
      setNestedTables(nestedApiData);
      return 1;
    });
  };

  const [awbOpt, setAwbOpt] = useState<any>();

  const [selectedAWB, setSelectedAWB] = useState<any>("");

  const [awbOptions, setAwbOptions] = useState<any>([
    { value: 0, label: "Loading..." },
  ]);

  const handleAwbSelect = (value) => {
    setSelectedAWB((prevData) => {
      console.log(
        "=========> selectedAWB",
        [...prevData.split(","), value].join(",")
      );
      setFieldValue(
        "awb_nos",
        prevData === "" ? value : [...prevData.split(","), value].join(",")
      );
      return prevData === ""
        ? value
        : [...prevData.split(","), value].join(",");
    });
    setAwbOptions((prevData) => prevData.filter((d) => d.value !== value));
  };

  const {
    error: awbError,
    isFetching: awbIsFetching,
    isError: awbIsError,
    isFetched: awbIsFetched,
    isLoading: awbIsLoading,
    refetch: awbRefetch,
  } = useQuery(
    ["AWBListApi"],
    () =>
      ListCommanWay(
        "/admin/awbdata/list",
        { assign_to_delivery_agent: values.agent_id },
        {}
      ),
    {
      keepPreviousData: true,
      onSuccess(data) {
        if (data.status == "SUCCESS") {
          let opt = [];
          if (data?.data?.data) {
            Object.values(data?.data?.data)?.forEach((d: any) => {
              opt.push({
                value: d?.awb_no,
                label: d?.awb_no,
              });
            });
            console.log("====>< data", data.data.data);

            setAwbOptions(opt);
          }
        }
      },
    }
  );

  console.log("-=======-----> newTableData", newTableData);

  const getInput: any = (
    type,
    label,
    value,
    name,
    isDisabled,
    width,
    options = []
  ) => {
    let ip = <div></div>;

    switch (type) {
      case ADT.NUMBER:
        ip = (
          <div
            className={`inline-flex items-center gap-2 ${
              width ? width : "w-auto"
            }`}
          >
            <label className="w-full mb-2 text-sm font-semibold text-gray-900">
              {label}
            </label>
            <input
              type="number"
              className={`${
                isDisabled ? "bg-gray-300" : ""
              } bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5`}
              placeholder={label}
              name={name}
              value={values[name]}
              onBlur={(e) => {
                handleBlur(e);
                if (name === "agent_id") awbRefetch();
              }}
              onChange={(e) => {
                if (Number(e.target.value) >= 0) {
                  handleChange(e);
                }
              }}
              onWheel={handleWheel}
              disabled={isDisabled}
            />
          </div>
        );
        break;
      case ADT.TEXT:
        ip = (
          <div
            className={`flex items-center gap-2 ${width ? width : "w-auto"}`}
          >
            <label className="mb-2 w-full text-sm font-semibold text-gray-900">
              {label}
            </label>
            <input
              type="text"
              className={`${
                isDisabled ? "bg-gray-300" : ""
              } bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5`}
              placeholder={label}
              name={name}
              value={values[name]}
              onBlur={async (e) => {
                handleBlur(e);
              }}
              onChange={handleChange}
              disabled={isDisabled}
            />
          </div>
        );
        break;
      case ADT.DB:
        ip = (
          <div
            className={`flex items-center gap-2 ${width ? width : "w-auto"}`}
          >
            <label className="mb-2 w-full text-sm font-semibold text-gray-900">
              {label}
            </label>
            <Select
              className={`${
                isDisabled ? "bg-gray-300" : ""
              } bg-gray-50 border-gray-300 text-gray-900 text-sm rounded-lg block w-full`}
              options={value.all}
              onBlur={handleBlur}
              onChange={(e) => {
                setFieldValue(name, e);
                if (name === "awb_no") handleAwbSelect(e.value);
              }}
              name={name}
              value={values[name]}
              isDisabled={isDisabled}
            />
          </div>
        );
        break;
      case ADT.SELECT:
        ip = (
          <div
            className={`inline-flex items-center gap-2 ${
              width ? width : "w-auto"
            }`}
          >
            <label className="mb-2 text-sm font-semibold text-gray-900">
              {label}
            </label>
            <Select
              className={`${
                isDisabled ? "bg-gray-300" : ""
              } bg-gray-50 border-gray-300 text-gray-900 text-sm rounded-lg block w-full`}
              options={options}
              onBlur={handleBlur}
              onChange={(e) => {
                setFieldValue(name, e);
              }}
              name={name}
              value={values[name]}
              isDisabled={isDisabled}
            />
          </div>
        );
        break;
      case ADT.TEXTAREA:
        break;
      case ADT.DATETIME:
        ip = (
          <div
            className={`inline-flex items-center gap-2 ${
              width ? width : "w-auto"
            }`}
          >
            <label className="mb-2 text-sm font-semibold text-gray-900">
              {label}
            </label>
            <input
              type="datetime-local"
              className={`${
                isDisabled ? "bg-gray-300" : ""
              } bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5`}
              placeholder={label}
              name={name}
              value={values[name]}
              onBlur={handleBlur}
              onChange={handleChange}
              disabled={isDisabled}
            />
          </div>
        );
        break;
      case ADT.DATE:
        ip = (
          <div
            className={`inline-flex items-center gap-2 ${
              width ? width : "w-auto"
            }`}
          >
            <label className="mb-2 text-sm font-semibold text-gray-900">
              {label}
            </label>
            <input
              type="date"
              className={`${
                isDisabled ? "bg-gray-300" : ""
              } bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5`}
              placeholder={label}
              name={name}
              value={values[name]}
              onBlur={handleBlur}
              onChange={handleChange}
              disabled={isDisabled}
            />
          </div>
        );
        break;
      case ADT.FILE:
        break;
      default:
        ip = <></>;
        break;
    }
    return ip;
  };
  return (
    <>
      <h1 className="text-center mb-4 mt-3 text-lg font-semibold underline">
        Regular Vouchure
      </h1>

      <form
        onSubmit={(e) => {
          e.preventDefault();
          handleSubmit(e);
        }}
      >
        <div className="flex flex-col gap-4 px-4">
          <div className="flex gap-2 flex-1 flex-wrap border p-2 rounded-2xl">
            <div className="flex flex-1 w-full font-semibold underline">
              Regular Vouchure Details
            </div>
            {Object.keys(userDetails).length &&
            (userDetails?.roleName === roles.SUPER_ADMIN ||
              userDetails?.roleName === roles.BRANCH_ADMIN)
              ? getInput(
                  ADT.DB,
                  "Sub-branch",
                  { sel: subbranchOpt, all: subbranchOptions },
                  "subbranch",
                  isDisabled,
                  "w-full"
                )
              : null}
            {getInput(
              ADT.NUMBER,
              "Enter Agent ID",
              "",
              "agent_id",
              isDisabled,
              "w-full"
            )}
            {getInput(
              ADT.TEXT,
              "Enter Remark",
              "",
              "remark",
              isDisabled,
              "w-full"
            )}
            {/* {getInput(
              ADT.TEXT,
              "Enter AWB No.",
              "",
              "awb_nos",
              isDisabled,
              "w-full"
            )} */}
            {getInput(
              ADT.DB,
              "Select AWB Nos",
              { sel: awbOpt, all: awbOptions },
              "awb_no",
              isDisabled,
              "w-full"
            )}
            <div className="flex flex-1 items-end justify-end">
              <label className="text-sm font-semibold text-gray-900 ">
                {selectedAWB}
              </label>
            </div>
            {getInput(ADT.NUMBER, "Latitude", "", "lat", isDisabled, "w-full")}
            {getInput(
              ADT.NUMBER,
              "Longitude",
              "",
              "long",
              isDisabled,
              "w-full"
            )}
            {getInput(ADT.NUMBER, "KM", "", "km", isDisabled, "w-full")}
          </div>
        </div>

        <div className="mt-4 text-right px-4">
          <button
            type="submit"
            className="rounded-md px-4 py-2 text-sm font-medium text-white bg-green-500 border border-gray-200 hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-2 focus:ring-blue-700 focus:text-blue-700"
          >
            Submit
          </button>
        </div>
      </form>
    </>
  );
};

export default AddRegularVouchure;
