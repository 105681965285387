import { MinusCircleIcon } from "@heroicons/react/24/outline";
import { UseMutateFunction, useMutation, useQuery } from "@tanstack/react-query";
import { CreateCommanWay, GetCommanWay, ListCommanWay, UpdateBookingCommanWay, UpdateCommanWay } from "API/commonApi";
import { AxiosResponse } from "axios";
import { Button, Input } from "components";
import { InputTable } from "components/DataTable/InputTable";
import { useFormik } from "formik";
import { debounce } from "lodash";
import moment from "moment";
import { any } from "prop-types";
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Select from "react-select";
import Swal from "sweetalert2";
import UseToast from "utils/AfToast";
import { roles } from "utils/constant";
import handleWheel from "utils/handleOnWheel";
import Spinner from "utils/Spinner";
import { AfDataType as ADT } from "utils/TableColumnData";
import { number, object, string } from "yup";

type OptionType = {
  value: number;
  label: string;
};

interface MotherBagState {
  mode?: string;
  data?: { [key: string]: any }; // Adjust the type based on your data structure
  // Add other properties if needed
}

interface ValuesType {
  destination?: { value: string; label: string };
}

const ManageDRS: React.FC = ({}) => {
  let isDisabled = false;
  const navigate = useNavigate();
  const { state } = useLocation();
  const { mode = "add", data = {} } = (state || {}) as MotherBagState; // Check for existence and type assertion

  const [awbScanVal, setAwbScanVal] = useState<any>("");
  const [acceptedAwbNos, setAcceptedAwbNos] = useState<any>([]);

  const [dataList, setDataList] = useState<any>([]);
  const { refetch: refetchData } = useQuery(["DRSAwbList"], () => ListCommanWay("/admin/drs_awb/list", {}, { paginate: 1000 }), {
    keepPreviousData: true,
    onSuccess(data) {
      if (data.status == "SUCCESS") {
        console.log(data.data);
        setDataList(data.data || []);
      }
    },
  });

  const onInputAWB = (no) => {};

  const { mutate: mutateAddNewDRS, isLoading: addNewDrsLoading } = useMutation(
    (data) =>
      CreateCommanWay({
        url: "/admin/drs/add",
        data: {
          awb_no: acceptedAwbNos,
        },
      }),
    {
      onSuccess: (data: any) => {
        console.log(data, data.status == "SUCCESS");
        if (data.status == "SUCCESS") {
          UseToast("Data Added Successfully", "success");
          console.log(data.data.code);
          refetchData();
          Swal.fire({
            title: "Success",
            text: "DRS Code : " + data.data.code + ``,
            icon: "success",
            confirmButtonText: "Okay",
            showCancelButton: true,
          }).then((x) => {
            if (x.isConfirmed) {
              //. Dont know do something
            }
          });
        } else {
          UseToast(data.message, "error");
        }
      },
      onError: (data: any) => {
        typeof data === "string" ? UseToast(data, "error") : UseToast(data.message, "error");
      },
    }
  );

  const { handleSubmit, handleBlur, values, handleChange, errors, setValues, setErrors, touched, dirty, resetForm, setFieldValue } =
    useFormik({
      validationSchema: object().shape({}),
      initialValues: {
        subbranch: { value: 0, label: "" },
      },
      onSubmit: async (values, { resetForm }) => {
        console.log("values", values);
        mutateAddNewDRS();
        //mutateUpdateMotherBag(data.id);
        //navigate("/mother_bag/list");
        return;
      },
    });

  const disableEnterKey = (event) => {
    if (event.key === "Enter" || event.key === "Tab") {
      event.preventDefault();
    }
  };

  return (
    <>
      {addNewDrsLoading && <Spinner />}
      <h1 className="text-center mb-4 mt-3 text-lg font-semibold underline">Manage DRS</h1>

      <form
        onSubmit={(e) => {
          e.preventDefault();
          handleSubmit(e);
        }}
      >
        {/* <div className="grid grid-cols-2 sm:grid-cols-1 gap-4 px-4"> */}
        <div className="flex gap-2 flex-wrap border p-2">
          <div className="w-full text-center">
            Scan AWB No:{" "}
            <input
              type="text"
              value={awbScanVal}
              className="bg-blue-200 rounded"
              onKeyDown={disableEnterKey}
              onInput={(e: any) => {
                let found = false;
                dataList.forEach((a) => {
                  console.log(a.awb_no == e.target.value, a.awb_no, e.target.value);
                  if (a.awb_no == e.target.value) {
                    found = true;
                  }
                });
                console.log(found, !acceptedAwbNos.includes(e.target.value));

                if (found && !acceptedAwbNos.includes(e.target.value)) {
                  let obj = [...acceptedAwbNos];
                  obj.push(e.target.value);
                  setAcceptedAwbNos(obj);
                  setAwbScanVal("");
                } else {
                  setAwbScanVal(e.target.value);
                }
              }}
            />
          </div>
          <div className="w-full relative overflow-x-auto">
            <table className="w-full text-sm text-left rtl:text-right text-black-900">
              <thead className="text-black-900 uppercase font-semibold bg-gray-50 border-y-2">
                <tr>
                  <td className="px-3 py-2">Check</td>
                  <td className="px-3 py-2">Motherbag code</td>
                  <td className="px-3 py-2">Booking Date</td>
                  <td className="px-3 py-2">No</td>
                  <td className="px-3 py-2">Weight</td>
                  <td className="px-3 py-2">Consignee</td>
                  <td className="px-3 py-2">Destination</td>

                  <td className="px-3 py-2">Shipper</td>
                  <td className="px-3 py-2">Origin</td>
                </tr>
              </thead>
              <tbody>
                {dataList.map((e) => {
                  return (
                    <tr className={`border-b ${acceptedAwbNos.includes(e.awb_no) ? "bg-green-300" : "bg-white"}`}>
                      <td className="px-3 py-2">
                        {
                          <input
                            type="checkbox"
                            className="focus:border-black-900"
                            onChange={(ip) => {
                              console.log(ip.target.checked);
                              if (ip.target.checked) {
                                let obj = [...acceptedAwbNos];
                                obj.push(e.awb_no);
                                setAcceptedAwbNos(obj);
                                setAwbScanVal("");
                              } else {
                                let obj = [...acceptedAwbNos];
                                const index = obj.indexOf(e.awb_no);
                                if (index > -1) {
                                  obj.splice(index, 1);
                                }
                                setAcceptedAwbNos(obj);
                                setAwbScanVal("");
                              }
                            }}
                            checked={acceptedAwbNos.includes(e.awb_no)}
                          />
                        }
                      </td>
                      <td className="px-3 py-2">{e.mother_bag_code}</td>
                      <td className="px-3 py-2">{moment(e.bdate).format("DD-MM-YYYY")}</td>
                      <td className="px-3 py-2">
                        {}
                        <a target="_blank" className="text-blue-600 font-medium hover:underline" href={`/pod/tracking?awb=${e.awb_no}`}>
                          {e.awb_no}
                        </a>
                      </td>

                      <td className="px-3 py-2">{e.weight}</td>
                      <td className="px-3 py-2">{e.consignee}</td>
                      <td className="px-3 py-2">{e.destination}</td>
                      <td className="px-3 py-2">{e.shipper_sh}</td>
                      <td className="px-3 py-2">{e.shipper_city}</td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        </div>
        {/* </div> */}
        <div className="flex w-full justify-end mt-5 pr-3">
          <Button
            type="submit"
            className="rounded-md inline-block px-4 py-2 text-sm font-medium text-white bg-blue-800 border border-gray-200 hover:bg-opacity-60 focus:z-10 "
          >
            Submit
          </Button>
        </div>
      </form>
    </>
  );
};

export default ManageDRS;
