import { UseMutateFunction, useMutation, useQuery } from "@tanstack/react-query";
import { CreateCommanWay, GetCommanWay, ListCommanWay, UpdateBookingCommanWay } from "API/commonApi";
import axios, { AxiosResponse } from "axios";
import ImageUploadField from "components/ImageUploadField/ImageUploadField";
import { useFormik } from "formik";
import { isObject } from "lodash";
import moment from "moment";
import React, { useEffect, useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Select from "react-select";
import UseToast from "utils/AfToast";
import handleWheel from "utils/handleOnWheel";
import { AfDataType as ADT } from "utils/TableColumnData";
import { object } from "yup";

interface BookingState {
  mode?: string;
  data?: { [key: string]: any }; // Adjust the type based on your data structure
  // Add other properties if needed
}

const UpdatePOD: React.FC = ({}) => {
  let isDisabled = false;
  const navigate = useNavigate();
  const { state } = useLocation();
  const { mode = "add", data = {} } = (state || {}) as BookingState; // Check for existence and type assertion

  const [isBranchSet, setIsBranchSet] = useState<any>();
  const [newTableData, setNewTableData] = useState<any>([]);
  const [nestedTables, setNestedTables] = useState<any>({});
  const [pincodeSection, setPincodeSection] = useState<any>("");
  const [podStatusList, setPodStatusList] = useState<any[]>([]);
  const [awbData, setAwbData] = useState<any>({});
  const [bookData, setBookData] = useState<any>({});

  const initialValues: any = {
    awb_no: "",
    new_awb: "",
    contact_no: "",
    assign_to_delivery_agent: "",
    consignee: "",
    address: "",
    base_price: "",
    message_code: "",
    message_code_id: null,
    receiver: "",
    receiver_relation: "",
    remark: "",
    updatedAt: "",
    previous_receiver: "",
    previous_receiver_relation: "",
    previous_remark: "",
    previous_message_code: "",
    booking_date: "",
    delivery_date: "",
    system_del_date: "",
    status: "",
    is10_30: false,
    holiday: false,
    signature: "",
    rec: "",
  };
  const {
    mutate,
    isLoading: isloading,
  }: {
    mutate: UseMutateFunction<AxiosResponse<any, any>, unknown, any, unknown>;
    isLoading: any;
  } = useMutation((data) => CreateCommanWay(data), {
    onSuccess: (data: any) => {
      console.log(data, data.status == "SUCCESS");
      if (data.status == "SUCCESS") {
        UseToast("Data Added Successfully", "success");
        //successNavigate();
      } else {
        UseToast(data.message, "error");
      }
    },
    onError: (data: any) => {
      typeof data === "string" ? UseToast(data, "error") : UseToast(data.message, "error");
    },
  });

  const { mutate: mutateVoucher } = useMutation((data) => ListCommanWay("/admin/vouchure/regular/create", data, {}), {
    onSuccess: (data: any) => {},
    onError: (data: any) => {
      typeof data === "string" ? UseToast(data, "error") : UseToast(data.message, "error");
    },
  });

  const { handleSubmit, handleBlur, values, handleChange, errors, setValues, setErrors, touched, dirty, setFieldValue, handleReset } =
    useFormik({
      validationSchema: object().shape({}),
      initialValues: initialValues,
      onSubmit: async (values, { resetForm }) => {
        let req = { ...values };
        req.message_code_id =
          isObject(req?.message_code) && "value" in req?.message_code ? (req?.message_code as { value: string }).value : undefined;

        req.message_code =
          isObject(req?.message_code) && "value" in req?.message_code ? (req?.message_code as { label: string }).label : undefined;

        let tmpLabel = req.message_code.split(" - ");
        try {
          tmpLabel.shift();
          req.message_code = tmpLabel.join("");
        } catch (ignore) {}

        req.receiver_relation =
          isObject(req?.receiver_relation) && "value" in req?.receiver_relation
            ? (req?.receiver_relation as { value: string }).value.toString()
            : undefined;

        req.statusCode = req.message_code_id;
        if ([5, 16, 17].includes(req.message_code_id)) {
          req.new_awb = values.new_awb || "";
        }

        console.log("req", req);

        if (!req.signature && !req.rec) {
          if (req.message_code) {
            /*let voucherObj = {
              lat
              long
              image
              signature
              new_awb
              pincode
              mobile
              cgnee
              address
              receiver
              receiver_relation
              remark
              awb_nos
              statusCode // code
              status_reason // reason
              message_code //reason
            }*/
            req.isWebsite = true;
            mutate({ url: "/admin/vouchure/regular/create", data: req }); // invalid vals in param

            mutate({ url: "/admin/proof_of_delivery/create", data: req });
            //window.location.reload();
            //resetForm();
          }
        } else {
          const formData = new FormData();

          // Convert values to FormData
          Object.keys(values).forEach((key) => {
            // Check if the value is an object with a 'value' property
            if (isObject(values[key]) && "value" in values[key]) {
              formData.append(key, values[key].value.toString());
            } else {
              formData.append(key, values[key]);
            }
          });

          // Perform your API call with FormData
          try {
            const response = await axios.post(process.env.REACT_APP_BACKEND_URL + "/admin/proof_of_delivery/create", formData, {
              headers: {
                "Content-Type": "multipart/form-data",
              },
            });

            console.log("API response:", response.data);

            if (response.data) {
              UseToast("Data Added Successfully", "success");
              resetForm();
            } else {
              UseToast(response.data.message, "error");
            }
          } catch (error) {
            console.error("API error:", error);
          }
        }
      },
    });

  const {
    mutate: mutateAWBPOD,
    isLoading: isAWBPODloading,
  }: {
    mutate: UseMutateFunction<AxiosResponse<any, any>, unknown, any, unknown>;
    isLoading: any;
  } = useMutation((awbNo) => GetCommanWay("/admin/proof_of_delivery/awb_details/" + awbNo, {}, {}), {
    onSuccess: (data: any) => {
      if (data.status === "SUCCESS") {
        setAwbData(data.data.awb_details);
        setBookData(data.data.booking_details);
        Object.keys(data.data.awb_details).map((key) => {
          if (Object.keys(values).includes(key)) values[key] = data.data.awb_details[key];
        });

        values.booking_date = moment(data.data.booking_details.date, "DD-MM-YYYY HH:mm:ss").format("YYYY-MM-DD");
        Object.keys(data.data.foundProof_of_delivery).map((key) => {
          if (Object.keys(values).includes(`previous_${key}`)) {
            values[`previous_${key}`] = data.data.foundProof_of_delivery[key];
          }
        });
        return data.data;
      }
    },
    onError: (data: any) => {
      typeof data === "string" ? UseToast(data, "error") : UseToast(data.message, "error");
    },
  });

  const fetchDBInputData = async () => {
    let proms = [];

    let nestedApiData = {};

    return Promise.all(proms).then((x) => {
      setNestedTables(nestedApiData);
      return 1;
    });
  };

  useQuery(["pod_status_list"], () => GetCommanWay("/admin/status_list", {}, {}), {
    keepPreviousData: true,
    onSuccess(data) {
      if (data.status == "SUCCESS") {
        try {
          console.log(data.data);
          if (data.data) {
            let s_lst = [];
            data.data.forEach((s) => {
              if (s?.status_code !== undefined) {
                s_lst.push({
                  value: s.status_code,
                  label: s.status_code + " - " + s.reason,
                });
              }
            });
            setPodStatusList(s_lst || []);
          }
        } catch (c) {}
      }
    },
  });

  console.log("-=======-----> newTableData", newTableData);

  const getInput: any = (type, label, value, name, isDisabled, width, options = []) => {
    let ip = <div></div>;

    switch (type) {
      case ADT.NUMBER:
        ip = (
          <div className={`inline-flex items-center gap-2 ${width ? width : "w-auto"}`}>
            <label className="mb-2 text-sm font-semibold text-gray-900 whitespace-nowrap">{label}</label>
            <input
              type="number"
              className={`${
                isDisabled ? "bg-gray-300" : ""
              } bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5`}
              placeholder={label}
              name={name}
              value={values[name]}
              onBlur={handleBlur}
              onChange={(e) => {
                if (Number(e.target.value) >= 0) {
                  handleChange(e);
                }
              }}
              onWheel={handleWheel}
              disabled={isDisabled}
            />
          </div>
        );
        break;
      case ADT.TEXT:
        ip = (
          <div className={`inline-flex items-center gap-2 ${width ? width : "w-auto"}`}>
            <label className="mb-2 text-sm font-semibold text-gray-900 whitespace-nowrap">{label}</label>
            <input
              type="text"
              className={`${
                isDisabled ? "bg-gray-300" : ""
              } bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-1`}
              placeholder={label}
              name={name}
              value={values[name]}
              onBlur={async (e) => {
                handleBlur(e);
                if (name === "awb_no") {
                  Object.entries(initialValues).forEach(([key, value]) => {
                    setFieldValue(key, value);
                  });
                  mutateAWBPOD(e.target.value);
                }
              }}
              onChange={handleChange}
              disabled={isDisabled}
            />
          </div>
        );
        break;
      case ADT.DB:
        ip = (
          <div className={`inline-flex items-center gap-2 ${width ? width : "w-auto"}`}>
            <label className="mb-2 text-sm font-semibold text-gray-900 whitespace-nowrap">{label}</label>
            <Select
              className={`${isDisabled ? "bg-gray-300" : ""} bg-gray-50 border-gray-300 text-gray-900 text-sm rounded-lg block w-full`}
              options={value.all}
              onBlur={handleBlur}
              onChange={(e) => {
                setFieldValue(name, e);
              }}
              name={name}
              value={values[name]}
              isDisabled={isDisabled}
            />
          </div>
        );
        break;
      case ADT.SELECT:
        ip = (
          <div className={`inline-flex items-center gap-2 ${width ? width : "w-auto"}`}>
            <label className="mb-2 text-sm font-semibold text-gray-900 whitespace-nowrap">{label}</label>
            <Select
              className={`${isDisabled ? "bg-gray-300" : ""} bg-gray-50 border-gray-300 text-gray-900 text-sm rounded-lg block w-full`}
              options={options}
              onBlur={handleBlur}
              onChange={(e) => {
                setFieldValue(name, e);
              }}
              name={name}
              value={values[name]}
              isDisabled={isDisabled}
              styles={{
                control: (base) => ({
                  ...base,
                  height: 35,
                  minHeight: 35,
                }),
                indicatorsContainer: (base) => ({
                  ...base,
                  "& .book_dd__indicator": {
                    padding: "2px",
                  },
                }),
                input: (base) => ({
                  ...base,
                  margin: 0,
                  padding: 0,
                }),
                valueContainer: (base) => ({
                  ...base,
                  paddingTop: 0,
                  paddingBottom: 0,
                }),
              }}
            />
          </div>
        );
        break;
      case ADT.TEXTAREA:
        break;
      case ADT.DATETIME:
        ip = (
          <div className={`inline-flex items-center gap-2 ${width ? width : "w-auto"}`}>
            <label className="mb-2 text-sm font-semibold text-gray-900 whitespace-nowrap">{label}</label>
            <input
              type="datetime-local"
              className={`${
                isDisabled ? "bg-gray-300" : ""
              } bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-1`}
              placeholder={label}
              name={name}
              value={values[name]}
              onBlur={handleBlur}
              onChange={handleChange}
              disabled={isDisabled}
              min={name == "updatedAt" ? values.booking_date : "2000-01-01"}
            />
          </div>
        );
        break;
      case ADT.DATE:
        ip = (
          <div className={`inline-flex items-center gap-2 ${width ? width : "w-auto"}`}>
            <label className="mb-2 text-sm font-semibold text-gray-900">{label}</label>
            <input
              type="date"
              className={`${
                isDisabled ? "bg-gray-300" : ""
              } bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5`}
              placeholder={label}
              name={name}
              value={values[name]}
              onBlur={handleBlur}
              onChange={handleChange}
              disabled={isDisabled}
            />
          </div>
        );
        break;
      case ADT.FILE:
        break;
      default:
        ip = <></>;
        break;
    }
    return ip;
  };
  return (
    <>
      <h1 className="text-center mb-4 mt-3 text-lg font-semibold underline">Single POD Update</h1>

      <form
        onSubmit={(e) => {
          e.preventDefault();
          handleSubmit(e);
        }}
      >
        <div className="grid grid-cols-2 sm:grid-cols-1 gap-4 px-4">
          <div className="flex flex-col gap-2 flex-wrap border p-2 rounded-2xl">
            <div className="w-full font-semibold underline">POD Details</div>

            {getInput(ADT.TEXT, "Enter AWB No.", "", "awb_no", isDisabled, "w-full")}
            <p>
              <b>Shipper: </b> {bookData.shipper_sh}
            </p>
            <p>
              <b>Delivery Emp ID: </b> {values.assign_to_delivery_agent}
            </p>
            <p>
              <b>Cgnee: </b> {values.consignee}
            </p>
            <p>
              <b>Cgnee Add: </b> {values.address}
            </p>

            {getInput(ADT.SELECT, "Message Code", "", "message_code", isDisabled, "w-full", podStatusList)}
            {[5, 17].includes(values.message_code?.value) && getInput(ADT.TEXT, "New Awb", "", "new_awb", isDisabled, "w-full")}
            {getInput(ADT.TEXT, "Receiver", "", "receiver", isDisabled, "w-full")}
            {getInput(ADT.SELECT, "Relation", "", "receiver_relation", isDisabled, "w-full", [
              { label: "0 - Itself", value: "Itself" },
              { label: "1 - Husband/Wife", value: "Husband/Wife" },
              { label: "2 - Mother/Father", value: "Mother/Father" },
              { label: "3 - Child", value: "Child" },
              { label: "4 - Colleague", value: "Colleague" },
            ])}
            {getInput(ADT.TEXT, "Mobile No/Remarks", "", "remark", isDisabled, "w-full")}
            {getInput(ADT.DATETIME, "POD DateTime", "", "updatedAt", isDisabled, "w-full")}
          </div>

          <div className="flex flex-col gap-2 flex-wrap border p-2 rounded-2xl">
            <p>
              <b>Receiver: </b> {values.previous_receiver}
            </p>
            <p>
              <b>Relation: </b> {values.previous_receiver_relation}
            </p>

            <p>
              <b>Booking Date/Time: </b> {moment(values.booking_date).format("DD-MM-YYYY")}
            </p>
            <p>
              <b>Delivery Date/Time: </b> {values.delivery_date}
            </p>
            {/*<p>
              <b>System Del. Date/Time: </b> {values.system_del_date}
            </p>*/}

            <p>
              <b>Status: </b> {values.previous_message_code}
            </p>
            <p>
              <b>Mobile: </b> {values.remark}
            </p>
            <p>
              <b>TP: </b> {awbData.thirdPartyName}
            </p>
            <p>
              <b>RTO Awb No.:</b> {awbData.status == 5 ? awbData.returnAwb : "-"}
            </p>
            <p>
              <b>Delivery Emp: </b> {awbData?._assign_to_delivery_agent?.agent_name}
            </p>

            <p>
              <b>10:30 :</b> {awbData.is10_30 ? "Yes" : "No"}{" "}
            </p>
            <p>
              <b>Holiday:</b> {awbData.holiday ? "Yes" : "No"}{" "}
            </p>
          </div>
          <div className="flex gap-2 flex-wrap border p-2 rounded-2xl">
            <ImageUploadField title="Signature" propertyName="signature" values={values} setFieldValue={setFieldValue} />
          </div>
          <div className="flex gap-2 flex-wrap border p-2 rounded-2xl">
            <ImageUploadField title="Receiver" propertyName="rec" values={values} setFieldValue={setFieldValue} />
          </div>
        </div>

        <div className="mt-4 text-right px-4">
          <button
            type="submit"
            className="rounded-md px-4 py-2 text-sm font-medium text-white bg-green-500 border border-gray-200 hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-2 focus:ring-blue-700 focus:text-blue-700"
          >
            Update
          </button>
        </div>
      </form>
    </>
  );
};

export default UpdatePOD;
