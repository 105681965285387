import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { AfDataType } from "utils/TableColumnData";
import Select from "react-select";
import handleWheel from "utils/handleOnWheel";
import { ErrorMessage } from "components/ErrorMessage";
import { UseMutateFunction, useMutation } from "@tanstack/react-query";
import { GetCommanWay } from "API/commonApi";
import { AxiosResponse } from "axios";
import Swal from "sweetalert2";
import { InnerItems } from "./InnerItems";

export type AfTableProps = Partial<{
  errors: any[];

  columnData: any[];
  tableData: any[];
  customClasses: string;
  setNewTableData: any;
  mode?: string;
}>;

const IntlBookingInputTable: React.FC<AfTableProps> = ({
  errors = [],

  columnData = [],
  tableData = [],
  customClasses = "",
  setNewTableData,
  mode = "",
}) => {
  const [selectedAwb, setSelectedAwb] = useState<number>(0);
  const [isInnerItemsOpen, setIsInnerItemsOpen] = useState<boolean>(false);

  let incAry = [];
  columnData.forEach((c) => {
    if (c.db_col) {
      incAry.push("_" + c.db_col);
    }
  });

  const customStyles = {
    menuPortal: (base) => ({ ...base, zIndex: 9999 }),
  };

  const {
    mutate: mutatePincode,
    isLoading: isPincodeloading,
    data: pincodeData,
    isSuccess,
    reset: resetMutatePincode,
  } = useMutation((pincode) => GetCommanWay("/admin/address/" + pincode, {}, {}), {
    onSuccess: (data: any) => {
      if (data.status === "SUCCESS") {
        return data.data;
      }
    },
  });

  const handleChange = (e, id, key) => {
    setNewTableData((prevData) => {
      const newData = prevData.find((d) => Number(d.id) === Number(id));
      newData[e.target.name] =
        columnData.find((d) => d.key === e.target.name).type === AfDataType.TEXT
          ? e.target.value
          : columnData.find((d) => d.key === e.target.name).type === AfDataType.SELECT ||
            columnData.find((d) => d.key === e.target.name).type === AfDataType.DB
          ? e.target.value
          : e.target.value == ""
          ? e.target.value
          : Number(e.target.value);

      if (key == "pincode") {
        //resetMutatePincode();
      }
      if (key == "pincode" && e.target.value.length == 6) {
        /*mutatePincode(e.target.value);
        if (isSuccess) {
          newData["destination"] = pincodeData.data.city;
        }*/
      }
      return [...prevData.filter((d) => d.id !== id), newData].sort((a, b) => Number(a.id) - Number(b.id));
    });
  };
  const [nestedSelectedOptions, setNestedSelectedOptions] = useState<any>({});
  const disableEnterKey = (event) => {
    if (event.key === "Enter") {
      event.preventDefault();
    }
  };
  
  return (
    <>
      {isInnerItemsOpen && (
        <InnerItems
          tableData={tableData}
          selectedAwb={selectedAwb}
          isInnerItemsOpen={isInnerItemsOpen}
          setIsInnerItemsOpen={setIsInnerItemsOpen}
          setTableData={setNewTableData}
        />
      )}

      <div className={`flex ${customClasses}`}>
        <table className="w-full text-sm text-left text-gray-500 border-r border-t">
          <thead className="text-xs text-gray-700 uppercase bg-gray-50 ">
            <tr>
              {columnData.map((c) => {
                return (
                  c.view && (
                    <th scope="col" className="px-2 py-1 border-l border-b" key={c.label}>
                      <div className="flex items-center">{c.label}</div>
                    </th>
                  )
                );
              })}
            </tr>
          </thead>

          <tbody>
            {tableData.map((rd, j) => {
              return (
                <tr className="bg-white border-b" key={"tbl-tr-" + j}>
                  {columnData.map((cd, i) => {
                    return (
                      cd.view && (
                        <td className="border-l px-0.5 py-0.5" key={"tbl-td-" + i}>
                          {cd.type == "PIECE" ? (
                            <>
                              {rd[cd.key].length}
                              <button
                                type="button"
                                className="rounded-md w-8 h-8 text-sm font-medium text-white bg-green-500 border border-gray-200 hover:bg-gray-100 hover:text-blue-700 ml-2"
                                onClick={() => {
                                  console.log(j);
                                  setIsInnerItemsOpen(true);
                                  setSelectedAwb(j);
                                }}
                              >
                                +
                              </button>
                            </>
                          ) : cd.type == AfDataType.BOOLEAN ? (
                            <>{rd[cd.key] === true ? "true" : "false"}</>
                          ) : cd.key === "id" ? (
                            <div className=" ml-2">{rd[cd.key]}</div>
                          ) : cd.type === AfDataType.BUTTON ? (
                            <div className=" ml-2">{rd?.["id"] && cd.btnComponent(rd?.["id"])}</div>
                          ) : mode && mode === "view" ? (
                            <div className=" ml-2">{rd[cd.key]}</div>
                          ) : mode && mode === "edit" && !cd.edit ? (
                            <div className=" ml-2">{rd[cd.key]}</div>
                          ) : cd.type === AfDataType.SELECT || cd.type === AfDataType.DB ? (
                            <div key={"general_" + cd.key} className="flex">
                              <Select
                                key={"select-" + cd.key}
                                className={`flex-1 bg-gray-50 border-gray-300 text-gray-900 text-sm rounded-lg block w-full ${
                                  cd.css && cd.css
                                }`}
                                id={"general_" + cd.key}
                                options={cd.type === AfDataType.DB ? cd.options : cd.options}
                                onChange={(e) => {
                                  handleChange(
                                    {
                                      target: { name: cd.key, value: e.value },
                                    },
                                    rd.id,
                                    cd.key
                                  );
                                }}
                                name={cd.key}
                                value={nestedSelectedOptions[cd.key]}
                                styles={customStyles}
                                menuPortalTarget={document.body}
                                isDisabled={mode ? (mode === "view" ? true : mode === "edit" ? !cd.edit : false) : false}
                              />
                            </div>
                          ) : (
                            <input
                              type={cd.type == AfDataType.TEXT ? "text" : "number"}
                              id={"general_" + cd.key}
                              className={` bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-1 ${
                                cd.isDisabled ? "bg-gray-300" : ""
                              } ${cd.css && cd.css}`}
                              placeholder={cd.label}
                              onBlur={(e) => {
                                handleChange(e, rd.id, cd.key);
                              }}
                              onChange={(e) => {
                                if (AfDataType.NUMBER && Number(e.target.value) >= 0) {
                                  handleChange(e, rd.id, cd.key);
                                } else handleChange(e, rd.id, cd.key);
                              }}
                              onWheel={handleWheel}
                              onKeyDown={disableEnterKey}
                              name={cd.key}
                              value={rd[cd.key]}
                              disabled={
                                cd.isDisabled == true ? true : mode ? (mode === "view" ? true : mode === "edit" ? !cd.edit : false) : false
                              }
                            />
                          )}
                        </td>
                      )
                    );
                  })}
                </tr>
              );
            })}
          </tbody>
        </table>
        <ErrorMessage errors={errors} />
      </div>
    </>
  );
};

export { IntlBookingInputTable };
