import React, { useEffect, useState } from "react";
import axios, { AxiosResponse } from "axios";
import { DataTableWithoutButtons } from "components/DataTable/DataTableWithoutButtons";
import { AfDataType } from "utils/TableColumnData";
import handleWheel from "utils/handleOnWheel";
import { ListCommanWay, UpdateCommanWay } from "API/commonApi";
import { UseMutateFunction, useMutation, useQuery } from "@tanstack/react-query";
import { Img } from "components";
import moment from "moment";
import Swal from "sweetalert2";
import UseToast from "utils/AfToast";
import Select from "react-select";
import { roles } from "utils/constant";

const VouchureGen: React.FC<any> = (props) => {
  const ADT = AfDataType;
  const [tableData, setTableData] = useState<any>([]);
  const [tableDetails, setTableDetails] = useState<any>([]);

  const [startDate, setStartDate] = useState<any>(moment().startOf("day").format("YYYY-MM-DD"));
  const [endDate, setEndDate] = useState<any>(moment().endOf("day").format("YYYY-MM-DD"));
  const [query, setQuery] = useState<any>({
    bill_from: {
      $gte: moment().startOf("day"),
      $lte: moment().endOf("day"),
    },
  });
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const [fetchedData, setFetchedData] = useState([]);
  const [isDataAdded, setIsDataAdded] = useState(false);

  const [selectedAgent, setSelectedAgent] = useState({ id: undefined, name: "--- Select User ---" });
  const [allAgents, setAllAgents] = useState([]);

  useEffect(() => {
    const userD = localStorage.getItem("auth") ? JSON.parse(localStorage.getItem("auth")) : {};
    if (Object.keys(userD).length) {
      setUserDetails(userD.state.user);
      if (userD.state.user.roleName == roles.SUPER_ADMIN) {
        mutateListAgents({ subbranch: undefined });
      } else if (userD.state.user.roleName == roles.SUBBRANCH_ADMIN) {
        mutateListAgents({ subbranch: userD.state.user?.subbranch });
      } else {
        mutateListAgents({ subbranch: undefined });
      }
    }
  }, []);
  const { mutate: mutateListAgents }: any = useMutation(
    (qry: any) => ListCommanWay("/admin/user/list", { subbranch: qry?.subbranch }, { paginate: 100000 }),
    {
      onSuccess: (data: any) => {
        if (data.status == "SUCCESS") {
          setAllAgents([{ id: undefined, name: "--- Select User ---" }, ...data.data.data]);
        }
      },
      onError: (data: any) => {},
    }
  );

  const handleSubmit = async () => {
    setIsDataAdded(!isDataAdded);
    setQuery({
      bill_from: {
        $gte: startDate ? startDate : moment().startOf("day").subtract(7, "days"),
        $lte: endDate ? moment(endDate).endOf("day") : moment().endOf("day"),
      },
      bill_id: { $ne: null },
      addedBy: selectedAgent.id ? selectedAgent.id : undefined,
    });
  };

  const { isFetching, isLoading, refetch } = useQuery(
    ["admin_km_vouchures_list"],
    () =>
      ListCommanWay("/admin/km_vouchure/list", query, {
        include: [],
        paginate: 10000,
        doSum: true,
      }),
    {
      keepPreviousData: true,
      enabled: false,
      onSuccess(data) {
        if (data.status == "SUCCESS") {
          console.log("data.data", data.data);
          setTableData(data.data.data);
          setTableDetails([]);
        } else {
          setTableData([]);
        }
      },
      onError: (data) => {
        setTableData([]);
      },
    }
  );

  const { mutate: mutateVouchureDetail }: any = useMutation(
    (qry: any) =>
      ListCommanWay(
        "/admin/km_vouchure/list",
        {
          bill_id: qry,
        },
        { paginate: 100000 }
      ),
    {
      onSuccess: (data: any) => {
        if (data.status == "SUCCESS") {
          setTableDetails(data.data.data);
        }
      },
      onError: (data: any) => {},
    }
  );

  useEffect(() => {
    if (query.addedBy) {
      refetch();
    }
  }, [query]);

  const BASE_URL = process.env.REACT_APP_BACKEND_URL;
  const userD = localStorage.getItem("auth") ? JSON.parse(localStorage.getItem("auth")) : {};
  const [userDetails, setUserDetails] = useState<any>(userD?.state?.user || {});

  const {
    mutate: generateKmVoucher,
    isLoading: isloading,
  }: {
    mutate: UseMutateFunction<AxiosResponse<any, any>, unknown, any, unknown>;
    isLoading: any;
  } = useMutation(
    (destination = "") =>
      ListCommanWay(
        "/admin/km_vouchure/generate",
        {
          bill_from: startDate ? startDate : moment().startOf("day").subtract(7, "days"),
          bill_to: endDate ? moment(endDate).endOf("day") : moment().endOf("day"),
          addedBy: selectedAgent.id ? selectedAgent.id : undefined,
        },
        {}
      ),
    {
      onSuccess: (data: any) => {
        console.log(data, data.status == "SUCCESS");
        if (data.status == "SUCCESS") {
          UseToast("Data Added Successfully", "success");
        } else {
          UseToast(data.message, "error");
        }
      },
      onError: (data: any) => {
        typeof data === "string" ? UseToast(data, "error") : UseToast(data.message, "error");
      },
    }
  );

  const {
    mutate: deleteKmVoucher,
    isLoading: isDeleteVoucherloading,
  }: {
    mutate: UseMutateFunction<AxiosResponse<any, any>, unknown, any, unknown>;
    isLoading: any;
  } = useMutation((bill_id) => ListCommanWay("/admin/km_vouchure/delete", { bill_id }, {}), {
    onSuccess: (data: any) => {
      if (data.status == "SUCCESS") {
        refetch();
        UseToast("Data Delted Successfully", "success");
      } else {
        UseToast(data.message, "error");
      }
    },
    onError: (data: any) => {
      typeof data === "string" ? UseToast(data, "error") : UseToast(data.message, "error");
    },
  });

  const {
    mutate: updatePayInfo,
    isLoading: isloadingPayInfo,
  }: {
    mutate: UseMutateFunction<AxiosResponse<any, any>, unknown, any, unknown>;
    isLoading: any;
  } = useMutation((data) => UpdateCommanWay(data), {
    onSuccess: (data: any) => {
      console.log(data, data.status == "SUCCESS");
      if (data.status == "SUCCESS") {
        refetch();
      } else {
        UseToast(data.message, "error");
      }
    },
    onError: (data: any) => {
      typeof data === "string" ? UseToast(data, "error") : UseToast(data.message, "error");
    },
  });
  const onPayStatusChange = (id: any, status) => {
    Swal.fire({
      title: "Are you sure!",
      text: "Changing Status to : " + status,
      icon: "info",
      confirmButtonText: "Yes",
      showCancelButton: true,
      html: '<input id="transactionNo" class="swal2-input" placeholder="Transaction No">',
    }).then((x) => {
      if (x.isConfirmed) {
        let updateData: any = {
          transaction_no: (document.getElementById("transactionNo") as any).value,
          pay_status: status,
        };

        updatePayInfo({
          url: "/admin/km_vouchure/updatePayInfo/" + id,
          data: updateData,
        });
      }
    });
  };

  return (
    <>
      <h1 className="text-center mb-4 mt-3 text-lg font-semibold ">Generate KM. Vouchure</h1>
      <div className="flex items-end flex-col gap-4 px-4  border-[1px] p-4 m-4 rounded-md">
        <div className="flex gap-1 items-center flex-wrap border p-2 rounded-xl bg-gray-200 border-blue-600 w-full">
          {[1, 2, 3, 5, 9].includes(userDetails.role_name) && (
            <div className={`inline-flex items-center gap-2 mr-6`}>
              <label className="text-sm font-semibold text-gray-900 whitespace-nowrap">Select Employee</label>
              <Select
                className={`bg-gray-50 border-gray-300 text-gray-900 text-sm rounded-lg block w-52 `}
                options={allAgents}
                onChange={(e: any) => {
                  setSelectedAgent(e);
                }}
                value={selectedAgent}
                getOptionValue={(e) => {
                  return e.id + "";
                }}
                getOptionLabel={(e) => {
                  return e.name;
                }}
              />
            </div>
          )}

          <div className="flex items-center ml-12 gap-4">
            <div className="flex gap-4 w-full flex-1 items-center">
              <label className=" text-sm font-semibold text-gray-900 whitespace-nowrap">From Date</label>
              <input
                type="date"
                className={`bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-36 p-2.5`}
                placeholder="Start Date"
                name="startDate"
                value={startDate}
                onChange={(e) => setStartDate(e.target.value)}
              />
            </div>
            <div className="flex gap-4 w-full flex-1 items-center">
              <label className="text-sm font-semibold text-gray-900 whitespace-nowrap">To Date</label>
              <input
                type="date"
                className={`bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-36 p-2.5`}
                placeholder="End Date"
                name="endDate"
                value={endDate}
                onChange={(e) => setEndDate(e.target.value)}
              />
            </div>
          </div>

          <button
            type="button"
            onClick={handleSubmit}
            className="ml-2 rounded-md px-4 py-2 text-sm font-medium text-white bg-green-500 border border-gray-200 hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-2 focus:ring-blue-700 focus:text-blue-700"
          >
            Search
          </button>

          <button
            type="button"
            onClick={generateKmVoucher}
            className="ml-2 rounded-md px-4 py-2 text-sm font-medium text-white bg-blue-600 border-4 border-red-600 hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-2 focus:ring-blue-700 focus:text-blue-700"
          >
            Generate
          </button>
        </div>
      </div>
      <div>
        <table className="w-full table- mb-4 text-sm">
          <thead>
            <tr>
              <th className="border border-slate-200">Action</th>

              <th className="border border-slate-200">From Date</th>
              <th className="border border-slate-200">To Date</th>
              <th className="border border-slate-200">Vouchure Date</th>

              <th className="border border-slate-200">Vouchure Id</th>
              <th className="border border-slate-200">Total KM</th>
              <th className="border border-slate-200">Amount</th>
              <th className="border border-slate-200">Transaction No</th>
              <th className="border border-slate-200">Pay date</th>
              <th className="border border-slate-200">Pay</th>
            </tr>
          </thead>
          <tbody>
            {tableData.map((r) => {
              return (
                <tr className={r.pay_status == "PAID" ? "bg-green-400" : ""}>
                  <td className="text-center">
                    <button
                      onClick={() => {
                        mutateVouchureDetail(r.bill_id);
                      }}
                      className="rounded-md px-4 py-1 text-sm font-medium text-white bg-orange-400 border border-gray-200 hover:bg-gray-100 hover:text-blue-700 "
                    >
                      Load
                    </button>
                    <button
                      onClick={() => {
                        Swal.fire({
                          title: "Are you sure?",
                          icon: "error",
                          confirmButtonText: "Yes",
                          showCancelButton: true,
                        }).then((x) => {
                          if (x.isConfirmed) {
                            deleteKmVoucher(r.bill_id);
                          }
                        });
                        //
                      }}
                      className="ml-2 rounded-md px-4 py-1 text-sm font-medium text-white bg-red-600 border"
                    >
                      Delete
                    </button>
                  </td>
                  <td className="border border-slate-200 px-4 text-center">{moment(r.bill_from).format("DD-MM-YYYY")}</td>
                  <td className="border border-slate-200 px-4 text-center">{moment(r.bill_to).format("DD-MM-YYYY")}</td>
                  <td className="border border-slate-200 px-4 text-center">{moment(r.bill_date).format("DD-MM-YYYY")}</td>

                  <td className="border border-slate-200 px-4 text-center">{r.bill_id}</td>
                  <td className="border border-slate-200 px-4 text-center">{r.total_km / 1000}</td>
                  <td className="border border-slate-200 px-4 text-center">{((r.total_km / 1000) * 3).toFixed(2)}</td>
                  <td className="border border-slate-200 px-4 text-center">{r.transaction_no}</td>
                  <td className="border border-slate-200 px-4 text-center">{r.pay_date && moment(r.pay_date).format("DD-MM-YYYY")}</td>
                  <td className="border border-slate-200 px-4 text-center">
                    {r.pay_status != "PAID" && (
                      <button
                        onClick={() => {
                          onPayStatusChange(r.bill_id, "PAID");
                        }}
                        className="rounded-md px-4 py-1 text-sm font-medium text-white bg-green-600 border border-gray-200 hover:bg-gray-100 hover:text-blue-700 "
                      >
                        Pay
                      </button>
                    )}
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>

      <div className="px-4">
        <table className="w-full table- mb-4 text-sm">
          <thead>
            <tr>
              <th className="border border-slate-200">Trip id</th>
              <th className="border border-slate-200">Action</th>
              <th className="border border-slate-200">PRN</th>
              <th className="border border-slate-200">Awb</th>
              <th className="border border-slate-200 w-28">Started At</th>
              <th className="border border-slate-200">Km</th>
              <th className="border border-slate-200">Total</th>
              <th className="border border-slate-200">location</th>
              <th className="border border-slate-200">lat,long</th>
            </tr>
          </thead>
          <tbody>
            {tableDetails.map((r) => {
              return (
                <tr className={`${r.remark == "START" ? "border-t-4 border-blue-600" : ""}`}>
                  <td className="border border-slate-200 px-4">{r.vouchure_id}</td>
                  <td className="border border-slate-200 px-4">{r.remark}</td>

                  <td className="border border-slate-200 px-4">
                    {r.pickup_code?.split(",").map((a) => {
                      return (
                        <>
                          <a>{a}</a>
                          <br />
                        </>
                      );
                    })}
                  </td>

                  <td className="border border-slate-200 px-4">
                    {r.awb_nos?.split(",").map((a) => {
                      return (
                        <>
                          <a href={`/pod/tracking?awb=${a}`} target="_blank">
                            {a}
                          </a>
                          <br />
                        </>
                      );
                    })}
                  </td>

                  <td className="border border-slate-200 px-4">{moment(r.createdAt, "DD-MM-YYYY HH:mm:ss").format("DD-MM-YYYY HH:mm")}</td>

                  <td className="border border-slate-200 px-4 whitespace-nowrap text-right">{(r.km / 1000).toFixed(2)} km</td>
                  <td className="border border-slate-200 px-4 whitespace-nowrap text-right text-blue-600 font-semibold">
                    {r.remark == "STOP" && (r.total / 1000).toFixed(2)} km
                  </td>
                  <td className="border border-slate-200 px-4">{r.location}</td>
                  <td className="border border-slate-200 px-4">
                    {r.lat},{r.long}
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    </>
  );
};

export default VouchureGen;
