import React, { useEffect, useRef, useState } from "react";
import axios, { AxiosResponse } from "axios";
import { DataTableWithoutButtons } from "components/DataTable/DataTableWithoutButtons";
import { AfDataType } from "utils/TableColumnData";
import handleWheel from "utils/handleOnWheel";
import { ListCommanWay, UpdateCommanWay } from "API/commonApi";
import { UseMutateFunction, useMutation, useQuery } from "@tanstack/react-query";
import { Img } from "components";
import moment from "moment";
import Swal from "sweetalert2";
import UseToast from "utils/AfToast";
import Select from "react-select";
import { object } from "yup";
import { useFormik } from "formik";
import { Link, useNavigate } from "react-router-dom";
import { utils, write, writeFile } from "xlsx";
import { saveAs } from "file-saver";

const CashReport: React.FC<any> = (props) => {
  const ADT = AfDataType;
  const navigator = useNavigate();
  const [tableData, setTableData] = useState<any>([]);
  const [totals, setTotals] = useState<any>({});
  const [agentId, setAgentId] = useState("");
  const [startDate, setStartDate] = useState<any>(moment().startOf("day").format("YYYY-MM-DD"));
  const [endDate, setEndDate] = useState<any>(moment().endOf("day").format("YYYY-MM-DD"));

  const [query, setQuery] = useState<any>({
    date: {
      startDate: moment().startOf("day").format("YYYY-MM-DD HH:mm:ss"),
      endDate: moment().endOf("day").format("YYYY-MM-DD HH:mm:ss"),
    },
    /*qry: `date >= '${moment().startOf("day").format("YYYY-MM-DD HH:mm:ss")}' AND date <= '${moment()
      .endOf("day")
      .format("YYYY-MM-DD HH:mm:ss")}'`,*/
  });
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const [fetchedData, setFetchedData] = useState([]);
  const [isDataAdded, setIsDataAdded] = useState(false);

  const [nestedTables, setNestedTables] = useState<any>({});
  const [nestedSelectedOptions, setNestedSelectedOptions] = useState<any>({});

  const [branchOpt, setBranchOtp] = useState<any>();
  const [branchOptions, setBranchOptions] = useState<any>([{ value: 0, label: "Loading..." }]);
  const defaultOptions: any = [{ value: 0, label: "Loading..." }];

  const {
    error: branchError,
    isFetching: branchIsFetching,
    isError: branchIsError,
    isFetched: branchIsFetched,
    isLoading: branchIsLoading,
    refetch: branchRefetch,
  } = useQuery(["BranchesListApi"], () => ListCommanWay("/admin/subbranch/list", {}, { paginate: 1000 }), {
    keepPreviousData: true,
    onSuccess(data) {
      if (data.status == "SUCCESS") {
        let opt = [];
        data.data.data.forEach((p) => {
          opt.push({
            value: p.id,
            label: p.branch_name,
          });
        });
        setBranchOptions(opt);
      }
    },
  });

  const { isFetching, isLoading, refetch } = useQuery(
    ["admin_cash_report_list"],
    () =>
      ListCommanWay(
        "/admin/awbdata/cashreport",
        {
          ...query,
        },
        { include: ["_invoice_no_c_b"] }
      ),
    {
      keepPreviousData: true,
      onSuccess(data) {
        if (data.status == "SUCCESS") {
          console.log("data.data", data.data.rows);
          setTableData(data.data.rows);

          let ary = [...data.data.rows];
          let ttt = {
            base: 0,
            cgst: 0,
            sgst: 0,
            igst: 0,
            utgst: 0,
            grand: 0,
          };
          ary.forEach((a) => {
            console.log(a.base_value);
            ttt.base += Number(a.base_value || 0);
            ttt.cgst += Number((a.base_value * a.cgst) / 100);
            ttt.sgst += Number((a.base_value * a.sgst) / 100);
            ttt.igst += Number((a.base_value * a.igst) / 100);
            ttt.utgst += Number((a.base_value * (a.utgst || 0)) / 100);
            ttt.grand += Number(a.base_value + a.tax);
          });
          setTotals(ttt);
        } else {
          setTableData([]);
        }
      },
      onError: (data) => {
        setTableData([]);
      },
    }
  );

  useEffect(() => {
    setTotals({ grand: 0 });
    refetch();
  }, [query]);

  const __handleSubmit = async () => {
    setIsDataAdded(!isDataAdded);

    setQuery({
      date: {
        startDate: startDate ? startDate : moment().startOf("day").subtract(7, "days").format("YYYY-MM-DD HH:mm:ss"),
        endDate: endDate ? endDate : moment().endOf("day").format("YYYY-MM-DD HH:mm:ss"),
      },
      subbranch: branchOpt?.value,
    });

    /*setQuery({
      //agent_id: Number(agentId),
      date: {
        $gte: startDate ? startDate : moment().startOf("day").subtract(7, "days"),
        $lte: endDate ? endDate : moment().endOf("day"),
      },
    });*/
  };

  const BASE_URL = process.env.REACT_APP_BACKEND_URL;
  const userD = localStorage.getItem("auth") ? JSON.parse(localStorage.getItem("auth")) : {};
  const [userDetails, setUserDetails] = useState<any>(userD?.state?.user || {});
  const tableRef = useRef(null);

  const exportToExcel = () => {
    const table = tableRef.current;

    const tableClone = table.cloneNode(true);

    const tfoot = tableClone.getElementsByTagName("tfoot");
    if (tfoot.length > 0) {
      tfoot[0].remove();
    }

    const ws = utils.table_to_sheet(tableClone);
    const wb = utils.book_new();
    utils.book_append_sheet(wb, ws, "Sheet1");

    Object.keys(ws).forEach((key) => {
      if (key.startsWith("!")) return;

      let cell = ws[key];
      if (cell && cell.h && typeof cell.h === "string") {
        cell.h = null;
      }
      if (cell && cell.l) {
        delete cell.l;
      }
    });

    const wbout = write(wb, { bookType: "xlsx", type: "array" });

    const blob = new Blob([wbout], { type: "application/octet-stream" });
    saveAs(blob, "Cash Report.xlsx");
  };

  return (
    <>
      <h1 className="text-center mb-4 mt-3 text-lg font-semibold ">Cash Report</h1>
      <div className="px-4  border-[1px] p-4 m-4 rounded-md">
        <div className="flex flex-col items-center">
          <div className="flex items-center gap-x-4 gap-y-2">
            {userDetails.role_name == 1 && (
              <div className={`items-center gap-2`}>
                <label className="mb-2 text-sm font-semibold text-gray-900">Subbranch</label>
                <Select
                  className={`${false ? "bg-gray-300" : ""} w-48 bg-gray-50 border-gray-300 text-gray-900 text-sm rounded-lg block`}
                  options={branchOptions}
                  onChange={(e) => {
                    setBranchOtp(e);
                  }}
                  value={branchOpt}
                  isClearable={true}
                />
              </div>
            )}

            <div className="">
              <label className="block mb-0.5 text-sm font-medium text-gray-900">From Date</label>
              <input
                type="date"
                className={`bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5`}
                placeholder="Start Date"
                name="startDate"
                value={startDate}
                onChange={(e) => setStartDate(e.target.value)}
              />
            </div>
            <div className="">
              <label className="block mb-0.5 text-sm font-medium text-gray-900">To Date</label>
              <input
                type="date"
                className={`bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5`}
                placeholder="End Date"
                name="endDate"
                value={endDate}
                onChange={(e) => setEndDate(e.target.value)}
              />
            </div>
            <button
              type="button"
              onClick={__handleSubmit}
              className="ml-2 mt-2 rounded-md px-4 py-2 text-sm font-medium text-white bg-green-500 border border-gray-200 hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-2 focus:ring-blue-700 focus:text-blue-700"
            >
              Submit
            </button>

            <div>
              CASH TOTAL:{" "}
              <span style={{ fontWeight: 600, color: "red" }} className="">
                {new Intl.NumberFormat("en-IN", { style: "currency", currency: "INR" }).format(Number(totals.grand))}
              </span>
            </div>
            <button onClick={exportToExcel} className=" bg-blue-600 text-white px-4 py-1 rounded-md">
              Excel Export
            </button>
          </div>
        </div>
      </div>

      <div className="px-4">
        <table className="w-full table- mb-8" style={{ fontSize: "85%" }} id="myTable" ref={tableRef}>
          <thead>
            <tr>
              <th className="border border-slate-200 px-1">Sr.</th>
              <th className="border border-slate-200 px-1">Date Time</th>
              <th className="border border-slate-200 px-1">inv_no</th>
              <th className="border border-slate-200 px-1">AWB</th>
              <th className="border border-slate-200 px-1">weight</th>
              <th className="border border-slate-200 px-1">shipper_city</th>
              <th className="border border-slate-200 px-1">shipper</th>
              <th className="border border-slate-200 px-1">cgnee_cgnee</th>
              <th className="border border-slate-200 px-1">cgnee_city</th>
              <th className="border border-slate-200 px-1">Type</th>
              <th className="border border-slate-200 px-1">10:30</th>
              <th className="border border-slate-200 px-1">Holi</th>
              <th className="border border-slate-200 px-1">Base Rate</th>
              <th className="border border-slate-200 px-1">chc</th>
              <th className="border border-slate-200 px-1">Fuel</th>
              <th className="border border-slate-200 px-1">cgst</th>
              <th className="border border-slate-200 px-1">sgst</th>
              <th className="border border-slate-200 px-1">igst</th>
              <th className="border border-slate-200 px-1">utgst</th>
              <th className="border border-slate-200 px-1">Book By</th>
              <th className="border border-slate-200 px-1">shipper GST</th>
              <th className="border border-slate-200 px-1">Airfield GST</th>
              <th className="border border-slate-200 px-1">Grand Total</th>
            </tr>
          </thead>
          <tbody>
            {tableData &&
              tableData.map((r, i) => {
                return (
                  <tr key={"r" + r.awb_no} className={`${!r.isActive && "bg-red-200"}`}>
                    <td className="border border-slate-200 px-2">{i + 1}</td>
                    <td className="border border-slate-200 px-2">
                      {moment(r._assign_to_booking?.date, "DD-MM-YYYY HH:mm:ss").format("DD/MM/YYYY")}
                    </td>
                    <td className="border border-slate-200 px-2">
                      {r._subbranch?.branch_code + ("00000" + Number(r._invoice_no_c_b?.invoice_no)).slice(-5)}
                    </td>
                    <td className="border border-slate-200 px-2">
                      <Link to={`/pod/tracking?awb=${r.awb_no}`} className="text-primary2">
                        {r.awb_no}
                      </Link>
                    </td>
                    <td className="border border-slate-200 px-2">{r.weight}</td>
                    <td className="border border-slate-200 px-2">{r._assign_to_booking?.shipper_city}</td>
                    <td className="border border-slate-200 px-2">{r._assign_to_booking?.shipper_sh}</td>
                    <td className="border border-slate-200 px-2">{r.consignee}</td>
                    <td className="border border-slate-200 px-2">{r.destination}</td>
                    <td className="border border-slate-200 px-2">{r.dox_or_non_dox ? "Dox" : "Non_Dox"}</td>
                    <td className="border border-slate-200 px-2">{r.ten_30Rate}</td>
                    <td className="border border-slate-200 px-2">{r.holidayRate}</td>
                    <td className="border border-slate-200 px-2">{Number(r.base_value).toFixed(2)}</td>
                    <td className="border border-slate-200 px-2">0</td>
                    <td className="border border-slate-200 px-2">{r.fuelRate}</td>
                    <td className="border border-slate-200 px-2">{Number((r.base_value * r.cgst) / 100).toFixed(2) || "-"}</td>
                    <td className="border border-slate-200 px-2">{Number((r.base_value * r.sgst) / 100).toFixed(2) || "-"}</td>
                    <td className="border border-slate-200 px-2">{Number((r.base_value * r.igst) / 100).toFixed(2) || "-"}</td>
                    <td className="border border-slate-200 px-2">{Number((r.base_value * (r.utgst || 0)) / 100).toFixed(2) || "-"}</td>
                    <td className="border border-slate-200 px-2">{r._assign_to_booking?._addedBy?.name}</td>
                    <td className="border border-slate-200 px-2">{r._assign_to_booking?.shipper_gst_no}</td>
                    <td className="border border-slate-200 px-2">{r._subbranch?.gst_no}</td>
                    <td className="border border-slate-200 px-2">{Number(r.base_value + r.tax).toFixed(2)}</td>
                    <td className="border border-slate-200 px-2">{!r.isActive && "Cancelled"}</td>
                  </tr>
                );
              })}
          </tbody>
          <tfoot>
            <tr>
              <th className="border border-slate-200 px-1"></th>
              <th className="border border-slate-200 px-1"></th>
              <th className="border border-slate-200 px-1"></th>
              <th className="border border-slate-200 px-1"></th>
              <th className="border border-slate-200 px-1"></th>
              <th className="border border-slate-200 px-1"></th>
              <th className="border border-slate-200 px-1"></th>
              <th className="border border-slate-200 px-1"></th>
              <th className="border border-slate-200 px-1"></th>
              <th className="border border-slate-200 px-1"></th>
              <th className="border border-slate-200 px-1"></th>
              <th className="border border-slate-200 px-1"></th>
              <th className="border border-slate-200 px-1 py-2">{totals.base?.toFixed(2)}</th>
              <th className="border border-slate-200 px-1"></th>
              <th className="border border-slate-200 px-1"></th>
              <th className="border border-slate-200 px-1">{totals.cgst?.toFixed(2)}</th>
              <th className="border border-slate-200 px-1">{totals.sgst?.toFixed(2)}</th>
              <th className="border border-slate-200 px-1">{totals.igst?.toFixed(2)}</th>
              <th className="border border-slate-200 px-1">{totals.utgst?.toFixed(2)}</th>
              <th className="border border-slate-200 px-1"></th>
              <th className="border border-slate-200 px-1"></th>
              <th className="border border-slate-200 px-1">{Number(totals.grand).toFixed(2)}</th>
              <th className="border border-slate-200 px-1">-</th>
            </tr>
            <tr>
              <th className="border border-slate-200 px-1">Sr.</th>
              <th className="border border-slate-200 px-1">Date Time</th>
              <th className="border border-slate-200 px-1">inv_no</th>
              <th className="border border-slate-200 px-1">AWB</th>
              <th className="border border-slate-200 px-1">weight</th>
              <th className="border border-slate-200 px-1">shipper_city</th>
              <th className="border border-slate-200 px-1">shipper</th>
              <th className="border border-slate-200 px-1">cgnee_cgnee</th>
              <th className="border border-slate-200 px-1">cgnee_city</th>
              <th className="border border-slate-200 px-1">Type</th>
              <th className="border border-slate-200 px-1">10:30</th>
              <th className="border border-slate-200 px-1">Holi</th>
              <th className="border border-slate-200 px-1">Base Rate</th>
              <th className="border border-slate-200 px-1">chc</th>
              <th className="border border-slate-200 px-1">Fuel</th>
              <th className="border border-slate-200 px-1">cgst</th>
              <th className="border border-slate-200 px-1">sgst</th>
              <th className="border border-slate-200 px-1">igst</th>
              <th className="border border-slate-200 px-1">utgst</th>
              <th className="border border-slate-200 px-1">Book By</th>
              <th className="border border-slate-200 px-1">shipper GST</th>
              <th className="border border-slate-200 px-1">Airfield GST</th>
              <th className="border border-slate-200 px-1">Grand Total</th>
              <th className="border border-slate-200 px-1">Status</th>
            </tr>
          </tfoot>
        </table>
      </div>
    </>
  );
};

export default CashReport;
