import React, { useState, useEffect } from "react";

const ImageUploadField = ({ title, propertyName, values, setFieldValue }) => {
  const [selectedImage, setSelectedImage] = useState(null);

  const handleImageChange = (event) => {
    const file = event.target.files[0];

    if (file) {
      console.log("fileee", file);

      setSelectedImage(file);
      setFieldValue(propertyName, file);
    }
  };

  return (
    <div className="max-w-md p-6 rounded-md">
      <label
        className="block text-gray-700 text-sm font-bold mb-2"
        htmlFor="imageUpload"
      >
        {title}
      </label>
      <input
        type="file"
        id="imageUpload"
        accept="image/*"
        onChange={handleImageChange}
        className="border rounded-md px-4 py-2 w-full"
      />

      {values[propertyName] && (
        <div className="mt-4">
          <p className="text-gray-700">Selected Image:</p>
          <img
            src={URL.createObjectURL(values[propertyName])}
            alt="Selected"
            className="mt-2 rounded-md"
          />
        </div>
      )}
    </div>
  );
};

export default ImageUploadField;
