import React from "react";
/*import { CSSProperties } from "react";

const override: CSSProperties = {
  position: "fixed",
  top: "50%",
  left: "50%",
  zIndex: "2000",
};

let a = document.getElementsByClassName("body .sweet-loading");*/

function Spinner() {
  return (
    <>
      <div
        className="sweet-loading bg-gray-200 bg-opacity-70 fixed inset-0 min-h-screen flex justify-center items-center isolate z-50"
        style={{ height: "100vh" }}
      >
        <h1 className="text-5xl">Loading...</h1>
      </div>
    </>
  );
}

export default Spinner;
