import React, { useEffect, useState } from "react";
import axios, { AxiosResponse } from "axios";
import { DataTableWithoutButtons } from "components/DataTable/DataTableWithoutButtons";
import { AfDataType } from "utils/TableColumnData";
import handleWheel from "utils/handleOnWheel";
import { ListCommanWay, UpdateCommanWay } from "API/commonApi";
import { UseMutateFunction, useMutation, useQuery } from "@tanstack/react-query";
import { Img } from "components";
import moment from "moment";
import Swal from "sweetalert2";
import UseToast from "utils/AfToast";
import Select from "react-select";
import { roles } from "utils/constant";

const ExpenseVouchureList: React.FC<any> = (props) => {
  const ADT = AfDataType;
  const [tableData, setTableData] = useState<any>([]);
  const [agentId, setAgentId] = useState("");
  const [startDate, setStartDate] = useState<any>(moment().startOf("day").format("YYYY-MM-DD"));
  const [endDate, setEndDate] = useState<any>(moment().endOf("day").format("YYYY-MM-DD"));
  const [query, setQuery] = useState<any>({
    date: {
      $gte: moment().startOf("day"),
      $lte: moment().endOf("day"),
    },
  });
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const [fetchedData, setFetchedData] = useState([]);
  const [isDataAdded, setIsDataAdded] = useState(false);

  const [selectedAgent, setSelectedAgent] = useState({ id: null, name: "--- Select All ---" });
  const [allAgents, setAllAgents] = useState([]);

  const [selectedVouchers, setSelectedVouchers] = useState<any>([]);
  const [selectedTotal, setSelectedTotal] = useState<any>(0);
  const [firstVoucherStatus, setFirstVoucherStatus] = useState<string>("");

  const [selectedStatus, setSelectedStatus] = useState({ value: "", label: "--- Select All ---" });
  const [allStatusList, setAllStatusList] = useState([
    { value: "", label: "--- Select All ---" },
    { value: "PENDING", label: "Pending By Both" },
    { value: "APPROVED", label: "Approve By Manager, Pending By Acc." },
    { value: "DISBURSED", label: "Disbursed" },
    { value: "REJECTED", label: "Rejected / Cancel" },
  ]);

  useEffect(() => {
    let total = 0;
    let status = "";
    if (tableData.length) {
      tableData.forEach((t) => {
        if (selectedVouchers.includes(t.id)) {
          total += Number(t.expense);
          status = t.status;
        }
      });
      setSelectedTotal(total);
      setFirstVoucherStatus(status);
    }
  }, [selectedVouchers]);

  useEffect(() => {
    const userD = localStorage.getItem("auth") ? JSON.parse(localStorage.getItem("auth")) : {};
    if (Object.keys(userD).length) {
      setUserDetails(userD.state.user);
      if (userD.state.user.roleName == roles.SUPER_ADMIN) {
        mutateListAgents({ subbranch: undefined });
      } else if (userD.state.user.roleName == roles.SUBBRANCH_ADMIN) {
        mutateListAgents({ subbranch: userD.state.user?.subbranch });
      } else {
        mutateListAgents({ subbranch: undefined });
      }
    }
  }, []);

  const { isFetching, isLoading, refetch } = useQuery(
    ["admin_expense_vouchure_list"],
    () =>
      ListCommanWay("/admin/expense_vouchure/list", query, {
        include: ["_user"],
      }),
    {
      keepPreviousData: true,
      onSuccess(data) {
        if (data.status == "SUCCESS") {
          console.log("data.data", data.data);
          setTableData(data.data.data);
          setSelectedVouchers([]);
          setSelectedTotal(0);
          setFirstVoucherStatus("");
        } else {
          setTableData([]);
        }
      },
      onError: (data) => {
        setTableData([]);
      },
    }
  );

  const { mutate: mutateListAgents }: any = useMutation(
    (qry: any) => ListCommanWay("/admin/user/list", { subbranch: qry?.subbranch }, { paginate: 100000 }),
    {
      onSuccess: (data: any) => {
        if (data.status == "SUCCESS") {
          setAllAgents([{ id: null, name: "--- Select All ---" }, ...data.data.data]);
        }
      },
      onError: (data: any) => {},
    }
  );

  const {
    mutate,
    isLoading: isloadingPrice,
  }: {
    mutate: UseMutateFunction<AxiosResponse<any, any>, unknown, any, unknown>;
    isLoading: any;
  } = useMutation((data) => UpdateCommanWay(data), {
    onSuccess: (data: any) => {
      console.log(data, data.status == "SUCCESS");
      if (data.status == "SUCCESS") {
        refetch();
      } else {
        UseToast(data.message, "error");
      }
    },
    onError: (data: any) => {
      typeof data === "string" ? UseToast(data, "error") : UseToast(data.message, "error");
    },
  });
  const onStateChange = (id: any, status) => {
    Swal.fire({
      title: "Are you sure!",
      text: "Changing Status to : " + status,
      icon: "info",
      confirmButtonText: "Yes",
      showCancelButton: true,
      html:
        '<input id="user_remark" class="swal2-input" placeholder="Enter Remark">' +
        '<input id="transactionNo" class="swal2-input" placeholder="Transaction No">',
    }).then((x) => {
      if (x.isConfirmed) {
        let updateData: any = {
          account_remark: undefined,
          transactionNo: (document.getElementById("transactionNo") as any).value,
          account_date: undefined,
          status: status,
        };
        if (userDetails.role_name == 9) {
          updateData.admin_remark = (document.getElementById("user_remark") as any).value;
          updateData.admin_date = new Date();
        } else {
          updateData.account_remark = (document.getElementById("user_remark") as any).value;
          updateData.account_date = new Date();
        }
        if (typeof id == "object") {
          mutate({
            url: "/admin/expense_vouchure/updateBulk",
            data: {
              filter: { id: selectedVouchers },
              data: updateData,
            },
          });
        } else {
          mutate({
            url: "/admin/expense_vouchure/update/" + id,
            data: updateData,
          });
        }
      }
    });
  };

  useEffect(() => {
    refetch();
  }, [query]);

  const handleSubmit = async () => {
    setIsDataAdded(!isDataAdded);
    setQuery({
      //agent_id: Number(agentId),
      date: {
        $gte: startDate ? startDate : moment().startOf("day").subtract(7, "days"),
        $lte: endDate ? moment(endDate).endOf("day") : moment().endOf("day"),
      },
      user: selectedAgent.id ? selectedAgent.id : undefined,
      status: selectedStatus.value == "" ? undefined : selectedStatus.value,
    });
  };

  const BASE_URL = process.env.REACT_APP_BACKEND_URL;
  const userD = localStorage.getItem("auth") ? JSON.parse(localStorage.getItem("auth")) : {};
  const [userDetails, setUserDetails] = useState<any>(userD?.state?.user || {});

  return (
    <>
      <h1 className="text-center mb-4 mt-3 text-lg font-semibold ">Expense Vouchure</h1>
      <div className="flex items-end flex-col gap-4 px-4  border-[1px] p-4 m-4 rounded-md">
        {/*<div className="flex gap-4 w-full flex-1">
          <label className="w-full text-sm font-semibold text-gray-900">
            Agent
          </label>
          <input
            type="number"
            className={`bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5`}
            placeholder="Agent ID"
            name="agent_id"
            value={agentId}
            onBlur={(e) => {
              setAgentId(e.target.value);
              if (!e.target.value) {
                setQuery({});
              }
            }}
            onChange={(e) => {
              setAgentId(e.target.value);
              if (!e.target.value) {
                setQuery({});
              }
            }}
            onWheel={handleWheel}
          />
          </div>*/}
        <div className="flex gap-1 items-center flex-wrap border p-2 rounded-xl bg-gray-200 border-blue-600 w-full">
          {[1, 2, 3, 5, 9].includes(userDetails.role_name) && (
            <div className={`inline-flex items-center gap-2 mr-6`}>
              <label className="text-sm font-semibold text-gray-900 whitespace-nowrap">Select Employee</label>
              <Select
                className={`bg-gray-50 border-gray-300 text-gray-900 text-sm rounded-lg block w-52 `}
                options={allAgents}
                onChange={(e: any) => {
                  setSelectedAgent(e);
                }}
                value={selectedAgent}
                getOptionValue={(e) => {
                  return e.id + "";
                }}
                getOptionLabel={(e) => {
                  return e.name;
                }}
              />
            </div>
          )}

          <div className={`inline-flex items-center gap-2`}>
            <label className="text-sm font-semibold text-gray-900 whitespace-nowrap">Select Status</label>
            <Select
              className={`bg-gray-50 border-gray-300 text-gray-900 text-sm rounded-lg block w-full `}
              options={allStatusList}
              onChange={(e: any) => {
                setSelectedStatus(e);
              }}
              value={selectedStatus}
            />
          </div>

          <div className="flex items-center ml-12 gap-4">
            <div className="flex gap-4 w-full flex-1 items-center">
              <label className=" text-sm font-semibold text-gray-900 whitespace-nowrap">From Date</label>
              <input
                type="date"
                className={`bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-36 p-2.5`}
                placeholder="Start Date"
                name="startDate"
                value={startDate}
                onChange={(e) => setStartDate(e.target.value)}
              />
            </div>
            <div className="flex gap-4 w-full flex-1 items-center">
              <label className="text-sm font-semibold text-gray-900 whitespace-nowrap">To Date</label>
              <input
                type="date"
                className={`bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-36 p-2.5`}
                placeholder="End Date"
                name="endDate"
                value={endDate}
                onChange={(e) => setEndDate(e.target.value)}
              />
            </div>
          </div>

          <button
            type="button"
            onClick={handleSubmit}
            className="ml-2 rounded-md px-4 py-2 text-sm font-medium text-white bg-green-500 border border-gray-200 hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-2 focus:ring-blue-700 focus:text-blue-700"
          >
            Submit
          </button>
        </div>
      </div>
      <div className="px-4">
        {selectedTotal >= 0 && (
          <div className="text-center mb-4 flex justify-center items-center gap-10">
            <p className={`font-medium text-2xl underline`}>
              Selected Total: <b> {selectedTotal}</b>
            </p>

            <div className="flex items-center justify-center gap-1">
              <button
                type="button"
                onClick={() => {
                  onStateChange(selectedVouchers, userDetails.role_name == 9 ? "APPROVED" : "DISBURSED");
                }}
                disabled={selectedTotal == 0}
                className={`ml-2 rounded-md px-4 py-2 font-medium text-white bg-green-500 border border-gray-200 hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-2 focus:ring-blue-700 focus:text-blue-700 ${
                  selectedTotal == 0 ? "bg-gray-300 pointer-events-none" : ""
                }`}
              >
                {userDetails.role_name == 9 ? "Bulk Approve" : "Bulk Disbursed"}
              </button>
              <button
                type="button"
                onClick={() => {
                  onStateChange(selectedVouchers, "REJECTED");
                }}
                className={`ml-2 rounded-md px-4 py-2 font-medium text-white bg-red-500 border border-gray-200 hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-2 focus:ring-blue-700 focus:text-blue-700 ${
                  selectedTotal == 0 ? "bg-gray-300 pointer-events-none" : ""
                }`}
              >
                Bulk Reject
              </button>
            </div>
          </div>
        )}
        <table className="w-full table- mb-4">
          <thead>
            <tr>
              {[1, 2, 3, 5, 9].includes(userDetails.role_name) && (
                <th className="border border-slate-200">
                  <input
                    type="checkbox"
                    className="focus:border-black-900"
                    onChange={(ip) => {
                      let checked_ = ip.target.checked;
                      console.log(checked_);
                      if (checked_ && tableData.length) {
                        let firstStatus = tableData[0].status;
                        let obj = [];
                        tableData.forEach(x => {
                          if(x.status == firstStatus){
                            obj.push(x.id);
                          }
                        })
                        setSelectedVouchers(obj);
                      }else{
                        setSelectedVouchers([]);
                      }
                    }}
                    checked={selectedVouchers.length}
                  />
                  Check
                </th>
              )}

              <th className="border border-slate-200">Transaction No</th>
              <th className="border border-slate-200">User</th>
              <th className="border border-slate-200">Head</th>
              <th className="border border-slate-200">Amount</th>
              <th className="border border-slate-200 w-32">User Time</th>
              <th className="border border-slate-200 w-32">System Time</th>
              <th className="border border-slate-200 w-28">Image</th>
              <th className="border border-slate-200">Status</th>
              <th className="border border-slate-200">Manager Remark</th>
              <th className="border border-slate-200">Account Remark</th>
              {[1, 2, 3, 5, 9].includes(userDetails.role_name) && <th className="border border-slate-200 w-24">ACTION</th>}
            </tr>
          </thead>
          <tbody>
            {tableData.map((r) => {
              return (
                <tr
                  style={{
                    backgroundColor:
                      r.status != "PENDING" && (r.status == "APPROVED" ? "#60b9ff" : r.status == "REJECTED" ? "#ff6a5f" : "#57ca5c"),
                  }}
                  className={`${selectedVouchers.includes(r.id) ? "bg-primary_light2" : "bg-white"}`}
                >
                  {[1, 2, 3, 5, 9].includes(userDetails.role_name) && (
                    <td className="border border-slate-200 px-4">
                      {r.status != "REJECTED" &&
                      (selectedVouchers.length == 0 || (selectedVouchers.length && firstVoucherStatus == r.status)) ? (
                        <input
                          type="checkbox"
                          className="focus:border-black-900"
                          onChange={(ip) => {
                            if (ip.target.checked) {
                              let obj = [...selectedVouchers];
                              obj.push(r.id);
                              setSelectedVouchers(obj);
                            } else {
                              let obj = [...selectedVouchers];
                              const index = obj.indexOf(r.id);
                              if (index > -1) {
                                obj.splice(index, 1);
                              }
                              setSelectedVouchers(obj);
                            }
                          }}
                          checked={selectedVouchers.includes(r.id)}
                        />
                      ) : (
                        <p>-</p>
                      )}
                    </td>
                  )}

                  <td className="border border-slate-200 px-4">{r.transactionNo}</td>
                  <td className="border border-slate-200 px-4">{r._user?.name}</td>
                  <td className="border border-slate-200 px-4">{r.remark}</td>
                  <td className="border border-slate-200 px-4">{r.expense}</td>
                  <td className="border border-slate-200 px-4">{r.date}</td>
                  <td className="border border-slate-200 px-4">{r.createdAt}</td>

                  <td className="border border-slate-200 px-4">
                    {r.image
                      ? r.image.split(",").map((i) => {
                          return (
                            <a href={BASE_URL + i} target="_blank">
                              <Img width={80} src={BASE_URL + i} />
                            </a>
                          );
                        })
                      : "-"}
                  </td>
                  <td className="border border-slate-200 px-4">{r.status}</td>
                  <td className="border border-slate-200 px-4">
                    {r.admin_remark}
                    <br /> <b>{r.admin_date && moment(r.admin_date).format("DD-MM-YYYY HH:mm")}</b>
                  </td>
                  <td className="border border-slate-200 px-4">
                    {r.account_remark}
                    <br /> <b>{r.account_date && moment(r.account_date).format("DD-MM-YYYY HH:mm")}</b>
                  </td>
                  {[1, 2, 3, 5, 9].includes(userDetails.role_name) && (
                    <td className="border border-slate-200 px-4">
                      {r.status == (userDetails.role_name == 9 ? "PENDING" : "APPROVED") && (
                        <div className="flex flex-col items-center justify-center gap-1">
                          <button
                            type="button"
                            onClick={() => {
                              onStateChange(r.id, userDetails.role_name == 9 ? "APPROVED" : "DISBURSED");
                            }}
                            className="ml-2 rounded-md px-3 py-1 text-sm font-medium text-white bg-green-500 border border-gray-200 hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-2 focus:ring-blue-700 focus:text-blue-700"
                          >
                            {userDetails.role_name == 9 ? "Approve" : "Disburse"}
                          </button>
                        </div>
                      )}

                      {
                        /*r.status == (userDetails.role_name == 5 ? "APPROVED" : "PENDING") &&*/
                        r.status != "REJECTED" && (
                          <div className="flex flex-col items-center justify-center gap-1">
                            <button
                              type="button"
                              onClick={() => {
                                onStateChange(r.id, "REJECTED");
                              }}
                              className="ml-2 rounded-md px-3 py-1 text-sm font-medium text-white bg-red-500 border border-gray-200 hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-2 focus:ring-blue-700 focus:text-blue-700"
                            >
                              Reject
                            </button>
                          </div>
                        )
                      }
                    </td>
                  )}
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    </>
  );
};

export default ExpenseVouchureList;
