import { MinusCircleIcon } from "@heroicons/react/24/outline";
import { UseMutateFunction, useMutation, useQuery } from "@tanstack/react-query";
import { CreateCommanWay, GetCommanWay, ListCommanWay, UpdateBookingCommanWay, UpdateCommanWay } from "API/commonApi";
import { AxiosResponse } from "axios";
import { Button, Input } from "components";
import { InputTable } from "components/DataTable/InputTable";
import { useFormik } from "formik";
import { debounce } from "lodash";
import moment from "moment";
import { any } from "prop-types";
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Select from "react-select";
import Swal from "sweetalert2";
import UseToast from "utils/AfToast";
import { roles } from "utils/constant";
import handleWheel from "utils/handleOnWheel";
import { AfDataType as ADT } from "utils/TableColumnData";
import { number, object, string } from "yup";

type OptionType = {
  value: number;
  label: string;
};

interface MotherBagState {
  mode?: string;
  data?: { [key: string]: any }; // Adjust the type based on your data structure
  // Add other properties if needed
}

interface ValuesType {
  destination?: { value: string; label: string };
}

const MotherBagInScan: React.FC = ({}) => {
  let isDisabled = false;
  const navigate = useNavigate();
  const { state } = useLocation();
  const { mode = "add", data = {} } = (state || {}) as MotherBagState; // Check for existence and type assertion

  const [motherBagList, setMotherBagList] = useState<any>([]);
  const [selectedMotherbag, setSelectedMotherbag] = useState<number>(null);
  const [awbDataList, setAwbDataList] = useState<any>([]);

  const [acceptedAwbNos, setAcceptedAwbNos] = useState<any>([]);
  const [awbScanVal, setAwbScanVal] = useState<any>("");

  const [filterStartDt, setFilterStartDt] = useState<any>(moment().subtract(1, "day").startOf("day"));
  const [filterEndDt, setFilterEndDt] = useState<any>(moment().endOf("day"));

  const [motherbagStatusList, setMotherbagStatusList] = useState<any[]>([]);
  const [selectedMotherbagStatus, setSelectedMotherbagStatus] = useState<any>({});
  const [statusFilter, setStatusFilter] = useState<any>({ value: "", label: "All" });

  const [mySubbranch, setMySubbranch] = useState<any>(false);
  React.useEffect(() => {
    const userD = localStorage.getItem("auth") ? JSON.parse(localStorage.getItem("auth")) : {};
    if (Object.keys(userD).length) {
      setMySubbranch(userD.state.user.subbranch);
    }
  }, []);

  React.useEffect(() => {
    console.log("mySubbranch", mySubbranch);
    if (mySubbranch) {
      setTimeout(refetchData, 200);
    }
  }, [mySubbranch]);

  const { refetch: mbag_status } = useQuery(["MbagStatusList"], () => GetCommanWay("/admin/mbag_status_list", {}, {}), {
    keepPreviousData: true,
    onSuccess(data) {
      if (data.status == "SUCCESS") {
        console.log(data.data);
        setMotherbagStatusList(data.data);
      }
    },
  });

  useEffect(() => {
    if (selectedMotherbag) {
      console.log(selectedMotherbag);
      setTimeout((x) => {
        awbDataRefetch();
      }, 100);
    }
  }, [selectedMotherbag]);
  const { refetch: awbDataRefetch } = useQuery(
    ["AWBList"],
    () => ListCommanWay("/admin/awbdata/list", { mother_bag_id: selectedMotherbag, isInscan: true }, { include: [] }),
    {
      keepPreviousData: true,
      enabled: false,
      onSuccess(data) {
        console.log(data);
        if (data.status == "SUCCESS") {
          setAwbDataList(data.data.data);
        }
      },
    }
  );
  const { refetch: refetchData } = useQuery(
    ["MotherBagInApi"],
    () =>
      ListCommanWay(
        "/admin/mother_bag/list",
        {
          outscanDone: true,
          isInscan: true,
          delivery_subbranch: mySubbranch,
          $and: {
            $or: {
              $and: {
                createdAt: {
                  $gte: filterStartDt,
                  $lte: filterEndDt,
                },
              },
            },
          },
        },
        { paginate: 10000, include: ["_destination", "_subbranch"] }
      ),
    {
      keepPreviousData: true,
      enabled: false,
      onSuccess(data) {
        if (data.status == "SUCCESS") {
          setMotherBagList(data.data.data);
        }
      },
    }
  );

  const { mutate: addMotherbag_pod } = useMutation(CreateCommanWay, {
    onSuccess: (data: any) => {
      if (data.status == "SUCCESS") {
        UseToast("Motherbag Status Changed", "success");
        resetForm();
        setAwbDataList([]);
        setSelectedMotherbag(null);
        refetchData();
      } else {
        UseToast(data.message, "error");
      }
    },
    onError: (data: any) => {
      //typeof data === "string" ? UseToast(data, "error") : UseToast(data.message, "error");
      UseToast("Motherbag Status Change error. Try Again OR Contact Admin!", "error");
    },
  });

  const { mutate: ReleaseAwbNosMutate } = useMutation(UpdateCommanWay, {
    onSuccess: (data: any) => {
      if (data.status == "SUCCESS") {
        UseToast("Airwaybill release successfully", "success");
        proceedToInScan();
      } else {
        UseToast(data.message, "error");
      }
    },
    onError: (data: any) => {
      //typeof data === "string" ? UseToast(data, "error") : UseToast(data.message, "error");
      UseToast("Cannot Release Airwaybill Numbers. Try Again OR Contact Admin!", "error");
    },
  });
  const { mutate: InScanMotherbag } = useMutation(UpdateCommanWay, {
    onSuccess: (data: any) => {
      if (data.status == "SUCCESS") {
        UseToast("Motherbag in-scan complete", "success");
        resetForm();
        setAwbDataList([]);
        setSelectedMotherbag(null);
        refetchData();
      } else {
        UseToast(data.message, "error");
      }
    },
    onError: (data: any) => {
      //typeof data === "string" ? UseToast(data, "error") : UseToast(data.message, "error");
      UseToast("Motherbag in-scan. Try Again OR Contact Admin!", "error");
    },
  });

  const proceedToInScan = () => {
    InScanMotherbag({
      url: "/admin/mother_bag/update/" + selectedMotherbag,
      data: {
        inScanPOD: true,
        inscanDone: true,
        status: "RECEIVED",
      },
    });
  };

  const { handleSubmit, handleBlur, values, handleChange, errors, setValues, setErrors, touched, dirty, resetForm, setFieldValue } =
    useFormik({
      validationSchema: object().shape({}),
      initialValues: {
        subbranch: { value: 0, label: "" },
      },
      onSubmit: async (values, { resetForm }) => {
        console.log("values", values);
        console.log("selcted mbag", selectedMotherbag);
        console.log("accpeted", acceptedAwbNos);
        if (!selectedMotherbag) {
          UseToast("Select Motherbag", "error");
          return;
        }
        if (acceptedAwbNos.length == 0) {
          UseToast("Scan atleast 1 Airwaybill", "error");
          return;
        }

        let awbToRelease = [];
        awbDataList.forEach((a) => {
          if (!acceptedAwbNos.includes(a.awb_no)) {
            awbToRelease.push(a.awb_no);
          }
        });

        if (awbToRelease.length > 0) {
          alert("Cannnot Inscan! missing " + awbToRelease.length + " Airwaybill");
          return;
          Swal.fire({
            title: "Are you Sure!",
            text: "you want to release these Awb Nos: " + awbToRelease.join(", "),
            icon: "question",
            confirmButtonText: "Yes",
            showCancelButton: true,
          })
            .then((x) => {
              if (x.isConfirmed) {
                ReleaseAwbNosMutate({
                  url: "/admin/awbdata/updatebulk",
                  data: {
                    filter: { awb_no: awbToRelease },
                    data: { mother_bag_id: "" },
                  },
                });
              } else {
                ////proceedToInScan();
              }
            }).catch((c) => {
              ////proceedToInScan();
            });
        } else {
          proceedToInScan();
        }
        //mutateUpdateMotherBag(data.id);
        //navigate("/mother_bag/list");
        return;
      },
    });

  const disableEnterKey = (event) => {
    if (event.key === "Enter" || event.key === "Tab") {
      event.preventDefault();
    }
  };

  const doShowRow = (m: any) => {
    switch (statusFilter.value) {
      case "":
        return true;
        break;
      case "pending":
        return m.outscanDone && !m.inscanDone ? true : false;
        break;
      case "received":
        return m.inscanDone ? true : false;
        break;
    }
  };

  return (
    <>
      <h1 className="text-center mb-4 mt-3 text-lg font-semibold underline">Mother Bag IN-SCAN</h1>

      <div className="flex items-center justify-center my-5 gap-3">
        <div>
          From:{" "}
          <input
            type="date"
            className="focus:border-colors2 rounded"
            value={filterStartDt.format("YYYY-MM-DD")}
            onChange={(e: any) => {
              setFilterStartDt(moment(e.target.value).startOf("day"));
            }}
          />
        </div>
        <div>
          To:{" "}
          <input
            type="date"
            className="focus:border-colors2 rounded"
            value={filterEndDt.format("YYYY-MM-DD")}
            onChange={(e: any) => {
              setFilterEndDt(moment(e.target.value).endOf("day"));
            }}
          />
        </div>
        <Button
          type="button"
          className="rounded-md px-2 py-2.5 text-sm font-medium text-white bg-blue-500 border hover:bg-opacity-60 focus:z-10"
          onClick={refetchData}
        >
          Search
        </Button>

        <div className="w-36 ml-10">
          <Select
            className={`flex-1 bg-gray-50 border-gray-300 text-gray-900 text-sm rounded-lg block `}
            options={[
              { value: "", label: "All" },
              { value: "pending", label: "Pending" },
              { value: "received", label: "Received" },
            ]}
            value={statusFilter}
            onChange={(e) => {
              setStatusFilter(e);
            }}
            menuPortalTarget={document.body}
          />
        </div>
      </div>

      <form
        onSubmit={(e) => {
          e.preventDefault();
          handleSubmit(e);
        }}
      >
        {/* <div className="grid grid-cols-2 sm:grid-cols-1 gap-4 px-4"> */}
        <div className="flex gap-2 flex-wrap border p-2 rounded-2xl">
          <table className="border text-left px-4 rounded">
            <thead className="border-b">
              <tr>
                <th className="py-2 px-2">Action</th>
                <th>ID.</th>
                <th>Date</th>
                <th>Motherbag No</th>
                <th>Origin</th>
                <th>Destination</th>
                <th>Total Nos.</th>
                <th>Remark</th>
                <th>Status</th>
                <th>Last Remark</th>
                <th className="max-w-[300px]">Action</th>
              </tr>
            </thead>
            <tbody>
              {motherBagList.map((m) => {
                return (
                  doShowRow(m) && (
                  <tr className={`border-t ${m.inscanDone ? "bg-green-500" : m.id == selectedMotherbag ? "bg-yellow-200" : "bg-red-300"}`}>
                    <td>
                      {m.inscanDone ? (
                        <>
                          {/*<Button
                          type="button"
                          className="rounded-md px-2 py-1 text-sm font-medium text-white bg-blue-500 border hover:bg-opacity-60 focus:z-10"
                          onClick={() => {
                            setSelectedMotherbag(m.id);
                            resetForm();
                          }}
                        >
                          Show DRS
                        </Button>*/}
                        </>
                      ) : m.id == selectedMotherbag ? (
                        <p className="py-1 px-1 text-sm">Selcted</p>
                      ) : (
                        <Button
                          type="button"
                          className="rounded-md px-2 py-1 text-sm font-medium text-white bg-green-500 border border-gray-200 hover:bg-opacity-60 focus:z-10 "
                          onClick={() => {
                            setSelectedMotherbag(m.id);
                            resetForm();
                          }}
                        >
                          Select
                        </Button>
                      )}
                    </td>
                    <td>{m.id}</td>
                    <td>{moment(m.createdAt, "DD-MM-YYYY").format("DD/MM/YYYY")}</td>
                    <td> <span
                          className="cursor-pointer hover:underline hover:font-semibold"
                          onClick={() => {
                            navigate("/mother_bag/add", {
                              state: {
                                mode: "view",
                                data: m,
                              },
                            });
                          }}
                        >
                          {m.mother_bag_code}
                        </span></td>
                    <td>{m._subbranch?.branch_name}</td>
                    <td>{m._destination?.city_name}</td>
                    <td>{m.totalNos}</td>
                    <td>{m.remark}</td>
                    <td>{m.status}</td>
                    <td>{m.motherbad_pods && m.motherbad_pods.length > 0 && m.motherbad_pods[0].remark}</td>
                    <td className="flex p-1 gap-1 max-w-[300px]">
                      <Select
                        className={`flex-1 bg-gray-50 border-gray-300 text-gray-900 text-sm rounded-lg block `}
                        options={motherbagStatusList}
                        value={selectedMotherbagStatus}
                        onChange={(e) => {
                          setSelectedMotherbagStatus(e);
                        }}
                        styles={{
                          control: (provided, state) => ({
                            ...provided,
                            minHeight: "30px",
                            height: "30px",
                          }),
                          valueContainer: (provided, state) => ({
                            ...provided,
                            height: "30px",
                            padding: "0 6px",
                          }),
                          input: (provided, state) => ({
                            ...provided,
                            margin: "0px",
                          }),

                          indicatorsContainer: (provided, state) => ({
                            ...provided,
                            height: "30px",
                          }),
                        }}
                        menuPortalTarget={document.body}
                      />
                      <Button
                        type="button"
                        className="rounded-md px-2 py-1 text-sm font-medium text-white bg-green-500 border border-gray-200 hover:bg-opacity-60 focus:z-10 "
                        onClick={() => {
                          addMotherbag_pod({
                            url: "/admin/motherbad_pod/create/",
                            data: {
                              bagId: m.id,
                              status: selectedMotherbagStatus.value + "",
                              remark: selectedMotherbagStatus.label,
                              dateTime: new Date(),
                            },
                          });
                        }}
                      >
                        Save
                      </Button>
                    </td>
                  </tr>)
                );
              })}
            </tbody>
          </table>
          <div className="w-full font-semibold underline text-center mt-5 border-t">Aiwaybill Information</div>

          <div className="w-full text-center">
            Scan AWB No:
            <input
              type="text"
              value={awbScanVal}
              className="bg-blue-200 rounded"
              onKeyDown={disableEnterKey}
              onInput={(e: any) => {
                let found = false;
                awbDataList.forEach((a) => {
                  console.log(a.awb_no == e.target.value, a.awb_no, e.target.value);
                  if (a.awb_no == e.target.value) {
                    found = true;
                  }
                });
                console.log(found, !acceptedAwbNos.includes(e.target.value));

                if (found && !acceptedAwbNos.includes(e.target.value)) {
                  let obj = [...acceptedAwbNos];
                  obj.push(e.target.value);
                  setAcceptedAwbNos(obj);
                  setAwbScanVal("");
                } else {
                  setAwbScanVal(e.target.value);
                }
              }}
            />
          </div>
          <div className="w-full relative overflow-x-auto">
            <table className="w-full text-sm text-left rtl:text-right text-black-900">
              <thead className="text-black-900 uppercase font-semibold bg-gray-50 border-y-2">
                <tr>
                  <td className="px-3 py-2">Check</td>
                  <td className="px-3 py-2">No</td>
                  <td className="px-3 py-2">Destination</td>
                  <td className="px-3 py-2">Weight</td>
                  <td className="px-3 py-2">Consignee</td>
                  <td className="px-3 py-2">Contact</td>
                </tr>
              </thead>
              <tbody>
                {awbDataList.map((e) => {
                  return (
                    <tr className={`border-b ${acceptedAwbNos.includes(e.awb_no) ? "bg-green-300" : "bg-white"}`}>
                      <td className="px-3 py-2">{acceptedAwbNos.includes(e.awb_no) ? "YES" : "-"}</td>
                      <td className="px-3 py-2">{e.awb_no}</td>
                      <td className="px-3 py-2">{e.destination}</td>
                      <td className="px-3 py-2">{e.weight}</td>
                      <td className="px-3 py-2">{e.consignee}</td>
                      <td className="px-3 py-2">{e.contact_no}</td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        </div>
        {/* </div> */}
        <div className="flex w-full justify-end mt-5 pr-3">
          <Button
            type="submit"
            className="rounded-md inline-block px-4 py-2 text-sm font-medium text-white bg-blue-800 border border-gray-200 hover:bg-opacity-60 focus:z-10 "
          >
            Accept In-Scan
          </Button>
        </div>
      </form>
    </>
  );
};

export default MotherBagInScan;
