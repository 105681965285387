import { UseMutateFunction, useMutation, useQuery } from "@tanstack/react-query";
import { CreateCommanWay, GetCommanWay, ListCommanWay } from "API/commonApi";
import { AxiosResponse } from "axios";
import { Button, Img } from "components";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import Swal from "sweetalert2";
import UseToast from "utils/AfToast";
import { roles } from "utils/constant";

const TrackingAWB = () => {
  const BASE_URL = process.env.REACT_APP_BACKEND_URL;
  const navigator = useNavigate();

  const [awbNo, setAwbNo] = useState("");
  const [awbTrackingData, setAwbTrackingData] = useState<any>({
    tracking_data: [],
    bookingDate: "",
    awbData: {},
    bookingData: {},
  });
  const [searchParams, setSearchParams] = useSearchParams();
  const [deliveryDoneInfo, setDeliveryDoneInfo] = useState<any>({});
  const [productList, setProductList] = useState<any>([]);
  const [commentsList, setCommentsList] = useState<any>([]);

  const [showImg, setShowImg] = useState<any>(false);
  const [showVolume, setShowVolume] = useState<any>(false);

  const [canEditBook, setCanEditBook] = useState<any>(false);
  React.useEffect(() => {
    const userD = localStorage.getItem("auth") ? JSON.parse(localStorage.getItem("auth")) : {};
    if (Object.keys(userD).length) {
      if (userD.state.user.role_name == 1 || userD.state.user.role_name == 2 || userD.state.user.role_name == 3) {
        setCanEditBook(true);
      }
      if (userD.state.user.roleName == "CUSTOMER") {
        navigator("/");
      }
    }
  }, []);

  const { isFetching, isLoading, refetch } = useQuery(["admin_product_list"], () => ListCommanWay("/admin/product/list", {}, {}), {
    keepPreviousData: true,
    onSuccess(data) {
      if (data.status == "SUCCESS") {
        setProductList(data.data.data);
      }
    },
    onError: (data) => {},
  });

  const { refetch: commnetsRefetching } = useQuery(
    ["comments_list"],
    () =>
      ListCommanWay(
        "/admin/awbcomment/list",
        {
          awb_id: awbTrackingData?.awbData?.id,
        },
        { include: ["_addedBy"] }
      ),
    {
      keepPreviousData: true,
      enabled: false,
      onSuccess(data) {
        if (data.status == "SUCCESS") {
          setCommentsList(data.data.data);
        }
      },
      onError: (data) => {},
    }
  );

  useEffect(() => {
    if (awbTrackingData?.awbData?.id) {
      commnetsRefetching();
    }
  }, [awbTrackingData]);

  const {
    mutate,
    isLoading: isloading,
  }: {
    mutate: UseMutateFunction<AxiosResponse<any, any>, unknown, any, unknown>;
    isLoading: any;
  } = useMutation((awb_no) => GetCommanWay("/admin/proof_of_delivery/track/" + awb_no, {}, {}), {
    onSuccess: (data: any) => {
      console.log(data, data.status == "SUCCESS");
      if (data.status == "SUCCESS") {
        setAwbTrackingData(data.data);

        if (data.data.tracking_data) {
          try {
            data.data.tracking_data.forEach((t) => {
              if (t.message_code == "Shipment Delivered" || t.message_code == "0 - Shipment Delivered") {
                setDeliveryDoneInfo(t);
              }
            });
          } catch (e) {}
        }
      } else {
        UseToast(data.message, "error");
      }
    },
    onError: (data: any) => {
      typeof data === "string" ? UseToast(data, "error") : UseToast(data.message, "error");
    },
  });
  const handleSubmit = () => {
    setAwbTrackingData({
      tracking_data: [],
      bookingDate: "",
      awbData: {},
      bookingData: {},
    });
    mutate(awbNo);
  };

  useEffect(() => {
    if (searchParams.get("awb")) {
      setAwbNo(searchParams.get("awb"));
      setAwbTrackingData({
        tracking_data: [],
        bookingDate: "",
        awbData: {},
        bookingData: {},
      });
      mutate(searchParams.get("awb"));
    }
  }, [searchParams]);

  const getTRline = (name, value, isEven: any = false) => {
    return (
      <tr className={isEven ? "bg-blue-100" : ""}>
        <td className="text-right pr-5 whitespace-nowrap">{name}</td>
        <td>{value}</td>
      </tr>
    );
  };

  const getChargeableWeight = (weight, product_code, ll, bb, hh) => {
    ll = Number(ll);
    bb = Number(bb);
    hh = Number(hh);
    let dWeight = (ll * bb * hh) / (product_code == "X" ? 2700 : 5000);
    return Math.max(Number(weight), dWeight);
  };

  const {
    mutate: mutateComment,
    isLoading: isCommentloading,
  }: {
    mutate: UseMutateFunction<AxiosResponse<any, any>, unknown, any, unknown>;
    isLoading: any;
  } = useMutation((data) => CreateCommanWay(data), {
    onSuccess: (data: any) => {
      console.log(data, data.status == "SUCCESS");
      if (data.status == "SUCCESS") {
        window.location.reload();
      } else {
        UseToast(data.message, "error");
      }
    },
    onError: (data: any) => {
      typeof data === "string" ? UseToast(data, "error") : UseToast(data.message, "error");
    },
  });

  const handleClickComment = () => {
    Swal.fire({
      title: "Post Comment",
      text: "",
      input: "text",
      confirmButtonText: "Save",
      showCancelButton: true,
      inputPlaceholder: "Enter Comment",
      inputAttributes: {
        style: "border: 1px solid gray; border-radius: 8px",
      },
    }).then((x) => {
      if (x.isConfirmed) {
        console.log(x);
        mutateComment({
          url: "/admin/awbcomment/create",
          data: { comment: x.value, awb_id: awbTrackingData?.awbData?.id },
        });
      }
    });
  };

  return (
    <>
      {showVolume && (
        <div className="absolute left-0 top-0 w-full h-full bg-black-900 bg-opacity-20 flex items-center justify-center">
          <div className="rounded-xl border-2 border-primary p-4 bg-white relative">
            <div className="flex items-center justify-between bg-primary text-white p-2 rounded-lg">
              <p>Total Weight: {awbTrackingData?.awbData?.weight}</p>
              <button
                className="px-1"
                onClick={() => {
                  setShowVolume(false);
                }}
              >
                ✖
              </button>
            </div>

            <div>
              <table className="table mt-2">
                <tr>
                  <th className="px-2 border-r">Sr.</th>
                  <th className="px-2">Lenght</th>
                  <th className="px-2">Width</th>
                  <th className="px-2">Height</th>
                  <th className="px-2">Weight</th>
                </tr>
                {awbTrackingData?.awbData?.innerItemsJson && awbTrackingData?.awbData?.innerItemsJson != "[]" ? (
                  JSON.parse(awbTrackingData?.awbData?.innerItemsJson).map((i, a) => {
                    return (
                      <tr className="border-t">
                        <td className="px-4 border-r">{a + 1}</td>
                        <td className="px-4">{i.l}</td>
                        <td className="px-4">{i.b}</td>
                        <td className="px-4">{i.h}</td>
                        <td className="px-4">{i.weight}</td>
                      </tr>
                    );
                  })
                ) : (
                  <tr className="border-t">
                    <td className="px-4">1</td>
                    <td className="px-4">{awbTrackingData?.awbData?.l}</td>
                    <td className="px-4">{awbTrackingData?.awbData?.b}</td>
                    <td className="px-4">{awbTrackingData?.awbData?.h}</td>
                    <td className="px-4">{awbTrackingData?.awbData?.weight}</td>
                  </tr>
                )}
              </table>
            </div>
          </div>
        </div>
      )}
      <div className="flex flex-col gap-4 px-4 mt-2">
        <div className=" border p-2 rounded-2xl">
          <div className="flex gap-2">
            <div>
              <h1 className="text-center mb-4 mt-3 text-lg font-semibold underline whitespace-nowrap">Courier Details:</h1>

              <p>Airway Bill No:</p>
              <h1 className="text-blue-800 font-semibold bg-yellow-200 inline-block px-2 py-1 rounded-md">{awbNo}</h1>
              <hr className="my-2" />
              {awbTrackingData.awbData?.invoice_no_c_b && (
                <a
                  target="_blank"
                  href={`${process.env.REACT_APP_BACKEND_URL}/invoices/${awbTrackingData.awbData.subbranch}/${awbTrackingData.awbData._invoice_no_c_b?.invoice_no}.pdf`}
                  className="text-blue-A700 underline"
                >
                  Invoice View
                </a>
              )}

              {canEditBook && (
                <p
                  className="cursor-pointer text-primary2 underline"
                  onClick={() => {
                    navigator("/booking/add", {
                      state: {
                        mode: "edit",
                        data: awbTrackingData.bookingData,
                      },
                    });
                  }}
                >
                  Edit Booking
                </p>
              )}
            </div>
            <table>
              {getTRline("Book Date", moment(awbTrackingData.bookingData?.date, "DD-MM-YYYY HH:mm:ss").format("DD-MM-YYYY"), 1)}
              {getTRline(
                "Product",
                productList
                  .map((p) => {
                    return p.product_code == awbTrackingData.awbData.prefix ? p.product_name : "";
                  })
                  .join(""),
                0
              )}
              {getTRline("Reference_Code", awbTrackingData.bookingData.pickup_code, 1)}
              {getTRline("From", awbTrackingData.bookingData.shipper_city)}
              {getTRline("To", awbTrackingData.awbData.destination, 1)}
              {getTRline("Product Type", awbTrackingData.awbData.dox_or_non_dox ? "DOX" : "Non-Dox")}
              {getTRline("Client Name", awbTrackingData.bookingData.customer_sh + ` (${awbTrackingData.bookingData.customer_code})`, 1)}
              {getTRline("Shipper Name", awbTrackingData.bookingData.shipper_sh)}
              {getTRline("Shipper Addr", awbTrackingData.bookingData.shipper_address, 1)}
              {getTRline("Consignee Name", awbTrackingData.awbData.consignee)}
              {getTRline("Consignee Addr", awbTrackingData.awbData.address, 1)}
              {getTRline("Origin", awbTrackingData.bookingData.shipper_pincode)}
              {getTRline("Destination", awbTrackingData.awbData.pincode)}
            </table>

            <table>
              {getTRline("Receiver", deliveryDoneInfo.receiver)}
              {getTRline("Relation", deliveryDoneInfo.receiver_relation)}
              {getTRline("Delivery Date", deliveryDoneInfo.updatedAt || deliveryDoneInfo.createdAt )}
              {getTRline("Shipper Mobile", awbTrackingData.bookingData?.shipper_mobile)}
              {getTRline("Consignee Mobile", awbTrackingData.awbData?.consignee_mobile)}
              {getTRline("MAWB No", awbTrackingData?.awbData?._mother_bag_id?.mawb || "-")}
              {getTRline("Motherbag No", awbTrackingData?.awbData?._mother_bag_id?.mother_bag_code || "-")}
              {/*getTRline("TP ref. No.", awbTrackingData?.awbData?._mother_bag_id?.refNo || "-")*/}
              {getTRline("TP Name", <b>{awbTrackingData?.awbData?.thirdPartyName || "-"}</b>)}
              {getTRline("TP ref. No.", <b>{awbTrackingData?.awbData?.thirdPartyRef || "-"}</b>)}
              {getTRline("Delivery Emp.", deliveryDoneInfo._addedBy?.name)}
              {getTRline(<b>Current Status</b>, awbTrackingData.tracking_data[awbTrackingData.tracking_data.length - 1]?.message_code)}
              {awbTrackingData.tracking_data[awbTrackingData.tracking_data.length - 1]?.message_code.includes("RTO") &&
                getTRline(
                  <b>RTO Awb No.</b>,
                  (
                    <a style={{ color: "blue" }} href={`/pod/tracking?awb=${awbTrackingData.awbData?.returnAwb}`}>
                      {awbTrackingData.awbData?.returnAwb}
                    </a>
                  ) || "-"
                )}
            </table>
          </div>
          <div className="flex justify-between mt-2 border-t pt-2">
            <div className="flex gap-8">
              {awbTrackingData.awbData.realWeight && <p>
                Actual Weight: <b>{awbTrackingData.awbData.realWeight} kg</b>
              </p>}
              <p>
                Chargeable Weight:{" "}
                <b>
                  {getChargeableWeight(
                    awbTrackingData.awbData.weight,
                    awbTrackingData.awbData.prefix,
                    awbTrackingData.awbData.l,
                    awbTrackingData.awbData.b,
                    awbTrackingData.awbData.h
                  )}{" "}
                  kg
                </b>
              </p>
              <p>
                10_30: <b>{awbTrackingData.awbData.is10_30 ? "YES" : "NO"}</b>
              </p>
              <p>
                Holiday: <b>{awbTrackingData.awbData.holiday ? "YES" : "NO"}</b>
              </p>
            </div>

            <div>
              <Button
                className="bg-blue-600 px-2 py-1 rounded-md text-white mr-3"
                onClick={() => {
                  setShowVolume(true);
                }}
              >
                Show Volume Info
              </Button>

              <Button
                className="bg-blue-600 px-2 py-1 rounded-md text-white mr-3"
                onClick={() => {
                  setShowImg(!showImg);
                }}
              >
                Show Images
              </Button>

              <Button className="bg-secondary1 px-2 py-1 rounded-md text-white" onClick={handleClickComment}>
                Post Comment
              </Button>
            </div>
          </div>
        </div>

        {showImg && (
          <div className="flex gap-4">
            {awbTrackingData.tracking_data[awbTrackingData.tracking_data.length - 1]?.signature && (
              <Img className="h-48" src={BASE_URL + awbTrackingData.tracking_data[awbTrackingData.tracking_data.length - 1]?.signature} />
            )}
            {awbTrackingData.tracking_data[awbTrackingData.tracking_data.length - 1]?.photo && (
              <Img className="h-48" src={BASE_URL + awbTrackingData.tracking_data[awbTrackingData.tracking_data.length - 1]?.photo} />
            )}
          </div>
        )}

        {awbTrackingData.tracking_data.length ? (
          <div className="flex gap-2 flex-wrap border p-2 rounded-2xl pb-8">
            <div className="w-full font-bold underline ">Tracking Details</div>
            <table className={`flex-1 items-center w-auto justify-center`}>
              <tr className="border-y-2 text-left text-blue-800">
                <th>Date time</th>
                <th>Status</th>
                <th>Location</th>
                <th>Employee</th>
                <th>Device</th>
              </tr>
              {awbTrackingData.tracking_data.length &&
                [
                  /*{
                    id: 0,
                    message_code: "Shipment Booked",
                    createdAt: awbTrackingData.bookingDate,
                  },*/
                  ...awbTrackingData.tracking_data,
                ]
                  .sort((a, b) => a.id - b.id)
                  .map((d, i) => (
                    <>
                      <tr className="" key={`track-data${d.id}`}>
                        <td>
                          {moment(d.updatedAt).format("DD-MM-YYYY") == "Invalid date"
                            ? d.updatedAt
                            : moment(d.updatedAt, "DD-MM-YYYY HH:mm").format("DD-MM-YYYY HH:mm")}
                        </td>
                        <td>{d.message_code}</td>
                        <td>{d.subbranch?._pincode?._city?.city_name}</td>
                        <td>{d._addedBy ? d._addedBy.name : "-"}</td>
                        <td>{d.isMobile ? "Mobile" : "Web"}</td>
                      </tr>
                    </>
                  ))}
            </table>
          </div>
        ) : null}

        {commentsList.length ? (
          <div className="flex gap-2 flex-wrap border p-2 rounded-2xl pb-8">
            <div className="w-full font-bold underline ">Comments</div>
            <table className={`flex-1 items-center w-auto justify-center`}>
              <tr className="border-y-2 text-left text-blue-800">
                <th>Date</th>
                <th>Time</th>
                <th>comments</th>
                <th>Employee</th>
              </tr>
              {commentsList.map((d, i) => (
                <>
                  <tr className="" key={`track-data${d.id}`}>
                    <td>{moment(d.createdAt, "DD-MM-YYYY HH:mm:ss").format("DD-MM-YYYY")}</td>
                    <td>{moment(d.createdAt, "DD-MM-YYYY HH:mm:ss").format("HH:mm")}</td>
                    <td>{d.comment}</td>
                    <td>{d._addedBy ? d._addedBy.name : "-"}</td>
                  </tr>
                </>
              ))}
            </table>
          </div>
        ) : null}
      </div>
    </>
  );
};

export default TrackingAWB;
