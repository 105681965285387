import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { AfDataType } from "utils/TableColumnData";
import Select from "react-select";
import handleWheel from "utils/handleOnWheel";
import { ErrorMessage } from "components/ErrorMessage";
import { UseMutateFunction, useMutation } from "@tanstack/react-query";
import { GetCommanWay } from "API/commonApi";
import { AxiosResponse } from "axios";
import Swal from "sweetalert2";

export type AfTableProps = Partial<{
  selectedAwb: any;
  isInnerItemsOpen: any;
  tableData: any[];
  setIsInnerItemsOpen: any;
  setTableData: any;
  product: string;
}>;

const InnerItems: React.FC<AfTableProps> = ({
  tableData = [],
  selectedAwb = 0,
  isInnerItemsOpen,
  setIsInnerItemsOpen,
  setTableData,
  product,
}) => {
  //const [tableData_, settableData_] = useState<any[]>(tableData);

  useEffect(() => {
    setTableData(tableData);
  }, [tableData]);

  const onInputchange = (e, i, key) => {
    let _tableData_ = JSON.parse(JSON.stringify(tableData));
    _tableData_[selectedAwb].piece[i][key] = e.target.value;
    setTableData(_tableData_);
    console.log(_tableData_);
  };

  const addNewLine = () => {
    let _tableData_ = JSON.parse(JSON.stringify(tableData));
    console.log(_tableData_);
    _tableData_[selectedAwb].piece.push({
      l: 0,
      b: 0,
      h: 0,
      weight: 0,
    });
    setTableData(_tableData_);
  };

  const removeLine = (i) => {
    let _tableData_ = JSON.parse(JSON.stringify(tableData));
    _tableData_[selectedAwb].piece.splice(i, 1);
    setTableData(_tableData_);
  };

  return (
    <div className="absolute top-0 left-0 w-full h-full bg-white bg-opacity-80 z-10 flex justify-center items-center">
      <div className="bg-white border border-blue-300 p-3 rounded-md shadow-lg">
        <table>
          <tr className="border-b border-black-900">
            <td>
              <button
                className="rounded-md w-8 h-8 text-sm font-medium text-white bg-green-500 border border-gray-200 hover:bg-gray-100 hover:text-blue-700"
                type="button"
                onClick={addNewLine}
              >
                +
              </button>
            </td>
            <td>Sr.</td>
            <td>L</td>
            <td>B</td>
            <td>H</td>
            <td>Weight</td>
            <td>Chargable Weight</td>
          </tr>

          {tableData &&
            tableData[selectedAwb]?.piece?.map((a, i) => {
              return (
                <tr>
                  <td>
                    <button
                      className="rounded-md w-8 h-8 text-sm font-medium text-white bg-red-500 border border-gray-200 hover:bg-gray-100 hover:text-blue-700"
                      type="button"
                      onClick={() => {
                        removeLine(i);
                      }}
                    >
                      -
                    </button>
                  </td>
                  <td>
                    <p>{i + 1}</p>
                  </td>
                  <td>
                    <input
                      type="text"
                      value={a.l}
                      onChange={(e) => {
                        onInputchange(e, i, "l");
                      }}
                      className="w-16 focus:border-black-900 p-1 rounded-md"
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      value={a.b}
                      onChange={(e) => {
                        onInputchange(e, i, "b");
                      }}
                      className="w-16 focus:border-black-900 p-1 rounded-md"
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      value={a.h}
                      onChange={(e) => {
                        onInputchange(e, i, "h");
                      }}
                      className="w-16 focus:border-black-900 p-1 rounded-md"
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      value={a.weight}
                      onChange={(e) => {
                        onInputchange(e, i, "weight");
                      }}
                      className="w-16 focus:border-black-900 p-1 rounded-md"
                    />
                  </td>

                  <td className="text-right">
                    <span className="font-medium">
                      {Math.max(
                        Number(a.weight),
                        (a.l * a.b * a.h) / (tableData[selectedAwb].awb_no[0]?.toUpperCase() == "X" ? 2700 : 5000)
                      )}
                    </span>
                  </td>
                </tr>
              );
            })}
        </table>
        <div className="text-right">
          <button
            className="rounded-md py-2 px-3 mt-3 text-sm font-medium text-white bg-blue-800 border border-gray-200 hover:bg-gray-100 hover:text-blue-700 ml-2"
            type="button"
            onClick={() => {
              setIsInnerItemsOpen(false);
              console.log(tableData);
            }}
          >
            Close
          </button>
        </div>
      </div>
    </div>
  );
};

export { InnerItems };
