import React, { useCallback, useEffect, useState } from "react";
import {
  UseMutateFunction,
  useMutation,
  useQuery,
} from "@tanstack/react-query";
import { CreateCommanWay, GetCommanWay, ListCommanWay } from "API/commonApi";
import { AfDataType as ADT } from "utils/TableColumnData";
import { useFormik } from "formik";
import { AxiosResponse } from "axios";
import UseToast from "utils/AfToast";
import { debounce } from "lodash";
import { useNavigate, useParams } from "react-router-dom";
import Select from "react-select";
import { constantValues } from "utils/constant";

export type AfTableProps = Partial<{
  className: string;
  columnData: any[];
  selectable: boolean;
  doAdd: boolean;
  doEdit: boolean;
  doDelete: boolean;
  doExport: boolean;
  paginate: boolean;
  addBtnUrl: string;
  validationSchema: any;
  initialValues: any;
  apiUrl: string;
  routeUrl: string;
  successNav: string;
  cancelNav: string;
  mode: string;
  setIsVisible: any;
  fetchRateList: any;
  cellMode: string;
  setCellMode: any;
  formData: any;
  setFormData: any;
  saveData: any;
  deleteData: any;
  newTableData: any;
  setNewTableData: any;
  isAnyChange: boolean;
  setIsAnyChange: any;
}>;

const RateSearch: React.FC<AfTableProps> = ({
  validationSchema,
  initialValues = {
    country: 1
  },
  apiUrl = "",
  routeUrl = "",
  successNav = "/",
  cancelNav = "/",
  fetchRateList,
  cellMode,
  setCellMode,
  formData,
  setFormData,
  saveData,
  deleteData,
  newTableData,
  setNewTableData,
  isAnyChange,
  setIsAnyChange,
}) => {
  const navigate = useNavigate();
  const columnData = [
    {
      label: "Region Name",
      key: "region",
      type: ADT.DB,
      db: "region",
      db_col: "region_name",
    },
    {
      label: "State Name",
      key: "state",
      type: ADT.DB,
      db: "state",
      db_col: "state_name",
    },
    {
      label: "City Name",
      key: "city",
      type: ADT.DB,
      db: "city",
      db_col: "city_name",
    },
    {
      label: "Customer Name",
      key: "customer",
      type: ADT.DB,
      db: "customer",
      db_col: "code",
    },
    {
      label: "Bound",
      key: "bound",
      type: ADT.SELECT,
      options: [
        { value: null, label: "-- ALL --" },
        { value: "IN", label: "IN" },
        { value: "OUT", label: "OUT" },
      ],
    },
    {
      label: "Product",
      key: "product",
      type: ADT.DB,
      db: "product",
      db_col: "product_name",
    },
  ];
  type OptionType = {
    value: number;
    label: string;
  };
  const defaultOptions: any = [{ value: 0, label: "Loading..." }];
  const [nestedTables, setNestedTables] = useState<any>({});
  const [nestedSelectedOptions, setNestedSelectedOptions] = useState<any>({});

  const handleSave = () => {
    if (cellMode === "R") {
      let finalData = JSON.parse(JSON.stringify(newTableData));
      finalData["zone a"].forEach((x,i) => {
        if(x.from === undefined || x.to === undefined || x.rate === undefined){
          console.log(!x.from || !x.to || !x.rate, x.from ,x.to, x.rate, "zone a", i)
          delete finalData["zone a"][i];
        }
      })
      finalData["zone b"].forEach((x,i) => {
        if(x.from === undefined || x.to === undefined || x.rate === undefined){
          console.log(!x.from || !x.to || !x.rate, x.from ,x.to, x.rate, "zone b", i)
          delete finalData["zone b"][i];
        }
      })
      console.log("Asd", finalData, newTableData);
      const promises = Object.keys(finalData).map((zoneName) => {
        return finalData[zoneName].map(async (data) => {
          if (typeof data.id !== "string"){
            await deleteData({
              url: apiUrl + "/delete/" + data.id,
            });
          }
          const newData = data;
          delete newData.id;
          constantValues.defaultFields.forEach((d) => delete newData[d]);
          !newData.city && delete newData.city;
          !newData.region && delete newData.region;
          !newData.state && delete newData.state;
          !newData.pincode && delete newData.pincode;
          return await saveData({ url: apiUrl + "/create", data: newData });
        });
      });
      Promise.all(promises).then((data) => {
        fetchRateList({
          url: apiUrl + "/product",
          data: formData,
        });
      });
    }
    setCellMode((prevData) => (prevData === "N" ? "R" : "N"));
  };

  useEffect(() => {
    let proms = [];

    let nestedApiData = {};
    columnData.forEach((cd) => {
      if (cd.type == ADT.DB) {
        proms.push(
          new Promise((res, rej) => {
            ListCommanWay(`/admin/${cd.db}/list`, {}, { paginate: 1000 })
              .then((x) => {
                let data: OptionType[] = [];
                if (x.status == "SUCCESS") {
                  if (cd.db == "customer") {
                    x.data.data = [
                      {
                        id: "",
                        product_name: "--- CASH ---",
                        code: "--- CASH ---",
                      },
                      ...x.data.data,
                    ];
                  }
                  data = x.data.data.map((d) => {
                    return { value: d.id, label: d[cd.db_col] };
                  });
                  nestedApiData[cd.db] = data;
                }
                res(1);
              })
              .catch((c) => {
                res(0);
              });
          })
        );
      }
    });

    Promise.all(proms).then((x) => {
      setNestedTables(nestedApiData);
    });
  }, []);

  const {
    handleSubmit,
    handleBlur,
    values,
    handleChange,
    errors,
    setValues,
    setErrors,
    touched,
    dirty,
    setFieldValue,
  } = useFormik({
    validationSchema: validationSchema,
    initialValues: initialValues,
    onSubmit: async (values, { resetForm }) => {
      if (values?.customer == "") {
        delete values?.customer;
      }
      fetchRateList({
        url: apiUrl + "/product",
        data: values,
      });
      setFormData(values);
    },
  });

  const successNavigate = useCallback(
    debounce(() => navigate(successNav), 200),
    []
  );

  const onCancel = () => {
    navigate(cancelNav);
  };

  return (
    <div className="p-5">
      <form
        onSubmit={(e) => {
          e.preventDefault();
          handleSubmit(e);
        }}
      >
        <div className="grid grid-cols-3 gap-4">
          {columnData.map((c) => {
            switch (c.type) {
              case ADT.NUMBER:
                return (
                  <div key={"general_" + c.key}>
                    <label
                      htmlFor={"general_" + c.key}
                      className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                    >
                      {c.label}
                    </label>
                    <input
                      type="number"
                      id={"general_" + c.key}
                      className={` bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-1.5`}
                      placeholder={c.label}
                      onBlur={handleBlur}
                      onChange={handleChange}
                      name={c.key}
                      value={values[c.key]}
                    />

                    <p className="mt-21 text-sm text-red-600">
                      {touched[c.key] && Boolean(errors[c.key])
                        ? errors[c.key]
                        : ""}
                    </p>
                  </div>
                );
                break;
              case ADT.TEXT:
                return (
                  <div key={"general_" + c.key}>
                    <label
                      htmlFor={"general_" + c.key}
                      className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                    >
                      {c.label}
                    </label>
                    <input
                      type="text"
                      id={"general_" + c.key}
                      className={`bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-1.5`}
                      placeholder={c.label}
                      onBlur={handleBlur}
                      onChange={handleChange}
                      name={c.key}
                      value={values[c.key]}
                    />

                    <p className="mt-21 text-sm text-red-600">
                      {touched[c.key] && Boolean(errors[c.key])
                        ? errors[c.key]
                        : ""}
                    </p>
                  </div>
                );
                break;
              case ADT.DB:
                return (
                  <div key={"general_" + c.key}>
                    <label
                      htmlFor={"general_" + c.key}
                      className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                    >
                      {c.label}
                    </label>

                    <Select
                      key={
                        "select-" +
                        (nestedTables[c.db]
                          ? nestedTables[c.db].length
                          : "undef")
                      }
                      className={`bg-gray-50 border-gray-300 text-gray-900 text-sm rounded-lg block w-full`}
                      id={"general_" + c.key}
                      options={
                        nestedTables[c.db] ? nestedTables[c.db] : defaultOptions
                      }
                      onChange={(el) => {
                        setFieldValue(c.key, el.value);
                      }}
                      name={c.key}
                      value={nestedSelectedOptions[c.db]}
                    />

                    <p className="mt-21 text-sm text-red-600">
                      {touched[c.key] && Boolean(errors[c.key])
                        ? errors[c.key]
                        : ""}
                    </p>
                  </div>
                );
                break;
              case ADT.SELECT:
                return (
                  <div key={"general_" + c.key}>
                    <label
                      htmlFor={"general_" + c.key}
                      className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                    >
                      {c.label}
                    </label>

                    <Select
                      key={"select-" + c.key}
                      className={`bg-gray-50 border-gray-300 text-gray-900 text-sm rounded-lg block w-full`}
                      id={"general_" + c.key}
                      options={c.options}
                      onChange={(el) => {
                        setFieldValue(c.key, el.value);
                      }}
                      name={c.key}
                      //   value={values[c.key]}
                      value={nestedSelectedOptions[c.db]}
                    />

                    <p className="mt-21 text-sm text-red-600">
                      {touched[c.key] && Boolean(errors[c.key])
                        ? errors[c.key]
                        : ""}
                    </p>
                  </div>
                );
                break;
              case ADT.TEXTAREA:
                break;
              case ADT.DATETIME:
                break;
              case ADT.FILE:
                break;
              default:
                return <></>;
                break;
            }
          })}
        </div>

        <div className="flex justify-between">
          <div
            className="inline-flex rounded-md overflow-hidden shadow-sm mb-1 mt-4"
            role="group"
          >
            <button
              type="submit"
              className="px-4 py-2 text-sm font-medium text-white bg-green-500 border border-gray-200 hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-2 focus:ring-blue-700 focus:text-blue-700"
            >
              Get
            </button>
            <button
              type="button"
              onClick={handleSave}
              className="px-4 py-2 text-sm font-medium text-white bg-sky-500 border border-gray-200 hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-2 focus:ring-blue-700 focus:text-blue-700"
            >
              {cellMode === "N" ? "Add / Update" : "Save"}
            </button>
          </div>
        </div>
      </form>
    </div>
  );
};

export default RateSearch;
