import React from "react";
import { DataTable } from "components/DataTable/DataTable";
import { GenericColumnData } from "utils/TableColumnData";

const GenericTableList: React.FC<any> = (props) => {
  const CustomComponent =
    GenericColumnData[props.tableName]?.customComponent || null;

  return (
    <>
      {CustomComponent ? (
        <CustomComponent
          {...props}
          columnData={GenericColumnData[props.tableName]?.tableColumnData}
        />
      ) : (
        <DataTable
          apiUrl={`/admin/${props.tableName}/list`}
          routeUrl={`/master/${props.tableName}`}
          columnData={GenericColumnData[props.tableName]?.tableColumnData}
          selectable={true}
          doAdd={true}
          doEdit={true}
          doDelete={true}
          doExport={true}
          paginate={true}
        />
      )}
    </>
  );
};

export default GenericTableList;
