import React, { useEffect, useState } from "react";
import { RateDataTables } from "components/DataTable/RateDataTables";
import { UseMutateFunction, useMutation, useQuery } from "@tanstack/react-query";
import { CreateCommanWay, DeleteCommanWay, ListCommanWay } from "API/commonApi";
import RateSearch from "./RateSearch";
import { AxiosResponse } from "axios";
import UseToast from "utils/AfToast";
import { useFormik } from "formik";

interface RateListProps {
  tableName: string;
  columnData: any;
  children?: React.ReactNode;
}
const RateList: React.FC<RateListProps> = (props) => {
  const [tableData, setTableData] = useState<any>([]);
  const [newTableData, setNewTableData] = useState<any>({});
  const [isVisible, setIsVisible] = useState<any>(false);
  const [isAnyChange, setIsAnyChange] = useState<boolean>(false);
  const [msg, setMsg] = useState<any>("");
  const [updateFormData, setUpdateFormData] = useState<any>({
    f_charge: null,
    chc_charge: null,
    ab_charge: null,
  });
  const [cellMode, setCellMode] = useState("N");
  const [formData, setFormData] = useState({});
  const apiUrl = `/admin/${props.tableName}`;
  const routeUrl = `/master/${props.tableName}`;
  const columnData = props.columnData;
  const zonesArr = ["zone a", "zone b"];

  let incAry = [];
  columnData.forEach((c) => {
    if (c.db_col) {
      incAry.push("_" + c.db_col);
    }
  });

  const {
    mutate,
    isLoading: isloading,
  }: {
    mutate: UseMutateFunction<AxiosResponse<any, any>, unknown, any, unknown>;
    isLoading: any;
  } = useMutation(CreateCommanWay, {
    onSuccess: (data: any) => {
      if (data.status == "SUCCESS") {
        const fetchData = data.data || [];
        if (!fetchData.length) {
          const arrayOfArrays = [
            ...zonesArr.map((zoneName) => {
              return [
                {
                  id: Math.round(Math.random() * Date.now()).toString(),
                  zone: zoneName,
                  is_dox: true,
                  ...formData,
                },
                {
                  id: Math.round(Math.random() * Date.now()).toString(),
                  zone: zoneName,
                  is_dox: false,
                  ...formData,
                },
              ];
            }),
          ];
          for (let i = 0; i < arrayOfArrays.length; i++) {
            const innerArray = arrayOfArrays[i];
            for (let j = 0; j < innerArray.length; j++) {
              const object = innerArray[j];
              fetchData.push(object);
            }
          }
        }

        setTableData(fetchData);
        setIsVisible(true);
        zonesArr.forEach((zoneName) => {
          setNewTableData((prevState) => {
            console.log(fetchData);
            let filteredData = fetchData.filter((element) => element.zone.toLowerCase() === zoneName);
            let newData = filteredData.length
              ? filteredData
              : [
                  { id: Math.round(Math.random() * Date.now()).toString(), zone: zoneName, is_dox: true, ...formData },
                  { id: Math.round(Math.random() * Date.now()).toString(), zone: zoneName, is_dox: false, ...formData },
                ];

            let hasDox = false;
            let hasNonDox = false;
            newData.forEach((a) => {
              if (a.is_dox == true) {
                hasDox = true;
              }
              if (a.is_dox == false) {
                hasNonDox = true;
              }
            });
            if (!hasDox) {
              newData.push({ id: Math.round(Math.random() * Date.now()).toString(), zone: zoneName, is_dox: true, ...formData });
            }
            if (!hasNonDox) {
              newData.push({ id: Math.round(Math.random() * Date.now()).toString(), zone: zoneName, is_dox: false, ...formData });
            }

            newData.sort((a, b) => a.from - b.from);
            prevState[zoneName] = newData;
            return prevState;
          });
        });
      } else {
        UseToast(data.message, "error");
      }
    },
    onError: (data: any) => {
      typeof data === "string" ? UseToast(data, "error") : UseToast(data.message, "error");
    },
  });

  const {
    mutate: saveData,
    isLoading: isloadingData,
  }: {
    mutate: UseMutateFunction<AxiosResponse<any, any>, unknown, any, unknown>;
    isLoading: any;
  } = useMutation(CreateCommanWay, {
    onSuccess: (data: any) => {
      if (data.status == "SUCCESS") {
        UseToast("Data Added Successfully", "success");
        setIsVisible(false);
      } else {
        UseToast(data.message, "error");
      }
    },
    onError: (data: any) => {
      typeof data === "string" ? UseToast(data, "error") : UseToast(data.message, "error");
    },
  });

  const {
    mutate: deleteData,
    isLoading: isloadingDeletedData,
  }: {
    mutate: UseMutateFunction<AxiosResponse<any, any>, unknown, any, unknown>;
    isLoading: any;
  } = useMutation(DeleteCommanWay, {
    onSuccess: (data: any) => {
      if (data.status == "SUCCESS") {
        UseToast("Data deleted Successfully", "success");
      } else {
        UseToast(data.message, "error");
      }
    },
    onError: (data: any) => {
      typeof data === "string" ? UseToast(data, "error") : UseToast(data.message, "error");
    },
  });

  const handleChange = (e) => {
    setUpdateFormData((prevData) => ({
      ...prevData,
      [e.target.name]: e.target.value,
    }));
  };

  return (
    <>
      <RateSearch
        apiUrl={apiUrl}
        routeUrl={routeUrl}
        setIsVisible={setIsVisible}
        fetchRateList={mutate}
        setCellMode={setCellMode}
        cellMode={cellMode}
        formData={formData}
        setFormData={setFormData}
        saveData={saveData}
        deleteData={deleteData}
        newTableData={newTableData}
        setNewTableData={setNewTableData}
      />
      {isVisible && Object.keys(newTableData)?.length ? (
        <div className="flex flex-col mt-6">
          <div className="flex gap-2 self-end mt-[-95px]">
            <div key={"general_" + "f_charge"}>
              <label htmlFor={"general_" + "f_charge"} className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
                Fuel Charge(in %)
              </label>
              <div className="flex gap-1">
                <input
                  type="number"
                  id={"general_" + "f_charge"}
                  className={` bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5`}
                  placeholder={"Fuel Charge(in %)"}
                  onBlur={handleChange}
                  onChange={handleChange}
                  name={"f_charge"}
                  value={updateFormData["f_charge"]}
                />

                <button
                  type="button"
                  onClick={() => {
                    if (updateFormData["f_charge"] === 0 || updateFormData["f_charge"] > 0)
                      Object.keys(newTableData).map((zoneName) => {
                        setNewTableData((prevData) => ({
                          ...prevData,
                          [zoneName]: prevData[zoneName].map((d) => ({
                            ...d,
                            f_charge: updateFormData["f_charge"],
                          })),
                        }));
                      });
                  }}
                  className="px-4 py-2 flex-1 rounded-sm text-sm font-medium text-white bg-green-500 border border-gray-200 hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-2 focus:ring-blue-700 focus:text-blue-700"
                >
                  Update
                </button>
              </div>
            </div>
            <div key={"general_" + "chc_charge"}>
              <label htmlFor={"general_" + "chc_charge"} className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
                CHC Charge(in %)
              </label>
              <div className="flex gap-1">
                <input
                  type="number"
                  id={"general_" + "chc_charge"}
                  className={` bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5`}
                  placeholder={"CHC Charge(in %)"}
                  onBlur={handleChange}
                  onChange={handleChange}
                  name={"chc_charge"}
                  value={updateFormData["chc_charge"]}
                />

                <button
                  type="button"
                  onClick={() => {
                    if (updateFormData["chc_charge"] === 0 || updateFormData["chc_charge"] > 0)
                      Object.keys(newTableData).map((zoneName) => {
                        setNewTableData((prevData) => ({
                          ...prevData,
                          [zoneName]: prevData[zoneName].map((d) => ({
                            ...d,
                            chc_charge: updateFormData["chc_charge"],
                          })),
                        }));
                      });
                  }}
                  className="px-4 py-2 text-sm rounded-sm font-medium text-white bg-green-500 border border-gray-200 hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-2 focus:ring-blue-700 focus:text-blue-700"
                >
                  Update
                </button>
              </div>
            </div>
            <div key={"general_" + "ab_charge"}>
              <label htmlFor={"general_" + "ab_charge"} className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
                AB Charge(in %)
              </label>
              <div className="flex gap-1">
                <input
                  type="number"
                  id={"general_" + "ab_charge"}
                  className={` bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5`}
                  placeholder={"AB Charge(in %)"}
                  onBlur={handleChange}
                  onChange={handleChange}
                  name={"ab_charge"}
                  value={updateFormData["ab_charge"]}
                />

                <button
                  type="button"
                  onClick={() => {
                    if (updateFormData["ab_charge"] === 0 || updateFormData["ab_charge"] > 0)
                      Object.keys(newTableData).map((zoneName) => {
                        setNewTableData((prevData) => ({
                          ...prevData,
                          [zoneName]: prevData[zoneName].map((d) => ({
                            ...d,
                            ab_charge: updateFormData["ab_charge"],
                          })),
                        }));
                      });
                  }}
                  className="px-4 py-2 text-sm rounded-sm font-medium text-white bg-green-500 border border-gray-200 hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-2 focus:ring-blue-700 focus:text-blue-700"
                >
                  Update
                </button>
              </div>
            </div>
          </div>
          {Object.keys(newTableData)?.map((zoneName) => (
            <div className="flex" key={zoneName}>
              <div className="flex flex-col flex-1 overflow-auto p-5">
                <h3 className="mb-3">{zoneName.toUpperCase()} Dox</h3>
                <RateDataTables
                  routeUrl={`/master/${props.tableName}`}
                  columnData={columnData}
                  tableData={newTableData[zoneName].filter((data) => data.is_dox === true)}
                  newTableData={newTableData}
                  setNewTableData={setNewTableData}
                  selectable={true}
                  customClasses="flex-1"
                  cellMode={cellMode}
                  setCellMode={setCellMode}
                  formData={formData}
                />
              </div>
              <div className="flex flex-col flex-1 overflow-auto p-5 ml-3">
                <h3 className="mb-3">{zoneName.toUpperCase()} Non-Dox</h3>
                <RateDataTables
                  routeUrl={`/master/${props.tableName}`}
                  columnData={columnData}
                  tableData={newTableData[zoneName].filter((data) => data.is_dox === false)}
                  newTableData={newTableData}
                  setNewTableData={setNewTableData}
                  selectable={true}
                  customClasses="flex-1"
                  cellMode={cellMode}
                  setCellMode={setCellMode}
                  formData={formData}
                />
              </div>
            </div>
          ))}
        </div>
      ) : (
        msg && <h2 className="mt-3 ml-4 text-lg font-bold">{msg}</h2>
      )}
    </>
  );
};

export default RateList;
