import React, { useCallback, useEffect, useState } from "react";
import {
  UseMutateFunction,
  useMutation,
  useQuery,
} from "@tanstack/react-query";
import { CreateCommanWay, GetCommanWay, ListCommanWay } from "API/commonApi";
import { AfDataType as ADT } from "utils/TableColumnData";
import { useFormik } from "formik";
import { AxiosResponse } from "axios";
import UseToast from "utils/AfToast";
import { debounce } from "lodash";
import { useNavigate, useParams } from "react-router-dom";
import Select from "react-select";
import { constantValues } from "utils/constant";
import { number, object, string } from "yup";
import handleWheel from "utils/handleOnWheel";
import { useAuthStore } from "store/auth";

export type AfTableProps = Partial<{}>;

const RateCalculator: React.FC<AfTableProps> = ({}) => {
  const navigate = useNavigate();
  const authData = useAuthStore();
  const columnData = [
    {
      label: "Weight",
      key: "weight",
      type: ADT.NUMBER,
    },
    {
      label: "Product",
      key: "product",
      type: ADT.DB,
      db: "product",
      db_col: "product_name",
    },
    authData.user?.role_name == 1 ? {
      label: "Customer Name",
      key: "customer",
      type: ADT.DB,
      db: "customer",
      db_col: "code",
    }:{}, 
    /*{
      label: "Customer Name",
      key: "customer",
      type: ADT.DB,
      db: "customer",
      db_col: "code",
    },*/
    {
      label: "Pincode",
      key: "pincode",
      type: ADT.TEXT,
    },
    {
      label: "10:30",
      key: "is10_30",
      type: ADT.SELECT,
      options: [
        { value: true, label: "YES" },
        { value: false, label: "NO" },
      ],
    },
    {
      label: "Dox/Non Dox",
      key: "dox_or_non_dox",
      type: ADT.SELECT,
      options: [
        { value: true, label: "DOX" },
        { value: false, label: "NON DOX" },
      ],
    },
    {
      label: "Holiday",
      key: "holiday",
      type: ADT.SELECT,
      options: [
        { value: true, label: "YES" },
        { value: false, label: "NO" },
      ],
    },
    {
      label: "Bound",
      key: "bound",
      type: ADT.SELECT,
      options: [
        { value: null, label: "-- ALL --" },
        { value: "IN", label: "IN" },
        { value: "OUT", label: "OUT" },
      ],
    },
  ];
  type OptionType = {
    value: number;
    label: string;
  };
  const defaultOptions: any = [{ value: 0, label: "Loading..." }];
  const [nestedTables, setNestedTables] = useState<any>({});
  const [nestedSelectedOptions, setNestedSelectedOptions] = useState<any>({});
  const [rate, setRate] = useState(0);
  const [rateInfo, setRateInfo] = useState<any>({});

  useEffect(() => {
    let proms = [];

    let nestedApiData = {};
    columnData.forEach((cd) => {
      if (cd.type == ADT.DB) {
        proms.push(
          new Promise((res, rej) => {
            ListCommanWay(`/admin/${cd.db}/list`, {}, { paginate: 1000 })
              .then((x) => {
                let data: OptionType[] = [];
                if (x.status == "SUCCESS") {
                  let ary = [...x.data.data];
                  if (cd.db == "customer") {
                    ary = [
                      {
                        id: "",
                        product_name: "--- ALL ---",
                        code: "--- ALL ---",
                      },
                      ...ary,
                    ];
                    console.log("customer new ary", ary);
                  }
                  data = ary.map((d) => {
                    return { value: d.id, label: d[cd.db_col] };
                  });
                  nestedApiData[cd.db] = data;
                }
                res(1);
              })
              .catch((c) => {
                res(0);
              });
          })
        );
      }
    });

    Promise.all(proms).then((x) => {
      setNestedTables(nestedApiData);
    });
  }, []);

  const {
    mutate,
    isLoading: isloading,
  }: {
    mutate: UseMutateFunction<AxiosResponse<any, any>, unknown, any, unknown>;
    isLoading: any;
  } = useMutation(CreateCommanWay, {
    onSuccess: (data: any) => {
      if (data.status == "SUCCESS") {
        const fetchData = data.data?.base_value;
        console.log("-------------->data.data", data.data);

        if (fetchData) {
          setRate(fetchData);
          setRateInfo(data.data?.info);
        }
      } else {
        UseToast(data.message, "error");
      }
    },
    onError: (data: any) => {
      typeof data === "string"
        ? UseToast(data, "error")
        : UseToast(data.message, "error");
    },
  });
  const apiUrl = `/admin/rate/calculate`;

  const {
    handleSubmit,
    handleBlur,
    values,
    handleChange,
    errors,
    setValues,
    setErrors,
    touched,
    dirty,
    setFieldValue,
  } = useFormik({
    validationSchema: object().shape({}),
    initialValues: {
      awb_no: "",
      l: "",
      b: "",
      h: "",
      weight: "",
      product: "",
      customer: "",
      pincode: "",
      is10_30: "",
      dox_or_non_dox: "",
      holiday: "",
    },
    onSubmit: async (values, { resetForm }) => {
      console.log("valuesssss", values);
      setRate(0);
      setRateInfo({});
      mutate({
        url: apiUrl,
        data: values,
      });
    },
  });

  return (
    <div className="p-5">
      <form
        className="flex flex-col"
        onSubmit={(e) => {
          e.preventDefault();
          handleSubmit(e);
        }}
      >
        <div className="grid grid-cols-3 gap-4">
          {columnData.map((c) => {
            switch (c.type) {
              case ADT.NUMBER:
                return (
                  <div key={"general_" + c.key}>
                    <label
                      htmlFor={"general_" + c.key}
                      className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                    >
                      {c.label}
                    </label>
                    <input
                      type="number"
                      id={"general_" + c.key}
                      className={` bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5`}
                      placeholder={c.label}
                      onBlur={handleBlur}
                      onChange={handleChange}
                      name={c.key}
                      value={values[c.key]}
                      onWheel={handleWheel}
                    />

                    <p className="mt-21 text-sm text-red-600">
                      {touched[c.key] && Boolean(errors[c.key])
                        ? errors[c.key]
                        : ""}
                    </p>
                  </div>
                );
                break;
              case ADT.TEXT:
                return (
                  <div key={"general_" + c.key}>
                    <label
                      htmlFor={"general_" + c.key}
                      className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                    >
                      {c.label}
                    </label>
                    <input
                      type="text"
                      id={"general_" + c.key}
                      className={`bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5`}
                      placeholder={c.label}
                      onBlur={handleBlur}
                      onChange={handleChange}
                      name={c.key}
                      value={values[c.key]}
                    />

                    <p className="mt-21 text-sm text-red-600">
                      {touched[c.key] && Boolean(errors[c.key])
                        ? errors[c.key]
                        : ""}
                    </p>
                  </div>
                );
                break;
              case ADT.DB:
                return (
                  <div key={"general_" + c.key}>
                    <label
                      htmlFor={"general_" + c.key}
                      className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                    >
                      {c.label}
                    </label>

                    <Select
                      key={
                        "select-" +
                        (nestedTables[c.db]
                          ? nestedTables[c.db].length
                          : "undef")
                      }
                      className={`bg-gray-50 border-gray-300 text-gray-900 text-sm rounded-lg block w-full`}
                      id={"general_" + c.key}
                      options={
                        nestedTables[c.db] ? nestedTables[c.db] : defaultOptions
                      }
                      onChange={(el) => {
                        setFieldValue(c.key, el.value);
                      }}
                      name={c.key}
                      value={nestedSelectedOptions[c.db]}
                    />

                    <p className="mt-21 text-sm text-red-600">
                      {touched[c.key] && Boolean(errors[c.key])
                        ? errors[c.key]
                        : ""}
                    </p>
                  </div>
                );
                break;
              case ADT.SELECT:
                return (
                  <div key={"general_" + c.key}>
                    <label
                      htmlFor={"general_" + c.key}
                      className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                    >
                      {c.label}
                    </label>

                    <Select
                      key={"select-" + c.key}
                      className={`bg-gray-50 border-gray-300 text-gray-900 text-sm rounded-lg block w-full`}
                      id={"general_" + c.key}
                      options={c?.options}
                      onChange={(el) => {
                        setFieldValue(c.key, el.value);
                      }}
                      name={c.key}
                      //   value={values[c.key]}
                      value={nestedSelectedOptions[c.db]}
                    />

                    <p className="mt-21 text-sm text-red-600">
                      {touched[c.key] && Boolean(errors[c.key])
                        ? errors[c.key]
                        : ""}
                    </p>
                  </div>
                );
                break;
              case ADT.TEXTAREA:
                break;
              case ADT.DATETIME:
                break;
              case ADT.FILE:
                break;
              default:
                return <></>;
                break;
            }
          })}
        </div>

        <button
          type="submit"
          className="px-4 py-2 text-sm self-end font-medium text-white bg-green-500 border border-gray-200 hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-2 focus:ring-blue-700 focus:text-blue-700"
        >
          Get
        </button>
        <div className="flex flex-col items-center gap-5 justify-center flex-1 w-full">
          <div className="w-60">
            <table>
              <tr>
                <td>Base Rate: </td>
                <td>{rate.toFixed(2)}</td>
              </tr>
              <tr>
                <td>Tax: </td>
                <td>{(rate * 0.18).toFixed(2)}</td>
              </tr>
              <tr>
                <td>Total: </td>
                <td>{(rate + rate * 0.18).toFixed(0)}</td>
              </tr>
            </table>
          </div>

          <div>
          <table hidden={!rateInfo.base_value} className="divide-y divide-gray-200">
              <tr>
                <td className="px-3 py-1 text-center text-xs font-medium text-gray-500 border-2">Base Rate</td>
                <td className="px-3 py-1 text-center text-xs font-medium text-gray-500 border-2">Additional</td>
                <td className="px-3 py-1 text-center text-xs font-medium text-gray-500 border-2">10_30</td>
                <td className="px-3 py-1 text-center text-xs font-medium text-gray-500 border-2">Additional 10_30</td>
                <td className="px-3 py-1 text-center text-xs font-medium text-gray-500 border-2">Holiday</td>
                <td className="px-3 py-1 text-center text-xs font-medium text-gray-500 border-2">AB Chrg</td>
                <td className="px-3 py-1 text-center text-xs font-medium text-gray-500 border-2">Fuel Chrg</td>
                <td className="px-3 py-1 text-center text-xs font-medium text-gray-500 border-2">Chc Chrg</td>
              </tr>
              <tr>
                <td className="px-3 py-1 text-center font-bold text-gray-800 border-2">{rateInfo?.rate}</td>
                <td className="px-3 py-1 text-center font-bold text-gray-800 border-2">{rateInfo?.addition_amount}</td>
                <td className="px-3 py-1 text-center font-bold text-gray-800 border-2">{rateInfo?.rate_1030_1230}</td>
                <td className="px-3 py-1 text-center font-bold text-gray-800 border-2">{rateInfo?.addition_1030}</td>
                <td className="px-3 py-1 text-center font-bold text-gray-800 border-2">{rateInfo?.sunday_rate}</td>
                <td className="px-3 py-1 text-center font-bold text-gray-800 border-2">{rateInfo?.ab_charge}</td>
                <td className="px-3 py-1 text-center font-bold text-gray-800 border-2">{rateInfo?.f_charge}</td>
                <td className="px-3 py-1 text-center font-bold text-gray-800 border-2">{rateInfo?.chc_charge}</td>
              </tr>
            </table>
          </div>
        </div>
      </form>
    </div>
  );
};

export default RateCalculator;
