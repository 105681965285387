import { MinusCircleIcon } from "@heroicons/react/24/outline";
import { UseMutateFunction, useMutation, useQuery } from "@tanstack/react-query";
import { CreateCommanWay, GetCommanWay, ListCommanWay, UpdateBookingCommanWay, UpdateCommanWay } from "API/commonApi";
import { AxiosResponse } from "axios";
import { Button, Input } from "components";
import { InputTable } from "components/DataTable/InputTable";
import { useFormik } from "formik";
import { debounce } from "lodash";
import moment from "moment";
import { any } from "prop-types";
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Select from "react-select";
import Swal from "sweetalert2";
import UseToast from "utils/AfToast";
import { roles } from "utils/constant";
import handleWheel from "utils/handleOnWheel";
import { AfDataType as ADT } from "utils/TableColumnData";
import { number, object, string } from "yup";

type OptionType = {
  value: number;
  label: string;
};

interface MotherBagState {
  mode?: string;
  data?: { [key: string]: any }; // Adjust the type based on your data structure
  // Add other properties if needed
}

interface ValuesType {
  destination?: { value: string; label: string };
}

const SendToThirdParty: React.FC = ({}) => {
  let isDisabled = false;
  const navigate = useNavigate();
  const { state } = useLocation();
  const { mode = "add", data = {} } = (state || {}) as MotherBagState; // Check for existence and type assertion

  const [awbScanVal, setAwbScanVal] = useState<any>("");
  const [acceptedAwbNos, setAcceptedAwbNos] = useState<any>([]);

  const [providerOpt, setProviderOpt] = useState<any>();
  const [thirdPartyRefNo, setThirdPartyRefNo] = useState<any>("");

  const [dataList, setDataList] = useState<any>([]);
  const { refetch: refetchData } = useQuery(["DRSAwbList"], () => ListCommanWay("/admin/drs_awb/list", {}, {}), {
    keepPreviousData: true,
    onSuccess(data) {
      if (data.status == "SUCCESS") {
        console.log(data.data);
        setDataList(data.data || []);
      }
    },
  });

  const onInputAWB = (no) => {};

  const { mutate: mutateAddThirdparty, isLoading: isUpdateAWBloading } = useMutation(
    (data) =>
      CreateCommanWay({
        url: "/admin/thirdparty/new",
        data: {
          awb_no: acceptedAwbNos,
          thirdPartyName: providerOpt?.label || "-",
          thirdPartyRef: thirdPartyRefNo,
        },
      }),
    {
      onSuccess: (data: any) => {
        console.log(data, data.status == "SUCCESS");
        if (data.status == "SUCCESS") {
          UseToast("Data Added Successfully", "success");
          console.log(data.data.code);
          //refetchData();
          window.location.reload();
        } else {
          UseToast(data.message, "error");
        }
      },
      onError: (data: any) => {
        typeof data === "string" ? UseToast(data, "error") : UseToast(data.message, "error");
      },
    }
  );

  const { handleSubmit, handleBlur, values, handleChange, errors, setValues, setErrors, touched, dirty, resetForm, setFieldValue } =
    useFormik({
      validationSchema: object().shape({}),
      initialValues: {
        subbranch: { value: 0, label: "" },
      },
      onSubmit: async (values, { resetForm }) => {
        console.log("values", values, providerOpt, thirdPartyRefNo);
        mutateAddThirdparty();
        return;
      },
    });

  const disableEnterKey = (event) => {
    if (event.key === "Enter" || event.key === "Tab") {
      event.preventDefault();
    }
  };

  return (
    <>
      <h1 className="text-center mb-4 mt-3 text-lg font-semibold underline">Send Shipment to ThirdParty</h1>

      <form
        onSubmit={(e) => {
          e.preventDefault();
          handleSubmit(e);
        }}
      >
        {/* <div className="grid grid-cols-2 sm:grid-cols-1 gap-4 px-4"> */}
        <div className="flex gap-2 flex-wrap border p-2">
          <div className="w-full text-center">
            Scan AWB No:{" "}
            <input
              type="text"
              value={awbScanVal}
              className="bg-blue-200 rounded"
              onKeyDown={disableEnterKey}
              onInput={(e: any) => {
                let found = false;
                dataList.forEach((a) => {
                  console.log(a.awb_no == e.target.value, a.awb_no, e.target.value);
                  if (a.awb_no == e.target.value) {
                    found = true;
                  }
                });
                console.log(found, !acceptedAwbNos.includes(e.target.value));

                if (found && !acceptedAwbNos.includes(e.target.value)) {
                  let obj = [...acceptedAwbNos];
                  obj.push(e.target.value);
                  setAcceptedAwbNos(obj);
                  setAwbScanVal("");
                } else {
                  setAwbScanVal(e.target.value);
                }
              }}
            />
          </div>
          <div className="w-full relative overflow-x-auto">
            <table className="w-full text-sm text-left rtl:text-right text-black-900">
              <thead className="text-black-900 uppercase font-semibold bg-gray-50 border-y-2">
                <tr>
                  <td className="px-3 py-2">Check</td>
                  <td className="px-3 py-2">Motherbag code</td>
                  <td className="px-3 py-2">Booking Date</td>
                  <td className="px-3 py-2">No</td>
                  <td className="px-3 py-2">Weight</td>
                  <td className="px-3 py-2">Consignee</td>
                  <td className="px-3 py-2">Destination</td>

                  <td className="px-3 py-2">Shipper</td>
                  <td className="px-3 py-2">Origin</td>
                </tr>
              </thead>
              <tbody>
                {dataList.map((e) => {
                  return (
                    <tr className={`border-b ${acceptedAwbNos.includes(e.awb_no) ? "bg-green-300" : "bg-white"}`}>
                      <td className="px-3 py-2">
                        {
                          <input
                            type="checkbox"
                            className="focus:border-black-900"
                            onChange={(ip) => {
                              console.log(ip.target.checked);
                              if (ip.target.checked) {
                                let obj = [...acceptedAwbNos];
                                obj.push(e.awb_no);
                                setAcceptedAwbNos(obj);
                                setAwbScanVal("");
                              } else {
                                let obj = [...acceptedAwbNos];
                                const index = obj.indexOf(e.awb_no);
                                if (index > -1) {
                                  obj.splice(index, 1);
                                }
                                setAcceptedAwbNos(obj);
                                setAwbScanVal("");
                              }
                            }}
                            checked={acceptedAwbNos.includes(e.awb_no)}
                          />
                        }
                      </td>
                      <td className="px-3 py-2">{e.mother_bag_code}</td>
                      <td className="px-3 py-2">{moment(e.bdate).format("DD-MM-YYYY")}</td>
                      <td className="px-3 py-2">{e.awb_no}</td>

                      <td className="px-3 py-2">{e.weight}</td>
                      <td className="px-3 py-2">{e.consignee}</td>
                      <td className="px-3 py-2">{e.destination}</td>
                      <td className="px-3 py-2">{e.shipper_sh}</td>
                      <td className="px-3 py-2">{e.shipper_city}</td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        </div>

        <div className="flex flex-col justify-center items-center gap-4 text-center mt-6">
          <div className={`inline-flex items-center gap-2`}>
            <label className="text-sm font-semibold text-gray-900 whitespace-nowrap">Select Third Party</label>
            <Select
              className={`${isDisabled ? "bg-gray-300" : ""} bg-gray-50 border-gray-300 text-gray-900 text-sm rounded-lg block w-52`}
              options={[
                { label: "OTHER", value: "OTHER" },
                { label: "UPS SAVER", value: "UPS SAVER" },
                { label: "DHL", value: "DHL" },
                { label: "FEDEX EXPORT", value: "FEDEX EXPORT" },
                { label: "ARAMEX EXPORT", value: "ARAMEX EXPORT" },
                { label: "Aramex UAE", value: "Aramex UAE" },
                { label: "BLUE DART", value: "BLUE DART" },
                { label: "MARUTI", value: "MARUTI" },
                
              ]}
              onBlur={handleBlur}
              onChange={(e) => {
                setProviderOpt(e);
              }}
              classNamePrefix="book_dd"
              name={"provider"}
              value={providerOpt}
              isDisabled={isDisabled}
              styles={{
                control: (base) => ({
                  ...base,
                  height: 30,
                  minHeight: 30,
                }),
                indicatorsContainer: (base) => ({
                  ...base,
                  "& .book_dd__indicator": {
                    padding: "2px",
                  },
                }),
                input: (base) => ({
                  ...base,
                  margin: 0,
                  padding: 0,
                }),
                valueContainer: (base) => ({
                  ...base,
                  paddingTop: 0,
                  paddingBottom: 0,
                }),
              }}
            />
          </div>

          <div className={`inline-flex items-center gap-2`}>
            <label className="text-sm font-semibold text-gray-900 whitespace-nowrap">Third Party AWB No.</label>
            <input
              type="text"
              className={`bg-gray-50 border  text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-1 w-52 ${
                isDisabled ? "bg-gray-300 border-red" : "border-gray-300"
              }`}
              placeholder={"Awb No"}
              name={"thirdPartyRefNo"}
              value={thirdPartyRefNo}
              onChange={(e) => {
                setThirdPartyRefNo(e.target.value);
              }}
              disabled={mode && (mode === "view" || mode === "edit") ? true : isDisabled}
            />
          </div>
        </div>
        {/* </div> */}
        <div className="flex w-full justify-end mt-5 pr-3">
          <Button
            type="submit"
            className="rounded-md inline-block px-4 py-2 text-sm font-medium text-white bg-blue-800 border border-gray-200 hover:bg-opacity-60 focus:z-10 "
          >
            Submit
          </Button>
        </div>
      </form>
    </>
  );
};

export default SendToThirdParty;
