import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { AfDataType } from "utils/TableColumnData";
import Select from "react-select";
import handleWheel from "utils/handleOnWheel";
import { ErrorMessage } from "components/ErrorMessage";
import { UseMutateFunction, useMutation } from "@tanstack/react-query";
import { GetCommanWay } from "API/commonApi";
import { AxiosResponse } from "axios";
import Swal from "sweetalert2";

export type AfTableProps = Partial<{
  selectedAwb: any;
  isExtraOpen: any;
  tableData: any[];
  setIsExtraOpen: any;
  setTableData: any;
  product: string;
}>;

const ExtraAmtCustomerData: React.FC<AfTableProps> = ({
  tableData = [],
  selectedAwb = 0,
  isExtraOpen,
  setIsExtraOpen,
  setTableData,
  product,
}) => {
  //const [tableData_, settableData_] = useState<any[]>(tableData);

  const [amtOrGrand, setAmtOrGrand] = useState("");

  useEffect(() => {
    setTableData(tableData);
  }, [tableData]);

  const onInputchange = (e, key) => {
    //return;
    let _tableData_ = JSON.parse(JSON.stringify(tableData));
    let val = e.target.value === "" ? e.target.value : Number(e.target.value);
    if (isNaN(val)) {
      return;
    }
    _tableData_[selectedAwb][key] = val;
    setTableData(_tableData_);
  };

  const onCheckboxCheck = (e, key) => {
    //return;
    let _tableData_ = JSON.parse(JSON.stringify(tableData));
    _tableData_[selectedAwb][key] = e.target.checked ? "1" : "";
    setTableData(_tableData_);
  };

  return (
    <div className="absolute top-0 left-0 w-full h-full bg-white bg-opacity-60 z-10 flex justify-center items-center">
      <div className="bg-white border border-blue-300 p-3 rounded-md shadow-lg">
        <table>
          <tr className="border-b border-black-900">
            <td className="pb-1 border-x px-2 text-center w-28">Dry Ice</td>
            <td className="pb-1 border-x px-2 text-center w-28">DG</td>
            <td className="pb-1 border-x px-2 text-center w-28">PKG</td>

            <td className="pb-1 border-x px-2 text-center w-28">1 LT</td>
            <td className="pb-1 border-x px-2 text-center w-28">4 LT</td>
            <td className="pb-1 border-x px-2 text-center w-28">10 LT</td>

          </tr>

          {tableData && (
            <tr>
              <td className="pt-2 border-x px-2 text-center">
                <input
                  type="checkbox"
                  checked={tableData[selectedAwb]["diceWeight"] != "0" && tableData[selectedAwb]["diceWeight"]}
                  onChange={(e) => {
                    onCheckboxCheck(e, "diceWeight");
                  }}
                  className={`w-8 h-8 focus:border-black-900 p-1 rounded-md`}
                />
              </td>

              <td className="pt-2 border-x px-2 text-center">
                <input
                  type="checkbox"
                  checked={tableData[selectedAwb]["dgRate"] != "0" && tableData[selectedAwb]["dgRate"]}
                  onChange={(e) => {
                    onCheckboxCheck(e, "dgRate");
                  }}
                  className={`w-8 h-8 focus:border-black-900 p-1 rounded-md`}
                />
              </td>

              <td className="pt-2 border-x px-2 text-center">
                <input
                  type="checkbox"
                  checked={tableData[selectedAwb]["extraPkgRate"] != "0" && tableData[selectedAwb]["extraPkgRate"]}
                  onChange={(e) => {
                    onCheckboxCheck(e, "extraPkgRate");
                  }}
                  className={`w-8 h-8 focus:border-black-900 p-1 rounded-md`}
                />
              </td>

              <td className="pt-2 border-x px-2 text-center">
                <input
                  type="checkbox"
                  checked={tableData[selectedAwb]["pkg1Rate"] != "0" && tableData[selectedAwb]["pkg1Rate"]}
                  onChange={(e) => {
                    onCheckboxCheck(e, "pkg1Rate");
                  }}
                  className={`w-8 h-8 focus:border-black-900 p-1 rounded-md`}
                />
              </td>

              <td className="pt-2 border-x px-2 text-center">
                <input
                  type="checkbox"
                  checked={tableData[selectedAwb]["pkg2Rate"] != "0" && tableData[selectedAwb]["pkg2Rate"]}
                  onChange={(e) => {
                    onCheckboxCheck(e, "pkg2Rate");
                  }}
                  className={`w-8 h-8 focus:border-black-900 p-1 rounded-md`}
                />
              </td>

              <td className="pt-2 border-x px-2 text-center">
                <input
                  type="checkbox"
                  checked={tableData[selectedAwb]["pkg3Rate"] != "0" && tableData[selectedAwb]["pkg3Rate"]}
                  onChange={(e) => {
                    onCheckboxCheck(e, "pkg3Rate");
                  }}
                  className={`w-8 h-8 focus:border-black-900 p-1 rounded-md`}
                />
              </td>
            </tr>
          )}
        </table>
        <div className="text-right">
          <button
            className="rounded-md py-2 px-3 mt-3 text-sm font-medium text-white bg-blue-800 border border-gray-200 hover:bg-gray-100 hover:text-blue-700 ml-2"
            type="button"
            onClick={() => {
              setIsExtraOpen(false);
              console.log(tableData);
            }}
          >
            Close
          </button>
        </div>
      </div>
    </div>
  );
};

export { ExtraAmtCustomerData };
