import React, { useState } from "react";

import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";

import { PostLoginResponseType, postLogin } from "service/api";
import * as yup from "yup";

import { Button, Img, Input, Text } from "components";

import useForm from "hooks/useForm";

import "react-toastify/dist/ReactToastify.css";
import { useAuthStore } from "store/auth";
import UseToast from "utils/AfToast";

export const Login: React.FC = () => {
  const { setToken, setUser, token } = useAuthStore();
  const [login, setLogin] = React.useState<PostLoginResponseType>();
  const [togglePassVisible, setTogglePassVisible] = useState<boolean>(false);
  const formValidationSchema = yup.object().shape({
    username: yup.string().required("Username is required"),
    password: yup.string().required("Password is required"),
  });
  const form = useForm(
    { username: "", password: "" },
    {
      validate: true,
      validateSchema: formValidationSchema,
      validationOnChange: true,
    }
  );
  const navigate = useNavigate();

  function login1(data: Partial<{ username: string; password: string }>) {
    const req = {
      data: { username: data?.username, password: data?.password },
    };

    postLogin(req)
      .then((res: any) => {
        setLogin(res?.data);

        setToken(res?.data?.data?.token);
        setUser(res?.data?.data);

        localStorage.setItem("af_token", JSON.stringify(res?.data?.data?.token));

        navigate("/dashboard");
      })
      .catch((err) => {
        console.error(err);
        UseToast("Login Failed! Try Again", "error");
      });
  }

  return (
    <>
      <div className="flex h-[100vh]">
        <div className="flex-1 bg-[#004394] flex items-center justify-center">
          <div>
            <Img src={"/logo3.png"} className="w-[300px] ml-auto" />
          </div>
        </div>
        <div className="flex-1 bg-gray-50 h-full flex font-gilroy items-center justify-center mx-auto w-full">
          <div className="flex flex-col gap-6 items-center justify-start w-full">
            <Text className="md:text-3xl sm:text-[28px] text-[32px] text-blue_gray-900 font-opensans font-semibold">
              {window.location.host == "portal.airfield.co.in" ? "Client " : "Employee "} Login
            </Text>
            <div className="flex flex-col gap-4 items-center justify-start w-full font-sans text-xl leading-5">
              <div className="flex flex-col gap-1 items-start justify-start w-[400px] sm:w-full">
                <div className="flex flex-col items-start justify-end pr-1 py-1 w-full">Username</div>
                <Input
                  name="email"
                  placeholder="Enter Your Username"
                  className="font-medium md:h-auto p-0 placeholder:text-blue_gray-300 sm:h-auto sm:pr-5 text-lg text-blue_gray-300 text-left w-full"
                  wrapClassName="bg-white-A700 border border-blue_gray-100 border-solid p-3 rounded-lg w-full"
                  type="text"
                  onChange={(e) => {
                    form.handleChange("username", e);
                  }}
                  errors={form?.errors?.username}
                  value={form?.values?.username}
                ></Input>
              </div>
              <div className="flex flex-col gap-1 items-start justify-start w-[400px] sm:w-full">
                <div className="flex flex-col items-start justify-end pr-1 py-1 w-full">Password</div>
                <div className="flex flex-col gap-[15px] items-end justify-start mt-1 w-full">
                  <Input
                    name="InputField"
                    placeholder="Enter Password"
                    className="font-medium p-0 placeholder:text-blue_gray-300 text-lg leading-5 text-blue_gray-300 text-left w-full"
                    wrapClassName="bg-white-A700 border border-blue_gray-100 border-solid flex pl-3 py-3 rounded-lg w-[400px] sm:w-full"
                    type={togglePassVisible ? "text" : "password"}
                    onChange={(e) => {
                      form.handleChange("password", e);
                    }}
                    onKeyDown={(event) => {
                      if (event.key === "Enter") {
                        form.handleSubmit(login1);
                      }
                    }}
                    errors={form?.errors?.password}
                    value={form?.values?.password}
                    suffix={
                      <Img
                        className="h-5 ml-[35px] mr-3"
                        src="images/img_eye.svg"
                        alt="eye"
                        onClick={() => {
                          setTogglePassVisible(!togglePassVisible);
                        }}
                      />
                    }
                  ></Input>
                  {/*<a href="javascript:" className="text-blue-A700 text-lg">
                      <Text size="txtGilroyMedium18BlueA700">
                        Forgot Password?
                      </Text>
                    </a>*/}
                </div>
              </div>
              <Button
                className="mt-3 common-pointer bg-[#004394] text-white text-xl cursor-pointer font-medium py-2 pb-3 text-center w-[400px] border-2 border-black-900 border-l-4 border-t-4 rounded-xl"
                onClick={() => {
                  form.handleSubmit(login1);
                }}
              >
                Log in
              </Button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
