import React, { useEffect, useState } from "react";
import { ErrorMessage } from "../ErrorMessage";
import { Link } from "react-router-dom";
import { AfDataType } from "utils/TableColumnData";
import Select from "react-select";
import handleWheel from "utils/handleOnWheel";

export type AfTableProps = Partial<{
  errors: any[];

  columnData: any[];
  tableData: any[];
  customClasses: string;
  setNewTableData: any;
  mode?: string;
}>;

const InputTable: React.FC<AfTableProps> = ({
  errors = [],

  columnData = [],
  tableData = [],
  customClasses = "",
  setNewTableData,
  mode = "",
}) => {
  let incAry = [];
  columnData.forEach((c) => {
    if (c.db_col) {
      incAry.push("_" + c.db_col);
    }
  });

  const customStyles = {
    menuPortal: (base) => ({ ...base, zIndex: 9999 }),
  };

  const handleChange = (e, id) => {
    setNewTableData((prevData) => {
      const newData = prevData.find((d) => Number(d.id) === Number(id));
      newData[e.target.name] =
        columnData.find((d) => d.key === e.target.name).type === AfDataType.TEXT
          ? e.target.value
          : columnData.find((d) => d.key === e.target.name).type === AfDataType.SELECT ||
            columnData.find((d) => d.key === e.target.name).type === AfDataType.DB
          ? e.target.value
          : Number(e.target.value);
      return [...prevData.filter((d) => d.id !== id), newData].sort((a, b) => Number(a.id) - Number(b.id));
    });
  };
  const [nestedSelectedOptions, setNestedSelectedOptions] = useState<any>({});

  return (
    <div className={`flex ${customClasses}`}>
      <table className="w-full text-sm text-left text-gray-500 border-r border-t">
        <thead className="text-xs text-gray-700 uppercase bg-gray-50 ">
          <tr>
            {columnData.map((c) => {
              return (
                c.view && (
                  <th scope="col" className="px-6 py-3 border-l border-b" key={c.label}>
                    <div className="flex items-center">
                      {c.sort && (
                        <div className="invisible">
                          <svg
                            className="w-3 h-3 ml-1.5"
                            aria-hidden="true"
                            xmlns="http://www.w3.org/2000/svg"
                            fill="currentColor"
                            viewBox="0 0 24 24"
                          >
                            <path d="M8.574 11.024h6.852a2.075 2.075 0 0 0 1.847-1.086 1.9 1.9 0 0 0-.11-1.986L13.736 2.9a2.122 2.122 0 0 0-3.472 0L6.837 7.952a1.9 1.9 0 0 0-.11 1.986 2.074 2.074 0 0 0 1.847 1.086Zm6.852 1.952H8.574a2.072 2.072 0 0 0-1.847 1.087 1.9 1.9 0 0 0 .11 1.985l3.426 5.05a2.123 2.123 0 0 0 3.472 0l3.427-5.05a1.9 1.9 0 0 0 .11-1.985 2.074 2.074 0 0 0-1.846-1.087Z" />
                          </svg>
                        </div>
                      )}
                      {c.label}
                      {c.sort && (
                        <div className="invisible">
                          <svg
                            className="w-3 h-3 ml-1.5"
                            aria-hidden="true"
                            xmlns="http://www.w3.org/2000/svg"
                            fill="currentColor"
                            viewBox="0 0 24 24"
                          >
                            <path d="M8.574 11.024h6.852a2.075 2.075 0 0 0 1.847-1.086 1.9 1.9 0 0 0-.11-1.986L13.736 2.9a2.122 2.122 0 0 0-3.472 0L6.837 7.952a1.9 1.9 0 0 0-.11 1.986 2.074 2.074 0 0 0 1.847 1.086Zm6.852 1.952H8.574a2.072 2.072 0 0 0-1.847 1.087 1.9 1.9 0 0 0 .11 1.985l3.426 5.05a2.123 2.123 0 0 0 3.472 0l3.427-5.05a1.9 1.9 0 0 0 .11-1.985 2.074 2.074 0 0 0-1.846-1.087Z" />
                          </svg>
                        </div>
                      )}
                    </div>
                  </th>
                )
              );
            })}
          </tr>
        </thead>

        <tbody>
          {tableData.map((rd, j) => {
            return (
              <tr className="bg-white border-b" key={"tbl-tr-" + j}>
                {columnData.map((cd, i) => {
                  return (
                    cd.view && (
                      <td className="border-l px-2 py-1" key={"tbl-td-" + i}>
                        {cd.type == AfDataType.BOOLEAN ? (
                          <>{rd[cd.key] === true ? "true" : "false"}</>
                        ) : cd.key === "id" ? (
                          <div className=" ml-2">{rd[cd.key]}</div>
                        ) : cd.type === AfDataType.BUTTON ? (
                          <div className=" ml-2">{rd?.["id"] && cd.btnComponent(rd?.["id"])}</div>
                        ) : mode && mode === "view" ?  (
                          <div className=" ml-2">
                            {cd.key == "awb_no" ? <a target="_blank" className="text-blue-600 font-medium hover:underline" href={`/pod/tracking?awb=${rd[cd.key]}`}>{rd[cd.key]}</a> : rd[cd.key]}
                          </div>
                        ) : mode && mode === "edit" && !cd.edit ? (
                          <div className=" ml-2">{rd[cd.key]}</div>
                        ) : cd.type === AfDataType.SELECT || cd.type === AfDataType.DB ? (
                          <div key={"general_" + cd.key} className="flex">
                            <Select
                              key={"select-" + cd.key}
                              className={`flex-1 bg-gray-50 border-gray-300 text-gray-900 text-sm rounded-lg block w-full`}
                              id={"general_" + cd.key}
                              options={cd.type === AfDataType.DB ? cd.options[cd.key] : cd.options}
                              onChange={(e) => {
                                handleChange(
                                  {
                                    target: { name: cd.key, value: e.value },
                                  },
                                  rd.id
                                );
                              }}
                              name={cd.key}
                              value={nestedSelectedOptions[cd.key]}
                              styles={customStyles}
                              menuPortalTarget={document.body}
                              isDisabled={mode ? (mode === "view" ? true : mode === "edit" ? !cd.edit : false) : false}
                            />
                          </div>
                        ) : (
                            <input
                              type={cd.type == AfDataType.TEXT ? "text" : "number"}
                              id={"general_" + cd.key}
                              className={` bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5`}
                              placeholder={cd.label}
                              onBlur={(e) => {
                                handleChange(e, rd.id);
                              }}
                              onChange={(e) => {
                                if (AfDataType.NUMBER && Number(e.target.value) >= 0) {
                                  handleChange(e, rd.id);
                                } else handleChange(e, rd.id);
                              }}
                              onWheel={handleWheel}
                              name={cd.key}
                              value={rd[cd.key]}
                              disabled={mode ? (mode === "view" ? true : mode === "edit" ? !cd.edit : false) : false}
                            />
                        )}
                      </td>
                    )
                  );
                })}
              </tr>
            );
          })}
        </tbody>
      </table>
      <ErrorMessage errors={errors} />
    </div>
  );
};

export { InputTable };
