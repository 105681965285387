import React, { useEffect, useState } from "react";
import { DataTable } from "components/DataTable/DataTable";
import { AfDataType, GenericColumnData } from "utils/TableColumnData";
import { DataTableWithoutButtons } from "components/DataTable/DataTableWithoutButtons";
import { useNavigate } from "react-router-dom";
import { UseMutateFunction, useMutation } from "@tanstack/react-query";
import { AxiosResponse } from "axios";
import { UpdateCommanWay } from "API/commonApi";
import UseToast from "utils/AfToast";
import { roles } from "utils/constant";

const viewActionColumn = {
  label: "Action",
  key: "id",
  render: (_) => {
    return `<a>${_}</a>`;
  },
  type: AfDataType.VIEW,
  view: true,
  add: false,
  edit: false,
  filter: false,
  sort: false,
};
const AWBList: React.FC<any> = (props) => {
  const ADT = AfDataType;
  const navigator = useNavigate();
  const [userDetails, setUserDetails] = useState<any>({});

  useEffect(() => {
    const userD = localStorage.getItem("auth")
      ? JSON.parse(localStorage.getItem("auth"))
      : {};
    if (Object.keys(userD).length) {
      setUserDetails(userD.state.user);
      console.log("userD.state", userD.state.user);
    }
  }, []);
  console.log(
    userDetails?.roleName !== roles.SUPER_ADMIN &&
      userDetails?.roleName !== roles.BRANCH_ADMIN &&
      userDetails?.roleName !== roles.SUBBRANCH_ADMIN,
    userDetails?.roleName
  );

  const tableColumnData = [
    {
      label: "Id",
      key: "id",
      type: ADT.NUMBER,
      view: true,
      add: false,
      edit: true,
      filter: true,
      sort: true,
    },
    {
      label: "AWB Number",
      key: "awb_no",
      type: ADT.TEXT,
      view: true,
      add: true,
      edit: true,
      filter: true,
      sort: true,
    },
    {
      label: "L",
      key: "l",
      type: ADT.TEXT,
      view: true,
      add: true,
      edit: true,
      filter: true,
      sort: true,
    },
    {
      label: "B",
      key: "b",
      type: ADT.TEXT,
      view: true,
      add: true,
      edit: true,
      filter: true,
      sort: true,
    },
    {
      label: "H",
      key: "h",
      type: ADT.TEXT,
      view: true,
      add: true,
      edit: true,
      filter: true,
      sort: true,
    },
    {
      label: "Weight",
      key: "weight",
      type: ADT.TEXT,
      view: true,
      add: true,
      edit: true,
      filter: true,
      sort: true,
    },
    {
      label: "Pincode",
      key: "pincode",
      type: ADT.TEXT,
      view: true,
      add: true,
      edit: true,
      filter: true,
      sort: true,
    },
    {
      label: "Destination",
      key: "destination",
      type: ADT.TEXT,
      view: true,
      add: true,
      edit: true,
      filter: true,
      sort: true,
    },
    {
      label: "Assigned to booking",
      key: "assign_to_booking",
      type: ADT.TEXT,
      view: true,
      add: true,
      edit: true,
      filter: true,
      sort: true,
    },
    {
      label: "Invoice no.",
      key: "invoice_no_c_b",
      type: ADT.TEXT,
      view: true,
      add: true,
      edit: true,
      filter: true,
      sort: true,
    },
    {
      label: "Assigned to Agent",
      key: "assign_to_delivery_agent",
      type: ADT.TEXT,
      view: true,
      add: true,
      edit: true,
      filter: true,
      sort: true,
    },
    ...(userDetails?.roleName !== roles.SUPER_ADMIN &&
    userDetails?.roleName !== roles.BRANCH_ADMIN &&
    userDetails?.roleName !== roles.SUBBRANCH_ADMIN
      ? [
          {
            label: "Action",
            key: "customId",
            render: (_) => {
              return `<a>${_}</a>`;
            },
            type: ADT.CUSTOM,
            view: true,
            add: false,
            edit: false,
            filter: false,
            sort: false,
          },
        ]
      : []),
  ];

  const {
    mutate: mutateUpdateAWB,
    isLoading: isUpdateAWBloading,
  }: {
    mutate: UseMutateFunction<AxiosResponse<any, any>, unknown, any, unknown>;
    isLoading: any;
  } = useMutation(
    (data) =>
      UpdateCommanWay({
        url: "/admin/awbdata/update/" + data.id,
        data: {
          assign_to_delivery_agent: userDetails?.agent_id,
        },
      }),
    {
      onSuccess: (data: any) => {
        console.log(data, data.status == "SUCCESS");
        if (data.status == "SUCCESS") {
          UseToast("Data Added Successfully", "success");
          window.location.reload();
        } else {
          UseToast(data.message, "error");
        }
      },
      onError: (data: any) => {
        typeof data === "string"
          ? UseToast(data, "error")
          : UseToast(data.message, "error");
      },
    }
  );
  const customRenderFunction = (data) => {
    return data?.assign_to_delivery_agent ? null : (
      <button
        className="text-dark1"
        onClick={() => {
          mutateUpdateAWB(data);
        }}
      >
        Assign
      </button>
    );
  };
  return (
    <>
      <DataTableWithoutButtons
        apiUrl={`/admin/awbdata/list`}
        columnData={tableColumnData}
        handleCustom={(data) => {
          mutateUpdateAWB(data);
        }}
        customRenderFunction={customRenderFunction}
      />
    </>
  );
};

export default AWBList;
