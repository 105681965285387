import { request } from "../config/request";
import { type AxiosResponse } from "../types";

export const GetCommanWay = async (url: string, query: any, options: any) => {
  const response: AxiosResponse<any> = await request({
    url: url,
    method: "GET",
  });
  return response;
};

export const ListCommanWay = async (url: string, query: any, options: any) => {
  const response: AxiosResponse<any> = await request({
    url: url,
    method: "POST",
    body: {
      query: query,
      options: options,
      isCountOnly: false,
    },
  });
  return response;
};

export const CreateCommanWay = async (data: any) => {
  const response = await request({
    url: data.url,
    method: "POST",
    body: data.data,
  });
  return response;
};

export const CreatePickupApi = async (data: any) => {
  const response = await request({
    url: "/admin/pickup/create",
    method: "POST",
    body: data,
  });
  return response;
};

export const UpdateCommanWay = async (data: any) => {
  const response = await request({
    url: data.url,
    method: "PUT",
    body: data.data,
  });
  return response;
};

export const UpdateBookingCommanWay = async (data: any) => {
  const response = await request({
    url: data.url,
    method: "PUT",
    body: {
      data: data.data,
      query: data.query,
      options: data.options,
    },
  });
  return response;
};

export const DeleteCommanWay = async (data: any) => {
  const response = await request({
    url: data.url,
    method: "DELETE",
    body: data.data,
  });
  return response;
};

export const ServiceSupplierapiList = async (data: any) => {
  let query = "/admin/service-supplier?";
  for (const val in data) {
    if (data[val] || data[val] === 0) query += `${val}=${data[val]}&`;
  }
  const URL = query.substring(0, query.length - 1);

  const response: AxiosResponse<any[]> = await request({
    url: URL,
    method: "GET",
  });
  return response;
};

export const serviceSupplierAboutAPI = async (id: string | undefined) => {
  const response: AxiosResponse<any> = await request({
    url: `/admin/service-supplier/about/${id}`,
    method: "GET",
  });
  return response;
};

export const EditSupplierAboutAPI = async (body: any) => {
  const { id, ...data } = body;
  const response = await request({
    url: `/admin/service-supplier/edit/about/${id}`,
    method: "PUT",
    body,
  });
  return response;
};

export const CreateServiceSupplier = async (body: any) => {
  const response = await request({
    url: "/admin/service-supplier/create",
    method: "POST",
    body,
  });
  return response;
};
