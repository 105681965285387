import { MinusCircleIcon } from "@heroicons/react/24/outline";
import { UseMutateFunction, useMutation, useQuery } from "@tanstack/react-query";
import { CreateCommanWay, GetCommanWay, ListCommanWay, UpdateBookingCommanWay, UpdateCommanWay } from "API/commonApi";
import { AxiosResponse } from "axios";
import { Button } from "components";
import { DataTable } from "components/DataTable/DataTable";
import { InputTable } from "components/DataTable/InputTable";
import { useFormik } from "formik";
import { debounce } from "lodash";
import moment from "moment";
import { any } from "prop-types";
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Select from "react-select";
import UseToast from "utils/AfToast";
import { roles } from "utils/constant";
import handleWheel from "utils/handleOnWheel";
import { AfDataType as ADT } from "utils/TableColumnData";
import { number, object, string } from "yup";

type OptionType = {
  value: number;
  label: string;
};

interface MotherBagState {
  mode?: string;
  data?: { [key: string]: any }; // Adjust the type based on your data structure
  // Add other properties if needed
}

interface ValuesType {
  destination?: { value: string; label: string };
}

const MotherBagOutScan: React.FC = ({}) => {
  let isDisabled = false;
  const navigate = useNavigate();

  const [mode, setMode] = useState<any>("");

  const [filterStartDt, setFilterStartDt] = useState<any>(moment().subtract(1, "day").startOf("day"));
  const [filterEndDt, setFilterEndDt] = useState<any>(moment().endOf("day"));

  const [motherBagList, setMotherBagList] = useState<any>([]);
  const [selectedMotherbag, setSelectedMotherbag] = useState<number>(null);
  const [selectedMotherbagData, setSelectedMotherbagData] = useState<any>({});
  const [motherbagStatusList, setMotherbagStatusList] = useState<any[]>([]);
  const [selectedMotherbagStatus, setSelectedMotherbagStatus] = useState<any>({ value: -1, label: "Select..." });
  const [statusFilter, setStatusFilter] = useState<any>({ value: "", label: "All" });

  const [modeOfPkgOptions, set_modeOfPkgOptions] = useState<any>([
    { value: 0, label: "Select Mode" },
    { value: "By Air", label: "By Air" },
    { value: "By Road", label: "By Road" },
    { value: "By Train", label: "By Train" },
  ]);
  const [airlineOptions, set_airlineOptions] = useState<any>([]);
  const [flightOptions, set_flightOptions] = useState<any>([]);
  const [vendorOptions, set_vendorOptions] = useState<any>([]);
  const [typeOptions, set_typeOptions] = useState<any>([
    { value: 1, label: "Cargo" },
    { value: 2, label: "Console" },
  ]);

  const { refetch: mbag_status } = useQuery(["MbagStatusList"], () => GetCommanWay("/admin/mbag_status_list", {}, {}), {
    keepPreviousData: true,
    onSuccess(data) {
      if (data.status == "SUCCESS") {
        console.log(data.data);
        setMotherbagStatusList(data.data);
      }
    },
  });

  const {
    mutate: mutateUpdateMotherBag,
    isLoading: isUpdateMotherBagloading,
  }: {
    mutate: UseMutateFunction<AxiosResponse<any, any>, unknown, any, unknown>;
    isLoading: any;
  } = useMutation(
    () =>
      UpdateCommanWay({
        url: "/admin/mother_bag/update/" + selectedMotherbag,
        data: {
          outscanDone: true,
          airline: values.airline.id,
          flight: values.flight.id,
          mawb: values.mawb,
          modeOfPkg: values.modeOfPkg.value,
          realWeight: values.realWeight,
          remark: values.remark,
          /*sysWeight: values.sysWeight,
          totalNos: parseInt(values.totalNos),*/
          type: values.type.value,
          vendor: values.vendor.id,
          status: "PENDING",
          outScanPOD: true,
        },
      }),
    {
      onSuccess: (data: any) => {
        console.log(data, data.status == "SUCCESS");
        if (data.status == "SUCCESS") {
          UseToast("Motherbag Outscan Complete", "success");
          resetForm();
          setSelectedMotherbag(null);
          setSelectedMotherbagData({});
          refetchData();
        } else {
          UseToast(data.message, "error");
        }
      },
      onError: (data: any) => {
        typeof data === "string" ? UseToast(data, "error") : UseToast(data.message, "error");
      },
    }
  );

  const { handleSubmit, handleBlur, values, handleChange, errors, setValues, setErrors, touched, dirty, setFieldValue, resetForm } =
    useFormik({
      validationSchema: object().shape({
        modeOfPkg: object().required(),
      }),
      initialValues: {
        modeOfPkg: modeOfPkgOptions[0],
        airline: { id: 0, name: "Select Airline", value: 0, label: "" },
        flight: { id: 0, name: "Select Flight", value: 0, label: "" },
        vendor: { id: 0, vendor_name: "Select Vendor", value: 0, label: "" },
        mawb: "",
        sysWeight: "",
        realWeight: "",
        totalNos: "",
        remark: "",
        type: typeOptions[0],
      },
      onSubmit: async (values, { resetForm }) => {
        console.log("======> values", values);
        if (selectedMotherbag == null) {
          UseToast("Select Motherbag !", "error");
          return;
        }
        if (values.modeOfPkg.value == 0 || !values.realWeight) {
          UseToast("Select Mode and Weights", "error");
          return;
        }
        mutateUpdateMotherBag(selectedMotherbag);
      },
    });

  useQuery(["airlineApi"], () => ListCommanWay("/admin/airline/list", {}, { paginate: 10000, include: [] }), {
    keepPreviousData: true,
    onSuccess(data) {
      if (data.status == "SUCCESS") {
        set_airlineOptions(data.data.data);
      }
    },
  });

  const { refetch: flightRefetch } = useQuery(
    ["flightApi"],
    () => ListCommanWay("/admin/flight/list", { airline: values.airline.id }, { paginate: 10000, include: [] }),
    {
      keepPreviousData: true,
      enabled: false,
      onSuccess(data) {
        if (data.status == "SUCCESS") {
          set_flightOptions(data.data.data);
        }
      },
    }
  );
  useEffect(() => {
    if (values.airline.id) {
      flightRefetch();
    }
  }, [values.airline.id]);

  useEffect(() => {
    if (values.airline.id) {
      setFieldValue("flight", { id: 0, name: "Select Flight", value: 0, label: "" });
    }
  }, [values.airline.id]);

  useQuery(["vendorApi"], () => ListCommanWay("/admin/vendor/list", {}, { paginate: 10000, include: [] }), {
    keepPreviousData: true,
    onSuccess(data) {
      if (data.status == "SUCCESS") {
        set_vendorOptions(data.data.data);
      }
    },
  });

  const [thirdPartyOpt, setThirdPartyOtp] = useState<any>();
  const [thirdPartyOptions, setThirdPartyOptions] = useState<any>([{ value: 0, label: "Loading..." }]);

  const { refetch: thirdPartyRefetch } = useQuery(
    ["ThirdPartyListApi"],
    () => ListCommanWay("/admin/third_party/list", {}, { paginate: 10000 }),
    {
      keepPreviousData: true,
      onSuccess(data) {
        if (data.status == "SUCCESS") {
          let opt = [];
          data.data.data.forEach((p) => {
            opt.push({
              value: p.id,
              label: p.name,
            });
          });
          setThirdPartyOptions(opt);
        }
      },
    }
  );

  const { refetch: refetchData } = useQuery(
    ["MotherBagApi"],
    () =>
      ListCommanWay(
        "/admin/mother_bag/list",
        {
          $or: {
            outscanDone: { $or: [null, false] },
            $and: {
              createdAt: {
                $gte: filterStartDt,
                $lte: filterEndDt,
              },
            },
          },
        },
        { paginate: 10000, include: ["_destination", "_subbranch"] }
      ),
    {
      keepPreviousData: true,
      onSuccess(data) {
        if (data.status == "SUCCESS") {
          setMotherBagList(data.data.data);
        }
      },
    }
  );

  const { mutate: addMotherbag_pod } = useMutation(CreateCommanWay, {
    onSuccess: (data: any) => {
      if (data.status == "SUCCESS") {
        UseToast("Motherbag Status Changed", "success");
        resetForm();
        setSelectedMotherbag(null);
        setSelectedMotherbagData({});
        setSelectedMotherbagStatus({ value: -1, label: "Select..." });
        refetchData();
      } else {
        UseToast(data.message, "error");
      }
    },
    onError: (data: any) => {
      //typeof data === "string" ? UseToast(data, "error") : UseToast(data.message, "error");
      UseToast("Motherbag Status Change error. Try Again OR Contact Admin!", "error");
    },
  });

  const getInput: any = (type, label, value, name, isDisabled, width, valueKey, labelKey) => {
    let ip = <div></div>;

    switch (type) {
      case ADT.NUMBER:
        ip = (
          <div className={`inline-flex items-center gap-2 ${width ? width : "w-auto"}`}>
            <label className="mb-2 text-sm font-semibold text-gray-900">{label}</label>
            <input
              type="number"
              className={`${
                isDisabled ? "bg-gray-300" : ""
              } bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5`}
              placeholder={label}
              name={name}
              value={values[name]}
              onBlur={handleBlur}
              onChange={(e) => {
                if (Number(e.target.value) >= 0) {
                  handleChange(e);
                }
              }}
              onWheel={handleWheel}
              disabled={mode && (mode === "view" || mode === "edit") ? true : isDisabled}
            />
          </div>
        );
        break;
      case ADT.TEXT:
        ip = (
          <div className={`inline-flex items-center gap-2 ${width ? width : "w-auto"}`}>
            <label className="mb-2 text-sm font-semibold text-gray-900">{label}</label>

            <input
              type="text"
              className={`${
                isDisabled ? "bg-gray-300" : ""
              } bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5`}
              placeholder={label}
              name={name}
              value={values[name]?.label !== undefined ? values[name]?.label : values[name]}
              onBlur={handleBlur}
              onChange={handleChange}
              disabled={mode && (mode === "view" || mode === "edit") ? true : isDisabled}
            />
          </div>
        );
        break;
      case ADT.DB:
        ip = (
          <div className={`inline-flex items-center gap-2 ${width ? width : "w-auto"}`}>
            <label className="mb-2 text-sm font-semibold text-gray-900">{label}</label>
            <Select
              className={`${isDisabled ? "bg-gray-300" : ""} bg-gray-50 border-gray-300 text-gray-900 text-sm rounded-lg block w-full `}
              options={value.all}
              onBlur={handleBlur}
              getOptionValue={(x) => (valueKey ? x[valueKey] : x.value)}
              getOptionLabel={(x) => (labelKey ? x[labelKey] : x.label)}
              onChange={(e) => {
                setFieldValue(name, e);
              }}
              name={name}
              value={values[name]}
              isDisabled={mode === "view" ? true : isDisabled}
            />
          </div>
        );
        break;
      case ADT.TEXTAREA:
        break;
      case ADT.DATETIME:
        break;
      case ADT.DATE:
        ip = (
          <div className={`inline-flex items-center gap-2 ${width ? width : "w-auto"}`}>
            <label className="mb-2 text-sm font-semibold text-gray-900">{label}</label>
            <input
              type="date"
              className={`${
                isDisabled ? "bg-gray-300" : ""
              } bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5`}
              placeholder={label}
              name={name}
              value={values[name]}
              onBlur={handleBlur}
              onChange={handleChange}
              disabled={mode && (mode === "view" || mode === "edit") ? true : isDisabled}
            />
          </div>
        );
        break;
      case ADT.FILE:
        break;
      default:
        ip = <></>;
        break;
    }
    return ip;
  };

  const doShowRow = (m: any) => {
    switch (statusFilter.value) {
      case "":
        return true;
        break;
      case "pending":
        return !m.outscanDone;
        break;
      case "outscan":
        return m.outscanDone && !m.inscanDone ? true : false;
        break;
      case "received":
        return m.inscanDone ? true : false;
        break;
    }
  };

  return (
    <>
      <h1 className="text-center mb-4 mt-3 text-lg font-semibold underline">Mother Bag Out Scan</h1>

      <div className="flex items-center justify-center my-5 gap-3">
        <div>
          From:{" "}
          <input
            type="date"
            className="focus:border-colors2 rounded"
            value={filterStartDt.format("YYYY-MM-DD")}
            onChange={(e: any) => {
              setFilterStartDt(moment(e.target.value).startOf("day"));
            }}
          />
        </div>
        <div>
          To:{" "}
          <input
            type="date"
            className="focus:border-colors2 rounded"
            value={filterEndDt.format("YYYY-MM-DD")}
            onChange={(e: any) => {
              setFilterEndDt(moment(e.target.value).endOf("day"));
            }}
          />
        </div>

        <Button
          type="button"
          className="rounded-md px-2 py-2.5 text-sm font-medium text-white bg-blue-500 border hover:bg-opacity-60 focus:z-10"
          onClick={refetchData}
        >
          Search
        </Button>

        <div className="w-36 ml-10">
          <Select
            className={`flex-1 bg-gray-50 border-gray-300 text-gray-900 text-sm rounded-lg block `}
            options={[
              { value: "", label: "All" },
              { value: "pending", label: "Pending" },
              { value: "outscan", label: "Outscan" },
              { value: "received", label: "Received" },
            ]}
            value={statusFilter}
            onChange={(e) => {
              setStatusFilter(e);
            }}
            menuPortalTarget={document.body}
          />
        </div>
      </div>

      <form
        onSubmit={(e) => {
          e.preventDefault();
          handleSubmit(e);
        }}
      >
        <div className="px-6">
          <table className="border text-left px-4 rounded">
            <thead className="border-b">
              <tr>
                <th className="py-2 px-2 print:hidden">-</th>
                <th className="px-1">Added On</th>
                <th className="px-1">Motherbag No</th>
                <th className="px-1">Origin</th>
                <th className="px-1">Destination</th>
                <th className="px-1">No Of Awb</th>
                <th className="px-1">Total Weight</th>
                <th className="px-1">Status</th>
                <th className="px-1 print:hidden">Last Reason</th>
                <th className="px-1 print:hidden">Reason</th>
              </tr>
            </thead>
            <tbody>
              {motherBagList.map((m) => {
                return (
                  doShowRow(m) && (
                    <tr
                      key={"tr-" + m.id}
                      className={`border-t ${m.id == selectedMotherbag && "bg-blue-200"} 
                    ${m.inscanDone ? "bg-green-500" : m.outscanDone ? "bg-yellow-100" : "bg-red-400"}`}
                    >
                      <td className="print:hidden">
                        {m.outscanDone ? (
                          <p className="py-1 px-1 text-sm">-</p>
                        ) : m.id == selectedMotherbag ? (
                          <p className="py-1 px-1 text-sm">Selcted</p>
                        ) : (
                          <>
                            <Button
                              type="button"
                              className="rounded-md px-2 py-1 text-sm font-medium text-white bg-green-500 border border-gray-200 hover:bg-opacity-60 focus:z-10 "
                              onClick={() => {
                                setSelectedMotherbag(m.id);
                                setSelectedMotherbagData(m);
                                resetForm();
                              }}
                            >
                              Select
                            </Button>

                            {!m.outscanDone && <Button
                              type="button"
                              className="rounded-md px-2 py-1 text-sm font-medium text-white bg-blue-500 border border-gray-200 hover:bg-opacity-60 focus:z-10 ml-2"
                              onClick={() => {
                                navigate("/mother_bag/add", {
                                  state: {
                                    mode: "edit",
                                    data: m,
                                  },
                                });
                              }}
                            >
                              Edit
                            </Button>}
                          </>
                        )}
                      </td>
                      <td>{moment(m.createdAt, "DD-MM-YYYY HH:mm").format("DD-MM-YYYY HH:mm")}</td>
                      <td className="px-2">
                        <span
                          className="cursor-pointer hover:underline hover:font-semibold"
                          onClick={() => {
                            navigate("/mother_bag/add", {
                              state: {
                                mode: "view",
                                data: m,
                              },
                            });
                          }}
                        >
                          {m.mother_bag_code}
                        </span>
                      </td>
                      <td>{m._subbranch?.branch_name}</td>
                      <td>{m._destination?.city_name}</td>
                      <td>{m.totalNos}</td>
                      <td>{Number(m.sysWeight).toFixed(2)}</td>
                      <td>{m.inscanDone ? "Received" : m.outscanDone ? "Outscan" : "Pending"}</td>
                      <td>{m.motherbad_pods && m.motherbad_pods.length > 0 && m.motherbad_pods[0].remark}</td>
                      <td className="flex p-1 gap-1 max-w-[300px] print:hidden">
                        <Select
                          className={`flex-1 bg-gray-50 border-gray-300 text-gray-900 text-sm rounded-lg block `}
                          options={motherbagStatusList}
                          value={selectedMotherbagStatus}
                          onChange={(e) => {
                            setSelectedMotherbagStatus(e);
                          }}
                          styles={{
                            control: (provided, state) => ({
                              ...provided,
                              minHeight: "30px",
                              height: "30px",
                            }),
                            valueContainer: (provided, state) => ({
                              ...provided,
                              height: "30px",
                              padding: "0 6px",
                            }),
                            input: (provided, state) => ({
                              ...provided,
                              margin: "0px",
                            }),

                            indicatorsContainer: (provided, state) => ({
                              ...provided,
                              height: "30px",
                            }),
                          }}
                          menuPortalTarget={document.body}
                        />
                        <Button
                          type="button"
                          className="rounded-md px-2 py-1 text-sm font-medium text-white bg-green-500 border border-gray-200 hover:bg-opacity-60 focus:z-10 "
                          onClick={() => {
                            selectedMotherbagStatus.value != -1 &&
                              addMotherbag_pod({
                                url: "/admin/motherbad_pod/create/",
                                data: {
                                  bagId: m.id,
                                  status: selectedMotherbagStatus.value + "",
                                  remark: selectedMotherbagStatus.label,
                                  dateTime: new Date(),
                                },
                              });
                          }}
                        >
                          Save
                        </Button>
                      </td>
                    </tr>
                  )
                );
              })}
            </tbody>
          </table>

          <div className="flex gap-2 flex-wrap border p-2 rounded-xl mt-4">
            <div className="w-full font-semibold underline">Motherbag Information</div>

            <div className="grid grid-cols-3 w-full mt-3">
              {getInput(ADT.DB, "Mode Of Package", { all: modeOfPkgOptions }, "modeOfPkg", isDisabled, "border-x border-[#000] px-2")}

              {getInput(ADT.DB, "Airline", { all: airlineOptions }, "airline", isDisabled, "border-x border-[#000] px-2", "id", "name")}

              {getInput(ADT.DB, "Flight", { all: flightOptions }, "flight", isDisabled, "border-x border-[#000] px-2", "id", "name")}

              {getInput(ADT.DB, "Vendor", { all: vendorOptions }, "vendor", isDisabled, "border-x border-[#000] px-2", "id", "vendor_name")}

              {getInput(ADT.TEXT, "MAWB_No", {}, "mawb", isDisabled, "border-x border-[#000] px-2")}

              {getInput(ADT.DB, "Type", { all: typeOptions }, "type", isDisabled, "border-x border-[#000] px-2")}
              {/*getInput(
                ADT.TEXT,
                "Weight",
                selectedMotherbagData.totalNos,
                "sysWeight",
                isDisabled,
                "border-x border-[#000] px-2"
              )*/}

              {getInput(ADT.TEXT, "Actual Weight", {}, "realWeight", isDisabled, "border-x border-[#000] px-2")}

              {/*getInput(
                ADT.TEXT,
                "Total Pieces",
                {},
                "totalNos",
                true,
                "border-x border-[#000] px-2"
              )*/}

              {getInput(ADT.TEXT, "Remark", {}, "remark", isDisabled, "border-x border-[#000] px-2")}
            </div>
            <div className="flex w-full justify-end">
              <Button
                type="submit"
                className="rounded-md inline-block px-4 py-2 text-sm font-medium text-white bg-blue-800 border border-gray-200 hover:bg-opacity-60 focus:z-10 "
              >
                Outscan
              </Button>
            </div>
          </div>
        </div>
      </form>
    </>
  );
};

export default MotherBagOutScan;
