import { UseMutateFunction, useMutation, useQuery } from "@tanstack/react-query";
import { CreateCommanWay, GetCommanWay, ListCommanWay, UpdateBookingCommanWay, UpdateCommanWay } from "API/commonApi";
import { AxiosResponse } from "axios";
import { InputTable } from "components/DataTable/InputTable";
import { useFormik } from "formik";
import { debounce } from "lodash";
import moment from "moment";
import { any } from "prop-types";
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Select from "react-select";
import UseToast from "utils/AfToast";
import { constantValues, roles } from "utils/constant";
import handleWheel from "utils/handleOnWheel";
import { AfDataType as ADT } from "utils/TableColumnData";
import { number, object, string } from "yup";
import { BookingInputTable } from "./BookingInputTable";
import Swal from "sweetalert2";
import { IntlBookingInputTable } from "./IntlBookingInputTable";
import { request } from "config/request";

type OptionType = {
  value: number;
  label: string;
};

interface BookingState {
  mode?: string;
  data?: { [key: string]: any }; // Adjust the type based on your data structure
  // Add other properties if needed
}
declare global {
  interface Window {
    SEND_WAPP: (num:any, link:any, msg: any) => void;
  }
}

const AddIntlBooking: React.FC = ({}) => {
  let isDisabled = false;
  const BASE_URL = process.env.REACT_APP_BACKEND_URL;

  const navigate = useNavigate();
  const { state } = useLocation();
  const { mode = "add", data = {} } = (state || {}) as BookingState; // Check for existence and type assertion

  const [isBranchSet, setIsBranchSet] = useState<any>();
  const [newTableData, setNewTableData] = useState<any>([]);
  const [nestedTables, setNestedTables] = useState<any>({});
  const [pincodeSection, setPincodeSection] = useState<any>("");
  const [userDetails, setUserDetails] = useState<any>({});
  const [subbranchOpt, setSubBranchOpt] = useState<any>();
  const [providerOpt, setProviderOpt] = useState<any>();

  const [isDateDisabled, setIsDateDisabled] = useState<boolean>(false);
  const [bookingData, setBookingData] = useState<any>();

  const SEND_WAPP = (num:any, link:any, msg: any) => {
    const response = request({
      url: `http://148.251.129.118/whatsapp/api/send?mobile=91${num}&pdf=${link}&msg=${msg}&apikey=5920c8bcd968411083ffa428b95c9495`,
      method: "GET",
    });
  }

  useEffect(() => {
    const userD = localStorage.getItem("auth") ? JSON.parse(localStorage.getItem("auth")) : {};
    if (Object.keys(userD).length) {
      setUserDetails(userD.state.user);
      console.log("userD.state", userD.state.user);
      if (userD.state.user.roleName !== roles.SUPER_ADMIN && userD.state.user.roleName !== roles.BRANCH_ADMIN) {
        setSubBranchOpt(userD.state.user.branch_name);
      }

      if (userD.state.user.roleName !== roles.SUPER_ADMIN) {
        setIsDateDisabled(true);
      }
      fetchDBInputData();
    }
    window.SEND_WAPP = SEND_WAPP;
  }, []);

  


  const columnData = [
    {
      label: "NO.",
      key: "id",
      type: ADT.TEXT,
      initialValue: "",
      view: true,
      edit: false,
      add: true,
      filter: true,
      sort: true,
    },
    {
      label: "Pieces",
      key: "piece",
      type: "PIECE",
      initialValue: [],
      view: true,
      add: true,
      edit: false,
      filter: false,
      sort: true,
    },
    {
      label: "AWB NO.",
      key: "awb_no",
      type: ADT.TEXT,
      initialValue: "",
      view: true,
      edit: false,
      add: true,
      filter: true,
      sort: true,
      css: "w-32",
    },
    {
      label: "L",
      key: "l",
      type: ADT.NUMBER,
      initialValue: "",
      view: true,
      add: true,
      edit: true,
      filter: true,
      sort: true,
      css: "w-10",
    },
    {
      label: "B",
      key: "b",
      type: ADT.NUMBER,
      initialValue: "",
      view: true,
      add: true,
      edit: true,
      filter: true,
      sort: true,
      css: "w-10",
    },
    {
      label: "H",
      key: "h",
      type: ADT.NUMBER,
      initialValue: "",
      view: true,
      add: true,
      edit: true,
      filter: true,
      sort: true,
      css: "w-10",
    },
    {
      label: "Weight",
      key: "weight",
      type: ADT.NUMBER,
      initialValue: 0,
      view: true,
      add: true,
      edit: true,
      filter: true,
      sort: true,
      css: "w-[55px]",
    },
    {
      label: "Zipcode",
      key: "pincode",
      type: ADT.TEXT,
      initialValue: "",
      view: true,
      add: true,
      edit: false,
      filter: true,
      sort: true,
      css: "w-[75px]"
    },
    {
      label: "Country",
      key: "consignee_country",
      type: ADT.DB,
      options: nestedTables?.consignee_country || [],
      db: "country",
      db_col: "country_name",
      initialValue: false,
      view: true,
      add: true,
      edit: false,
      filter: true,
      sort: true,
      css: "w-40",
    },
    {
      label: "Contact No",
      key: "contact_no",
      type: ADT.TEXT,
      initialValue: "",
      view: true,
      add: true,
      edit: false,
      filter: true,
      sort: true,
      css: "w-32",
    },
    {
      label: "Consignee",
      key: "consignee",
      type: ADT.TEXT,
      initialValue: "",
      view: true,
      add: true,
      edit: false,
      filter: true,
      sort: true,
    },
    {
      label: "Address",
      key: "address",
      type: ADT.TEXT,
      initialValue: "",
      view: true,
      add: true,
      edit: false,
      filter: true,
      sort: true,
    },
    {
      label: "Dox/Non Dox",
      key: "dox_or_non_dox",
      type: ADT.SELECT,
      options: [
        { value: true, label: "DOX" },
        { value: false, label: "NON DOX" },
      ],
      initialValue: true,
      view: true,
      add: true,
      edit: false,
      filter: true,
      sort: true,
      css: "w-24",
    },
  ];

  const {
    mutate: priceCheckMutate,
    isLoading: isloadingPrice,
  }: {
    mutate: UseMutateFunction<AxiosResponse<any, any>, unknown, any, unknown>;
    isLoading: any;
  } = useMutation((data) => CreateCommanWay(data), {
    onSuccess: (data: any) => {
      console.log(data, data.status == "SUCCESS");
      if (data.status == "SUCCESS") {
        Swal.fire({
          title: "Are you sure!",
          text: data.data == "NA" ? "---" : "Price: " + ((data.data.price + data.data.tax).toFixed(0)),
          icon: "info",
          confirmButtonText: "Do Confirm Booking",
          showCancelButton: true,
        }).then((x) => {
          if (x.isConfirmed) {
            //console.log(bookingData);
            mutate({ url: "/admin/booking/create", data: bookingData });
          }
        });
      } else {
        UseToast(data.message, "error");
      }
    },
    onError: (data: any) => {
      typeof data === "string" ? UseToast(data, "error") : UseToast(data.message, "error");
    },
  });

  const {
    mutate,
    isLoading: isloading,
  }: {
    mutate: UseMutateFunction<AxiosResponse<any, any>, unknown, any, unknown>;
    isLoading: any;
  } = useMutation((data) => CreateCommanWay(data), {
    onSuccess: (data: any) => {
      console.log(data, data.status == "SUCCESS");
      if (data.status == "SUCCESS") {
        //UseToast("Data Added Successfully", "success");
        resetForm();
        setNewTableData([]);
        //successNavigate();
        Swal.fire({
          title: "Booking Successful",
          text: data.data?.pdfPath,
          html: data.data?.pdfPath ? `
          <input id="shipper_mobile_wapp" type="text" value="${values.shipper_mobile}"/>
          <a onClick="" class="text-primary underline">Send Whatsapp</a><br><br>
          <a class="text-primary underline" href='${BASE_URL}${data.data?.pdfPath}' target="_blank">Download Invoice</a>` : 'Thank you!',
          icon: "info",
          showConfirmButton: false,
          showCancelButton: true,
        }).then((x) => {});

      } else {
        UseToast(data.message, "error");
      }
    },
    onError: (data: any) => {
      typeof data === "string" ? UseToast(data, "error") : UseToast(data.message, "error");
    },
  });

  const {
    mutate: updateBookingMutate,
    isLoading: isupdateBookingloading,
  }: {
    mutate: UseMutateFunction<AxiosResponse<any, any>, unknown, any, unknown>;
    isLoading: any;
  } = useMutation((data) => UpdateCommanWay(data), {
    onSuccess: (data: any) => {
      console.log(data, data.status == "SUCCESS");
      if (data.status == "SUCCESS") {
        UseToast("Data Updated Successfully", "success");
        navigate(-1);
      } else {
        UseToast(data.message, "error");
      }
    },
    onError: (data: any) => {
      typeof data === "string" ? UseToast(data, "error") : UseToast(data.message, "error");
    },
  });

  const handleQuantityChange = (e) => {
    let quantity = e.target.value;
    if (quantity > 99) {
      setFieldValue("quantity", 99);
      return;
    }
    setNewTableData((prevData) => {
      const columnKeys = columnData.map((d) => d.key);
      const newData = [];
      for (let index = 0; index < quantity; index++) {
        const obj = {};
        for (let j = 0; j < columnKeys.length; j++) {
          obj[columnKeys[j]] = columnData.find((column) => column.key === columnKeys[j]).initialValue;
        }
        obj["id"] = newData[newData.length - 1]?.id ? newData[newData.length - 1]?.id + 1 : 1;
        newData.push(obj);
      }
      return newData;
    });
  };

  const { handleSubmit, handleBlur, values, handleChange, errors, setValues, setErrors, touched, dirty, setFieldValue, resetForm } =
    useFormik({
      validationSchema: object().shape({}),
      initialValues: {
        date: moment().format("YYYY-MM-DD"),
        quantity: 0,
        type: "WI",
        pickup_code: "",
        customer_code: "",
        weight: 0,
        ten_30: { value: 0 },
        project_code: "",
        product_name: { value: 0, label: "" },
        holiday: { value: 0 },

        customer_pincode: "",
        customer_mobile: "",
        customer_sh: "",
        customer_city: "",
        customer_state: "",
        customer_country: "",
        customer_address: "",
        consignee_pincode: "",
        consignee_mobile: "",
        consignee_cgnee: "",
        consignee_city: "",
        consignee_state: "",
        consignee_country: "",
        consignee_address: "",

        shipper_pincode: "",
        shipper_mobile: "",
        shipper_sh: "",
        shipper_city: "",
        shipper_state: "",
        shipper_country: "",
        shipper_pan: "",
        shipper_gst_no: "",
        shipper_address: "",
        change_shipper: false,

        oda_charge: 0,
        pkg_charge: 0,
        d_ice_charge: 0,

        branch_id: { value: 0, label: "" },
        subbranch: { value: 0, label: "select branch" },
        provider: { value: 0, label: "Select Provider" },
      },
      onSubmit: async (values, { resetForm }) => {
        if (mode && mode === "edit") {
          updateAwb({ data: newTableData, bookingId: data.id });
          let req: any = { ...values };
          req.type = req.type;
          req.ten_30 = req.ten_30 ? req.ten_30.value : undefined;
          req.product_name = req.product_name ? req.product_name.value : undefined;
          req.holiday = req.holiday === 1 ? true : false;

          req.weight = parseFloat(req.weight).toString();
          req.customer_mobile = Number(req.customer_mobile);
          req.shipper_mobile = Number(req.shipper_mobile);
          req.consignee_mobile = Number(req.consignee_mobile);
          req.change_shipper = req.change_shipper.toString();

          req.oda_charge = parseFloat(req.oda_charge);
          req.pkg_charge = parseFloat(req.pkg_charge);
          req.d_ice_charge = parseFloat(req.d_ice_charge);
          userDetails?.roleName !== roles.SUPER_ADMIN && userDetails?.roleName !== roles.BRANCH_ADMIN
            ? (req.subbranch = userDetails.subbranch)
            : (req.subbranch = req.subbranch ? req.subbranch.value : null);

          req["awb_data"] = newTableData;
          req.provider = req.provider.value;
          delete req.id;
          delete req?.createdAt;
          constantValues.defaultFields.forEach((d) => delete req[d]);

          console.log(req);

          updateBookingMutate({
            url: "/admin/booking/update/" + data.id,
            data: req,
          });
          //return;
        }
        let req: any = { ...values };
        req.type = req.type;
        req.ten_30 = req.ten_30 ? req.ten_30.value : undefined;
        req.product_name = req.product_name ? req.product_name.value : undefined;
        req.holiday = req.holiday === 1 ? true : false;

        req.weight = parseFloat(req.weight).toString();
        req.customer_mobile = Number(req.customer_mobile);
        req.shipper_mobile = Number(req.shipper_mobile);
        req.consignee_mobile = Number(req.consignee_mobile);
        req.change_shipper = req.change_shipper.toString();
        req.provider = req.provider.value;

        req.oda_charge = parseFloat(req.oda_charge);
        req.pkg_charge = parseFloat(req.pkg_charge);
        req.d_ice_charge = parseFloat(req.d_ice_charge);
        userDetails?.roleName !== roles.SUPER_ADMIN && userDetails?.roleName !== roles.BRANCH_ADMIN
          ? (req.subbranch = userDetails.subbranch)
          : (req.subbranch = req.subbranch ? req.subbranch.value : null);
        newTableData.forEach((a) => {
          a.prefix = a.awb_no[0]?.toUpperCase();
          a.provider = req.provider;
          a.destination = "INTERNATIONAL";
          nestedTables?.consignee_country.forEach((c) => {
            if (c.value == a.consignee_country) {
              a.destination = c.label;
            }
          });
        });
        req["awb_data"] = newTableData;

        delete req.id;
        delete req?.createdAt;
        constantValues.defaultFields.forEach((d) => delete req[d]);

        try {
          req.consignee_country = newTableData[0].consignee_country;
          req.consignee_address = newTableData[0].consignee_address;
          req.consignee_cgnee = newTableData[0].consignee_cgnee;
          req.consignee_city = newTableData[0].consignee_city;
          req.consignee_mobile = newTableData[0].consignee_mobile;
          req.consignee_pincode = newTableData[0].consignee_pincode;
          req.consignee_state = newTableData[0].consignee_state;
        } catch (c) {}

        console.log(req);
        //return;
        priceCheckMutate({ url: "/admin/booking/check-price", data: req });
        setBookingData(req);
      },
    });

  const {
    mutate: mutatePincode,
    isLoading: isPincodeloading,
  }: {
    mutate: UseMutateFunction<AxiosResponse<any, any>, unknown, any, unknown>;
    isLoading: any;
  } = useMutation((pincode) => GetCommanWay("/admin/address/" + pincode, {}, {}), {
    onSuccess: (data: any) => {
      if (data.status === "SUCCESS") {
        Object.keys(data.data).map((d) => {
          values[pincodeSection + "_" + d] = data.data[d];
        });
        return data.data;
      }
    },
    onError: (data: any) => {
      typeof data === "string" ? UseToast(data, "error") : UseToast(data.message, "error");
    },
  });

  const {
    mutate: mutatePickup,
    isLoading: isPickuploading,
  }: {
    mutate: UseMutateFunction<AxiosResponse<any, any>, unknown, any, unknown>;
    isLoading: any;
  } = useMutation((pickup_code) => ListCommanWay("/admin/pickup/list", { pickup_code }, {}), {
    onSuccess: (data: any) => {
      if (data.status === "SUCCESS") {
        console.log(" data.data", data.data);

        data.data.data.map((d) => {
          Object.keys(d).map((obj) => {
            if (!["type", "createdAt"].includes(obj)) values[obj] = d[obj];

            if (obj === "quantity" && Number(d[obj]) >= 0) {
              fetchDBInputData().then((value) => {
                value && handleQuantityChange({ target: { value: d[obj] } });
              });
            }
          });
        });
        return data.data[0];
      }
    },
    onError: (data: any) => {
      typeof data === "string" ? UseToast(data, "error") : UseToast(data.message, "error");
    },
  });

  const {
    mutate: mutateCustomer,
    isLoading: isCustomerloading,
  }: {
    mutate: UseMutateFunction<AxiosResponse<any, any>, unknown, any, unknown>;
    isLoading: any;
  } = useMutation((code) => GetCommanWay("/admin/customer/get/" + code, {}, {}), {
    onSuccess: (data: any) => {
      if (data.status === "SUCCESS") {
        Object.keys(values).map((d) => {
          if (d.split("customer_")[1]) {
            values["customer_" + d.split("customer_")[1]] = data.data[d.split("customer_")[1]];
          }
        });
        return data.data;
      }
    },
    onError: (data: any) => {
      //typeof data === "string" ? UseToast(data, "error") : UseToast(data.message, "error");
    },
  });

  const {
    mutate: mutateBooking,
    isLoading: isBookingloading,
  }: {
    mutate: UseMutateFunction<AxiosResponse<any, any>, unknown, any, unknown>;
    isLoading: any;
  } = useMutation((code) => GetCommanWay("/admin/booking/" + code, {}, {}), {
    onSuccess: (data: any) => {
      if (data.status === "SUCCESS") {
        console.log("data.data", data.data);

        setNewTableData(data.data.awb_data);
        return data.data;
      }
    },
    onError: (data: any) => {
      typeof data === "string" ? UseToast(data, "error") : UseToast(data.message, "error");
    },
  });

  const {
    mutate: updateAwb,
    isLoading: isUpdateAwbloading,
  }: {
    mutate: UseMutateFunction<AxiosResponse<any, any>, unknown, any, unknown>;
    isLoading: any;
  } = useMutation(
    ({ data, bookingId }) =>
      UpdateBookingCommanWay({
        url: "/admin/awbdata/updateBulk",
        data,
        query: {
          assign_to_booking: bookingId,
        },
        options: {},
      }),
    {
      onSuccess: (data: any) => {
        if (data.status === "SUCCESS") {
          console.log("data.data", data.data);

          setNewTableData(data.data);
          return data.data;
        }
      },
      onError: (data: any) => {
        typeof data === "string" ? UseToast(data, "error") : UseToast(data.message, "error");
      },
    }
  );

  const fetchDBInputData = async () => {
    let proms = [];
    //console.log(JSON.stringify(nestedTables));
    if (JSON.stringify(nestedTables).length > 5) {
      return Promise.all(proms).then((x) => {
        return 1;
      });
    }
    console.log("asdadasdasdas");

    let nestedApiData = {};
    columnData.forEach((cd: any) => {
      if (cd.type == ADT.DB) {
        proms.push(
          new Promise((res, rej) => {
            ListCommanWay(`/admin/${cd.db}/list`, {}, { paginate: 1000 })
              .then((x) => {
                let data = [];
                if (x.status == "SUCCESS") {
                  data = x.data.data.map((d) => {
                    return {
                      value: cd.key == "consignee_country" ? d.country_code : cd?.needID === false ? d[cd.db_col] : d.id,
                      label: d[cd.db_col],
                    };
                  });
                  nestedApiData[cd.key] = data;
                  cd["options"] = data;
                }
                res(1);
              })
              .catch((c) => {
                res(0);
              });
          })
        );
      }
    });
    return Promise.all(proms).then((x) => {
      console.log("nestedApiData", nestedApiData);
      nestedApiData["placeholder"] = [1, 2, 3, 4, 5];
      setNestedTables(nestedApiData);
      return 1;
    });
  };

  const modeActions = () => {
    if (mode) {
      console.log("data", data);

      if (mode === "edit") {
        mutateBooking(data.id);
        Object.keys(data).map((obj) => {
          if (!["createdAt"].includes(obj)) values[obj] = data[obj];

          if (obj === "quantity" && Number(data[obj]) >= 0) {
            fetchDBInputData().then((value) => {
              value &&
                handleQuantityChange({
                  target: { value: data[obj] },
                });
            });
          }
          if (obj === "weight" && Number(data[obj]) >= 0) {
            values[obj] = Number(data[obj]);
          }
          if (obj === "date") {
            console.log(
              "moment(new Date(data[obj])).format(YYYY-DD-MM)",
              data[obj],
              moment(data[obj], "DD-MM-YYYY HH:mm:ss").format("YYYY-DD-MM")
            );
            values[obj] = moment(data[obj], "DD-MM-YYYY HH:mm:ss").format("YYYY-MM-DD");
          }
        });
      } else if (mode === "view") {
        mutateBooking(data.id);

        Object.keys(data).map((obj) => {
          if (!["createdAt"].includes(obj)) values[obj] = data[obj];

          if (obj === "quantity" && Number(data[obj]) >= 0) {
            fetchDBInputData().then((value) => {
              value &&
                handleQuantityChange({
                  target: { value: data[obj] },
                });
            });
          }
          if (obj === "weight" && Number(data[obj]) >= 0) {
            values[obj] = Number(data[obj]);
          }
          if (obj === "date") {
            values[obj] = moment(data[obj], "DD-MM-YYYY HH:mm:ss").format("YYYY-MM-DD");
          }
        });
      }
    }
  };

  const [subbranchOptions, setSubBranchOptions] = useState<any>([{ value: 0, label: "Loading..." }]);

  const {
    error: subbranchError,
    isFetching: subbranchIsFetching,
    isError: subbranchIsError,
    isFetched: subbranchIsFetched,
    isLoading: subbranchIsLoading,
    refetch: subbranchRefetch,
  } = useQuery(["SubBranchesListApi"], () => ListCommanWay("/admin/subbranch/list", {}, { paginate: 1000 }), {
    keepPreviousData: true,
    onSuccess(data) {
      if (data.status == "SUCCESS") {
        let opt = [];
        data.data.data.forEach((p) => {
          opt.push({
            value: p.id,
            label: p.branch_name,
          });
        });
        setSubBranchOptions(opt);
      }
    },
  });

  useEffect(() => {
    modeActions();
    handleQuantityChange({
      target: { value: values.quantity },
    });
  }, []);
  console.log("-=======-----> newTableData", newTableData);

  const getInput: any = (type, label, value, name, isDisabled, width) => {
    let ip = <div></div>;

    switch (type) {
      case ADT.NUMBER:
        ip = (
          <div className={`inline-flex items-center gap-2 ${width ? width : "w-auto"}`}>
            <label className=" text-sm font-semibold text-gray-900">{label}</label>
            <input
              type="number"
              className={`${
                isDisabled ? "bg-gray-300" : ""
              } bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-1`}
              placeholder={label}
              name={name}
              value={values[name]}
              onBlur={handleBlur}
              max={name == "quantity" ? 99 : 9999999}
              onChange={(e) => {
                if (Number(e.target.value) >= 0) {
                  handleChange(e);
                  if (name === "quantity") {
                    fetchDBInputData().then((value) => {
                      value && handleQuantityChange(e);
                    });
                  }
                }
              }}
              onWheel={handleWheel}
              disabled={mode && (mode === "view" || mode === "edit") ? true : isDisabled}
            />
          </div>
        );
        break;
      case ADT.TEXT:
        ip = (
          <div className={`inline-flex items-center gap-2 ${width ? width : "w-auto"}`}>
            <label className="text-sm font-semibold text-gray-900 whitespace-nowrap">{label}</label>
            <input
              type="text"
              className={`bg-gray-50 border  text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-1 ${
                isDisabled ? "bg-gray-300 border-red" : "border-gray-300"
              }`}
              placeholder={label}
              name={name}
              value={values[name]}
              onBlur={async (e) => {
                handleBlur(e);
                if (label === "Pincode") {
                  setPincodeSection(e.target.name.split("_")[0]);
                  if (e.target.value.length == 6) {
                    const responseData = mutatePincode(e.target.value);
                  }
                }
                if (name === "customer_code") {
                  const responseData = mutateCustomer(e.target.value);
                }
                if (name === "pickup_code") {
                  const responseData = mutatePickup(e.target.value);
                }
              }}
              onChange={handleChange}
              disabled={mode && (mode === "view" || mode === "edit") ? true : isDisabled}
            />
          </div>
        );
        break;
      case ADT.DB:
        ip = (
          <div className={`inline-flex items-center gap-2 ${width ? width : "w-auto"}`}>
            <label className="text-sm font-semibold text-gray-900 whitespace-nowrap">{label}</label>
            <Select
              className={`${isDisabled ? "bg-gray-300" : ""} bg-gray-50 border-gray-300 text-gray-900 text-sm rounded-lg block w-full`}
              options={value.all}
              onBlur={handleBlur}
              onChange={(e) => {
                if (name === "branch_name") {
                  setIsBranchSet(e.value);
                }
                setFieldValue(name, e);
              }}
              classNamePrefix="book_dd"
              name={name}
              value={values[name]}
              isDisabled={mode && (mode === "view" || mode === "edit") ? true : isDisabled}
              styles={{
                control: (base) => ({
                  ...base,
                  height: 30,
                  minHeight: 30,
                }),
                indicatorsContainer: (base) => ({
                  ...base,
                  "& .book_dd__indicator": {
                    padding: "2px",
                  },
                }),
                input: (base) => ({
                  ...base,
                  margin: 0,
                  padding: 0,
                }),
                valueContainer: (base) => ({
                  ...base,
                  paddingTop: 0,
                  paddingBottom: 0,
                }),
              }}
            />
          </div>
        );
        break;
      case ADT.TEXTAREA:
        break;
      case ADT.DATETIME:
        break;
      case ADT.DATE:
        ip = (
          <div className={`inline-flex items-center gap-2 ${width ? width : "w-auto"}`}>
            <label className="text-sm font-semibold text-gray-900 whitespace-nowrap">{label}</label>
            <input
              type="date"
              className={`${
                isDisabled ? "bg-gray-300" : ""
              } bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-1`}
              placeholder={label}
              name={name}
              value={values[name]}
              onBlur={handleBlur}
              onChange={handleChange}
              disabled={mode && (mode === "view" || mode === "edit") ? true : isDisabled}
            />
          </div>
        );
        break;
      case ADT.FILE:
        break;
      default:
        ip = <></>;
        break;
    }
    return ip;
  };
  return (
    <>
      <h1 className="text-center mb-4 mt-3 text-lg font-semibold underline">ADD INTERNATIONAL BOOKING</h1>

      <form
        onSubmit={(e) => {
          e.preventDefault();
          handleSubmit(e);
        }}
      >
        <div className="grid grid-cols-2 sm:grid-cols-1 gap-1 px-4">
          <div className="flex gap-y-1 gap-x-4 flex-wrap border p-2 rounded-2xl bg-emerald-400 col-span-2 sm:col-span-1">
            <div className="w-full flex justify-between items-center">
              <div className="flex">
                <div className="w-full font-semibold underline">Booking Form</div>
                <div className="flex">
                  {Object.keys(userDetails).length &&
                  (userDetails?.roleName === roles.SUPER_ADMIN || userDetails?.roleName === roles.BRANCH_ADMIN)
                    ? getInput(ADT.DB, "", { sel: subbranchOpt, all: subbranchOptions }, "subbranch", isDisabled, "w-36")
                    : null}
                </div>
              </div>
            </div>

            {values.type === "PU" && getInput(ADT.TEXT, "Reference Code", "", "pickup_code", isDisabled, "w-[calc(42%-6px)]")}
            {getInput(ADT.DATE, "Booking Date", "", "date", isDateDisabled, "w-52")}
            {/* <div className="flex flex-col flex-1 w-full  gap-2"> */}
            {getInput(ADT.NUMBER, "Quantity", "", "quantity", isDisabled, "w-32")}

            {getInput(ADT.NUMBER, "Weight(KG)", "", "weight", isDisabled, "w-40")}
            {/* </div> */}
            {getInput(ADT.TEXT, "Customer (CODE)", "", "customer_code", isDisabled, "w-52")}

            {getInput(ADT.TEXT, "Remarks", "", "remarks", isDisabled, "w-64")}

            {getInput(
              ADT.DB,
              "Provider",
              {
                sel: providerOpt,
                all: [
                  { label: "UPS SAVER", value: "UPS SAVER" },
                  { label: "DHL", value: "DHL" },
                  { label: "FEDEX EXPORT", value: "FEDEX EXPORT" },
                  { label: "ARAMEX EXPORT", value: "ARAMEX EXPORT" },
                  { label: "Aramex UAE", value: "Aramex UAE" },
                ],
              },
              "provider",
              isDisabled,
              "w-64"
            )}
          </div>

          <div className="flex gap-1 flex-wrap border p-2 rounded-2xl bg-gray-200 hidden">
            <div className="w-full font-semibold underline">Consignee Information</div>
            {getInput(ADT.TEXT, "Mobile", "", "consignee_mobile", isDisabled, "w-[calc(33.33%-6px)]")}
            {getInput(ADT.TEXT, "Pincode", "", "consignee_pincode", isDisabled, "w-[calc(33.33%-6px)]")}
            {getInput(ADT.TEXT, "Cgnee", "", "consignee_cgnee", isDisabled, "w-[calc(33.33%-6px)]")}

            {getInput(ADT.TEXT, "City", "", "consignee_city", true, "w-[calc(33.33%-6px)]")}
            {getInput(ADT.TEXT, "State", "", "consignee_state", true, "w-[calc(33.33%-6px)]")}

            {getInput(ADT.TEXT, "Country", "INDIA", "consignee_country", true, "w-[calc(33.33%-6px)]")}

            {getInput(ADT.TEXT, "Address", "", "consignee_address", isDisabled, "w-full")}
          </div>

          <div className="flex gap-1 flex-wrap border p-2 rounded-2xl bg-gray-200">
            <div className="w-full font-semibold underline">Customer Information</div>
            {getInput(ADT.TEXT, "Mobile", "", "customer_mobile", true, "w-[calc(33.33%-6px)]")}
            {getInput(ADT.TEXT, "Pincode", "", "customer_pincode", true, "w-[calc(33.33%-6px)]")}
            {getInput(ADT.TEXT, "S/H", "", "customer_sh", true, "w-[calc(33.33%-6px)]")}

            {getInput(ADT.TEXT, "City", "", "customer_city", true, "w-[calc(33.33%-6px)]")}
            {getInput(ADT.TEXT, "State", "", "customer_state", true, "w-[calc(33.33%-6px)]")}
            {getInput(ADT.TEXT, "Country", "INDIA", "customer_country", true, "w-[calc(33.33%-6px)]")}

            {getInput(ADT.TEXT, "Address", "", "customer_address", true, "w-full")}
          </div>

          <div className="flex gap-1 flex-wrap border p-2 rounded-2xl bg-gray-200">
            <div className="w-full font-semibold underline">Shipper Information</div>
            {getInput(ADT.TEXT, "Mobile", "", "shipper_mobile", isDisabled, "w-[calc(33.33%-6px)]")}
            {getInput(ADT.TEXT, "Pincode", "", "shipper_pincode", isDisabled, "w-[calc(33.33%-6px)]")}
            {getInput(ADT.TEXT, "S/H", "", "shipper_sh", isDisabled, "w-[calc(33.33%-6px)]")}

            {getInput(ADT.TEXT, "City", "", "shipper_city", true, "w-[calc(33.33%-6px)]")}
            {getInput(ADT.TEXT, "State", "", "shipper_state", true, "w-[calc(33.33%-6px)]")}
            {getInput(ADT.TEXT, "Country", "INDIA", "shipper_country", true, "w-[calc(33.33%-6px)]")}

            {getInput(ADT.TEXT, "PAN", "", "shipper_pan", isDisabled, "w-[calc(50%-6px)]")}
            {getInput(ADT.TEXT, "GST", "", "shipper_gst_no", isDisabled, "w-[calc(50%-6px)]")}

            {getInput(ADT.TEXT, "Address", "", "shipper_address", isDisabled, "w-full")}

            <div className={`inline-flex items-center gap-2 w-full hidden`}>
              <input
                type="checkbox"
                className={` bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-auto p-2.5`}
                name="change_shipper"
                checked={values.change_shipper}
                onBlur={(e) => {
                  setFieldValue(e.target.name, e.target.checked);
                }}
                onChange={(e) => {
                  setFieldValue(e.target.name, e.target.checked);
                }}
                disabled={mode && (mode === "view" || mode === "edit") ? true : isDisabled}
              />
              <label className="text-sm font-semibold text-gray-900">Change Shipper</label>
            </div>
          </div>
        </div>

        <div className="mt-4 text-right px-4">
          {mode && mode === "view" ? (
            <button
              type="button"
              onClick={() => navigate(-1)}
              className="rounded-md px-4 py-2 text-sm font-medium text-white bg-green-500 border border-gray-200 hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-2 focus:ring-blue-700 focus:text-blue-700"
            >
              Go Back
            </button>
          ) : mode && mode === "edit" ? (
            <button
              type="submit"
              className="rounded-md px-4 py-2 text-sm font-medium text-white bg-green-500 border border-gray-200 hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-2 focus:ring-blue-700 focus:text-blue-700"
            >
              Update
            </button>
          ) : (
            <button
              type="submit"
              className="rounded-md px-4 py-2 text-sm font-medium text-white bg-green-500 border border-gray-200 hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-2 focus:ring-blue-700 focus:text-blue-700"
            >
              Save
            </button>
          )}
        </div>
        <div className="flex flex-col flex-1 overflow-x-auto mx-4 my-4">
          <IntlBookingInputTable
            columnData={columnData}
            tableData={newTableData}
            setNewTableData={setNewTableData}
            customClasses="flex-1"
            mode={mode}
          />
        </div>
      </form>
    </>
  );
};

export default AddIntlBooking;
