import React, { useEffect, useState } from "react";
import { ErrorMessage } from "../../components/ErrorMessage";
import { UseMutateFunction, useMutation, useQuery } from "@tanstack/react-query";
import { ListCommanWay } from "API/commonApi";
import { Link } from "react-router-dom";
import { AfDataType } from "utils/TableColumnData";
import moment from "moment";
import { AxiosResponse } from "axios";
import { Popover, Transition } from "@headlessui/react";
import { ChevronDownIcon } from "@heroicons/react/24/outline";

export type AfTableProps = Partial<{
  errors: any[];
  columnData: any[];
  apiUrl: string;
  isDataAdded: any;
  query: any;
  responseData: string;
  handleEdit: Function;
  handleView: Function;
  handleCustom: Function;
  customBtnText?: string;
  fetchedData?: any;
  setFetchedData?: any;
  customRenderFunction?: Function;
}>;

const PickupDatatable: React.FC<AfTableProps> = ({
  errors = [],
  columnData = [],
  apiUrl = "/",
  isDataAdded = false,
  handleEdit = (id) => {},
  handleView = (id) => {},
  handleCustom = (id) => {},
  customBtnText = "",
  query = {},
  responseData = "",
  fetchedData = null,
  setFetchedData = null,
  customRenderFunction = undefined,
}) => {
  const [tableData, setTableData] = useState<any>([]);

  const [currentPage, setCurrentPage] = useState<number>(1);
  const [perPage, setPerPageCount] = useState<number>(25);
  const [pageCount, setPageCount] = useState<number>(1);
  const [itemCount, setItemCount] = useState<number>(1);
  const [foundAwbs, setFoundAwbs] = useState<any>({});

  //const [includes, setIncludes] = useState<any>([]);
  let incAry = [];
  columnData.forEach((c) => {
    if (c.db_col) {
      incAry.push("_" + c.key);
    }
  });
  //setIncludes(incAry);

  const { error, isFetching, isError, isFetched, isLoading, refetch } = useQuery(
    [apiUrl.replaceAll("/", "__")],
    () =>
      ListCommanWay(apiUrl, Object.keys(query).length > 0 ? query : {}, {
        include: incAry,
        order: [["id", "desc"]],
        ...(currentPage !== 1 ? { page: currentPage } : null),
      }),
    {
      keepPreviousData: true,
      onSuccess(data) {
        if (data.status == "SUCCESS") {
          console.log("data.data", data.data);

          if (setFetchedData) {
            setFetchedData(data[responseData]);
          }
          if (responseData) {
            setTableData(data[responseData]);
            setCurrentPage(data[responseData]?.currentPage);
            setPerPageCount(data[responseData]?.perPage);
            setPageCount(data[responseData]?.pageCount);
            setItemCount(data[responseData]?.itemCount);
            return;
          }

          let pickupCodes = [];
          data.data.data.forEach((p) => {
            pickupCodes.push(p.pickup_code);
          });
          mutate(pickupCodes);

          setTableData(data.data.data);
          setCurrentPage(data.data.paginator?.currentPage);
          setPerPageCount(data.data.paginator?.perPage);
          setPageCount(data.data.paginator?.pageCount);
          setItemCount(data.data.paginator?.itemCount);
        } else {
          console.log("asadssad");

          setTableData([]);
          setCurrentPage(1);
          setPerPageCount(25);
          setPageCount(1);
          setItemCount(1);
        }
      },
      onError: (data) => {
        setTableData([]);
        setCurrentPage(1);
        setPerPageCount(25);
        setPageCount(1);
        setItemCount(1);
      },
    }
  );

  const { mutate: mutate }: any = useMutation((codes) => ListCommanWay("/admin/pickup/list", { findAwbInPickup: 1, codes }, {}), {
    onSuccess: (data: any) => {
      if (data.status == "SUCCESS") {
        console.log("findAwbInPickup", data.data);
        let awbs: any = {};
        data.data.forEach((a) => {
          if (!awbs[a.pickup_code]) {
            awbs[a.pickup_code] = [];
          }
          awbs[a.pickup_code].push(a.awb_no);
        });
        setFoundAwbs(awbs);
      }
    },
    onError: (data: any) => {},
  });

  const renderSwitch = (param) => {
    switch (param) {
      case "foo":
        return "bar";
      default:
        return "foo";
    }
  };

  useEffect(() => {
    //console.log("fetchedData", fetchedData);
    refetch();
  }, [fetchedData]);
  //console.log("========> fetchedData", fetchedData, "currentPage", currentPage);

  useEffect(() => {
    refetch();
  }, [isDataAdded, currentPage]);

  return (
    <div className="p-5">
      <div className="flex overflow-visible">
        <table className="w-full text-sm text-left text-gray-500 border-r border-t">
          <thead className="text-xs text-gray-700 uppercase bg-gray-50 ">
            <tr>
              {columnData.map((c) => {
                return (
                  c.view && (
                    <th scope="col" className={`px-2 py-3 border-l border-b ${c.css ? c.css : ''}`} key={c.label + "data-tbl"}>
                      <div className="flex items-center">
                        {c.label}
                        {c.sort && (
                          <a href="#">
                            <svg
                              className="w-3 h-3 ml-1.5"
                              aria-hidden="true"
                              xmlns="http://www.w3.org/2000/svg"
                              fill="currentColor"
                              viewBox="0 0 24 24"
                            >
                              <path d="M8.574 11.024h6.852a2.075 2.075 0 0 0 1.847-1.086 1.9 1.9 0 0 0-.11-1.986L13.736 2.9a2.122 2.122 0 0 0-3.472 0L6.837 7.952a1.9 1.9 0 0 0-.11 1.986 2.074 2.074 0 0 0 1.847 1.086Zm6.852 1.952H8.574a2.072 2.072 0 0 0-1.847 1.087 1.9 1.9 0 0 0 .11 1.985l3.426 5.05a2.123 2.123 0 0 0 3.472 0l3.427-5.05a1.9 1.9 0 0 0 .11-1.985 2.074 2.074 0 0 0-1.846-1.087Z" />
                            </svg>
                          </a>
                        )}
                      </div>
                      {c.filter && (
                        <>
                          {" "}
                          <input
                            type="text"
                            className="block p-1 text-sm text-gray-900 border border-gray-300 rounded-lg bg-gray-50 focus:ring-blue-500 focus:border-blue-500 "
                            placeholder="Search"
                          />
                        </>
                      )}
                    </th>
                  )
                );
              })}
            </tr>
          </thead>

          <tbody>
            {tableData.map((rd, j) => {
              return (
                <tr className="bg-white border-b text-black-900" key={"tbl-tr-" + j}>
                  {columnData.map((cd, i) => {
                    //console.log(cd.key,cd.render);
                    if (cd.key == "inv") {
                      //console.log(cd.render(rd[cd.key]));
                    }

                    let bgColor = "";
                    if (cd.bg_color_key) {
                      let index = columnData.findIndex((item) => item.key == cd.bg_color_key);
                      let bgColorAry = columnData[index].bg_color;
                      bgColor = bgColorAry[rd[cd.bg_color_key]];
                    }

                    return (
                      cd.view && (
                        <td className={`border-l px-2 py-1 bg-${bgColor}`} key={"tbl-td-" + i}>
                          {cd.type == AfDataType.CUSTOM ? (
                            customRenderFunction !== undefined ? (
                              customRenderFunction(rd)
                            ) : (
                              <button className="text-dark1" onClick={() => handleCustom(rd)}>
                                {customBtnText}
                              </button>
                            )
                          ) : cd.type == AfDataType.EDIT ? (
                            <button className="text-dark1" onClick={() => handleEdit(rd)}>
                              Edit
                            </button>
                          ) : cd.type == AfDataType.VIEW ? (
                            <button className="text-dark1" onClick={() => handleView(rd)}>
                              View
                            </button>
                          ) : cd.type == AfDataType.DB ? (
                            <>{rd["_" + cd.key] ? rd["_" + cd.key][cd.db_col] : "-"}</>
                          ) : cd.type == AfDataType.SELECT ? (
                            <>
                              {cd.options.map((o) => {
                                return rd[cd.key] == o.value ? o.label : "";
                              })}
                            </>
                          ) : cd.type == AfDataType.BOOLEAN ? (
                            <>{rd[cd.key] === true ? "true" : "false"}</>
                          ) : cd.type == AfDataType.DATE ? (
                            <>{moment(rd[cd.key], cd.dateFormat ? cd.dateFormat : "DD-MM-YYYY").format("DD-MM-YYYY")}</>
                          ) : cd.type == AfDataType.DATETIME ? (
                            <>{moment(rd[cd.key], cd.dateFormat ? cd.dateFormat : "DD-MM-YYYY").format("DD-MM-YYYY hh:mm")}</>
                          ) : cd.render ? (
                            cd.render(rd[cd.key], rd)
                          ) : cd.key == "foundAwbs" ? (
                            <>
                              <div className="flex gap-2">
                                {foundAwbs[rd.pickup_code]?.length || 0}
                                {foundAwbs[rd.pickup_code]?.length > 0 && (
                                  <Popover>
                                    {({ open }) => (
                                      <>
                                        <Popover.Button className={"flex items-center"}>
                                          Show
                                          <ChevronDownIcon className="ui-open:rotate-180 ui-open:transform h-4" />
                                        </Popover.Button>
                                        <Transition
                                          show={open}
                                          enter="transition duration-100 ease-out"
                                          enterFrom="transform scale-95 opacity-0"
                                          enterTo="transform scale-100 opacity-100"
                                          leave="transition duration-75 ease-out"
                                          leaveFrom="transform scale-100 opacity-100"
                                          leaveTo="transform scale-95 opacity-0"
                                        >
                                          <Popover.Panel
                                            className={
                                              "absolute bg-white p-2 shadow-2xl border-2 border-secondary1 rounded-md -translate-x-full -translate-y-full flex flex-col gap-1"
                                            }
                                          >
                                            {foundAwbs[rd.pickup_code].map((p) => {
                                              return (
                                                <a target="_blank" className="underline hover:bg-blue-400 px-2" href={`/pod/tracking?awb=${p}`}>
                                                  {p}
                                                </a>
                                              );
                                            })}
                                          </Popover.Panel>
                                        </Transition>
                                      </>
                                    )}
                                  </Popover>
                                )}
                              </div>
                            </>
                          ) : (
                            cd.key == 'sr_no' ? (j+1) : rd[cd.key]
                          )}
                        </td>
                      )
                    );
                  })}
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>

      <nav className="flex items-center justify-between pt-4" aria-label="Table navigation">
        <span className="text-sm font-normal text-gray-500">
          Total: <span className="font-semibold text-gray-900">{itemCount}</span>
        </span>
        <ul className="inline-flex -space-x-px text-sm h-8">
          <li>
            <div
              onClick={() => setCurrentPage((prevData) => (prevData > 1 ? prevData - 1 : prevData))}
              className="flex items-center justify-center px-3 h-8 ml-0 leading-tight text-gray-500 bg-white border border-gray-300 rounded-l-lg hover:bg-gray-100 hover:text-gray-700 "
            >
              Previous
            </div>
          </li>
          {[pageCount].map((p) => {
            //console.log("======> p", p);
            return currentPage === p ? (
              <li key={"paginate-" + p}>
                <a
                  href="#"
                  aria-current="page"
                  className="flex items-center justify-center px-3 h-8 text-blue-600 border border-gray-300 bg-blue-50 hover:bg-blue-100 hover:text-blue-700"
                >
                  {p}
                </a>
              </li>
            ) : (
              <li key={"paginate-" + p}>
                <a
                  href="#"
                  className="flex items-center justify-center px-3 h-8 leading-tight text-gray-500 bg-white border border-gray-300 hover:bg-gray-100 hover:text-gray-700 "
                >
                  {currentPage}
                </a>
              </li>
            );
          })}
          <li>
            <div
              // href="#"
              onClick={() => setCurrentPage((prevData) => (prevData < pageCount ? prevData + 1 : prevData))}
              className="flex items-center justify-center px-3 h-8 leading-tight text-gray-500 bg-white border border-gray-300 rounded-r-lg hover:bg-gray-100 hover:text-gray-700 "
            >
              Next
            </div>
          </li>
        </ul>
      </nav>
      <ErrorMessage errors={errors} />
    </div>
  );
};

export { PickupDatatable };
